import React from 'react';

import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';

import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';
import UserProfileImage from 'components/molecules/UserProfileImage';

import { GetPostPurchaseHistoryResponse } from 'api/PostReportBase';
import { AppDispatch, RootState } from 'redux/store';

import { ETextVariant } from 'entities/components';

type PostPurchaseTableProps = {
    postPurchaseHistoryData: GetPostPurchaseHistoryResponse[];
    loading?: boolean;
};

const PostPurchaseTable = (props: PostPurchaseTableProps): JSX.Element => {
    const {
        postPurchaseHistoryData,
        loading,
    } = props;
    const postReportsTableHeader = ['User', 'User Tier', 'Purchase Date'];

    const renderTableBody = () => {
        if (!postPurchaseHistoryData || postPurchaseHistoryData.length === 0) {
            return (
                <tr>
                    <td
                        colSpan={postReportsTableHeader.length}
                        className='py-xl text-center'
                    >
                        <Text variant={ETextVariant.default}>
                            There is no data! try adjusting the filters.
                        </Text>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return (
                <tr>
                    <td
                        colSpan={postReportsTableHeader.length}
                        className='py-xl text-center'
                    >
                        <div className='flex items-center justify-center gap-default'>
                            <RotatingLines
                                strokeColor='white'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='100'
                                visible
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return postPurchaseHistoryData?.map((purchase) => {
            return (
                <tr key={purchase._id}>
                    <td className='p-md'>
                        <div className='flex items-center gap-md'>
                            <div className='flex size-[50px] h-full 2xl:size-[60px] 3xl:size-[80px]'>
                                <UserProfileImage
                                    profileImageUrl={purchase.userDetails.profileImageUrl}
                                    userTier={purchase.userDetails.userTier}
                                    className='2xl:!p-[0.5rem]'
                                />
                            </div>
                            <div className='flex flex-col'>
                                <Text
                                    variant={ETextVariant.default}
                                    className='font-general-bold text-base 2xl:text-xl'
                                >
                                    {purchase.userDetails.displayName}
                                </Text>
                                <Text variant={ETextVariant.subheader}>
                                    {purchase.userDetails.username}
                                </Text>
                            </div>
                        </div>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{purchase.userDetails.userTier}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>
                            {dayjs(purchase.createdAt).format('DD MMM YYYY')}
                        </Text>
                        <Text>
                            {dayjs(purchase.createdAt).format('h:mm a')}
                        </Text>
                    </td>
                </tr>
            );
        });
    };

    return (
        <Table
            className='table-auto'
            tableHeader={postReportsTableHeader}
            tableData={renderTableBody()}
            expandedColumns={0}
            expandedWidth='w-2/3'
        />
    );
};

PostPurchaseTable.defaultProps = {
    loading: false,
};

const mapStateToProps = (state: RootState) => ({
    // uiConfirmReportModalIsOpen: Selectors.uiConfirmReportModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PostPurchaseTable);
