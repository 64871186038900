import { createSlice } from '@reduxjs/toolkit';

import { EmptyIndexedResponse } from 'entities/report';

import {
    DeleteUserCommentParamsPayload,
    GetRepLeaderboardParamsPayload,
    GetRepLeaderboardResponsePayload,
    GetTotalUsersParamsPayload,
    GetTotalUsersResponsePayload,
    GetUserCommentsParamsPayload,
    GetUserCommentsResponsePayload,
    GetUserGradesParamsPayload,
    GetUserGradesResponsePayload,
    GetUserPostsParamsPayload,
    GetUserPostsResponsePayload,
    GetUserReportParamsPayload,
    GetUserReportResponsePayload,
    GetUserSummaryParamsPayload,
    GetUserSummaryResponsePayload,
    SetUserCommissionParamsPayload,
    SetUserToBanParamsPayload,
    SetUserToUnbanParamsPayload,
    StringOrNullPayload,
    UserReportReduxState,
} from './types';

const initialState: UserReportReduxState = {
    actions: {
        getUserReport: false,
        getTotalUsers: false,
        getUserSummary: false,
        getUserPosts: false,
        getUserComments: false,
        getUserGrades: false,
        getRepLeaderboard: false,
        setUserCommisionRate: false,
        setUserToBan: false,
        setUserToUnban: false,
        deleteUserComment: false,
    },
    userReport: EmptyIndexedResponse,
    totalUsers: {
        totalUsers: 0,
        newUsers: 0,
        activeUsers: 0,
        newUsersData: [],
        activeUsersData: [],
    },
    userSummary: {
        _id: '',
        username: '',
        displayName: '',
        followersCount: 0,
        followingCount: 0,
        topics: [],
    },
    userPosts: EmptyIndexedResponse,
    userComments: EmptyIndexedResponse,
    userGrades: EmptyIndexedResponse,
    repLeaderboard: EmptyIndexedResponse,
    error: {
        getUserReport: '',
        getTotalUsers: '',
        getUserSummary: '',
        getUserPosts: '',
        getUserComments: '',
        getUserGrades: '',
        getRepLeaderboard: '',
        setUserCommisionRate: '',
        setUserToBan: '',
        setUserToUnban: '',
        deleteUserComment: '',
    },
};

const userReportSlice = createSlice({
    name: 'userReport',
    initialState,
    reducers: {
        userReportResetActions: (state) => {
            state.actions = {
                getUserReport: false,
                getTotalUsers: false,
                getUserSummary: false,
                getUserPosts: false,
                getUserComments: false,
                getUserGrades: false,
                getRepLeaderboard: false,
                setUserCommisionRate: false,
                setUserToBan: false,
                setUserToUnban: false,
                deleteUserComment: false,
            };
        },
        userReportResetErrors: (state) => {
            state.error = {
                getUserReport: '',
                getTotalUsers: '',
                getUserSummary: '',
                getUserPosts: '',
                getUserComments: '',
                getUserGrades: '',
                getRepLeaderboard: '',
                setUserCommisionRate: '',
                setUserToBan: '',
                setUserToUnban: '',
                deleteUserComment: '',
            };
        },
        userReportGetUserReportAttempt: (state, _action: GetUserReportParamsPayload) => {
            state.actions.getUserReport = true;
            state.error.getUserReport = '';
        },
        userReportGetUserReportSuccess: (state, action: GetUserReportResponsePayload) => {
            state.actions.getUserReport = false;
            if (action.payload) {
                state.userReport = action.payload;
            }
        },
        userReportGetUserReportFailure: (state, action: StringOrNullPayload) => {
            state.actions.getUserReport = false;
            if (action.payload) {
                state.error.getUserReport = action.payload;
            }
        },
        userReportGetTotalUsersAttempt: (state, _action: GetTotalUsersParamsPayload) => {
            state.actions.getTotalUsers = true;
            state.error.getTotalUsers = '';
        },
        userReportGetTotalUsersSuccess: (state, action: GetTotalUsersResponsePayload) => {
            state.actions.getTotalUsers = false;
            if (action.payload) {
                state.totalUsers = action.payload;
            }
        },
        userReportGetTotalUsersFailure: (state, action: StringOrNullPayload) => {
            state.actions.getTotalUsers = false;
            if (action.payload) {
                state.error.getTotalUsers = action.payload;
            }
        },
        userReportGetUserSummaryAttempt: (state, _action: GetUserSummaryParamsPayload) => {
            state.actions.getUserSummary = true;
            state.error.getUserSummary = '';
        },
        userReportGetUserSummarySuccess: (state, action: GetUserSummaryResponsePayload) => {
            state.actions.getUserSummary = false;
            if (action.payload) {
                state.userSummary = action.payload;
            }
        },
        userReportGetUserSummaryFailure: (state, action: StringOrNullPayload) => {
            state.actions.getUserSummary = false;
            if (action.payload) {
                state.error.getUserSummary = action.payload;
            }
        },
        userReportGetUserPostsAttempt: (state, _action: GetUserPostsParamsPayload) => {
            state.actions.getUserPosts = true;
            state.error.getUserPosts = '';
        },
        userReportGetUserPostsSuccess: (state, action: GetUserPostsResponsePayload) => {
            state.actions.getUserPosts = false;
            if (action.payload) {
                state.userPosts = action.payload;
            }
        },
        userReportGetUserPostsFailure: (state, action: StringOrNullPayload) => {
            state.actions.getUserPosts = false;
            if (action.payload) {
                state.error.getUserPosts = action.payload;
            }
        },
        userReportGetUserCommentsAttempt: (state, _action: GetUserCommentsParamsPayload) => {
            state.actions.getUserComments = true;
            state.error.getUserComments = '';
        },
        userReportGetUserCommentsSuccess: (state, action: GetUserCommentsResponsePayload) => {
            state.actions.getUserComments = false;
            if (action.payload) {
                state.userComments = action.payload;
            }
        },
        userReportGetUserCommentsFailure: (state, action: StringOrNullPayload) => {
            state.actions.getUserComments = false;
            if (action.payload) {
                state.error.getUserComments = action.payload;
            }
        },
        userReportGetUserGradesAttempt: (state, _action: GetUserGradesParamsPayload) => {
            state.actions.getUserGrades = true;
            state.error.getUserGrades = '';
        },
        userReportGetUserGradesSuccess: (state, action: GetUserGradesResponsePayload) => {
            state.actions.getUserGrades = false;
            if (action.payload) {
                state.userGrades = action.payload;
            }
        },
        userReportGetUserGradesFailure: (state, action: StringOrNullPayload) => {
            state.actions.getUserGrades = false;
            if (action.payload) {
                state.error.getUserGrades = action.payload;
            }
        },
        userReportGetRepLeaderboardAttempt: (state, _action: GetRepLeaderboardParamsPayload) => {
            state.actions.getRepLeaderboard = true;
            state.error.getRepLeaderboard = '';
        },
        userReportGetRepLeaderboardSuccess: (state, action: GetRepLeaderboardResponsePayload) => {
            state.actions.getRepLeaderboard = false;
            if (action.payload) {
                state.repLeaderboard = action.payload;
            }
        },
        userReportGetRepLeaderboardFailure: (state, action: StringOrNullPayload) => {
            state.actions.getRepLeaderboard = false;
            if (action.payload) {
                state.error.getRepLeaderboard = action.payload;
            }
        },
        userReportSetUserCommissionAttempt: (state, _action: SetUserCommissionParamsPayload) => {
            state.actions.setUserCommisionRate = true;
            state.error.setUserCommisionRate = '';
        },
        userReportSetUserCommissionSuccess: (state) => {
            state.actions.setUserCommisionRate = false;
        },
        userReportSetUserCommissionFailure: (state, action: StringOrNullPayload) => {
            state.actions.setUserCommisionRate = false;
            if (action.payload) {
                state.error.setUserCommisionRate = action.payload;
            }
        },
        userReportSetUserToBanAttempt: (state, _action: SetUserToBanParamsPayload) => {
            state.actions.setUserToBan = true;
            state.error.setUserToBan = '';
        },
        userReportSetUserToBanSuccess: (state) => {
            state.actions.setUserToBan = false;
        },
        userReportSetUserToBanFailure: (state, action: StringOrNullPayload) => {
            state.actions.setUserToBan = false;
            if (action.payload) {
                state.error.setUserToBan = action.payload;
            }
        },
        userReportSetUserToUnbanAttempt: (state, _action: SetUserToUnbanParamsPayload) => {
            state.actions.setUserToUnban = true;
            state.error.setUserToUnban = '';
        },
        userReportSetUserToUnbanSuccess: (state) => {
            state.actions.setUserToUnban = false;
        },
        userReportSetUserToUnbanFailure: (state, action: StringOrNullPayload) => {
            state.actions.setUserToUnban = false;
            if (action.payload) {
                state.error.setUserToUnban = action.payload;
            }
        },
        userReportDeleteUserCommentAttempt: (state, _action: DeleteUserCommentParamsPayload) => {
            state.actions.deleteUserComment = true;
            state.error.deleteUserComment = '';
        },
        userReportDeleteUserCommentSuccess: (state) => {
            state.actions.deleteUserComment = false;
        },
        userReportDeleteUserCommentFailure: (state, action: StringOrNullPayload) => {
            state.actions.deleteUserComment = false;
            if (action.payload) {
                state.error.deleteUserComment = action.payload;
            }
        },
    },
});

export type UserReportState = typeof initialState;

export default {
    actions: userReportSlice.actions,
    reducers: userReportSlice.reducer,
};
