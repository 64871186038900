import { GenericIndexedResponse, GetTopicsResponse } from 'api/TopicReportBase';

import { TopicReportState } from '.';

const getTopicsAttempting = (state: TopicReportState): boolean =>
    state.actions.getTopics || false;
const getTopicsError = (state: TopicReportState): string =>
    state.error.getTopics || '';
const getTopicsData = (state: TopicReportState): GenericIndexedResponse<GetTopicsResponse> =>
    state.topics;

const addTopicAttempting = (state: TopicReportState): boolean =>
    state.actions.addTopic || false;
const addTopicError = (state: TopicReportState): string =>
    state.error.addTopic || '';

const editTopicAttempting = (state: TopicReportState): boolean =>
    state.actions.editTopic || false;
const editTopicError = (state: TopicReportState): string =>
    state.error.editTopic || '';

const deleteTopicAttempting = (state: TopicReportState): boolean =>
    state.actions.deleteTopic || false;
const deleteTopicError = (state: TopicReportState): string =>
    state.error.deleteTopic || '';

export default {
    getTopicsAttempting,
    getTopicsError,
    getTopicsData,

    addTopicAttempting,
    addTopicError,

    editTopicAttempting,
    editTopicError,

    deleteTopicAttempting,
    deleteTopicError,
};
