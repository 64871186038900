/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import { FaChevronDown } from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';

import cn from 'lib/TailwindMerge';
import { ESelectVariant } from 'entities/components';

import Text from './Text';

interface Option {
    label: string;
    value: number | string;
}

interface SelectProps {
    options: Option[];
    defaultOption?: Option;
    onValueChange: (value: number | string) => void;
    variant?: ESelectVariant;
    placeholder?: string;
    className?: string;
    selectedClassName?: string;
    dropdownClassName?: string;
}

const Select = (props: SelectProps): JSX.Element => {
    const {
        options,
        defaultOption,
        onValueChange,
        variant,
        placeholder,
        className,
        selectedClassName,
        dropdownClassName,
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);

    useEffect(() => {
        setIsOpen(false);
    }, []);

    const handleOptionClick = (option: Option) => {
        setSelectedOption(option);
        setIsOpen(false);
        onValueChange(option.value);
    };

    const renderSelected = () => {
        if (variant === ESelectVariant.filter) {
            return (
                <div
                    className={cn(
                        'w-full rounded-input',
                        'border border-white',
                        'bg-glass filter backdrop-blur-glass',
                        'py-1.5 px-3',
                        'outline-none',
                    )}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className='flex items-center justify-between'>
                        <Text className={cn(selectedOption ? 'text-white' : 'text-slate-300')}>
                            {selectedOption?.label || defaultOption?.label || placeholder}
                        </Text>
                        <FiFilter size={20} color='white' />
                    </div>
                </div>
            );
        }

        return (
            <div
                className={cn(
                    'w-full rounded-full',
                    'border-2 border-white bg-transparent',
                    'py-2 px-3',
                    'outline-none',
                    className,
                )}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className='flex items-center justify-between'>
                    <Text
                        className={cn(
                            selectedOption ?? defaultOption ? 'text-white' : 'text-slate-300',
                            selectedClassName,
                        )}
                    >
                        {selectedOption?.label || defaultOption?.label || 'Select an option'}
                    </Text>
                    <FaChevronDown size={18} color='white' />
                </div>
            </div>
        );
    };

    return (
        <div className='relative w-full'>
            {renderSelected()}
            {isOpen && (
                <div
                    className={cn(
                        'absolute inset-x-0 top-full',
                        'flex flex-col gap-sm',
                        'bg-glass filter backdrop-blur-glass',
                        'border-2 border-white rounded-input',
                        'p-2',
                        'z-10',
                        dropdownClassName,
                    )}
                >
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className={cn(
                                'w-full rounded-input p-2',
                                'cursor-pointer',
                                'hover:bg-background',
                            )}
                            onClick={() => handleOptionClick(option)}
                        >
                            <Text>{option.label}</Text>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

Select.defaultProps = {
    defaultOption: null,
    variant: ESelectVariant.default,
    placeholder: '',
    className: '',
    selectedClassName: '',
    dropdownClassName: '',
};

export default Select;
