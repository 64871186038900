/* eslint-disable react/require-default-props */
import React, { InputHTMLAttributes, useState } from 'react';

import { IoIosEye, IoIosEyeOff, IoIosSearch } from 'react-icons/io';

import cn from 'lib/TailwindMerge';
import { EContainerVariant, EInputVariant } from 'entities/components';

import Container from './Container';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
    variant?: EInputVariant;
}

const Input = (props: InputProps): JSX.Element => {
    const {
        variant,
        className,
        type,
        ...otherProps
    } = props;

    const variantClasses = {
        [EInputVariant.default]: 'rounded-input bg-background p-2.5 w-full focus:outline-none text-sm text-color-primary',
        [EInputVariant.password]: 'rounded-input bg-background p-2.5 w-full focus:outline-none text-sm text-color-primary',
        [EInputVariant.searchbar]: 'bg-transparent py-[3px] 2xl:p-2 w-full focus:outline-none text-sm text-color-primary',
        [EInputVariant.checkbox]: 'flex size-[15px] accent-background',
        [EInputVariant.ghost]: 'bg-white p-sm font-general-bold rounded-[100px] text-center',
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleViewPassword = () => {
        setShowPassword(!showPassword);
    };
    const renderEyeIcon = () => {
        if (type === 'password') {
            return (
                <button
                    type='button'
                    onClick={handleViewPassword}
                    className={cn(
                        'absolute right-3 top-2',
                        'flex items-center justify-center',
                        'size-6 cursor-pointer',
                        'text-color-secondary',
                    )}
                >
                    {showPassword ? <IoIosEye size={20} /> : <IoIosEyeOff size={20} />}
                </button>
            );
        }

        return null;
    };

    if (variant === EInputVariant.checkbox) {
        return (
            <input
                type={type}
                className={cn(
                    variantClasses[EInputVariant.checkbox],
                    className,
                )}
                {...otherProps}
            />
        );
    }

    if (variant === EInputVariant.ghost) {
        return (
            <input
                type='number'
                className={cn(
                    variantClasses[EInputVariant.ghost],
                    className,
                )}
                {...otherProps}
            />
        );
    }

    if (variant === EInputVariant.searchbar) {
        return (
            <Container
                variant={EContainerVariant.glassBorder}
                className={cn(
                    'flex items-center justify-between',
                    'gap-sm px-sm',
                    'text-color-primary',
                    'rounded-input',
                    className,
                )}
            >
                <IoIosSearch className='size-[15px] 2xl:size-[30px]' />
                <input
                    type='text'
                    className={variantClasses[EInputVariant.searchbar]}
                    {...otherProps}
                />
            </Container>
        );
    }

    return (
        <Container
            variant={EContainerVariant.gradientBorder}
            className='relative w-full'
        >
            <input
                type={showPassword ? 'text' : type}
                className={cn(
                    variantClasses[variant ?? EInputVariant.default],
                    className,
                )}
                {...otherProps}
            />
            {variant === EInputVariant.password && renderEyeIcon()}
        </Container>
    );
};

export default Input;
