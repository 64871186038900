import React, { useEffect, useRef, useState } from 'react';

import throttle from 'lodash.throttle';
import { FaPlus } from 'react-icons/fa';
import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Paginator from 'components/molecules/Paginator';

import { GenericIndexedResponse, GetTopicsParams, GetTopicsResponse } from 'api/TopicReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { EButtonVariant, EInputVariant, ETextVariant } from 'entities/components';

import AddTopicModal from './components/AddTopicModal';
import DeleteTopicModal from './components/DeleteTopicModal';
import EditTopicModal from './components/EditTopicModal';
import TopicReportTable from './components/TopicReportTable';

type TopicProps = {
    getTopicsAttempting: boolean;
    getTopicsError: string;

    topics: GenericIndexedResponse<GetTopicsResponse>;

    setModalIsOpen: (state: boolean) => void;
    getTopics: (params: GetTopicsParams) => void;
};

const TopicReport = (props: TopicProps): JSX.Element => {
    const { getTopicsAttempting, getTopicsError, topics, setModalIsOpen, getTopics } = props;

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [page, setPage] = useState<number>(0);

    const throttledSetSearch = useRef(
        throttle(
            (searchWord: string) => {
                setSearchQuery(searchWord);
            },
            500,
            { leading: false },
        ),
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        throttledSetSearch.current(value);
    };

    useEffect(() => {
        getTopics({ index: page, searchQuery });
    }, [page, searchQuery]);

    useEffect(() => {
        setPage(0);
    }, [searchQuery]);

    useEffect(() => {
        setPage(topics.index);
    }, [topics.index]);

    return (
        <div className='flex flex-col gap-default 2xl:gap-xl'>
            <div className='flex w-full items-center justify-between'>
                <Text variant={ETextVariant.header} className='font-heading-bold text-[50px] 2xl:text-[60px]'>
                    TOPIC REPORT
                </Text>
                <Button
                    variant={EButtonVariant.default}
                    className='flex w-[200px] items-center gap-sm px-md'
                    onClick={() => setModalIsOpen(true)}
                >
                    <FaPlus />
                    <Text variant={ETextVariant.default} className='font-general-medium'>
                        New Topic
                    </Text>
                </Button>
            </div>
            <div className='flex flex-col gap-md'>
                <Input
                    className='w-[200px] 2xl:w-[300px]'
                    variant={EInputVariant.searchbar}
                    placeholder='Search...'
                    onChange={handleSearch}
                />
                <TopicReportTable topicReportData={topics.data} loading={false} />
                <Paginator
                    currentPage={page}
                    maxPage={(topics && topics.maxIndex !== 0 && topics.maxIndex) || 0}
                    onFirst={() => page !== 0 && setPage(0)}
                    onLast={() => topics.maxIndex !== 0 && setPage((topics && topics.maxIndex) || 0)}
                    onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                    onNext={() => topics.maxIndex !== 0 && setPage(page !== topics.maxIndex ? page + 1 : page)}
                />
                <AddTopicModal />
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    getTopicsAttempting: Selectors.topicReportGetTopicsAttempting(state),
    getTopicsError: Selectors.topicReportGetTopicsError(state),
    topics: Selectors.topicReportGetTopicsData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getTopics: (params: GetTopicsParams) => dispatch(Actions.topicReportGetTopicsAttempt(params)),
    setModalIsOpen: (state: boolean) => dispatch(Actions.uiSetAddTopicModalIsOpen(state)),
    logout: () => dispatch(Actions.authLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopicReport);
