import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAuthUser } from 'entities/auth';

import {
    AuthReduxState,
    ForgotPasswordParamsPayload,
    LoginActionParamsPayload,
    ResetPasswordParamsPayload,
    StringOrNullPayload,
    VerifyOtpParamsPayload,
} from './types';

const initialState: AuthReduxState = {
    actions: {
        login: false,
        startup: true,
        forgotPassword: false,
        verifyOtp: false,
        resetPassword: false,
    },
    authToken: '',
    userInfo: null,
    error: {
        login: '',
        startup: '',
        forgotPassword: '',
        verifyOtp: '',
        resetPassword: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authResetActions: (state) => {
            state.actions = initialState.actions;
        },
        authResetErrors: (state) => {
            state.error = initialState.error;
        },
        authStartupAttempt: (state) => {
            state.actions.startup = true;
            state.error.startup = '';
        },
        authStartupSuccess: (state) => {
            state.actions.startup = false;
        },
        authStartupFailure: (state, action: StringOrNullPayload) => {
            state.actions.startup = false;
            if (action.payload) state.error.startup = action.payload;
        },
        authLoginAttempt: (state, _action: LoginActionParamsPayload) => {
            state.actions.login = true;
            state.error.login = '';
        },
        authLoginSuccess: (state, action: PayloadAction<string>) => {
            state.actions.login = false;
            state.authToken = action.payload;
        },
        authLoginFailure: (state, action: StringOrNullPayload) => {
            state.actions.login = false;
            if (action.payload) {
                state.error.login = action.payload;
            }
        },
        authForgotPasswordAttempt: (state, _action: ForgotPasswordParamsPayload) => {
            state.actions.forgotPassword = true;
            state.error.forgotPassword = '';
        },
        authForgotPasswordSuccess: (state) => {
            state.actions.forgotPassword = false;
        },
        authForgotPasswordFailure: (state, action: StringOrNullPayload) => {
            state.actions.forgotPassword = false;
            if (action.payload) {
                state.error.forgotPassword = action.payload;
            }
        },
        authVerifyOtpAttempt: (state, _action: VerifyOtpParamsPayload) => {
            state.actions.verifyOtp = true;
        },
        authVertifyOtpSuccess: (state) => {
            state.actions.verifyOtp = false;
        },
        authVertifyOtpFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.verifyOtp = false;
            if (action.payload) {
                state.error.verifyOtp = action.payload;
            }
        },
        authResetPasswordAttempt: (state, _action: ResetPasswordParamsPayload) => {
            state.actions.resetPassword = true;
        },
        authResetPasswordSuccess: (state) => {
            state.actions.resetPassword = false;
        },
        authResetPasswordFailure: (state, action: StringOrNullPayload) => {
            state.actions.resetPassword = false;
            if (action.payload) {
                state.error.resetPassword = action.payload;
            }
        },
        authSetUserInfo: (state, action: PayloadAction<IAuthUser>) => {
            state.userInfo = action.payload;
        },
        authClearUserInfo: (state) => {
            state.userInfo = null;
        },
        authLogout: (state) => {
            state.actions.login = false;
            state.authToken = '';
        },
    },
});

export type AuthState = typeof initialState;

export default {
    actions: authSlice.actions,
    reducers: authSlice.reducer,
};
