import Gateway from 'api/types/Gateway';

import { GatewayResponse } from './types/types';

export type GenericParams<T> = {
    authToken: string;
    params: T;
};
export type GenericIndexedResponse<T> = {
    index: number;
    maxIndex: number;
    data: T[];
};
export type GenericResponse = string | undefined;

// Params
export type GetTopicsParams = {
    index: number;
    searchQuery?: string;
};

export type AddTopicParams = {
    name: string;
    description: string;
};

export type EditTopicParams = {
    topicId: string;
    name: string;
    description: string;
    enabled: boolean;
};

export type DeleteTopicParams = {
    topicId: string;
};

// Response
export type GetTopicsResponse = {
    name: string;
    description: string;
    enabled: boolean;
    _id: string;
    subscriberCount: number;
};

export type EditTopicResponse = {
    name: string;
    description: string;
    topicId: string;
    enabled: boolean;
};

export abstract class ITopicReportGateway extends Gateway {
    abstract getTopics(
        params: GenericParams<GetTopicsParams>,
    ): GatewayResponse<GenericIndexedResponse<GetTopicsResponse>>;

    abstract addTopic(params: GenericParams<AddTopicParams>): GatewayResponse<GenericResponse>;

    abstract editTopic(params: GenericParams<EditTopicParams>): GatewayResponse<GenericResponse>;

    abstract deleteTopic(params: GenericParams<DeleteTopicParams>): GatewayResponse<GenericResponse>;
}
