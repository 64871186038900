import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';

import {
    EditSubAdminAccessParams,
    GetSubAdminListParams,
    GetSubAdminListResponse,
    ISubAdminGateway,
    SetSubAdminAccessParams,
} from './SubAdminBase';
import { GenericParams, GenericResponse } from './SystemConfigurationBase';

export default class SubAdminGateway extends ISubAdminGateway {
    async getSubAdminList(params: GenericParams<GetSubAdminListParams>): GatewayResponse<GetSubAdminListResponse> {
        const response: ApiResponse<GetSubAdminListResponse> = await this.api.get(
            '/v1/a/subadmin/getSubAdminList',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async setSubAdminAccess(params: GenericParams<SetSubAdminAccessParams>): GatewayResponse<GenericResponse> {
        const response: ApiResponse<GenericResponse> = await this.api.post(
            '/v1/a/subadmin/setSubAdminAccess',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async editSubAdminAccess(params: GenericParams<EditSubAdminAccessParams>): GatewayResponse<GenericResponse> {
        const response: ApiResponse<GenericResponse> = await this.api.put(
            '/v1/a/subadmin/editSubAdminAccess',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }
}
