import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import throttle from 'lodash.throttle';
import { BsCashStack, BsCreditCard2Front } from 'react-icons/bs';
import { GiReceiveMoney } from 'react-icons/gi';
import { connect } from 'react-redux';

import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import Text from 'components/atoms/Text';
import CustomDateRangePicker from 'components/molecules/CustomDateRangePicker';
import Paginator from 'components/molecules/Paginator';

import {
    DateParams,
    GenericIndexedResponse,
    GetPaymentReportListingParams,
    GetPaymentReportListingResponse,
    GetTotalPaymentReportResponse,
} from 'api/PaymentReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { EInputVariant, ESelectVariant, ETextVariant, TDateFilterString } from 'entities/components';
import { EPaymentReportStatus } from 'entities/report';

import PaymentStatusCard from './components/index/PaymentStatusCard';
import PaymentTable from './components/index/PaymentTable';

type PaymentProps = {
    dateFilter: TDateFilterString;
    getTotalPaymentReportAttempting: boolean;
    getPaymentReportListingAttempting: boolean;

    getTotalPaymentReportError: string;
    getPaymentReportListingError: string;

    getTotalPaymentReport: (params: DateParams) => void;
    getPaymentReportListing: (params: GetPaymentReportListingParams) => void;

    setDateFilter: (date: TDateFilterString) => void;

    totalPaymentReport: GetTotalPaymentReportResponse;
    paymentReportListing: GenericIndexedResponse<GetPaymentReportListingResponse>;
};

const PaymentReport = (props: PaymentProps): JSX.Element => {
    const {
        getPaymentReportListing,
        getPaymentReportListingAttempting,
        getPaymentReportListingError,
        getTotalPaymentReport,
        getTotalPaymentReportAttempting,
        getTotalPaymentReportError,
        dateFilter,
        setDateFilter,
        totalPaymentReport,
        paymentReportListing,
    } = props;

    const [dateRangeFilter, setDateRangeFilter] = useState<[Date | null, Date | null]>([
        dayjs(dateFilter[0]).toDate(),
        dayjs(dateFilter[1]).toDate(),
    ]);
    const [startDate, endDate] = dateRangeFilter;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [statusFilter, setStatusFilter] = useState<EPaymentReportStatus>();

    const throttledSetSearch = useRef(
        throttle(
            (searchWord: string) => {
                setSearchQuery(searchWord);
            },
            500,
            { leading: false },
        ),
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        throttledSetSearch.current(value);
    };

    useEffect(() => {
        getPaymentReportListing({
            index: page,
            filter: statusFilter,
            searchQuery,
        });

        if (startDate && endDate) {
            getTotalPaymentReport({
                dateFrom: dayjs(startDate).startOf('day').utc().format(),
                dateTo: dayjs(endDate).endOf('day').utc().format(),
            });
            setDateFilter([dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]);
        }
    }, [endDate, statusFilter, searchQuery, page]);

    useEffect(() => {
        setPage(0);
    }, [endDate, statusFilter, searchQuery]);

    const handleValueChange = (value: number | string) => {
        if (value === 0) {
            setStatusFilter(undefined);
        } else {
            setStatusFilter(value as number);
        }
    };

    const paymentStatuses = [
        {
            label: 'All',
            value: 0,
        },
        {
            label: 'Pending',
            value: EPaymentReportStatus.PENDING,
        },
        {
            label: 'Completed',
            value: EPaymentReportStatus.COMPLETED,
        },
    ];

    return (
        <div className='flex flex-col gap-default 2xl:gap-xl'>
            <Text variant={ETextVariant.header} className='font-heading-bold text-[50px] 2xl:text-[60px]'>
                PAYMENT REPORT
            </Text>
            <div className='flex justify-end'>
                <CustomDateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(dates) => {
                        setDateRangeFilter(dates);
                    }}
                    className='w-menu'
                />
            </div>
            <div className='grid grid-cols-3 gap-md'>
                <div className='col-span-1'>
                    <PaymentStatusCard
                        value={totalPaymentReport.commissionMade}
                        label='Commission Made'
                        icon={<GiReceiveMoney size={40} color='white' />}
                    />
                </div>
                <div className='col-span-1'>
                    <PaymentStatusCard
                        value={totalPaymentReport.paymentsPending}
                        label='Payments Pending'
                        icon={<BsCreditCard2Front size={40} color='white' />}
                    />
                </div>
                <div className='col-span-1'>
                    <PaymentStatusCard
                        value={totalPaymentReport.paymentsCompleted}
                        label='Payments Completed'
                        icon={<BsCashStack size={40} color='white' />}
                    />
                </div>
            </div>
            <div className='flex flex-col gap-md'>
                <div className='flex justify-end'>
                    <div className='flex items-center gap-md'>
                        <Input
                            variant={EInputVariant.searchbar}
                            placeholder='Search...'
                            onChange={handleSearch}
                            className='w-full'
                        />
                        <Select
                            variant={ESelectVariant.filter}
                            placeholder='Status Filter'
                            options={paymentStatuses}
                            onValueChange={handleValueChange}
                        />
                    </div>
                </div>
                <div>
                    <PaymentTable
                        paymentReportData={paymentReportListing.data}
                        loading={getPaymentReportListingAttempting || endDate === null}
                    />
                    <Paginator
                        currentPage={page}
                        maxPage={(paymentReportListing && paymentReportListing.maxIndex !== 0 && paymentReportListing.maxIndex) || 0}
                        onFirst={() => page !== 0 && setPage(0)}
                        onLast={() => paymentReportListing.maxIndex !== 0 && setPage((paymentReportListing && paymentReportListing.maxIndex) || 0)}
                        onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                        onNext={() => paymentReportListing.maxIndex !== 0 && setPage(page !== paymentReportListing.maxIndex ? page + 1 : page)}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    dateFilter: Selectors.uiGetPaymentReportDateFilter(state),
    getPaymentReportListingAttempting: Selectors.paymentReportGetPaymentReportListingAttempting(state),
    getPaymentReportListingError: Selectors.paymentReportGetPaymentReportListingError(state),
    getTotalPaymentReportAttempting: Selectors.paymentReportGetTotalPaymentReportAttempting(state),
    getTotalPaymentReportError: Selectors.paymentReportGetTotalPaymentReportError(state),
    totalPaymentReport: Selectors.paymentReportGetTotalPaymentReportData(state),
    paymentReportListing: Selectors.paymentReportGetPaymentReportListingData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getTotalPaymentReport: (params: DateParams) => dispatch(Actions.paymentReportGetTotalPaymentReportAttempt(params)),
    getPaymentReportListing: (params: GetPaymentReportListingParams) =>
        dispatch(Actions.paymentReportGetPaymentReportListingAttempt(params)),
    setDateFilter: (date: TDateFilterString) => dispatch(Actions.uiSetPaymentReportDateFilter(date)),
    logout: () => dispatch(Actions.authLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReport);
