import Gateway from 'api/types/Gateway';

import {
    EModerationStatus,
    EModerationTargetType,
    EUserTier,
    TModerationDetails,
} from 'entities/report';

import { GatewayResponse } from './types/types';

export type GenericParams<T> = {
    authToken: string;
    params: T;
};
export type GenericIndexedResponse<T> = {
    index: number;
    maxIndex: number;
    data: T[];
};

export type GenericResponse = string | undefined;

// ! Params
export type DateParams = {
    dateFrom: string;
    dateTo: string;
};

export type GetModerationReportParams = {
    index: number;
    dateFrom: string;
    dateTo: string;
    status?: EModerationStatus;
    filter?: EModerationTargetType;
};

export type GetModerationReportDetailsParams = {
    moderationId: string;
};

export type SetModerationReportStatusParams = {
    moderationId: string;
    status: EModerationStatus;
};

// ! Response
export type GetModerationReportResponse = {
    index: number;
    maxIndex: number;
    data: TModerationDetails[];
};

export type GetModerationReportDetailsResponse = {
    _id: string;
    content: string;
    title: string;
    targetUserDetails: {
        _id: string;
        username: string;
        displayName?: string;
        userTier: EUserTier;
        profileImageUrl?: string;
    },
    reporterId: string;
    reporterUsername: string;
    targetType: EModerationTargetType;
};

export abstract class IModerationReportGateway extends Gateway {
    abstract getModerationReport(
        params: GenericParams<GetModerationReportParams>,
    ): GatewayResponse<GetModerationReportResponse>;

    abstract getModerationReportDetails(
        params: GenericParams<GetModerationReportDetailsParams>,
    ): GatewayResponse<GetModerationReportDetailsResponse>;

    abstract setModerationReportStatus(
        params: GenericParams<SetModerationReportStatusParams>,
    ): GatewayResponse<void>;
}
