import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PostReportGateway from 'api/PostReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetAllTopics(api: PostReportGateway): SagaWatcherReturnType {
    yield takeEvery('postReport/postReportGetAllTopicsAttempt', handleGetAllTopics, api);
}

function* handleGetAllTopics(api: PostReportGateway) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.getAllTopics], {
        authToken,
        params: '',
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.postReportGetAllTopicsFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.postReportGetAllTopicsSuccess(response.data));
    }
}
