import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PostReportGateway from 'api/PostReport';
import PurchaseReportGateway from 'api/PurchaseReport';
import TopicReportGateway from 'api/TopicReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { GetPostReportParamsPayload } from 'redux/slices/postReport/types';
import { GetPurchaseReportParamsPayload } from 'redux/slices/purchaseReport/types';
import { GetTopicsParamsPayload } from 'redux/slices/topicReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetTopics(api: TopicReportGateway): SagaWatcherReturnType {
    yield takeEvery('topicReport/topicReportGetTopicsAttempt', handleGetTopics, api);
}

function* handleGetTopics(api: TopicReportGateway, data: GetTopicsParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.getTopics], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.topicReportGetTopicsFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.topicReportGetTopicsSuccess(response.data));
    }
}
