import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';

import {
    DateParams,
    DeleteUserCommentParams,
    GenericIndexedResponse,
    GenericParams,
    GetRepLeaderboardParams,
    GetRepLeaderboardResponse,
    GetTotalUsersResponse,
    GetUserCommentsResponse,
    GetUserDetailsParams,
    GetUserGradesResponse,
    GetUserPostsResponse,
    GetUserReportParams,
    GetUserReportResponse,
    GetUserSummaryResponse,
    IUserReportGateway,
    SetUserCommissionParams,
    SetUserToBanParams,
    SetUserToUnbanParams,
} from './UserReportBase';

export default class UserReportGateway extends IUserReportGateway {
    async getUserReport(params: GenericParams<GetUserReportParams>):
        GatewayResponse<GenericIndexedResponse<GetUserReportResponse>> {
        const response: ApiResponse<GenericIndexedResponse<GetUserReportResponse>> = await this.api.get(
            '/v1/a/userReport/users',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getTotalUsers(params: GenericParams<DateParams>): GatewayResponse<GetTotalUsersResponse> {
        const { authToken, ...restOfParams } = params;
        const response: ApiResponse<GetTotalUsersResponse> = await this.api.get(
            '/v1/a/userReport/totalUsers',
            restOfParams.params,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getUserSummary(params: GenericParams<string>):
        GatewayResponse<GetUserSummaryResponse> {
        const response: ApiResponse<GetUserSummaryResponse> = await this.api.get(
            `/v1/a/userReport/user/${params.params}`,
            {},
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getUserPosts(params: GenericParams<GetUserDetailsParams>):
        GatewayResponse<GenericIndexedResponse<GetUserPostsResponse>> {
        const {
            userId,
            ...otherParams
        } = params.params;
        const response: ApiResponse<GenericIndexedResponse<GetUserPostsResponse>> = await this.api.get(
            `/v1/a/userReport/user/posts/${userId}`,
            otherParams,
            Utils.getHeaders(params.authToken),
        );
        return this.process(response);
    }

    async getUserComments(params: GenericParams<GetUserDetailsParams>):
        GatewayResponse<GenericIndexedResponse<GetUserCommentsResponse>> {
        const {
            userId,
            ...otherParams
        } = params.params;
        const response: ApiResponse<GenericIndexedResponse<GetUserCommentsResponse>> = await this.api.get(
            `/v1/a/userReport/user/comments/${userId}`,
            otherParams,
            Utils.getHeaders(params.authToken),
        );
        return this.process(response);
    }

    async getUserGrades(params: GenericParams<GetUserDetailsParams>):
        GatewayResponse<GenericIndexedResponse<GetUserGradesResponse>> {
        const {
            userId,
            ...otherParams
        } = params.params;
        const response: ApiResponse<GenericIndexedResponse<GetUserGradesResponse>> = await this.api.get(
            `/v1/a/userReport/user/scoredPosts/${userId}`,
            otherParams,
            Utils.getHeaders(params.authToken),
        );
        return this.process(response);
    }

    async getRepLeaderboard(params: GenericParams<GetRepLeaderboardParams>):
        GatewayResponse<GenericIndexedResponse<GetRepLeaderboardResponse>> {
        const response: ApiResponse<GenericIndexedResponse<GetRepLeaderboardResponse>> = await this.api.get(
            '/v1/c/user/leaderboard',
            params.params,
            Utils.getHeaders(params.authToken),
        );
        return this.process(response);
    }

    async setUserCommission(params: GenericParams<SetUserCommissionParams>):
        GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put(
            '/v1/a/userReport/user/setCommissionRate',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async setUserToBan(params: GenericParams<SetUserToBanParams>):
        GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put(
            '/v1/a/userReport/user/banUserAccount',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async setUserToUnban(params: GenericParams<SetUserToUnbanParams>):
        GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put(
            '/v1/a/userReport/user/unbanUserAccount',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async deleteUserComments(params: GenericParams<DeleteUserCommentParams>):
        GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put(
            '/v1/a/userReport/user/deleteUserComment',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }
}
