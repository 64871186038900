export enum EMenuList {
    home = 1,
    reports = 2,
    userReport = 21,
    postReport = 22,
    topicReport = 23,
    purchaseReport = 24,
    paymentReport = 25,
    settings = 3,
    systemSetting = 31,
    subadminSetting = 32,
    moderationReport = 33,
}

export enum ETotalUserType {
    newUser = 1,
    activeUser = 2,
}

export enum EUserDetailsTab {
    allPosts = 1,
    commentsHistories = 2,
    gradeHistories = 3,
    settings = 4,
}

export enum EPostDetailsTab {
    commentsHistories = 1,
    gradeHistories = 2,
    viewReport = 3,
    purchaseHistories = 4,
    settings = 5,
}

export default {
    EMenuList,
};
