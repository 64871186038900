import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import TopicReportGateway from 'api/TopicReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AddTopicParamsPayload } from 'redux/slices/topicReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchAddTopic(api: TopicReportGateway): SagaWatcherReturnType {
    yield takeEvery('topicReport/topicReportAddTopicAttempt', handleAddTopic, api);
}

function* handleAddTopic(api: TopicReportGateway, data: AddTopicParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.addTopic], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.topicReportAddTopicFailure(response.message || ''));
        yield put(Actions.uiSetAddTopicModalIsOpen(false));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.topicReportAddTopicSuccess());
        yield put(
            Actions.topicReportGetTopicsAttempt({
                index: 0,
                searchQuery: '',
            }),
        );
        yield put(Actions.uiSetAddTopicModalIsOpen(false));
        toast.success('New Topic added successfully!');
    }
}
