import React from 'react';

import { FaRegFileImage, FaRegUser } from 'react-icons/fa';

import {
    EModerationCategory,
    EModerationStatus,
    EModerationTargetType,
    EPaymentReportStatus,
    EPostRating,
    EPostStage,
    EPostType,
    EUserStatus,
    EUserTier,
} from 'entities/report';
import ESubAdminPermission from 'entities/subadmin';

import cn from './TailwindMerge';

// todo: later need to rename to renderUserImage
export const renderImage = (image?: string, size?: number): React.ReactNode => {
    if (image) {
        return <img className='aspect-square size-full rounded-[50%]' src={image} alt='user profile' />;
    }
    return (
        <div className={cn('flex justify-center items-center', 'size-full aspect-square rounded-[50%]', 'bg-white')}>
            <FaRegUser size={size || 20} color='#BEBEBE' />
        </div>
    );
};
export const renderPostImage = (image?: string, size?: number): React.ReactNode => {
    if (image) {
        return <img className='size-full' src={image} alt='post' />;
    }
    return <FaRegFileImage size={size ?? 50} color='#BEBEBE' />;
};

export const renderPostMedia = (mediaUrl: string): React.ReactNode => {
    if (mediaUrl) {
        if (mediaUrl.includes('.mp4' || '.mov')) {
            return (
                <div className='flex w-full items-center justify-center'>
                    <video src={mediaUrl} controls className='max-h-[300px] object-contain'>
                        <track kind='captions' />
                    </video>
                </div>
            );
        }
        return (
            <div className='w-full'>
                <img src={mediaUrl} alt='postImage' className='max-h-[300px] object-contain' />
            </div>
        );
    }
    return null;
};

export const renderUserTier = (userTier?: EUserTier, isGrade?: boolean): string => {
    switch (userTier) {
        case EUserTier.MASTER:
            return isGrade ? 'GRADE A' : 'Master';
        case EUserTier.ADVANCED:
            return isGrade ? 'GRADE B' : 'Advanced';
        case EUserTier.INTERMEDIATE:
            return isGrade ? 'GRADE C' : 'Intermediate';
        case EUserTier.BEGINNER:
            return isGrade ? 'GRADE D' : 'Beginner';
        default:
            return '-';
    }
};

export const renderPostStatus = (stage?: EPostStage): string => {
    switch (stage) {
        case EPostStage.DRAFT:
            return 'Draft';
        case EPostStage.PENDING_VERIFICATION:
            return 'Pending Verification';
        case EPostStage.VERIFIED:
            return 'Verified';
        case EPostStage.ADMIN_REMOVED:
            return 'Admin Removed';
        case EPostStage.REKOGNITION_REMOVED:
            return 'Rekognition Removed';
        case EPostStage.UNVERIFIED:
            return 'Unverified';
        case EPostStage.UNGRADED:
            return 'Ungraded';
        case EPostStage.USER_REMOVED:
            return 'User Removed';
        case EPostStage.REPOST:
            return 'Repost';
        default:
            return '-';
    }
};

export const renderPostRating = (type?: EPostRating, isGrade?: boolean): string => {
    switch (type) {
        case EPostRating.A:
            return isGrade ? 'GRADE A' : 'A';
        case EPostRating.B:
            return isGrade ? 'GRADE B' : 'B';
        case EPostRating.C:
            return isGrade ? 'GRADE C' : 'C';
        case EPostRating.D:
            return isGrade ? 'GRADE D' : 'D';
        default:
            return 'Non-graded';
    }
};

export const renderPostType = (type?: EPostType): string => {
    switch (type) {
        case EPostType.PAID:
            return 'Paid';
        case EPostType.PUBLIC:
            return 'Public';
        default:
            return '-';
    }
};

export const renderUserStatus = (status?: EUserStatus): string => {
    switch (status) {
        case EUserStatus.ActiveUser:
            return 'Active';
        case EUserStatus.DeletedUser:
            return 'Deleted';
        case EUserStatus.SuspendedUser:
            return 'Banned';
        default:
            return '-';
    }
};

export const renderPaymentStatus = (status?: EPaymentReportStatus): string => {
    switch (status) {
        case EPaymentReportStatus.PENDING:
            return 'Pending';
        case EPaymentReportStatus.COMPLETED:
            return 'Completed';
        default:
            return 'Pending';
    }
};

export const renderCurrency = (amount?: number, decimalPoints = 2): string => {
    return `SGD ${amount?.toFixed(decimalPoints ?? 0)}`;
};

export const renderSubAdminPermission = (permission?: ESubAdminPermission): string => {
    switch (permission) {
        case ESubAdminPermission.USER_REPORT:
            return 'User Report';
        case ESubAdminPermission.POST_REPORT:
            return 'Post Report';
        case ESubAdminPermission.TOPIC_REPORT:
            return 'Topic Report';
        case ESubAdminPermission.PURCHASE_REPORT:
            return 'Purchase Report';
        case ESubAdminPermission.PAYMENT_REPORT:
            return 'Payment Report';
        case ESubAdminPermission.SYSTEM_SETTING:
            return 'System Settings';
        default:
            return '-';
    }
};

export const renderModerationCategory = (category?: EModerationCategory): string => {
    switch (category) {
        case EModerationCategory.BOOSTING:
            return 'Boosting';
        case EModerationCategory.COPYRIGHT:
            return 'Copyright';
        case EModerationCategory.GORE:
            return 'Gore';
        case EModerationCategory.HARASSMENT:
            return 'Harassment';
        case EModerationCategory.NUDITY:
            return 'Nudity';
        case EModerationCategory.OFFENSIVE:
            return 'Offensive';
        case EModerationCategory.UNRELATED:
            return 'Unrelated';
        default:
            return '-';
    }
};

export const renderModerationStatusColor = (status: EModerationStatus): string => {
    switch (status) {
        case EModerationStatus.APPROVED:
            return 'text-green-500';
        case EModerationStatus.REJECTED:
            return 'text-red-500';
        case EModerationStatus.PENDING:
            return 'text-color-primary';
        default:
            return 'text-color-primary';
    }
};

export const renderModerationStatus = (status?: EModerationStatus): string => {
    switch (status) {
        case EModerationStatus.APPROVED:
            return 'Approved';
        case EModerationStatus.REJECTED:
            return 'Rejected';
        case EModerationStatus.PENDING:
            return 'Pending';
        default:
            return 'Pending';
    }
};

export const renderDiffPercentage = (diff: number | null): string => {
    const percentageValue = diff !== null ? (diff * 100).toFixed(2) : 0;
    return `${percentageValue}%`;
};

export const renderModerationType = (status?: EModerationTargetType): string => {
    switch (status) {
        case EModerationTargetType.COMMENT:
            return 'Comment';
        case EModerationTargetType.POST:
            return 'Post';
        case EModerationTargetType.USER:
            return 'User';
        default:
            return 'N/A';
    }
};
