import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import ModerationReportGateway from 'api/ModerationReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { GetModerationReportDetailsParamsPayload, GetModerationReportParamsPayload } from 'redux/slices/moderationReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetModerationReport(api: ModerationReportGateway): SagaWatcherReturnType {
    yield takeEvery('moderationReport/moderationReportGetModerationReportDetailsAttempt', handleGetModerationReportDetails, api);
}

function* handleGetModerationReportDetails(api: ModerationReportGateway, data: GetModerationReportDetailsParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.getModerationReportDetails], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.moderationReportGetModerationReportDetailsFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.moderationReportGetModerationReportDetailsSuccess(response.data));
    }
}
