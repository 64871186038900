import { fork } from 'redux-saga/effects';

import ModerationReportGateway from 'api/ModerationReport';
import { RootSagaReturnType } from 'sagas/types';

import watchGetModerationReport from './getModerationReport';
import watchGetModerationReportDetails from './getModerationReportDetails';
import watchSetModerationReportStatus from './setModerationReportStatus';

export default (api: ModerationReportGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetModerationReport, api);
        yield fork(watchGetModerationReportDetails, api);
        yield fork(watchSetModerationReportStatus, api);
    }

    return {
        rootSaga,
    };
};
