import React from 'react';

import Paginator from 'components/molecules/Paginator';

import { GenericIndexedResponse, GetPostPurchaseHistoryResponse } from 'api/PostReportBase';

import PostPurchaseTable from '../components/postDetails/PostPurchaseTable';

type PostPurchaseTabProps = {
    postPurchaseHistory: GenericIndexedResponse<GetPostPurchaseHistoryResponse>;

    page: number;
    setPage: (page: number) => void;

    loading?: boolean;
    error?: string;
};

const PostPurchaseTab = (props: PostPurchaseTabProps): JSX.Element => {
    const {
        postPurchaseHistory,
        page,
        setPage,
        loading,
        error,
    } = props;

    return (
        <div>
            <PostPurchaseTable
                postPurchaseHistoryData={postPurchaseHistory.data}
                loading={loading}
            />
            <Paginator
                currentPage={page}
                maxPage={(postPurchaseHistory && postPurchaseHistory.maxIndex !== 0 && postPurchaseHistory.maxIndex) || 0}
                onFirst={() => page !== 0 && setPage(0)}
                onLast={() => postPurchaseHistory.maxIndex !== 0 && setPage((postPurchaseHistory && postPurchaseHistory.maxIndex) || 0)}
                onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                onNext={() => postPurchaseHistory.maxIndex !== 0 && setPage(page !== postPurchaseHistory.maxIndex ? page + 1 : page)}
            />
        </div>
    );
};

PostPurchaseTab.defaultProps = {
    loading: false,
    error: '',
};
export default PostPurchaseTab;
