import React from 'react';

import cn from 'lib/TailwindMerge';

type LayoutProps = {
    children: React.ReactNode;
}

const AuthLayout = (props: LayoutProps): JSX.Element => {
    const { children } = props;
    return (
        <main className={cn(
            'flex flex-col',
            'items-center justify-center',
            'h-screen w-screen',
            'gap-xl 2xl:gap-[80px]',
            'bg-background-image',
            'font-general',
        )}
        >
            {children}
        </main>
    );
};

export default AuthLayout;
