import React from 'react';

import SideMenu from 'components/molecules/SideMenu';

import cn from 'lib/TailwindMerge';

type LayoutProps = {
    children: React.ReactNode;
}

const DashboardLayout = (props: LayoutProps): JSX.Element => {
    const { children } = props;
    return (
        <main className={cn(
            'flex flex-col',
            'items-center justify-center',
            'h-screen',
            'overflow-auto',
            'gap-xl',
            'bg-fixed bg-background-image',
            'p-default pl-menu',
            'font-general',
        )}
        >
            <SideMenu />
            {children}
        </main>
    );
};

export default DashboardLayout;
