import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { IoChevronBackOutline } from 'react-icons/io5';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Text from 'components/atoms/Text';
import ContentLayout from 'components/layout/ContentLayout';
import CustomDateRangePicker from 'components/molecules/CustomDateRangePicker';

import {
    GetPaymentReportDetailsParams,
    GetPaymentReportDetailsResponse,
    GetPaymentSelectorParams,
    GetPaymentSelectorResponse,
} from 'api/PaymentReportBase';
import { GetUserSummaryResponse } from 'api/UserReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import { ETextVariant, TDateFilterString } from 'entities/components';

import MakePaymentCard from './components/paymentDetails/MakePaymentCard';
import PaymentDetailsCard from './components/paymentDetails/PaymentDetailsCard';

import UserSummaryCard from '../user/components/userDetails/UserSummaryCard';

interface PaymentDetailsProps {
    getUserSummaryAttempting: boolean;
    getUserSummaryError: string;
    userSummary: GetUserSummaryResponse;

    getPaymentReportDetailsAttempting: boolean;
    getPaymentReportDetailsError: string;
    paymentReportDetails: GetPaymentReportDetailsResponse;

    getPaymentSelectorAttempting: boolean;
    getPaymentSelectorError: string;
    paymentSelector: GetPaymentSelectorResponse;

    dateFilter: TDateFilterString;

    getUserSummary: (params: string) => void;
    getPaymentReportDetails: (params: GetPaymentReportDetailsParams) => void;
    getPaymentSelector: (params: GetPaymentSelectorParams) => void;

    setDateFilter: (date: TDateFilterString) => void;
    // resetError: () => void;
}
const PaymentDetails = (props: PaymentDetailsProps): JSX.Element => {
    const {
        getUserSummaryAttempting,
        getUserSummaryError,

        getPaymentReportDetailsAttempting,
        getPaymentReportDetailsError,

        getPaymentSelectorAttempting,
        getPaymentSelectorError,

        getUserSummary,
        getPaymentReportDetails,
        getPaymentSelector,

        setDateFilter,
        // resetError,

        userSummary,
        paymentReportDetails,
        paymentSelector,
        dateFilter,
    } = props;

    const { pathname } = useLocation();
    const userId = pathname.split('/').pop();

    const [dateRangeFilter, setDateRangeFilter] = useState<[Date | null, Date | null]>([
        dayjs(dateFilter[0]).toDate(),
        dayjs(dateFilter[1]).toDate(),
    ]);
    const [startDate, endDate] = dateRangeFilter;

    useEffect(() => {
        if (startDate && endDate) {
            if (userId) {
                getPaymentReportDetails({
                    dateFrom: dayjs(startDate).startOf('day').utc().format(),
                    dateTo: dayjs(endDate).endOf('day').utc().format(),
                    userId,
                });
                getPaymentSelector({
                    dateFrom: dayjs(startDate).startOf('day').utc().format(),
                    dateTo: dayjs(endDate).endOf('day').utc().format(),
                    userId,
                });
            }
        }
    }, [endDate]);

    useEffect(() => {
        if (userId) {
            getUserSummary(userId);
        }
    }, []);

    const renderPaymentDetailsCards = () => {
        if (paymentReportDetails.length === 0) {
            return <Text variant={ETextVariant.default}>There is no payments yet! Try adjusting the filters or create a new payment.</Text>;
        }

        return paymentReportDetails.map((paymentDetail) => {
            return (
                <PaymentDetailsCard key={paymentDetail._id} paymentDetailsData={paymentDetail} />
            );
        });
    };

    if (getPaymentReportDetailsError || getPaymentSelectorError || getUserSummaryError) {
        return (
            <div className='flex items-center justify-center'>
                <Text variant={ETextVariant.error}>
                    {getPaymentReportDetailsError || getPaymentSelectorError || getUserSummaryError}
                </Text>
            </div>
        );
    }

    if (getPaymentReportDetailsAttempting || getPaymentSelectorAttempting) {
        return (
            <div className='flex items-center justify-center'>
                <RotatingLines strokeColor='white' strokeWidth='5' animationDuration='0.75' width='100' visible />
            </div>
        );
    }

    return (
        <ContentLayout className='gap-md'>
            <div className='flex'>
                <div
                    className='flex cursor-pointer flex-wrap items-center'
                    onClick={() => NavActions.navBack()}
                    aria-hidden='true'
                >
                    <IoChevronBackOutline size={30} color='white' />
                    <Text variant={ETextVariant.default}>Back</Text>
                </div>
            </div>
            <UserSummaryCard userSummary={userSummary} loading={getUserSummaryAttempting} error={getUserSummaryError} />
            <div className='flex items-end justify-between'>
                <Text variant={ETextVariant.header} className='text-[50px] 2xl:text-[40px] 3xl:text-[60px]'>
                    PAYMENT DETAILS
                </Text>
                <CustomDateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(dates) => {
                        setDateRangeFilter(dates);
                    }}
                    className='w-menu'
                />
            </div>
            <div className='flex flex-row gap-md'>
                <div className='flex w-3/5 flex-col gap-md'>{renderPaymentDetailsCards()}</div>
                <div className='w-2/5'>
                    <MakePaymentCard
                        paymentSelector={paymentSelector}
                        loading={getPaymentReportDetailsAttempting || getPaymentSelectorAttempting}
                        error={getPaymentSelectorError}
                        userId={userId ?? ''}
                    />
                </div>
            </div>
        </ContentLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    getUserSummaryAttempting: Selectors.userReportGetUserSummaryAttempting(state),
    getUserSummaryError: Selectors.userReportGetUserSummaryError(state),
    userSummary: Selectors.userReportGetUserSummaryData(state),

    getPaymentReportDetailsAttempting: Selectors.paymentReportGetPaymentReportDetailsAttempting(state),
    getPaymentReportDetailsError: Selectors.paymentReportGetPaymentReportDetailsError(state),
    paymentReportDetails: Selectors.paymentReportGetPaymentReportDetailsData(state),

    getPaymentSelectorAttempting: Selectors.paymentReportGetPaymentSelectorAttempting(state),
    getPaymentSelectorError: Selectors.paymentReportGetPaymentSelectorError(state),
    paymentSelector: Selectors.paymentReportGetPaymentSelectorData(state),

    dateFilter: Selectors.uiGetPaymentDetailsDateFilter(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUserSummary: (params: string) => dispatch(Actions.userReportGetUserSummaryAttempt(params)),
    getPaymentReportDetails: (params: GetPaymentReportDetailsParams) =>
        dispatch(Actions.paymentReportGetPaymentReportDetailsAttempt(params)),
    getPaymentSelector: (params: GetPaymentSelectorParams) =>
        dispatch(Actions.paymentReportGetPaymentSelectorAttempt(params)),
    setDateFilter: (date: TDateFilterString) => dispatch(Actions.uiSetPaymentDetailsDateFilter(date)),

    // resetError: () => dispatch(Actions.postReportResetErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
