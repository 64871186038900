import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import { FaCreditCard } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import ContentLayout from 'components/layout/ContentLayout';

import { GetDashboardDetailsResponse } from 'api/DashboardBase';
import { GetPostReportData } from 'api/mocks/DummyData';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import { EPermission, IAuthUser } from 'entities/auth';
import { EButtonVariant, ETextVariant } from 'entities/components';
import { EMenuList } from 'entities/ui';

import ChartCard from './components/ChartCard';
import StatusCard from './components/StatusCard';
import StatusWithChartCard from './components/StatusWithChartCard';

interface HomeScreenProps {
    logout: () => void;
    userInfo: IAuthUser | null;
    getDashboardDetailsAttempting: boolean;
    getDashboardDetailsError: string;
    dashboardDetails: GetDashboardDetailsResponse;
    getDashboardDetails: () => void;
}

const HomeScreen = (props: HomeScreenProps): JSX.Element => {
    const {
        logout,
        userInfo,
        getDashboardDetailsAttempting,
        getDashboardDetailsError,
        dashboardDetails,
        getDashboardDetails,
    } = props;

    const userEmail = userInfo?.email || '';
    const userRole = userInfo?.role || '';
    const userRolePermission = userInfo?.permissions || [];

    useEffect(() => {
        getDashboardDetails();
    }, [getDashboardDetails]);

    return (
        <ContentLayout>
            <div className='flex flex-col gap-default 2xl:gap-xl'>
                <Text
                    variant={ETextVariant.header}
                    className='font-heading-bold text-[50px] 2xl:text-[60px]'
                >
                    FINELINES ADMIN PORTAL
                </Text>
                <div className='flex gap-md'>
                    <StatusWithChartCard
                        value={dashboardDetails?.newUsers.count}
                        label='Total New Users'
                        diff={dashboardDetails?.newUsers.diff}
                        chartData={dashboardDetails?.newUsers.data}
                        loading={getDashboardDetailsAttempting}
                    />
                    <StatusWithChartCard
                        value={dashboardDetails?.activeUsers.count}
                        label='Total Active Users'
                        diff={dashboardDetails?.activeUsers.diff}
                        chartData={dashboardDetails?.activeUsers.data}
                        loading={getDashboardDetailsAttempting}
                    />
                    <StatusWithChartCard
                        value={dashboardDetails?.pendingReports.count}
                        label='Pending Reports'
                        diff={dashboardDetails?.pendingReports.diff}
                        chartData={dashboardDetails?.pendingReports.data}
                        loading={getDashboardDetailsAttempting}
                    />
                </div>
                <div className='flex justify-between gap-md'>
                    <div className='flex w-2/3 flex-col gap-md'>
                        <Text variant={ETextVariant.header}>
                            Purchase Report
                        </Text>
                        <ChartCard
                            label='Total purchases made of last 30 days'
                            value={dashboardDetails?.purchaseReport.amount}
                            data={dashboardDetails?.purchaseReport.data}
                            viewButton={userRolePermission.includes(EPermission.purchaseReport)}
                        />
                    </div>
                    <div className='flex w-1/3 flex-col gap-md'>
                        <div className='flex justify-between'>
                            <Text variant={ETextVariant.header}>
                                Payment Report
                            </Text>
                            {userRolePermission.includes(EPermission.paymentReport) && (
                                <Button className='w-fit px-8' onClick={() => NavActions.navToReports('paymentReport')}>
                                    <Text>View Details</Text>
                                </Button>
                            )}
                        </div>
                        <StatusCard
                            value={Math.round(dashboardDetails && dashboardDetails.paymentReport.commissionMade * 100) / 100}
                            label='SGD'
                            icon={<GiReceiveMoney size={40} color='white' />}
                        />
                        <StatusCard
                            value={dashboardDetails?.paymentReport.pendingPayment}
                            label='Paid Posting'
                            icon={<FaCreditCard size={40} color='white' />}
                        />
                    </div>
                </div>
            </div>
        </ContentLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    userInfo: Selectors.authGetUserInfo(state),
    getDashboardDetailsAttempting: Selectors.dashboardGetDashboardDetailsAttempting(state),
    getDashboardDetailsError: Selectors.dashboardGetDashboardDetailsError(state),
    dashboardDetails: Selectors.dashboardGetDashboardDetailsData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getDashboardDetails: () => dispatch(Actions.getDashboardDetailsAttempt()),
    logout: () => dispatch(Actions.authLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
