import React, { useState } from 'react';

import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';

import Badge from 'components/atoms/Badge';
import Button from 'components/atoms/Button';
import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';
import UserProfileImage from 'components/molecules/UserProfileImage';

import {
    GetPostModerationDetailsParams,
    GetPostModerationResponse,
    GetPostReportResponse,
} from 'api/PostReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import {
    renderModerationCategory,
    renderModerationStatus,
    renderModerationStatusColor,
} from 'lib/RenderFunction';
import { EButtonVariant, ETextVariant } from 'entities/components';
import { EModerationStatus } from 'entities/report';

import PostModerationReviewModal from './PostModerationReviewModal';

type PostReportsTableProps = {
    setPostModerationReviewModalIsOpen: (state: boolean) => void;
    postModerationData: GetPostModerationResponse[];
    loading?: boolean;
};

const PostReportsTable = (props: PostReportsTableProps): JSX.Element => {
    const { postModerationData, setPostModerationReviewModalIsOpen, loading } = props;
    const postReportsTableHeader = [
        'User',
        'Report Category',
        'Reported Date',
        'Status',
    ];

    const [reporterUserId, setReporterUserId] = useState<string>('');
    const [reportId, setReportId] = useState<string>('');
    const [moderationStatus, setModerationStatus] = useState<EModerationStatus>(EModerationStatus.PENDING);

    const setPostModerationReviewHandler = (
        inputReporterUserId: string,
        inputReportId: string,
        inputModerationStatus: number,
    ) => {
        setModerationStatus(inputModerationStatus);
        setReporterUserId(inputReporterUserId);
        setReportId(inputReportId);
        setPostModerationReviewModalIsOpen(true);
    };

    const renderTableBody = () => {
        if (!postModerationData || postModerationData.length === 0) {
            return (
                <tr>
                    <td
                        colSpan={postReportsTableHeader.length}
                        className='py-xl text-center'
                    >
                        <Text variant={ETextVariant.default}>
                            There is no data! Try adjusting the filters.
                        </Text>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return (
                <tr>
                    <td
                        colSpan={postReportsTableHeader.length}
                        className='py-xl text-center'
                    >
                        <div className='flex items-center justify-center gap-default'>
                            <RotatingLines
                                strokeColor='white'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='100'
                                visible
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return postModerationData?.map((report) => {
            return (
                <tr key={report._id}>
                    <td className='p-md'>
                        <div className='flex items-center gap-md'>
                            <div className='flex size-[50px] h-full 2xl:size-[60px] 3xl:size-[80px]'>
                                <UserProfileImage
                                    profileImageUrl={report.reporter.profileImageUrl}
                                    userTier={report.reporter.userTier}
                                    className='2xl:!p-[0.5rem]'
                                />
                            </div>
                            <div className='flex flex-col'>
                                <Text
                                    variant={ETextVariant.default}
                                    className='font-general-bold text-base 2xl:text-xl'
                                >
                                    {report.reporter.displayName}
                                </Text>
                                <Text variant={ETextVariant.subheader}>
                                    {report.reporter.username}
                                </Text>
                            </div>
                        </div>
                    </td>
                    <td className='py-md text-center'>
                        <Text>
                            {renderModerationCategory(report.reportCategory)}
                        </Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>
                            {dayjs(report.reportDate).format('DD MMM YYYY')}
                        </Text>
                    </td>
                    <td className='py-md text-center'>
                        {report.status === EModerationStatus.PENDING ? (
                            <div className='flex flex-row justify-center'>
                                <Button
                                    variant={EButtonVariant.ghost}
                                    className='m-0 px-sm font-general text-xs text-color-primary 2xl:text-base'
                                    onClick={() =>
                                        setPostModerationReviewHandler(
                                            report.reporter.userId,
                                            report._id,
                                            EModerationStatus.APPROVED,
                                        )}
                                >
                                    Approve
                                </Button>
                                <Text>/</Text>
                                <Button
                                    variant={EButtonVariant.ghost}
                                    className='m-0 px-sm font-general text-xs text-color-primary 2xl:text-base'
                                    onClick={() =>
                                        setPostModerationReviewHandler(
                                            report.reporter.userId,
                                            report._id,
                                            EModerationStatus.REJECTED,
                                        )}
                                >
                                    Reject
                                </Button>
                            </div>
                        ) : (
                            <Text
                                className={renderModerationStatusColor(
                                    report.status,
                                )}
                            >
                                {renderModerationStatus(report.status)}
                            </Text>
                        )}
                    </td>
                </tr>
            );
        });
    };

    return (
        <div>
            <Table
                className='table-auto'
                tableHeader={postReportsTableHeader}
                tableData={renderTableBody()}
                expandedColumns={0}
                expandedWidth='w-2/4'
            />
            <PostModerationReviewModal
                moderationStatus={moderationStatus}
                reporterUserId={reporterUserId}
                reportId={reportId}
            />
        </div>
    );
};

PostReportsTable.defaultProps = {
    loading: false,
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setPostModerationReviewModalIsOpen: (state: boolean) =>
        dispatch(Actions.uiSetPostModerationReviewModalIsOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostReportsTable);
