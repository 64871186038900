import React from 'react';

import cn from 'lib/TailwindMerge';
import { EContainerVariant } from 'entities/components';

type ContainerProps = {
    variant?: EContainerVariant;
    children: React.ReactNode;
    className?: string;
    parentClassName?: string;
    clickable?: boolean;
    onClick?: () => void;
};

const Container = (props: ContainerProps): JSX.Element => {
    const {
        variant,
        children,
        className,
        parentClassName,
        clickable,
        onClick,
    } = props;

    const variantClasses = {
        [EContainerVariant.default]: 'bg-background',
        [EContainerVariant.glass]: 'bg-glass rounded-default shadow-glass filter backdrop-blur-glass',
        [EContainerVariant.glassBorder]: 'bg-glass rounded-default shadow-glass filter backdrop-blur-glass border border-white',
        [EContainerVariant.gradientBorder]: 'size-full rounded-input bg-linear-gradient p-[2.5px] shadow-input',
        [EContainerVariant.modal]: 'bg-background rounded-default shadow-input',
    };

    if (variant === EContainerVariant.gradientBorder) {
        if (clickable) {
            return (
                <div
                    className={cn(variantClasses[EContainerVariant.gradientBorder], parentClassName)}
                >
                    <button
                        type='button'
                        onClick={onClick}
                        className={cn(
                            'text-left size-full rounded-input bg-background',
                            className,
                        )}
                    >
                        {children}
                    </button>
                </div>
            );
        }
        return (
            <div
                className={cn(variantClasses[EContainerVariant.gradientBorder], parentClassName)}
            >
                <div
                    className={cn(
                        'size-full rounded-input bg-background',
                        className,
                    )}
                >
                    {children}
                </div>
            </div>
        );
    }
    return (
        <div
            className={cn(
                variantClasses[variant ?? EContainerVariant.default],
                className,
            )}
        >
            {children}
        </div>
    );
};

Container.defaultProps = {
    variant: EContainerVariant.default,
    className: '',
    parentClassName: '',
    clickable: false,
    onClick: () => null,
};

export default Container;
