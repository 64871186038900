/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import {
    HiChevronLeft,
    HiChevronRight,
    HiOutlineChevronDoubleLeft,
    HiOutlineChevronDoubleRight,
} from 'react-icons/hi2';

import Text from 'components/atoms/Text';

type PaginatorProps = {
    currentPage: number;
    maxPage: number;
    onNext: () => void;
    onPrev: () => void;
    onFirst: () => void;
    onLast: () => void;
}

const Paginator = (props: PaginatorProps): JSX.Element => {
    const {
        currentPage,
        maxPage,
        onNext,
        onPrev,
        onFirst,
        onLast,
    } = props;
    return (
        <div className='flex w-full justify-center p-md'>
            {/* <div>
                <Text>
                    {`Showing ${currentPage} of ${maxPage} result`}
                </Text>
            </div> */}
            <div className='flex items-center gap-sm'>
                <button
                    type='button'
                    onClick={onFirst}
                >
                    <HiOutlineChevronDoubleLeft size={25} />
                </button>
                <button
                    type='button'
                    onClick={onPrev}
                >
                    <HiChevronLeft size={25} />
                </button>
                <Text className='px-sm text-center text-xl'>
                    {`Page ${currentPage + 1} of ${maxPage + 1}`}
                </Text>
                <button
                    type='button'
                    onClick={onNext}
                >
                    <HiChevronRight size={25} />
                </button>
                <button
                    type='button'
                    onClick={onLast}
                >
                    <HiOutlineChevronDoubleRight size={25} />
                </button>
            </div>
        </div>
    );
};

export default Paginator;
