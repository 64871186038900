import { fork } from 'redux-saga/effects';

import PostReportGateway from 'api/PostReport';
import { RootSagaReturnType } from 'sagas/types';

import watchDeleteUserPost from './deleteUserPost';
import watchGetAllTopics from './getAllTopics';
import watchGetPostCommentHistory from './getPostCommentHistory';
import watchGetPostDetails from './getPostDetails';
import watchGetPostGradeHistory from './getPostGradeHistory';
import watchGetPostModeration from './getPostModeration';
import watchGetPostModerationDetails from './getPostModerationDetails';
import watchGetPostPurchaseHistory from './getPostPurchaseHistory';
import watchGetPostReport from './getPostReport';
import watchSetFeaturedPost from './setFeaturedPost';
import watchSetPostModerationReview from './setPostModerationReview';

export default (api: PostReportGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetPostReport, api);
        yield fork(watchGetAllTopics, api);
        yield fork(watchGetPostDetails, api);
        yield fork(watchGetPostCommentHistory, api);
        yield fork(watchGetPostGradeHistory, api);
        yield fork(watchGetPostModeration, api);
        yield fork(watchGetPostModerationDetails, api);
        yield fork(watchSetPostModerationReview, api);
        yield fork(watchGetPostPurchaseHistory, api);
        yield fork(watchSetFeaturedPost, api);
        yield fork(watchDeleteUserPost, api);
    }

    return {
        rootSaga,
    };
};
