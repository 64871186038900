import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PostReportGateway from 'api/PostReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import {
    DeleteUserPostParamsPayload,
    GetPostModerationDetailsParamsPayload,
    GetPostModerationParamsPayload,
    SetFeaturedPostParamsPayload,
    SetPostModerationReviewParamsPayload,
} from 'redux/slices/postReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchDeleteUserPost(api: PostReportGateway): SagaWatcherReturnType {
    yield takeEvery('postReport/postReportDeleteUserPostAttempt', handleDeleteUserPost, api);
}

function* handleDeleteUserPost(api: PostReportGateway, data: DeleteUserPostParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.deleteUserPost], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.postReportDeleteUserPostFailure(response.message || ''));
        yield put(Actions.uiSetDeleteUserPostModalIsOpen(false));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.postReportDeleteUserPostSuccess());
        yield put(Actions.postReportGetPostDetailsAttempt(data.payload.id));
        yield put(Actions.uiSetDeleteUserPostModalIsOpen(false));
        toast.success('Successfully deleted user post!');
    }
}
