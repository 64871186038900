import React, { useState } from 'react';

import dayjs from 'dayjs';
import { BsThreeDots } from 'react-icons/bs';
import { LiaCommentDots } from 'react-icons/lia';
import { LuDot } from 'react-icons/lu';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import DeleteCommentModal from 'containers/reports/user/components/userDetails/DeleteCommentModal';
import Badge from 'components/atoms/Badge';
import Container from 'components/atoms/Container';
import Dropdown from 'components/atoms/Dropdown';
import Text from 'components/atoms/Text';
import Paginator from 'components/molecules/Paginator';
import UserProfileImage from 'components/molecules/UserProfileImage';

import { GenericIndexedResponse, GetPostCommentHistoryResponse } from 'api/PostReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { renderUserTier } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { EBadgeVariant, EContainerVariant, ETextVariant } from 'entities/components';
import { ECommentStatus, TPostChildComment } from 'entities/report';

type PostCommentsTabProps = {
    deleteUserCommentAttempt: boolean;
    deleteUserCommentError: string;

    setDeleteCommentModalIsOpen: (state: boolean) => void;

    postCommentHistory: GenericIndexedResponse<GetPostCommentHistoryResponse>;

    page: number;
    setPage: (page: number) => void;
    dateFrom: string;
    dateTo: string;

    loading?: boolean;
    error?: string;
};

const PostCommentsTab = (props: PostCommentsTabProps): JSX.Element => {
    const {
        deleteUserCommentAttempt,
        deleteUserCommentError,
        setDeleteCommentModalIsOpen,
        postCommentHistory,
        page,
        setPage,
        dateFrom,
        dateTo,
        loading,
        error,
    } = props;

    const { pathname } = useLocation();
    const postId = pathname.split('/').pop();

    const [commentId, setCommentId] = useState<string>('');

    const deleteCommentHandler = (id: string) => {
        setCommentId(id);
        setDeleteCommentModalIsOpen(true);
    };

    const renderCommentStatus = (status: ECommentStatus) => {
        switch (status) {
            case ECommentStatus.ADMIN_REMOVED:
                return (
                    <Badge
                        text='Deleted By Admin'
                        variant={EBadgeVariant.danger}
                    />
                );
            case ECommentStatus.USER_REMOVED:
                return (
                    <Badge
                        text='Deleted By User'
                        variant={EBadgeVariant.danger}
                    />
                );
            case ECommentStatus.ACTIVE:
                return null;
            default:
                return null;
        }
    };

    const renderComments = () => {
        if (!postCommentHistory.data || postCommentHistory.data.length === 0) {
            return (
                <Text>
                    There is no comment for this post yet!
                </Text>
            );
        }

        if (loading || deleteUserCommentAttempt) {
            return (
                <div className='flex items-center justify-center'>
                    <RotatingLines strokeColor='white' strokeWidth='5' animationDuration='0.75' width='100' visible />
                </div>
            );
        }

        if (error || deleteUserCommentError) {
            return (
                <div className='flex items-center justify-center'>
                    <Text variant={ETextVariant.error}>{error || deleteUserCommentError}</Text>
                </div>
            );
        }

        return postCommentHistory.data.map((comment) => {
            return (
                <div className='flex flex-col' key={comment._id}>
                    <div className='relative flex flex-col'>
                        <div className='relative flex flex-col'>
                            <div className='flex flex-row items-center gap-md'>
                                <div className='flex size-[60px] flex-col items-center 2xl:size-[70px] 3xl:size-[80px]'>
                                    <UserProfileImage
                                        profileImageUrl={comment.userDetails.profileImageUrl}
                                        userTier={comment.userDetails.userTier}
                                        className='z-10'
                                    />
                                    <div
                                        className={
                                            comment.childCommentCount > 0
                                                ? 'absolute top-[20%] h-4/5 w-[2px] bg-[#F0F0F0]'
                                                : ''
                                        }
                                    />
                                </div>
                                <div className='flex flex-col justify-center'>
                                    <div className='flex grow flex-row items-center gap-md'>
                                        <Text
                                            variant={ETextVariant.default}
                                            className='font-heading-bold text-xl uppercase tracking-wider 2xl:text-2xl'
                                        >
                                            {comment.userDetails.displayName}
                                        </Text>
                                        <Badge
                                            text={renderUserTier(comment.userDetails.userTier)}
                                            variant={EBadgeVariant.grade}
                                        />
                                        <Dropdown
                                            label={<BsThreeDots size={20} color='white' />}
                                            data={[
                                                {
                                                    text: 'Delete',
                                                    onClick: () => {
                                                        deleteCommentHandler(comment._id);
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                    <Text className='flex items-center font-general-medium text-xs text-slate-500 2xl:text-sm'>
                                        {`@${comment.userDetails.username}`}
                                        <LuDot size={20} />
                                        {dayjs(comment.createdAt).format('DD MMM YYYY')}
                                    </Text>
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-md'>
                                <div className='w-[60px] flex-none 2xl:w-[70px] 3xl:w-[80px]' />
                                <div className='flex w-full grow items-center gap-3'>
                                    {comment.commentStatus !== ECommentStatus.ACTIVE && (
                                        renderCommentStatus(comment.commentStatus)
                                    )}
                                    <Text variant={ETextVariant.default}>{comment.content}</Text>
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-md pt-1'>
                                <div className='w-[60px] 2xl:w-[70px] 3xl:w-[80px]' />
                                <Text
                                    variant={ETextVariant.subheader}
                                    className='flex items-center text-sm 2xl:text-lg'
                                >
                                    <LiaCommentDots size={20} className='mr-2' />
                                    {comment.childCommentCount}
                                </Text>
                            </div>
                        </div>
                        {renderChildComments(comment.childComments)}
                    </div>
                </div>
            );
        });
    };

    const renderChildComments = (childComments: TPostChildComment[] | undefined) => {
        if (!childComments) {
            return null;
        }

        return childComments.map((childComment, idx) => {
            return (
                <div className='flex flex-col'>
                    <div className='relative flex flex-row py-3'>
                        <div className='flex w-[60px] flex-col items-center justify-center 2xl:w-[70px] 3xl:w-[80px]'>
                            <div
                                className={cn(
                                    'absolute top-0 w-[2px] bg-[#F0F0F0]',
                                    `${idx === childComments.length - 1 ? 'h-[50%]' : 'h-full'}`,
                                )}
                            />
                            <div className='h-[2px] w-[50%] self-end bg-[#F0F0F0]' />
                        </div>
                        <div className='flex flex-row items-center gap-md'>
                            <div className='relative flex flex-row items-center'>
                                <UserProfileImage
                                    profileImageUrl={childComment.userDetails.profileImageUrl}
                                    userTier={childComment.userDetails.userTier}
                                    className='relative z-10 ml-md size-[60px] 2xl:size-[70px] 3xl:size-[80px]'
                                />
                                <div className='absolute h-[2px] w-[50%] bg-[#F0F0F0]' />
                            </div>
                            <div className='flex flex-col justify-center'>
                                <div className='flex grow flex-row items-center gap-md'>
                                    <Text
                                        variant={ETextVariant.default}
                                        className='font-heading-bold text-xl uppercase tracking-wider 2xl:text-2xl'
                                    >
                                        {childComment.userDetails.displayName}
                                    </Text>
                                    <Badge
                                        text={renderUserTier(childComment.userDetails.userTier)}
                                        variant={EBadgeVariant.grade}
                                    />
                                    <Dropdown
                                        label={<BsThreeDots size={20} color='white' />}
                                        data={[
                                            {
                                                text: 'Delete',
                                                onClick: () => {
                                                    deleteCommentHandler(childComment._id);
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                                <Text className='flex items-center font-general-medium text-xs text-slate-500 2xl:text-sm'>
                                    {`@${childComment.userDetails.username}`}
                                    <LuDot size={20} />
                                    {dayjs(childComment.createdAt).format('DD MMM YYYY')}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className='-mt-3 flex flex-row gap-md'>
                        <div className='relative flex w-[60px] flex-none justify-center 2xl:w-[70px] 3xl:w-[80px]'>
                            <div
                                className={cn(
                                    'absolute top-0 w-[2px] bg-[#F0F0F0]',
                                    `${idx === childComments.length - 1 ? '' : 'h-full'}`,
                                )}
                            />
                        </div>
                        <div className='flex flex-row items-center gap-md'>
                            <div className='w-[60px] flex-none 2xl:w-[70px] 3xl:w-[80px]' />
                            <div className='flex w-full grow'>
                                <Text variant={ETextVariant.default}>{childComment.content}</Text>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <>
            <Container variant={EContainerVariant.gradientBorder} className='flex w-full flex-col gap-md p-default'>
                {renderComments()}
                <DeleteCommentModal
                    commentId={commentId}
                    userId=''
                    postId={postId as string}
                    index={page}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                />
            </Container>
            <Paginator
                currentPage={page}
                maxPage={(postCommentHistory && postCommentHistory.maxIndex !== 0 && postCommentHistory.maxIndex) || 0}
                onFirst={() => page !== 0 && setPage(0)}
                onLast={() => postCommentHistory.maxIndex !== 0 && setPage((postCommentHistory && postCommentHistory.maxIndex) || 0)}
                onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                onNext={() => postCommentHistory.maxIndex !== 0 && setPage(page !== postCommentHistory.maxIndex ? page + 1 : page)}
            />
        </>
    );
};

PostCommentsTab.defaultProps = {
    loading: false,
    error: '',
};

const mapStateToProps = (state: RootState) => ({
    deleteUserCommentAttempt: Selectors.userReportDeleteUserCommentAttempting(state),
    deleteUserCommentError: Selectors.userReportDeleteUserCommentError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDeleteCommentModalIsOpen: (state: boolean) => dispatch(Actions.uiSetDeleteCommentModalIsOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostCommentsTab);
