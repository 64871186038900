import { GetPurchaseReportResponse, GetTotalPurchasesGraphResponse } from 'api/PurchaseReportBase';

import { PurchaseReportState } from '.';

const getTotalPurchasesGraphAttempting = (state: PurchaseReportState): boolean =>
    state.actions.getTotalPurchasesGraph || false;
const getTotalPurchasesGraphError = (state: PurchaseReportState): string => state.error.getTotalPurchasesGraph || '';
const getTotalPurchasesGraphData = (state: PurchaseReportState): GetTotalPurchasesGraphResponse =>
    state.totalPurchasesGraph;

const getPurchaseReportAttempting = (state: PurchaseReportState): boolean => state.actions.getPurchaseReport || false;
const getPurchaseReportError = (state: PurchaseReportState): string => state.error.getPurchaseReport || '';
const getPurchaseReportData = (state: PurchaseReportState): GetPurchaseReportResponse => state.purchaseReport;

export default {
    getTotalPurchasesGraphAttempting,
    getTotalPurchasesGraphData,
    getTotalPurchasesGraphError,

    getPurchaseReportAttempting,
    getPurchaseReportError,
    getPurchaseReportData,
};
