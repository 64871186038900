import { TPurchasesChartData } from 'entities/components';
import { TStatusDetails } from 'entities/dashboard';

import Gateway from './types/Gateway';
import { GatewayResponse } from './types/types';

export type GetDashboardDetailsParams = {
    authToken: string,
}

export type GetDashboardDetailsResponse = {
    newUsers:TStatusDetails;
    activeUsers:TStatusDetails;
    pendingReports: TStatusDetails;
    purchaseReport:{
        amount: number;
        data: TPurchasesChartData[]
    },
    paymentReport:{
        commissionMade: number;
        pendingPayment: number;
    }
}

export abstract class IDashboardGateway extends Gateway {
    abstract getDashboardDetails(params: GetDashboardDetailsParams): GatewayResponse<GetDashboardDetailsResponse>;
}
