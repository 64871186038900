import React from 'react';

import { FaRegUser, FaUserShield } from 'react-icons/fa';
import { FiShoppingBag } from 'react-icons/fi';
import { IoDocumentOutline, IoSettingsOutline } from 'react-icons/io5';
import { MdCancel, MdOutlineLightbulb, MdOutlinePayment } from 'react-icons/md';

import { EPermission } from 'entities/auth';
import {
    EMenuList,
    EPostDetailsTab,
    ETotalUserType,
    EUserDetailsTab,
} from 'entities/ui';

export type TMenuButton = {
    icon: React.ReactNode,
    text: string,
    type: EMenuList,
    permission: EPermission,
}
export type TTabButton = {
    text: string,
    type: number,
}
export const menuReports: TMenuButton[] = [
    {
        icon: <FaRegUser size={20} />,
        text: 'User Report',
        type: EMenuList.userReport,
        permission: EPermission.userReport,
    },
    {
        icon: <IoDocumentOutline size={20} />,
        text: 'Post Report',
        type: EMenuList.postReport,
        permission: EPermission.postReport,
    },
    {
        icon: <MdOutlineLightbulb size={20} />,
        text: 'Topic Report',
        type: EMenuList.topicReport,
        permission: EPermission.topicReport,
    },
    {
        icon: <FiShoppingBag size={20} />,
        text: 'Purchase Report',
        type: EMenuList.purchaseReport,
        permission: EPermission.purchaseReport,
    },
    {
        icon: <MdOutlinePayment size={20} />,
        text: 'Payment Report',
        type: EMenuList.paymentReport,
        permission: EPermission.paymentReport,
    },
    {
        icon: <MdCancel size={20} />,
        text: 'Moderation Report',
        type: EMenuList.moderationReport,
        permission: EPermission.moderationReport,
    },
];
export const menuSettings: TMenuButton[] = [
    {
        icon: <IoSettingsOutline size={20} />,
        text: 'System Configuration',
        type: EMenuList.systemSetting,
        permission: EPermission.systemSetting,
    },
    {
        icon: <FaUserShield size={20} />,
        text: 'Subadmin Access',
        type: EMenuList.subadminSetting,
        permission: EPermission.subadminSetting,
    },
];
export const tabTotalUserType: TTabButton[] = [
    {
        text: 'NEW USER',
        type: ETotalUserType.newUser,
    },
    {
        text: 'ACTIVE USER',
        type: ETotalUserType.activeUser,
    },
];
export const tabUserDetails: TTabButton[] = [
    {
        text: 'ALL POST',
        type: EUserDetailsTab.allPosts,
    },
    {
        text: 'COMMENTS HISTORIES ',
        type: EUserDetailsTab.commentsHistories,
    },
    {
        text: 'GRADE HISTORIES',
        type: EUserDetailsTab.gradeHistories,
    },
    {
        text: 'SETTINGS',
        type: EUserDetailsTab.settings,
    },
];

export const tabPostDetails: TTabButton[] = [
    {
        text: 'COMMENTS HISTORIES',
        type: EPostDetailsTab.commentsHistories,
    },
    {
        text: 'GRADE HISTORIES',
        type: EPostDetailsTab.gradeHistories,
    },
    {
        text: 'VIEW REPORT',
        type: EPostDetailsTab.viewReport,
    },
    {
        text: 'PURCHASE HISTORIES',
        type: EPostDetailsTab.purchaseHistories,
    },
    {
        text: 'SETTINGS',
        type: EPostDetailsTab.settings,
    },
];

export default {
    menuReports,
    menuSettings,
    tabUserDetails,
};
