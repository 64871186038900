import React from 'react';

import dayjs from 'dayjs';
import { IoEllipseSharp } from 'react-icons/io5';

import Badge from 'components/atoms/Badge';
import Container from 'components/atoms/Container';
import Divider from 'components/atoms/Divider';
import Text from 'components/atoms/Text';

import { renderCurrency, renderPaymentStatus } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { EBadgeVariant, EContainerVariant, ETextVariant } from 'entities/components';
import { TPaymentHistory, TPaymentReportDetails } from 'entities/report';

type PaymentDetailsCardProps = {
    paymentDetailsData: TPaymentReportDetails;
};

const PaymentDetailsCard = (props: PaymentDetailsCardProps): JSX.Element => {
    const { paymentDetailsData } = props;

    const renderPaymentHistorySection = () => {
        return paymentDetailsData.paymentHistory ? (
            <>
                <Divider className='my-2 h-[1px] bg-[#707070]' />
                <Text variant={ETextVariant.default} className='font-general-medium text-sm 2xl:text-base'>
                    Payment Histories
                </Text>
                <div className='flex flex-col gap-0'>{renderPaymentHistories(paymentDetailsData.paymentHistory)}</div>
            </>
        ) : (
            <div />
        );
    };

    const renderPaymentHistories = (paymentHistory: TPaymentHistory[]) => {
        const sortedPaymentHistory = [...paymentHistory].sort((a, b) => {
            return new Date(a.scheduledPaymentDate).valueOf() - new Date(b.scheduledPaymentDate).valueOf();
        });

        return sortedPaymentHistory.map((payment, idx) => {
            return (
                <div className='relative flex flex-row items-center justify-between py-sm'>
                    <div className='flex flex-row items-center gap-4'>
                        <div className='flex min-w-[60px] flex-col items-end gap-0 2xl:min-w-[80px]'>
                            <Text
                                variant={ETextVariant.default}
                                className='font-heading text-sm !leading-none tracking-wide 2xl:text-xl'
                            >
                                {dayjs(payment.scheduledPaymentDate).format('DD MMM YYYY')}
                            </Text>
                            <Text
                                variant={ETextVariant.default}
                                className='font-heading text-sm !leading-none tracking-wide 2xl:text-xl'
                            >
                                {dayjs(payment.scheduledPaymentDate).format('HH:mm')}
                            </Text>
                        </div>
                        <div className='flex flex-col items-center'>
                            <IoEllipseSharp
                                size='1rem'
                                color={idx === sortedPaymentHistory.length - 1 ? '#0076FF' : '#838696'}
                                className='z-10'
                            />
                            <div
                                className={
                                    paymentHistory.length > 1
                                        ? cn(
                                            'absolute bottom-0 w-[1px] bg-[#838696]',
                                            `${idx === 0 ? 'top-[50%]' : 'top-0'}`,
                                            `${idx === 0 || idx === paymentHistory.length - 1 ? 'h-1/2' : 'h-full'}`,
                                        )
                                        : ''
                                }
                            />
                        </div>
                        <Text
                            variant={ETextVariant.default}
                            className='font-general-medium text-sm text-[#838696] 2xl:text-base'
                        >
                            {payment.title}
                        </Text>
                    </div>
                    <Text variant={ETextVariant.default} className='font-general-bold text-sm 2xl:text-base'>
                        {renderCurrency(payment.paidAmount)}
                    </Text>
                </div>
            );
        });
    };

    return (
        <Container
            variant={EContainerVariant.gradientBorder}
            parentClassName='h-auto'
            className='flex h-auto items-center justify-between p-default'
        >
            <div className='flex size-full flex-col gap-sm'>
                <div className='flex items-center gap-sm'>
                    <Text variant={ETextVariant.default} className='font-general-bold text-[28px] 2xl:text-[28px]'>
                        {paymentDetailsData.title}
                    </Text>
                    <Badge text={renderPaymentStatus(paymentDetailsData.status)} variant={EBadgeVariant.paymentStatus} className='text-[18px]' />
                </div>
                <Divider className='my-3 h-[1px] bg-[#707070]' />
                <div className='flex justify-between'>
                    <Text variant={ETextVariant.default} className='font-general-medium text-sm 2xl:text-base'>
                        Total Owed
                    </Text>
                    <Text variant={ETextVariant.default} className='font-general-bold text-sm 2xl:text-base'>
                        {renderCurrency(paymentDetailsData.totalOwed)}
                    </Text>
                </div>
                <div className='flex justify-between'>
                    <Text variant={ETextVariant.default} className='font-general-medium text-sm 2xl:text-base'>
                        Total Payment
                    </Text>
                    <Text variant={ETextVariant.default} className='font-general-bold text-sm 2xl:text-base'>
                        {renderCurrency(paymentDetailsData.totalPaid)}
                    </Text>
                </div>
                <div className='flex justify-between'>
                    <Text
                        variant={ETextVariant.default}
                        className='self-end pb-2 font-general-medium text-sm 2xl:text-base'
                    >
                        Balance
                    </Text>
                    <Text
                        variant={ETextVariant.default}
                        className='font-general-bold text-[45px] text-button-primary 2xl:text-[45px]'
                    >
                        {renderCurrency(paymentDetailsData.balance)}
                    </Text>
                </div>
                {renderPaymentHistorySection()}
            </div>
        </Container>
    );
};

PaymentDetailsCard.defaultProps = {
    // paymentDetailsData: [],
};

export default PaymentDetailsCard;
