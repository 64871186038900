import React from 'react';

import { renderPaymentStatus, renderUserTier } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { EBadgeVariant, ETextVariant } from 'entities/components';
import { EPaymentReportStatus, EUserTier } from 'entities/report';

import Text from './Text';

type BadgeProps = {
    text: string;
    variant?: EBadgeVariant;
    className?: string;
}

const Badge = (props: BadgeProps): JSX.Element => {
    const {
        text,
        variant,
        className,
    } = props;

    const defaultClass = 'w-fit rounded-3xl px-md pb-1';
    const variantClasses = {
        [EBadgeVariant.default]: cn(defaultClass, 'bg-button-primary', className),
        [EBadgeVariant.success]: cn(defaultClass, 'bg-success', className),
        [EBadgeVariant.warning]: cn(defaultClass, 'bg-warning', className),
        [EBadgeVariant.danger]: cn(defaultClass, 'bg-error', className),
        [EBadgeVariant.topic]: cn(defaultClass, 'bg-button-primary', className),
        [EBadgeVariant.grade]: cn(defaultClass, className),
        [EBadgeVariant.paymentStatus]: cn(defaultClass, className),
    };

    const variantGradeClasses = {
        [renderUserTier(EUserTier.MASTER)]: 'bg-gradient-to-tr from-yellow-500 to-red-500',
        [renderUserTier(EUserTier.ADVANCED)]: 'bg-gradient-to-tr from-green-500 to-blue-500',
        [renderUserTier(EUserTier.INTERMEDIATE)]: 'bg-gradient-to-tr from-blue-500 to-stone-500',
        [renderUserTier(EUserTier.BEGINNER)]: 'bg-gradient-to-tr from-stone-500 to-slate-500',
        [renderUserTier(EUserTier.MASTER, true)]: 'bg-gradient-to-tr from-yellow-500 to-red-500',
        [renderUserTier(EUserTier.ADVANCED, true)]: 'bg-gradient-to-tr from-green-500 to-blue-500',
        [renderUserTier(EUserTier.INTERMEDIATE, true)]: 'bg-gradient-to-tr from-blue-500 to-stone-500',
        [renderUserTier(EUserTier.BEGINNER, true)]: 'bg-gradient-to-tr from-stone-500 to-slate-500',
    };

    const variantPaymentStatusClasses = {
        [renderPaymentStatus(EPaymentReportStatus.PENDING)]: 'bg-warning',
        [renderPaymentStatus(EPaymentReportStatus.COMPLETED)]: 'bg-success',
    };

    if (variant === EBadgeVariant.grade) {
        return (
            <div
                className={cn(
                    variantClasses[EBadgeVariant.grade],
                    variantGradeClasses[text],
                    className,
                )}
            >
                <Text
                    variant={ETextVariant.header}
                    className='text-xs uppercase 2xl:text-sm'
                >
                    {text}
                </Text>
            </div>
        );
    }

    if (variant === EBadgeVariant.paymentStatus) {
        return (
            <div
                className={cn(
                    variantClasses[EBadgeVariant.paymentStatus],
                    variantPaymentStatusClasses[text],
                    className,
                )}
            >
                <Text
                    variant={ETextVariant.header}
                    className='text-xs uppercase 2xl:text-sm'
                >
                    {text}
                </Text>
            </div>
        );
    }

    return (
        <div
            className={variantClasses[variant ?? EBadgeVariant.default]}
        >
            <Text
                variant={ETextVariant.header}
                className='text-xs 2xl:text-sm'
            >
                {text}
            </Text>
        </div>
    );
};

Badge.defaultProps = {
    variant: EBadgeVariant.default,
    className: '',
};

export default Badge;
