import { GetDashboardDetailsResponse } from 'api/DashboardBase';
import { GetModerationReportDetailsResponse, GetModerationReportResponse } from 'api/ModerationReportBase';
import { GetPaymentReportDetailsResponse, GetPaymentReportListingResponse, GetPaymentSelectorResponse, GetTotalPaymentReportResponse } from 'api/PaymentReportBase';
import { GetAllTopicsResponse, GetPostCommentHistoryResponse, GetPostDetailsResponse, GetPostGradeHistoryResponse, GetPostModerationDetailsResponse, GetPostModerationResponse, GetPostPurchaseHistoryResponse, GetPostReportResponse } from 'api/PostReportBase';
import { GetPurchaseReportResponse, GetTotalPurchasesGraphResponse } from 'api/PurchaseReportBase';
import { GetSubAdminListResponse } from 'api/SubAdminBase';
import { GetSystemConfigResponse } from 'api/SystemConfigurationBase';
import { GetTopicsResponse } from 'api/TopicReportBase';
import {
    GenericIndexedResponse,
    GetRepLeaderboardResponse,
    GetTotalUsersResponse,
    GetUserCommentsResponse,
    GetUserGradesResponse,
    GetUserPostsResponse,
    GetUserReportResponse,
    GetUserSummaryResponse,
} from 'api/UserReportBase';
import auth from 'redux/slices/auth/Selectors';
import dashboard from 'redux/slices/dashboard/Selectors';
import postReport from 'redux/slices/postReport/Selectors';
import topicReport from 'redux/slices/topicReport/Selectors';
import ui from 'redux/slices/ui/Selectors';
import userReport from 'redux/slices/userReport/Selectors';

import { IAuthUser } from 'entities/auth';
import { TDateFilterString } from 'entities/components';
import { EMenuList, EPostDetailsTab, ETotalUserType, EUserDetailsTab } from 'entities/ui';

import moderationReport from './slices/moderationReport/Selectors';
import paymentReport from './slices/paymentReport/Selectors';
import purchaseReport from './slices/purchaseReport/Selectors';
import subAdmin from './slices/subAdmin/Selectors';
import systemConfiguration from './slices/systemConfiguration/Selectors';
import { RootState } from './store';

// UI
const uiGetCurrentPage = (state: RootState): EMenuList => ui.getCurrentPage(state.ui);
const uiGetCurrentTotalUserType = (state: RootState): ETotalUserType => ui.getCurrentTotalUserType(state.ui);
const uiGetCurrentUserDetailsTab = (state: RootState): EUserDetailsTab => ui.getCurrentUserDetailsTab(state.ui);
const uiGetCurrentPostDetailsTab = (state: RootState): EPostDetailsTab => ui.getCurrentPostDetailsTab(state.ui);

const uiGetUserReportDateFilter = (state: RootState): TDateFilterString => ui.getCurrentUserReportDateFilter(state.ui);
const uiGetUserDetailsDateFilter = (state: RootState): TDateFilterString => ui.getCurrentUserDetailsDateFilter(state.ui);
const uiGetPostReportDateFilter = (state: RootState): TDateFilterString => ui.getCurrentPostReportDateFilter(state.ui);
const uiGetPostDetailsDateFilter = (state: RootState): TDateFilterString => ui.getCurrentPostDetailsDateFilter(state.ui);
const uiGetPurchaseReportDateFilter = (state: RootState): TDateFilterString => ui.getCurrentPurchaseReportDateFilter(state.ui);
const uiGetPaymentReportDateFilter = (state: RootState): TDateFilterString => ui.getCurrentPaymentReportDateFilter(state.ui);
const uiGetPaymentDetailsDateFilter = (state: RootState): TDateFilterString => ui.getCurrentPaymentDetailsDateFilter(state.ui);
const uiGetModerationReportDateFilter = (state: RootState): TDateFilterString => ui.getCurrentModerationReportDateFilter(state.ui);

const uiBanUserModalIsOpen = (state: RootState): boolean => ui.banUserModalIsOpen(state.ui);
const uiUnbanUserModalIsOpen = (state: RootState): boolean => ui.unbanUserModalIsOpen(state.ui);
const uiDeleteCommentIsOpen = (state: RootState): boolean => ui.deleteCommentModalIsOpen(state.ui);
const uiAddTopicModalIsOpen = (state: RootState): boolean => ui.addTopicModalIsOpen(state.ui);
const uiEditTopicModalIsOpen = (state: RootState): boolean => ui.editTopicModalIsOpen(state.ui);
const uiDeleteTopicModalIsOpen = (state: RootState): boolean => ui.deleteTopicModalIsOpen(state.ui);
const uiSetPostModerationReviewModalIsOpen = (state: RootState): boolean => ui.setPostModerationReviewModalIsOpen(state.ui);
const uiDeleteUserPostModalIsOpen = (state: RootState): boolean => ui.deleteUserPostModalIsOpen(state.ui);
const uiAddSubadminModalIsOpen = (state: RootState): boolean => ui.addSubadminModalIsOpen(state.ui);
const uiViewSubadminModalIsOpen = (state: RootState): boolean => ui.viewSubadminModalIsOpen(state.ui);
const uiEditSubadminModalIsOpen = (state: RootState): boolean => ui.editSubadminModalIsOpen(state.ui);
const uiRevokeSubadminModalIsOpen = (state: RootState): boolean => ui.revokeSubadminModalIsOpen(state.ui);
const uiGetModerationReportDetailsModalIsOpen = (state: RootState): boolean => ui.getModerationReportDetailsModalIsOpen(state.ui);

// Auth
const authGetLoginAttempting = (state: RootState): boolean => auth.getLoginAttempting(state.auth);
const authGetLoginError = (state: RootState): string => auth.getLoginError(state.auth);
const authGetStartupAttempting = (state: RootState): boolean => auth.getStartupAttempting(state.auth);
const authGetStartupError = (state: RootState): string => auth.getStartupError(state.auth);
const authGetForgotPasswordAttempting = (state: RootState): boolean => auth.getForgotPasswordAttempting(state.auth);
const authGetForgotPasswordError = (state: RootState): string => auth.getForgotPasswordError(state.auth);
const authGetVerifyOtpAttempting = (state: RootState): boolean => auth.getVerifyOtpAttempting(state.auth);
const authGetVerifyOtpError = (state: RootState): string => auth.getVerifyOtpError(state.auth);
const authGetResetPasswordAttempting = (state: RootState): boolean => auth.getResetPasswordAttempting(state.auth);
const authGetResetPasswordError = (state: RootState): string => auth.getResetPasswordError(state.auth);

const authGetAuthToken = (state: RootState): string => auth.getAuthToken(state.auth);
const authGetUserInfo = (state: RootState): IAuthUser | null => auth.getUserInfo(state.auth);

// Dashboard
const dashboardGetDashboardDetailsAttempting = (state: RootState): boolean => dashboard.getDashboardDetailsAttempting(state.dashboard);
const dashboardGetDashboardDetailsError = (state: RootState): string => dashboard.getDashboardDetailsError(state.dashboard);
const dashboardGetDashboardDetailsData = (state: RootState): GetDashboardDetailsResponse => dashboard.getDashboardDetailsData(state.dashboard);

// User Report
const userReportGetUserReportAttempting = (state: RootState): boolean => userReport.getUserReportAttempting(state.userReport);
const userReportGetUserReportError = (state: RootState): string => userReport.getUserReportError(state.userReport);
const userReportGetUserReportData = (state: RootState): GenericIndexedResponse<GetUserReportResponse> => userReport.getUserReportData(state.userReport);

const userReportGetTotalUsersAttempting = (state: RootState): boolean => userReport.getTotalUsersAttempting(state.userReport);
const userReportGetTotalUsersError = (state: RootState): string => userReport.getTotalUsersError(state.userReport);
const userReportGetTotalUsersData = (state: RootState): GetTotalUsersResponse => userReport.getTotalUsersData(state.userReport);

const userReportGetUserSummaryAttempting = (state: RootState): boolean => userReport.getUserSummaryAttempting(state.userReport);
const userReportGetUserSummaryError = (state: RootState): string => userReport.getUserSummaryError(state.userReport);
const userReportGetUserSummaryData = (state: RootState): GetUserSummaryResponse => userReport.getUserSummaryData(state.userReport);

const userReportGetUserPostsAttempting = (state: RootState): boolean => userReport.getUserPostsAttempting(state.userReport);
const userReportGetUserPostsError = (state: RootState): string => userReport.getUserPostsError(state.userReport);
const userReportGetUserPostsData = (state: RootState): GenericIndexedResponse<GetUserPostsResponse> => userReport.getUserPostsData(state.userReport);

const userReportGetUserCommentsAttempting = (state: RootState): boolean => userReport.getUserCommentsAttempting(state.userReport);
const userReportGetUserCommentsError = (state: RootState): string => userReport.getUserCommentsError(state.userReport);
const userReportGetUserCommentsData = (state: RootState): GenericIndexedResponse<GetUserCommentsResponse> => userReport.getUserCommentsData(state.userReport);

const userReportGetUserGradesAttempting = (state: RootState): boolean => userReport.getUserGradesAttempting(state.userReport);
const userReportGetUserGradesError = (state: RootState): string => userReport.getUserGradesError(state.userReport);
const userReportGetUserGradesData = (state: RootState): GenericIndexedResponse<GetUserGradesResponse> => userReport.getUserGradesData(state.userReport);

const userReportGetRepLeaderboardAttempting = (state: RootState): boolean => userReport.getRepLeaderboardAttempting(state.userReport);
const userReportGetRepLeaderboardError = (state: RootState): string => userReport.getRepLeaderboardError(state.userReport);
const userReportGetRepLeaderboardData = (state: RootState): GenericIndexedResponse<GetRepLeaderboardResponse> => userReport.getRepLeaderboardData(state.userReport);

const userReportSetUserCommissionAttempting = (state: RootState): boolean => userReport.setUserCommissionAttempting(state.userReport);
const userReportSetUserCommissionError = (state: RootState): string => userReport.setUserCommissionError(state.userReport);

const userReportSetUserToBanAttempting = (state: RootState): boolean => userReport.setUserToBanAttempting(state.userReport);
const userReportSetUserToBanError = (state: RootState): string => userReport.setUserToBanError(state.userReport);

const userReportSetUserToUnbanAttempting = (state: RootState): boolean => userReport.setUserToUnbanAttempting(state.userReport);
const userReportSetUserToUnbanError = (state: RootState): string => userReport.setUserToUnbanError(state.userReport);

const userReportDeleteUserCommentAttempting = (state: RootState): boolean => userReport.deleteUserCommentsAttempting(state.userReport);
const userReportDeleteUserCommentError = (state: RootState): string => userReport.deleteUserCommentsError(state.userReport);

// Post Report
const postReportGetPostReportAttempting = (state: RootState): boolean => postReport.getPostReportAttempting(state.postReport);
const postReportGetPostReportError = (state: RootState): string => postReport.getPostReportError(state.postReport);
const postReportGetPostReportData = (state: RootState): GetPostReportResponse => postReport.getPostReportData(state.postReport);

const postReportGetAllTopicsAttempting = (state: RootState): boolean => postReport.getAllTopicsAttempting(state.postReport);
const postReportGetAllTopicsError = (state: RootState): string => postReport.getAllTopicsError(state.postReport);
const postReportGetAllTopicsData = (state: RootState): GetAllTopicsResponse[] => postReport.getAllTopicsData(state.postReport);

const postReportGetPostDetailsAttempting = (state: RootState): boolean => postReport.getPostDetailsAttempting(state.postReport);
const postReportGetPostDetailsError = (state: RootState): string => postReport.getPostDetailsError(state.postReport);
const postReportGetPostDetailsData = (state: RootState): GetPostDetailsResponse => postReport.getPostDetailsData(state.postReport);

const postReportGetPostCommentHistoryAttempting = (state: RootState): boolean => postReport.getPostCommentHistoryAttempting(state.postReport);
const postReportGetPostCommentHistoryError = (state: RootState): string => postReport.getPostCommentHistoryError(state.postReport);
const postReportGetPostCommentHistoryData = (state: RootState): GenericIndexedResponse<GetPostCommentHistoryResponse> => postReport.getPostCommentHistoryData(state.postReport);

const postReportGetPostGradeHistoryAttempting = (state: RootState): boolean => postReport.getPostGradeHistoryAttempting(state.postReport);
const postReportGetPostGradeHistoryError = (state: RootState): string => postReport.getPostGradeHistoryError(state.postReport);
const postReportGetPostGradeHistoryData = (state: RootState): GenericIndexedResponse<GetPostGradeHistoryResponse> => postReport.getPostGradeHistoryData(state.postReport);

const postReportGetPostModerationAttempting = (state: RootState): boolean => postReport.getPostModerationAttempting(state.postReport);
const postReportGetPostModerationError = (state: RootState): string => postReport.getPostModerationError(state.postReport);
const postReportGetPostModerationData = (state: RootState): GenericIndexedResponse<GetPostModerationResponse> => postReport.getPostModerationData(state.postReport);

const postReportGetPostModerationDetailsAttempting = (state: RootState): boolean => postReport.getPostModerationDetailsAttempting(state.postReport);
const postReportGetPostModerationDetailsError = (state: RootState): string => postReport.getPostModerationDetailsError(state.postReport);
const postReportGetPostModerationDetailsData = (state: RootState): GetPostModerationDetailsResponse => postReport.getPostModerationDetailsData(state.postReport);

const postReportSetPostModerationReviewAttempting = (state: RootState): boolean => postReport.setPostModerationReviewAttempting(state.postReport);
const postReportSetPostModerationReviewError = (state: RootState): string => postReport.setPostModerationReviewError(state.postReport);

const postReportGetPostPurchaseHistoryAttempting = (state: RootState): boolean => postReport.getPostPurchaseHistoryAttempting(state.postReport);
const postReportGetPostPurchaseHistoryError = (state: RootState): string => postReport.getPostPurchaseHistoryError(state.postReport);
const postReportGetPostPurchaseHistoryData = (state: RootState): GenericIndexedResponse<GetPostPurchaseHistoryResponse> => postReport.getPostPurchaseHistoryData(state.postReport);

const postReportSetFeaturedPostAttempting = (state: RootState): boolean => postReport.setFeaturedPostAttempting(state.postReport);
const postReportSetFeaturedPostError = (state: RootState): string => postReport.setFeaturedPostError(state.postReport);

const postReportDeleteUserPostAttempting = (state: RootState): boolean => postReport.deleteUserPostAttempting(state.postReport);
const postReportDeleteUserPostError = (state: RootState): string => postReport.deleteUserPostError(state.postReport);

// Topic Report
const topicReportGetTopicsAttempting = (state: RootState): boolean => topicReport.getTopicsAttempting(state.topicReport);
const topicReportGetTopicsError = (state: RootState): string => topicReport.getTopicsError(state.topicReport);
const topicReportGetTopicsData = (state: RootState): GenericIndexedResponse<GetTopicsResponse> => topicReport.getTopicsData(state.topicReport);

const topicReportAddTopicAttempting = (state: RootState): boolean => topicReport.addTopicAttempting(state.topicReport);
const topicReportAddTopicError = (state: RootState): string => topicReport.addTopicError(state.topicReport);

const topicReportEditTopicAttempting = (state: RootState): boolean => topicReport.editTopicAttempting(state.topicReport);
const topicReportEditTopicError = (state: RootState): string => topicReport.editTopicError(state.topicReport);

const topicReportDeleteTopicAttempting = (state: RootState): boolean => topicReport.deleteTopicAttempting(state.topicReport);
const topicReportDeleteTopicError = (state: RootState): string => topicReport.deleteTopicError(state.topicReport);

// Purchase Report
const purchaseReportGetTotalPurchasesGraphAttempting = (state: RootState): boolean => purchaseReport.getTotalPurchasesGraphAttempting(state.purchaseReport);
const purchaseReportGetTotalPurchasesGraphError = (state: RootState): string => purchaseReport.getTotalPurchasesGraphError(state.purchaseReport);
const purchaseReportGetTotalPurchasesGraphData = (state: RootState): GetTotalPurchasesGraphResponse => purchaseReport.getTotalPurchasesGraphData(state.purchaseReport);

const purchaseReportGetPurchaseReportAttempting = (state: RootState): boolean => purchaseReport.getPurchaseReportAttempting(state.purchaseReport);
const purchaseReportGetPurchaseReportError = (state: RootState): string => purchaseReport.getPurchaseReportError(state.purchaseReport);
const purchaseReportGetPurchaseReportData = (state: RootState): GetPurchaseReportResponse => purchaseReport.getPurchaseReportData(state.purchaseReport);

// Payment Report
const paymentReportGetTotalPaymentReportAttempting = (state: RootState): boolean => paymentReport.getTotalPaymentReportAttempting(state.paymentReport);
const paymentReportGetTotalPaymentReportError = (state: RootState): string => paymentReport.getTotalPaymentReportError(state.paymentReport);
const paymentReportGetTotalPaymentReportData = (state: RootState): GetTotalPaymentReportResponse => paymentReport.getTotalPaymentReportData(state.paymentReport);

const paymentReportGetPaymentReportListingAttempting = (state: RootState): boolean => paymentReport.getPaymentReportListingAttempting(state.paymentReport);
const paymentReportGetPaymentReportListingError = (state: RootState): string => paymentReport.getPaymentReportListingError(state.paymentReport);
const paymentReportGetPaymentReportListingData = (state: RootState): GenericIndexedResponse<GetPaymentReportListingResponse> => paymentReport.getPaymentReportListingData(state.paymentReport);

const paymentReportGetPaymentSelectorAttempting = (state: RootState): boolean => paymentReport.getPaymentSelectorAttempting(state.paymentReport);
const paymentReportGetPaymentSelectorError = (state: RootState): string => paymentReport.getPaymentSelectorError(state.paymentReport);
const paymentReportGetPaymentSelectorData = (state: RootState): GetPaymentSelectorResponse => paymentReport.getPaymentSelectorData(state.paymentReport);

const paymentReportGetPaymentReportDetailsAttempting = (state: RootState): boolean => paymentReport.getPaymentReportDetailsAttempting(state.paymentReport);
const paymentReportGetPaymentReportDetailsError = (state: RootState): string => paymentReport.getPaymentReportDetailsError(state.paymentReport);
const paymentReportGetPaymentReportDetailsData = (state: RootState): GetPaymentReportDetailsResponse => paymentReport.getPaymentReportDetailsData(state.paymentReport);

const paymentReportCreatePaymentAttempting = (state: RootState): boolean => paymentReport.createPaymentAttempting(state.paymentReport);
const paymentReportCreatePaymentError = (state: RootState): string => paymentReport.createPaymentError(state.paymentReport);

// Moderation Report
const moderationReportGetModerationReportAttempting = (state: RootState): boolean => moderationReport.getModerationReportAttempting(state.moderationReport);
const moderationReportGetModerationReportError = (state: RootState): string => moderationReport.getModerationReportError(state.moderationReport);
const moderationReportGetModerationReportData = (state: RootState): GetModerationReportResponse => moderationReport.getModerationReportData(state.moderationReport);

const moderationReportGetModerationReportDetailsAttempting = (state: RootState): boolean => moderationReport.getModerationReportDetailsAttempting(state.moderationReport);
const moderationReportGetModerationReportDetailsError = (state: RootState): string => moderationReport.getModerationReportDetailsError(state.moderationReport);
const moderationReportGetModerationReportDetailsData = (state: RootState): GetModerationReportDetailsResponse => moderationReport.getModerationReportDetailsData(state.moderationReport);

const moderationReportSetModerationReportStatusAttempting = (state: RootState): boolean => moderationReport.setModerationReportStatusAttempting(state.moderationReport);
const moderationReportSetModerationReportStatusError = (state: RootState): string => moderationReport.setModerationReportStatusError(state.moderationReport);

// Sub Admin
const subAdminGetSubAdminListAttempting = (state: RootState): boolean => subAdmin.getSubAdminListAttempting(state.subAdmin);
const subAdminGetSubAdminListError = (state: RootState): string => subAdmin.getSubAdminListError(state.subAdmin);
const subAdminGetSubAdminListData = (state: RootState): GetSubAdminListResponse => subAdmin.getSubAdminListData(state.subAdmin);

const subAdminSetSubAdminAccessAttempting = (state: RootState): boolean => subAdmin.setSubAdminAccessAttempting(state.subAdmin);
const subAdminSetSubAdminAccessError = (state: RootState): string => subAdmin.setSubAdminAccessError(state.subAdmin);

const subAdminEditSubAdminAccessAttempting = (state: RootState): boolean => subAdmin.editSubAdminAccessAttempting(state.subAdmin);
const subAdminEditSubAdminAccessError = (state: RootState): string => subAdmin.editSubAdminAccessError(state.subAdmin);

// System Configuration
const systemConfigurationSetSystemConfigAttempting = (state: RootState): boolean => systemConfiguration.setSystemConfigAttempting(state.systemConfiguration);
const systemConfigurationSetSystemConfigError = (state: RootState): string => systemConfiguration.setSystemConfigError(state.systemConfiguration);

const systemConfigurationGetSystemConfigAttempting = (state: RootState): boolean => systemConfiguration.getSystemConfigAttempting(state.systemConfiguration);
const systemConfigurationGetSystemConfigError = (state: RootState): string => systemConfiguration.getSystemConfigError(state.systemConfiguration);
const systemConfigurationGetSystemConfigData = (state: RootState): GetSystemConfigResponse => systemConfiguration.getSystemConfigData(state.systemConfiguration);

export default {
    uiGetCurrentPage,
    uiGetCurrentTotalUserType,
    uiGetCurrentUserDetailsTab,
    uiGetCurrentPostDetailsTab,
    uiGetUserReportDateFilter,
    uiGetUserDetailsDateFilter,
    uiGetPostReportDateFilter,
    uiGetPostDetailsDateFilter,
    uiGetPurchaseReportDateFilter,
    uiGetPaymentReportDateFilter,
    uiGetPaymentDetailsDateFilter,
    uiGetModerationReportDateFilter,
    uiBanUserModalIsOpen,
    uiUnbanUserModalIsOpen,
    uiDeleteCommentIsOpen,
    uiAddTopicModalIsOpen,
    uiEditTopicModalIsOpen,
    uiDeleteTopicModalIsOpen,
    uiSetPostModerationReviewModalIsOpen,
    uiDeleteUserPostModalIsOpen,
    uiAddSubadminModalIsOpen,
    uiViewSubadminModalIsOpen,
    uiEditSubadminModalIsOpen,
    uiRevokeSubadminModalIsOpen,
    uiGetModerationReportDetailsModalIsOpen,

    authGetLoginAttempting,
    authGetLoginError,
    authGetStartupAttempting,
    authGetStartupError,
    authGetForgotPasswordAttempting,
    authGetForgotPasswordError,
    authGetVerifyOtpAttempting,
    authGetVerifyOtpError,
    authGetResetPasswordAttempting,
    authGetResetPasswordError,
    authGetAuthToken,
    authGetUserInfo,

    dashboardGetDashboardDetailsAttempting,
    dashboardGetDashboardDetailsError,
    dashboardGetDashboardDetailsData,

    userReportGetUserReportAttempting,
    userReportGetUserReportError,
    userReportGetUserReportData,
    userReportGetTotalUsersAttempting,
    userReportGetTotalUsersError,
    userReportGetTotalUsersData,
    userReportGetUserSummaryAttempting,
    userReportGetUserSummaryError,
    userReportGetUserSummaryData,
    userReportGetUserPostsAttempting,
    userReportGetUserPostsError,
    userReportGetUserPostsData,
    userReportGetUserCommentsAttempting,
    userReportGetUserCommentsError,
    userReportGetUserCommentsData,
    userReportGetUserGradesAttempting,
    userReportGetUserGradesError,
    userReportGetUserGradesData,
    userReportGetRepLeaderboardAttempting,
    userReportGetRepLeaderboardError,
    userReportGetRepLeaderboardData,
    userReportSetUserCommissionAttempting,
    userReportSetUserCommissionError,
    userReportSetUserToBanAttempting,
    userReportSetUserToBanError,
    userReportSetUserToUnbanAttempting,
    userReportSetUserToUnbanError,
    userReportDeleteUserCommentAttempting,
    userReportDeleteUserCommentError,

    postReportGetPostReportAttempting,
    postReportGetPostReportError,
    postReportGetPostReportData,
    postReportGetAllTopicsAttempting,
    postReportGetAllTopicsError,
    postReportGetAllTopicsData,
    postReportGetPostDetailsAttempting,
    postReportGetPostDetailsError,
    postReportGetPostDetailsData,
    postReportGetPostCommentHistoryAttempting,
    postReportGetPostCommentHistoryError,
    postReportGetPostCommentHistoryData,
    postReportGetPostGradeHistoryAttempting,
    postReportGetPostGradeHistoryError,
    postReportGetPostGradeHistoryData,
    postReportGetPostModerationAttempting,
    postReportGetPostModerationError,
    postReportGetPostModerationData,
    postReportGetPostModerationDetailsAttempting,
    postReportGetPostModerationDetailsError,
    postReportGetPostModerationDetailsData,
    postReportSetPostModerationReviewAttempting,
    postReportSetPostModerationReviewError,
    postReportGetPostPurchaseHistoryAttempting,
    postReportGetPostPurchaseHistoryError,
    postReportGetPostPurchaseHistoryData,
    postReportSetFeaturedPostAttempting,
    postReportSetFeaturedPostError,
    postReportDeleteUserPostAttempting,
    postReportDeleteUserPostError,

    topicReportGetTopicsAttempting,
    topicReportGetTopicsError,
    topicReportGetTopicsData,
    topicReportAddTopicAttempting,
    topicReportAddTopicError,
    topicReportEditTopicAttempting,
    topicReportEditTopicError,
    topicReportDeleteTopicAttempting,
    topicReportDeleteTopicError,

    purchaseReportGetTotalPurchasesGraphAttempting,
    purchaseReportGetTotalPurchasesGraphError,
    purchaseReportGetTotalPurchasesGraphData,
    purchaseReportGetPurchaseReportAttempting,
    purchaseReportGetPurchaseReportError,
    purchaseReportGetPurchaseReportData,

    paymentReportGetTotalPaymentReportAttempting,
    paymentReportGetTotalPaymentReportError,
    paymentReportGetTotalPaymentReportData,
    paymentReportGetPaymentReportListingAttempting,
    paymentReportGetPaymentReportListingError,
    paymentReportGetPaymentReportListingData,
    paymentReportGetPaymentSelectorAttempting,
    paymentReportGetPaymentSelectorError,
    paymentReportGetPaymentSelectorData,
    paymentReportGetPaymentReportDetailsAttempting,
    paymentReportGetPaymentReportDetailsError,
    paymentReportGetPaymentReportDetailsData,
    paymentReportCreatePaymentAttempting,
    paymentReportCreatePaymentError,

    moderationReportGetModerationReportAttempting,
    moderationReportGetModerationReportData,
    moderationReportGetModerationReportError,
    moderationReportGetModerationReportDetailsAttempting,
    moderationReportGetModerationReportDetailsData,
    moderationReportGetModerationReportDetailsError,
    moderationReportSetModerationReportStatusAttempting,
    moderationReportSetModerationReportStatusError,

    subAdminGetSubAdminListAttempting,
    subAdminGetSubAdminListError,
    subAdminGetSubAdminListData,
    subAdminSetSubAdminAccessAttempting,
    subAdminSetSubAdminAccessError,
    subAdminEditSubAdminAccessAttempting,
    subAdminEditSubAdminAccessError,

    systemConfigurationSetSystemConfigAttempting,
    systemConfigurationSetSystemConfigError,
    systemConfigurationGetSystemConfigAttempting,
    systemConfigurationGetSystemConfigError,
    systemConfigurationGetSystemConfigData,
};
