import React from 'react';

import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';

import Badge from 'components/atoms/Badge';
import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';
import UserProfileImage from 'components/molecules/UserProfileImage';

import { GetPostGradeHistoryResponse } from 'api/PostReportBase';
import { AppDispatch, RootState } from 'redux/store';

import { renderUserTier } from 'lib/RenderFunction';
import { EBadgeVariant, ETextVariant } from 'entities/components';

type PostGradeTableProps = {
    postGradeHistoryData: GetPostGradeHistoryResponse[];

    loading?: boolean;
};

const PostGradeTable = (props: PostGradeTableProps): JSX.Element => {
    const { postGradeHistoryData, loading } = props;
    const postReportsTableHeader = ['User', 'Rep Point', 'Grade Date', 'Grade'];

    const renderTableBody = () => {
        if (!postGradeHistoryData || postGradeHistoryData.length === 0) {
            return (
                <tr>
                    <td colSpan={postReportsTableHeader.length} className='py-xl text-center'>
                        <Text variant={ETextVariant.default}>There is no data! try adjusting the filters.</Text>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return (
                <tr>
                    <td colSpan={postReportsTableHeader.length} className='py-xl text-center'>
                        <div className='flex items-center justify-center gap-default'>
                            <RotatingLines
                                strokeColor='white'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='100'
                                visible
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return postGradeHistoryData?.map((grade) => {
            return (
                <tr key={grade._id}>
                    <td className='p-md'>
                        <div className='flex items-center gap-md'>
                            <div className='flex size-[50px] h-full 2xl:size-[60px] 3xl:size-[80px]'>
                                <UserProfileImage
                                    profileImageUrl={grade.userDetails.profileImageUrl}
                                    userTier={grade.userDetails.userTier}
                                    className='2xl:!p-[0.5rem]'
                                />
                            </div>
                            <div className='flex flex-col'>
                                <Text
                                    variant={ETextVariant.default}
                                    className='font-general-bold text-base 2xl:text-xl'
                                >
                                    {grade.userDetails.displayName}
                                </Text>
                                <Text variant={ETextVariant.subheader}>{grade.userDetails.username}</Text>
                            </div>
                        </div>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{grade.userDetails.pointsGained}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{dayjs(grade.createdAt).format('DD MMM YYYY')}</Text>
                        <Text>{dayjs(grade.createdAt).format('h:mm a')}</Text>
                    </td>
                    <td className='py-md'>
                        <div className='flex justify-center'>
                            <Badge text={renderUserTier(grade.rating, true)} variant={EBadgeVariant.grade} />
                        </div>
                    </td>
                </tr>
            );
        });
    };

    return (
        <Table
            className='table-auto'
            tableHeader={postReportsTableHeader}
            tableData={renderTableBody()}
            expandedColumns={0}
            expandedWidth='w-2/4'
        />
    );
};

PostGradeTable.defaultProps = {
    loading: false,
};

const mapStateToProps = (state: RootState) => ({
    // uiConfirmReportModalIsOpen: Selectors.uiConfirmReportModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PostGradeTable);
