type Status = 'Success' | 'Failed';

const Auth = {
    storeAuthToken: (authToken: string): void => {
        localStorage.setItem('authToken', authToken);
    },
    getAuthToken: (): string | null => {
        return localStorage.getItem('authToken');
    },
    clearAuthToken: (): void => {
        localStorage.removeItem('authToken');
    },
    storeUserEmail: (email: string): void => {
        localStorage.setItem('email', email);
    },
    getUserEmail: (): string | null => {
        return localStorage.getItem('email');
    },
    clearUserEmail: (): void => {
        localStorage.removeItem('email');
    },
    storeExpiresAt: (expireAt: string): void => {
        localStorage.setItem('expireAt', expireAt);
    },
    getExpiresAt: (): string | null => {
        return localStorage.getItem('expireAt');
    },
    clearExpiresAt: (): void => {
        localStorage.removeItem('expireAt');
    },
};

const LocalStorage = {
    getItem<T>(key: string): T | null {
        const storageItem = localStorage.getItem(key);

        if (!storageItem) return null;

        return JSON.parse(storageItem);
    },
    setItem: (key: string, item: unknown | null): Status => {
        if (!item) return 'Failed';
        const parse = JSON.stringify(item);

        localStorage.setItem(key, parse);
        return 'Success';
    },
    removeItem: (key: string): void => {
        localStorage.removeItem(key);
    },
};

type GetHeadersReturnType = {
    headers: {
        Authorization: string;
    }
    timeout?: number;
}

const getHeaders = (authToken: string, timeout?: number): GetHeadersReturnType => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
    timeout,
});

const simulateApi = (response: unknown): Promise<unknown> => {
    return new Promise(res => {
        setTimeout(() => {
            res(response);
        }, 500);
    });
};

export default {
    Auth,
    LocalStorage,
    getHeaders,
    simulateApi,
};
