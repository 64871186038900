import { GetDashboardDetailsResponse } from 'api/DashboardBase';

import { DashboardState } from '.';

const getDashboardDetailsAttempting = (state: DashboardState): boolean => state.actions.getDashboardDetails || false;
const getDashboardDetailsError = (state: DashboardState): string => state.error.getDashboardDetails || '';
const getDashboardDetailsData = (state: DashboardState): GetDashboardDetailsResponse => state.dashboardDetails;

export default {
    getDashboardDetailsAttempting,
    getDashboardDetailsError,
    getDashboardDetailsData,
};
