import React, { useEffect, useState } from 'react';

import { AiFillExclamationCircle } from 'react-icons/ai';
import { BsEyeFill } from 'react-icons/bs';
import { FaChevronLeft } from 'react-icons/fa';
import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Toggle from 'components/atoms/Toggle';
import Modal from 'components/molecules/Modal';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { renderImage, renderSubAdminPermission } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { EButtonVariant, EInputVariant, ETextVariant } from 'entities/components';
import ESubAdminPermission, { TSubAdminDetails } from 'entities/subadmin';

type ViewSubadminModalProps = {
    subAdminId: string;
    subAdminList: TSubAdminDetails[];
    modalIsOpen: boolean;
    setModalIsOpen: (state: boolean) => void;
};

const ViewSubadminModal = (props: ViewSubadminModalProps): JSX.Element => {
    const { subAdminId, subAdminList, modalIsOpen, setModalIsOpen } = props;
    const selectedSubAdmin = subAdminList.find((subAdmin) => subAdmin._id === subAdminId);

    const renderSubAdminPermissionList = () => {
        const permissionList = [
            ESubAdminPermission.USER_REPORT,
            ESubAdminPermission.POST_REPORT,
            ESubAdminPermission.TOPIC_REPORT,
            ESubAdminPermission.PURCHASE_REPORT,
            ESubAdminPermission.PAYMENT_REPORT,
            ESubAdminPermission.SYSTEM_SETTING,
        ];

        return permissionList.map((permission) => {
            return (
                <div className='flex w-full flex-row justify-between pt-md'>
                    <div className='flex flex-col gap-sm'>
                        <Text
                            variant={ETextVariant.default}
                            className='font-general-bold text-base !leading-none 2xl:text-lg'
                        >
                            {renderSubAdminPermission(permission)}
                        </Text>
                        <Text variant={ETextVariant.default} className='text-xs !leading-none 2xl:text-sm'>
                            Populate access per report later
                        </Text>
                    </div>
                    <div className='flex items-center gap-sm'>
                        <Toggle
                            isActive={
                                selectedSubAdmin?.permissions.find(
                                    (userPermission) => userPermission === permission,
                                ) !== undefined
                            }
                            onClick={() => ({})}
                            disabled
                        />
                    </div>
                </div>
            );
        });
    };

    if (!selectedSubAdmin) {
        return (
            <Modal
                isOpen={modalIsOpen}
                onClose={() => {
                    setModalIsOpen(false);
                }}
            >
                <div className={cn('w-[650px] flex flex-col', 'items-start justify-start', 'gap-sm p-xl')}>
                    <Text
                        variant={ETextVariant.default}
                        className='self-center pb-0 font-heading-bold text-[20px] text-white 2xl:text-[30px]'
                    >
                        No Sub Admin with that ID found, please try again
                    </Text>
                </div>
            </Modal>
        );
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => {
                setModalIsOpen(false);
            }}
        >
            <div className={cn('w-[650px] max-h-[90vh] flex flex-col', 'items-start justify-start', 'gap-sm p-xl')}>
                <Text
                    variant={ETextVariant.default}
                    className='self-center pb-0 font-heading-bold text-[20px] text-white 2xl:text-[30px]'
                >
                    VIEW SUBADMIN
                </Text>
                <div className={cn('flex items-center', 'gap-md 2xl:gap-default', 'py-md')}>
                    <div className='size-[50px] 2xl:size-[60px]'>{renderImage(selectedSubAdmin.profileImageUrl)}</div>
                    <div className='flex flex-col'>
                        <Text variant={ETextVariant.default} className='mb-sm text-base font-bold 2xl:text-xl'>
                            {selectedSubAdmin.email}
                        </Text>
                    </div>
                </div>
                <Text
                    variant={ETextVariant.default}
                    className='self-start pt-3 font-general-bold text-[20px] text-white 2xl:text-[22px]'
                >
                    Subadmin can manage the following
                </Text>
                <div className='w-full overflow-y-auto'>
                    {renderSubAdminPermissionList()}
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    modalIsOpen: Selectors.uiViewSubadminModalIsOpen(state),
    subAdminList: Selectors.subAdminGetSubAdminListData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) => dispatch(Actions.uiSetViewSubadminModalIsOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSubadminModal);
