import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';

import { GenericParams, GetModerationReportDetailsParams, GetModerationReportDetailsResponse, GetModerationReportParams, GetModerationReportResponse, IModerationReportGateway, SetModerationReportStatusParams } from './ModerationReportBase';

export default class ModerationReportGateway extends IModerationReportGateway {
    async getModerationReport(
        params: GenericParams<GetModerationReportParams>,
    ): GatewayResponse<GetModerationReportResponse> {
        const response: ApiResponse<GetModerationReportResponse> = await this.api.get(
            '/v1/a/moderation/moderationReportListing',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getModerationReportDetails(
        params: GenericParams<GetModerationReportDetailsParams>,
    ): GatewayResponse<GetModerationReportDetailsResponse> {
        const response: ApiResponse<GetModerationReportDetailsResponse> = await this.api.get(
            '/v1/a/moderation/moderationReportDetails',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async setModerationReportStatus(
        params: GenericParams<SetModerationReportStatusParams>,
    ): GatewayResponse<void> {
        const response: ApiResponse<void> = await this.api.put(
            '/v1/a/moderation/updateModerationStatus',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }
}
