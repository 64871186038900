import { fork } from 'redux-saga/effects';

import SystemConfigurationGateway from 'api/SystemConfiguration';
import { RootSagaReturnType } from 'sagas/types';

import watchGetSystemConfig from './getSystemConfig';
import watchSetSystemConfig from './setSystemConfig';

export default (api: SystemConfigurationGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchSetSystemConfig, api);
        yield fork(watchGetSystemConfig, api);
    }

    return {
        rootSaga,
    };
};
