import React, { useState } from 'react';

import cn from 'lib/TailwindMerge';
import { EButtonVariant, TDropdown } from 'entities/components';

import Button from './Button';

type DropdownProps = {
    label?: string | React.ReactNode;
    data: TDropdown[];
    className?: string;
}

const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
    const {
        label,
        data,
        className,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
        if (!event.target || !ref.current || !ref.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    const ref = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const renderContent = () => {
        return data.map((item) => {
            return (
                <Button
                    key={item.text}
                    variant={EButtonVariant.ghost}
                    onClick={item.onClick}
                    className='p-1 text-xs 2xl:text-sm'
                >
                    {item.text}
                </Button>
            );
        });
    };
    return (
        <div
            ref={ref}
            className='relative flex items-center'
        >
            <Button
                variant={EButtonVariant.ghost}
                onClick={() => setIsOpen(!isOpen)}
                className='select-none focus:outline-none'
            >
                {label}
            </Button>
            <div
                className={cn(
                    'absolute right-0 top-5',
                    'w-fit mt-sm px-sm',
                    'bg-glass filter backdrop-blur-glass',
                    'shadow-glass border border-white',
                    'rounded-input',
                    isOpen ? 'flex flex-col' : 'hidden',
                    className,
                )}
            >
                {renderContent()}
            </div>
        </div>
    );
};

Dropdown.defaultProps = {
    label: '',
    className: '',
};

export default Dropdown;
