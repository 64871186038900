import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PostReportGateway from 'api/PostReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { GetPostGradeHistoryParamsPayload } from 'redux/slices/postReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetPostGradeHistory(api: PostReportGateway): SagaWatcherReturnType {
    yield takeEvery('postReport/postReportGetPostGradeHistoryAttempt', handleGetPostGradeHistory, api);
}

function* handleGetPostGradeHistory(api: PostReportGateway, data: GetPostGradeHistoryParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.getPostGradeHistory], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.postReportGetPostGradeHistoryFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.postReportGetPostGradeHistorySuccess(response.data));
    }
}
