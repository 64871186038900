export enum ESubAdminPermission {
    USER_REPORT = 21,
    POST_REPORT = 22,
    TOPIC_REPORT = 23,
    PURCHASE_REPORT = 24,
    PAYMENT_REPORT = 25,
    SYSTEM_SETTING = 31,
}

export type TSubAdminDetails = {
    _id: string;
    email: string;
    password: string;
    role: number;
    signUpStage: number;
    topics: [];
    createdAt: string;
    updatedAt: string;
    __v: number;
    profileImageUrl: string;
    permissions: ESubAdminPermission[];
    commissionRate: number;
};

export default ESubAdminPermission;
