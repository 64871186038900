import React from 'react';

import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';

import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';

import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import { ETextVariant } from 'entities/components';
import { EPostStage, TPostDetails, TPurchaseDetails } from 'entities/report';
import { EPostDetailsTab } from 'entities/ui';

type PurchaseTableProps = {
    setCurrentTab: (tab: EPostDetailsTab) => void;
    purchaseReportData: TPurchaseDetails[] | [];
    loading?: boolean;
};

const PurchaseTable = (props: PurchaseTableProps): JSX.Element => {
    const { setCurrentTab, purchaseReportData, loading } = props;
    const PurchaseTableHeader = ['Date', 'Posting', 'Topic', 'Quantity', 'Price Set', 'Total Amount', '    '];

    const renderTableBody = () => {
        if (!purchaseReportData || purchaseReportData.length === 0) {
            return (
                <tr>
                    <td colSpan={PurchaseTableHeader.length} className='py-xl text-center'>
                        <Text variant={ETextVariant.default}>There is no data! try adjusting the filters.</Text>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return (
                <tr>
                    <td colSpan={PurchaseTableHeader.length} className='py-xl text-center'>
                        <div className='flex items-center justify-center gap-default'>
                            <RotatingLines
                                strokeColor='white'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='100'
                                visible
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return purchaseReportData?.map((post) => {
            return (
                <tr
                    key={post._id}
                    className='cursor-pointer hover:bg-glass'
                    onClick={() => {
                        NavActions.navToModuleDetails('postReport', post._id);
                        setCurrentTab(EPostDetailsTab.purchaseHistories);
                    }}
                >
                    <td className='py-md text-center'>
                        <Text>{dayjs(post.date).format('DD MMM YYYY')}</Text>
                        <Text>{dayjs(post.date).format('h:mm a')}</Text>
                    </td>
                    <td className='p-md'>
                        <div className='flex flex-col'>
                            <Text variant={ETextVariant.default} className='font-general-bold text-xl'>
                                {post.title}
                            </Text>
                            <Text variant={ETextVariant.subheader} className='line-clamp-6 break-words'>{post.content}</Text>
                        </div>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{post.topicName}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{post.quantity}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{post.price}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{(post.quantity * post.price).toFixed(2)}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text className='text-orange-400 underline'>View Detail</Text>
                    </td>
                </tr>
            );
        });
    };

    return (
        <Table
            className='table-fixed'
            tableHeader={PurchaseTableHeader}
            tableData={renderTableBody()}
            expandedColumns={1}
            expandedWidth='w-1/2'
        />
    );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setCurrentTab: (tab: EPostDetailsTab) => dispatch(Actions.uiSetCurrentPostDetailsTab(tab)),
});

PurchaseTable.defaultProps = {
    loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseTable);
