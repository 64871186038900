import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';

import {
    DeleteUserPostParams,
    GenericIndexedResponse,
    GenericParams,
    GenericResponse,
    GetAllTopicsResponse,
    GetPostCommentHistoryParams,
    GetPostCommentHistoryResponse,
    GetPostDetailsResponse,
    GetPostGradeHistoryParams,
    GetPostGradeHistoryResponse,
    GetPostModerationDetailsParams,
    GetPostModerationDetailsResponse,
    GetPostModerationParams,
    GetPostModerationResponse,
    GetPostPurchaseHistoryParams,
    GetPostPurchaseHistoryResponse,
    GetPostReportParams,
    GetPostReportResponse,
    IPostReportGateway,
    SetFeaturedPostParams,
    SetPostModerationReviewParams,
} from './PostReportBase';

export default class PostReportGateway extends IPostReportGateway {
    async getPostReport(
        params: GenericParams<GetPostReportParams>,
    ): GatewayResponse<GetPostReportResponse> {
        const response: ApiResponse<GetPostReportResponse> = await this.api.get(
            '/v1/a/postReport/posts',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getAllTopics(
        params: GenericParams<string>,
    ): GatewayResponse<GetAllTopicsResponse[]> {
        const response: ApiResponse<GetAllTopicsResponse[]> = await this.api.get(
            '/v1/a/postReport/topicsList',
            '',
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getPostDetails(
        params: GenericParams<string>,
    ): GatewayResponse<GetPostDetailsResponse> {
        const response: ApiResponse<GetPostDetailsResponse> = await this.api.get(
            `v1/a/postReport/post/${params.params}`,
            {},
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getPostCommentHistory(
        params: GenericParams<GetPostCommentHistoryParams>,
    ): GatewayResponse<GenericIndexedResponse<GetPostCommentHistoryResponse>> {
        const { id, ...otherParams } = params.params;
        const response: ApiResponse<GenericIndexedResponse<GetPostCommentHistoryResponse>> = await this.api.get(
            `v1/a/postReport/postCommentHistory/${id}`,
            otherParams,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getPostGradeHistory(
        params: GenericParams<GetPostGradeHistoryParams>,
    ): GatewayResponse<GenericIndexedResponse<GetPostGradeHistoryResponse>> {
        const { id, ...otherParams } = params.params;
        const response: ApiResponse<GenericIndexedResponse<GetPostGradeHistoryResponse>> = await this.api.get(
            `v1/a/postReport/postGradeHistory/${id}`,
            otherParams,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getPostModeration(
        params: GenericParams<GetPostModerationParams>,
    ): GatewayResponse<GenericIndexedResponse<GetPostModerationResponse>> {
        const response: ApiResponse<GenericIndexedResponse<GetPostModerationResponse>> = await this.api.get(
            'v1/a/postReport/postModeration',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getPostModerationDetails(
        params: GenericParams<GetPostModerationDetailsParams>,
    ): GatewayResponse<GetPostModerationDetailsResponse> {
        const response: ApiResponse<GetPostModerationDetailsResponse> = await this.api.get(
            'v1/a/postReport/postModerationDetails',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async setPostModerationReview(
        params: GenericParams<SetPostModerationReviewParams>,
    ): GatewayResponse<GenericResponse> {
        const { postId, ...actualParams } = params.params;
        const response: ApiResponse<GenericResponse> = await this.api.put(
            'v1/a/postReport/postModerationReview',
            actualParams,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getPostPurchaseHistory(
        params: GenericParams<GetPostPurchaseHistoryParams>,
    ): GatewayResponse<GenericIndexedResponse<GetPostPurchaseHistoryResponse>> {
        const { postId, ...otherParams } = params.params;
        const response: ApiResponse<GenericIndexedResponse<GetPostPurchaseHistoryResponse>> = await this.api.get(
            `v1/a/postReport/postPurchaseHistory/${postId}`,
            otherParams,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async setFeaturedPost(
        params: GenericParams<SetFeaturedPostParams>,
    ): GatewayResponse<GenericResponse> {
        const response: ApiResponse<GenericResponse> = await this.api.post(
            'v1/a/postReport/setFeaturedPost',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async deleteUserPost(
        params: GenericParams<DeleteUserPostParams>,
    ): GatewayResponse<GenericResponse> {
        const response: ApiResponse<GenericResponse> = await this.api.put(
            'v1/a/postReport/deleteUserPost',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }
}
