import { GetSystemConfigResponse } from 'api/SystemConfigurationBase';

import { SystemConfigurationState } from '.';

const setSystemConfigAttempting = (state: SystemConfigurationState): boolean => state.actions.setSystemConfig || false;
const setSystemConfigError = (state: SystemConfigurationState): string => state.error.setSystemConfig || '';

const getSystemConfigAttempting = (state: SystemConfigurationState): boolean => state.actions.getSystemConfig || false;
const getSystemConfigError = (state: SystemConfigurationState): string => state.error.getSystemConfig || '';
const getSystemConfigData = (state: SystemConfigurationState): GetSystemConfigResponse => state.systemConfig;

export default {
    setSystemConfigAttempting,
    setSystemConfigError,

    getSystemConfigAttempting,
    getSystemConfigError,
    getSystemConfigData,
};
