import createSagaMiddleware from '@redux-saga/core';
import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

import authReducer from 'redux/slices/auth';
import dashboardReducer from 'redux/slices/dashboard';
import moderationReportReducer from 'redux/slices/moderationReport';
import paymentReportReducer from 'redux/slices/paymentReport';
import postReportReducer from 'redux/slices/postReport';
import purchaseReportReducer from 'redux/slices/purchaseReport';
import topicReportReducer from 'redux/slices/topicReport';
import uiReducer from 'redux/slices/ui';
import userReportReducer from 'redux/slices/userReport';
import rootSaga from 'sagas';

import subAdmin from './slices/subAdmin';
import systemConfiguration from './slices/systemConfiguration';

const sagaMiddleware = createSagaMiddleware();
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const rootReducer = combineReducers({
    router: routerReducer,
    ui: uiReducer.reducers,
    auth: authReducer.reducers,
    dashboard: dashboardReducer.reducers,
    userReport: userReportReducer.reducers,
    postReport: postReportReducer.reducers,
    topicReport: topicReportReducer.reducers,
    purchaseReport: purchaseReportReducer.reducers,
    paymentReport: paymentReportReducer.reducers,
    moderationReport: moderationReportReducer.reducers,
    subAdmin: subAdmin.reducers,
    systemConfiguration: systemConfiguration.reducers,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: false,
        }).concat(sagaMiddleware, routerMiddleware),
    devTools: process.env.REACT_APP_STAGE !== 'prod',
});

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
