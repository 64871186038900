import { GenericIndexedResponse, GenericResponse, GetPaymentReportDetailsResponse, GetPaymentReportListingResponse, GetPaymentSelectorResponse, GetTotalPaymentReportResponse } from 'api/PaymentReportBase';

import { PaymentReportState } from '.';

const getTotalPaymentReportAttempting = (state: PaymentReportState): boolean => state.actions.getTotalPaymentReport || false;
const getTotalPaymentReportError = (state: PaymentReportState): string => state.error.getTotalPaymentReport || '';
const getTotalPaymentReportData = (state: PaymentReportState): GetTotalPaymentReportResponse => state.totalPaymentReport;

const getPaymentReportListingAttempting = (state: PaymentReportState): boolean => state.actions.getPaymentReportListing || false;
const getPaymentReportListingError = (state: PaymentReportState): string => state.error.getPaymentReportListing || '';
const getPaymentReportListingData = (state: PaymentReportState): GenericIndexedResponse<GetPaymentReportListingResponse> => state.paymentReportListing;

const getPaymentSelectorAttempting = (state: PaymentReportState): boolean => state.actions.getPaymentSelector || false;
const getPaymentSelectorError = (state: PaymentReportState): string => state.error.getPaymentSelector || '';
const getPaymentSelectorData = (state: PaymentReportState): GetPaymentSelectorResponse => state.paymentSelector;

const getPaymentReportDetailsAttempting = (state: PaymentReportState): boolean => state.actions.getTotalPaymentReport || false;
const getPaymentReportDetailsError = (state: PaymentReportState): string => state.error.getTotalPaymentReport || '';
const getPaymentReportDetailsData = (state: PaymentReportState): GetPaymentReportDetailsResponse => state.paymentReportDetails;

const createPaymentAttempting = (state: PaymentReportState): boolean => state.actions.getTotalPaymentReport || false;
const createPaymentError = (state: PaymentReportState): string => state.error.getTotalPaymentReport || '';
// const createPaymentData = (state: PaymentReportState): GenericResponse => state.{To define};

export default {
    getTotalPaymentReportAttempting,
    getTotalPaymentReportError,
    getTotalPaymentReportData,

    getPaymentReportListingAttempting,
    getPaymentReportListingError,
    getPaymentReportListingData,

    getPaymentSelectorAttempting,
    getPaymentSelectorError,
    getPaymentSelectorData,

    getPaymentReportDetailsAttempting,
    getPaymentReportDetailsError,
    getPaymentReportDetailsData,

    createPaymentAttempting,
    createPaymentError,
    // createPaymentData,
};
