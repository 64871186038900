import { createSlice } from '@reduxjs/toolkit';

import {
    EditSubAdminAccessParamsPayload,
    GetSubAdminListParamsPayload,
    GetSubAdminListResponsePayload,
    NumberPayload,
    SetSubAdminAccessParamsPayload,
    StringOrNullPayload,
    SubAdminReduxState,
} from './types';

const initialState: SubAdminReduxState = {
    actions: {
        getSubAdminList: false,
        setSubAdminAccess: false,
        editSubAdminAccess: false,
    },
    subAdminList: [],
    error: {
        getSubAdminList: '',
        setSubAdminAccess: '',
        editSubAdminAccess: '',
    },
};

const subAdminSlice = createSlice({
    name: 'subAdmin',
    initialState,
    reducers: {
        subAdminGetSubAdminListAttempt: (state, _action: GetSubAdminListParamsPayload) => {
            state.actions.getSubAdminList = true;
            state.error.getSubAdminList = '';
        },
        subAdminGetSubAdminListSuccess: (state, action: GetSubAdminListResponsePayload) => {
            state.actions.getSubAdminList = false;
            if (action.payload) {
                state.subAdminList = action.payload;
            }
        },
        subAdminGetSubAdminListFailure: (state, action: StringOrNullPayload) => {
            state.actions.getSubAdminList = false;
            if (action.payload) {
                state.error.getSubAdminList = action.payload;
            }
        },
        subAdminSetSubAdminAccessAttempt: (state, _action: SetSubAdminAccessParamsPayload) => {
            state.actions.setSubAdminAccess = true;
            state.error.setSubAdminAccess = '';
        },
        subAdminSetSubAdminAccessFailure: (state, action: StringOrNullPayload) => {
            state.actions.setSubAdminAccess = false;
            if (action.payload) {
                state.error.setSubAdminAccess = action.payload;
            }
        },
        subAdminSetSubAdminAccessSuccess: (state) => {
            state.actions.setSubAdminAccess = false;
        },
        subAdminEditSubAdminAccessAttempt: (state, _action: EditSubAdminAccessParamsPayload) => {
            state.actions.editSubAdminAccess = true;
            state.error.editSubAdminAccess = '';
        },
        subAdminEditSubAdminAccessFailure: (state, action: StringOrNullPayload) => {
            state.actions.editSubAdminAccess = false;
            if (action.payload) {
                state.error.editSubAdminAccess = action.payload;
            }
        },
        subAdminEditSubAdminAccessSuccess: (state) => {
            state.actions.editSubAdminAccess = false;
        },
        subAdminRevokeSubAdminAccessAttempt: (state, _action: EditSubAdminAccessParamsPayload) => {
            state.actions.editSubAdminAccess = true;
            state.error.editSubAdminAccess = '';
        },
        subAdminRevokeSubAdminAccessFailure: (state, action: StringOrNullPayload) => {
            state.actions.editSubAdminAccess = false;
            if (action.payload) {
                state.error.editSubAdminAccess = action.payload;
            }
        },
        subAdminRevokeSubAdminAccessSuccess: (state) => {
            state.actions.editSubAdminAccess = false;
        },
    },
});

export type SubAdminState = typeof initialState;

export default {
    actions: subAdminSlice.actions,
    reducers: subAdminSlice.reducer,
};
