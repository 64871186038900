/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import cn from 'lib/TailwindMerge';

type ToggleProps = {
    isActive: boolean;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
};

const Toggle: React.FC<ToggleProps> = (props: ToggleProps) => {
    const {
        isActive,
        onClick,
        disabled,
        className,
    } = props;

    return (
        <button
            type='button'
            className='relative flex items-center gap-1.5'
            onClick={onClick}
            disabled={disabled}
        >
            <div className={cn(
                'h-[1.5rem] 2xl:h-[2rem]',
                'w-[3rem] 2xl:w-[4rem]',
                'rounded-full',
                'bg-white',
            )}
            />
            <div
                className={cn(
                    'size-[1.5rem] 2xl:size-[2rem]',
                    'rounded-full',
                    'absolute',
                    'transition-all duration-300 ease-in-out',
                    // 'right-0',
                    isActive ? 'bg-button-primary' : 'bg-slate-400',
                    isActive ? 'translate-x-full' : '-translate-x-0',
                )}
            />
        </button>
    );
};

Toggle.defaultProps = {
    disabled: undefined,
    className: '',
};

export default Toggle;
