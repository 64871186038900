import React, { useEffect } from 'react';

import { FaChevronLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import AuthLayout from 'components/layout/AuthLayout';

import { ResetPasswordParams } from 'api/AuthBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import cn from 'lib/TailwindMerge';
import {
    EButtonVariant,
    EContainerVariant,
    EInputVariant,
    ETextVariant,
} from 'entities/components';

interface ResetPasswordProps {
    loading: boolean;
    error: string;

    resetPassword: (params: ResetPasswordParams) => void;
    resetErrors: () => void;
}

const ResetPassword = (props: ResetPasswordProps): JSX.Element => {
    const {
        loading,
        error,
        resetPassword,
        resetErrors,
    } = props;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramEmail = queryParams.get('email');
    const paramOtp = queryParams.get('otp');

    useEffect(() => {
        return () => {
            resetErrors();
        };
    }, []);

    const isPasswordValid = (password: string): boolean => {
        // Regular expression for alphanumeric characters and minimum 8 characters
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
        return passwordRegex.test(password);
    };

    const handleResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const password = formData.get('password') as string;
        const passwordConfirm = formData.get('password-confirm') as string;

        if (password !== passwordConfirm) {
            toast.error('Passwords do not match');
            return;
        }

        if (!isPasswordValid(password)) {
            toast.error('Password should be alphanumeric and at least 8 characters long');
            return;
        }

        if (paramEmail && paramOtp && password) {
            resetPassword({ email: paramEmail, otp: paramOtp, password });
        } else {
            toast.error('Please fill in all fields');
        }
    };

    return (
        <AuthLayout>
            <Container
                variant={EContainerVariant.glass}
                className={cn(
                    'w-[430px] 2xl:w-[480px]',
                    'flex flex-col',
                    'gap-default',
                    'p-default',
                )}
            >
                <Button
                    variant={EButtonVariant.ghost}
                    onClick={() => NavActions.navBack()}
                    className='flex size-fit items-center gap-2'
                >
                    <FaChevronLeft size={20} />
                    <div className='pb-1'>
                        BACK
                    </div>
                </Button>
                <div className='mb-default'>
                    <Text variant={ETextVariant.header}>
                        RESET PASSWORD
                    </Text>
                    <Text variant={ETextVariant.subheader}>
                        Please enter your new password.
                    </Text>
                </div>
                <form
                    className='flex flex-col gap-default'
                    onSubmit={handleResetPassword}
                >
                    <Input
                        variant={EInputVariant.password}
                        type='password'
                        name='password'
                        placeholder='New Password'
                    />
                    <Input
                        variant={EInputVariant.password}
                        type='password'
                        name='password-confirm'
                        placeholder='Confirm New Password'
                    />
                    <Button
                        type='submit'
                        className='mt-lg'
                        disabled={loading}
                        loading={loading}
                    >
                        Reset
                    </Button>
                </form>
                <Text
                    variant={ETextVariant.error}
                    className='flex justify-center'
                >
                    {error}
                </Text>
            </Container>
        </AuthLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.authGetResetPasswordAttempting(state),
    error: Selectors.authGetResetPasswordError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    resetPassword: (params: ResetPasswordParams) => dispatch(Actions.authResetPasswordAttempt(params)),
    resetErrors: () => dispatch(Actions.authResetErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
