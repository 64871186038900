import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IAuthUser } from 'entities/auth';

export interface LoginParams {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface LoginResponse {
    user: IAuthUser;
    token: string;
    exp: number;
}

export interface RefreshParam {
    authToken: string;
}

export type ForgotPasswordParam = Omit<LoginParams, 'password' | 'rememberMe'>

export type VerifyOtpParams = {
    email: string;
    otp: string
}

export type ResetPasswordParams = VerifyOtpParams & {
    password: string;
}

export abstract class IAuthGateway extends Gateway {
    abstract login(params: LoginParams): GatewayResponse<LoginResponse | null>;

    abstract refresh(params: RefreshParam): GatewayResponse<LoginResponse | null>;

    abstract forgotPassword(params: ForgotPasswordParam): GatewayResponse<string | null>;

    abstract verifyOtp(params: VerifyOtpParams): GatewayResponse<string | null>;

    abstract resetPassword(params: ResetPasswordParams): GatewayResponse<string | null>;
}
