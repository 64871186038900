import React, { useEffect, useState } from 'react';

import { FaChevronLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import AuthLayout from 'components/layout/AuthLayout';

import { VerifyOtpParams } from 'api/AuthBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import cn from 'lib/TailwindMerge';
import {
    EButtonVariant,
    EContainerVariant,
    ETextVariant,
} from 'entities/components';

interface VerifyOtpProps {
    loading: boolean;
    error: string;

    verifyOtp: (otp: VerifyOtpParams) => void;
    resetErrors: () => void;
}
const VerifyOtp = (props: VerifyOtpProps): JSX.Element => {
    const {
        loading,
        error,
        verifyOtp,
        resetErrors,
    } = props;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramEmail = queryParams.get('email');
    const paramOtp = queryParams.get('otp');

    const [newOtp, setNewOtp] = useState<string>(paramOtp ?? '');

    useEffect(() => {
        return () => {
            resetErrors();
        };
    }, []);

    const handleVerifyOtp = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        setNewOtp(formData.get('otp') as string);

        if (paramEmail && newOtp) {
            verifyOtp({ email: paramEmail, otp: newOtp });
        } else {
            toast.error('Please enter an OTP');
        }
    };

    return (
        <AuthLayout>
            <Container
                variant={EContainerVariant.glass}
                className={cn(
                    'w-[430px] 2xl:w-[480px]',
                    'flex flex-col',
                    'gap-default',
                    'p-default',
                )}
            >
                <Button
                    variant={EButtonVariant.ghost}
                    onClick={() => NavActions.navBack()}
                    className='flex size-fit items-center gap-2'
                >
                    <FaChevronLeft size={20} />
                    <div className='pb-1'>
                        BACK
                    </div>
                </Button>
                <div className='mb-default'>
                    <Text variant={ETextVariant.header}>
                        VERIFICATION
                    </Text>
                    <Text variant={ETextVariant.subheader}>
                        We have sent an email containing an OTP to your email.
                    </Text>
                </div>
                <form
                    className='flex flex-col gap-default'
                    onSubmit={handleVerifyOtp}
                >
                    <Input
                        type='text'
                        name='otp'
                        placeholder='OTP'
                        value={newOtp}
                        onChange={(e) => setNewOtp(e.target.value)}
                    />
                    <Button
                        type='submit'
                        className='mt-lg'
                        loading={loading}
                        disabled={loading}
                    >
                        Verify
                    </Button>
                </form>
                <Text
                    variant={ETextVariant.error}
                    className='flex justify-center'
                >
                    {error}
                </Text>
            </Container>
        </AuthLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.authGetVerifyOtpAttempting(state),
    error: Selectors.authGetVerifyOtpError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    verifyOtp: (params: VerifyOtpParams) => dispatch(Actions.authVerifyOtpAttempt(params)),
    resetErrors: () => dispatch(Actions.authResetErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtp);
