import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import config from 'config';

import { GatewayResponseStatus } from 'api/types/types';
import UserReportGateway from 'api/UserReport';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { DeleteUserCommentParamsPayload } from 'redux/slices/userReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchDeleteUserComment(api: UserReportGateway): SagaWatcherReturnType {
    yield takeEvery('userReport/userReportDeleteUserCommentAttempt', handleDeleteUserComment, api);
}

function* handleDeleteUserComment(api: UserReportGateway, data: DeleteUserCommentParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const deleteCommentPayload = {
        commentId: data.payload.commentId,
    };

    const response = yield* call([api, api.deleteUserComments], {
        authToken,
        params: deleteCommentPayload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.uiSetDeleteCommentModalIsOpen(false));
        yield put(Actions.userReportDeleteUserCommentFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.userReportDeleteUserCommentSuccess());
        yield put(Actions.uiSetDeleteCommentModalIsOpen(false));
        if (data.payload.userId) {
            yield put(Actions.userReportGetUserCommentsAttempt({
                userId: data.payload.userId,
                index: data.payload.index,
                dateTo: data.payload.dateTo,
                dateFrom: data.payload.dateFrom,
            }));
        }
        if (data.payload.postId) {
            yield put(Actions.postReportGetPostCommentHistoryAttempt({
                id: data.payload.postId,
                index: data.payload.index,
                dateTo: data.payload.dateTo,
                dateFrom: data.payload.dateFrom,
            }));
        }
        toast.success(config.useMockApi ? response.data : 'Comment deleted!');
    }
}
