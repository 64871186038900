import React, { useState } from 'react';

import dayjs from 'dayjs';
import { BsThreeDots } from 'react-icons/bs';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';

import Dropdown from 'components/atoms/Dropdown';
import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';

import { GetTopicsResponse } from 'api/TopicReportBase';
import { GetUserReportResponse } from 'api/UserReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import cn from 'lib/TailwindMerge';
import { ETextVariant } from 'entities/components';

import DeleteTopicModal from './DeleteTopicModal';
import EditTopicModal from './EditTopicModal';

type UserReportTableProps = {
    setDeleteTopicModalIsOpen: (state: boolean) => void;
    setEditTopicModalIsOpen: (state: boolean) => void;
    topicReportData: GetTopicsResponse[];
    loading?: boolean;
};

const TopicReportTable = (props: UserReportTableProps): JSX.Element => {
    const {
        topicReportData,
        setDeleteTopicModalIsOpen,
        setEditTopicModalIsOpen,
        loading,
    } = props;
    const topicReportTableHeader = ['Topic', 'Description', 'Subscribers', ''];

    const [selectedTopic, setSelectedTopic] = useState<GetTopicsResponse>();
    const [topicId, setTopicId] = useState<string>('');

    const editTopicClickHandler = (topic: GetTopicsResponse) => {
        setSelectedTopic(topic);
        setEditTopicModalIsOpen(true);
    };
    const deleteTopicClickHandler = (id: string) => {
        setTopicId(id);
        setDeleteTopicModalIsOpen(true);
    };

    const renderTableBody = () => {
        if (!topicReportData || topicReportData.length === 0) {
            return (
                <tr>
                    <td
                        colSpan={topicReportTableHeader.length}
                        className='py-xl text-center'
                    >
                        <Text variant={ETextVariant.default}>
                            There is no data! try adjusting the filters.
                        </Text>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return (
                <tr>
                    <td
                        colSpan={topicReportTableHeader.length}
                        className='py-xl text-center'
                    >
                        <div className='flex items-center justify-center gap-default'>
                            <RotatingLines
                                strokeColor='white'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='100'
                                visible
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return topicReportData?.map((topic) => {
            return (
                <tr
                    key={Math.random()}
                    // onClick={() => NavActions.navToModuleDetails('userReport', topic._id)}
                >
                    <td className='py-md'>
                        <Text
                            variant={ETextVariant.default}
                            className='font-general-bold'
                        >
                            {topic.name}
                        </Text>
                    </td>
                    <td className='py-md'>
                        <Text
                            variant={ETextVariant.default}
                            className='line-clamp-6 break-words font-general-medium'
                        >
                            {topic.description}
                        </Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text
                            variant={ETextVariant.default}
                            className='font-general-medium'
                        >
                            {topic.subscriberCount}
                        </Text>
                    </td>
                    <td className='py-md'>
                        <div className='flex justify-center'>
                            <Dropdown
                                label={<BsThreeDots size={20} color='white' />}
                                data={[
                                    {
                                        text: 'Edit',
                                        onClick: () => {
                                            editTopicClickHandler(topic);
                                        },
                                    },
                                    {
                                        text: 'Delete',
                                        onClick: () => {
                                            deleteTopicClickHandler(topic._id);
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div>
            <Table
                className='table-fixed'
                tableHeader={topicReportTableHeader}
                tableData={renderTableBody()}
                expandedColumns={1}
                expandedWidth='w-2/3'
            />
            <EditTopicModal topic={selectedTopic} />
            <DeleteTopicModal topicId={topicId} />
        </div>
    );
};

TopicReportTable.defaultProps = {
    loading: false,
};

const mapStateToProps = (state: RootState) => ({
    deleteTopicAttempt: Selectors.topicReportDeleteTopicAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDeleteTopicModalIsOpen: (state: boolean) =>
        dispatch(Actions.uiSetDeleteTopicModalIsOpen(state)),
    setEditTopicModalIsOpen: (state: boolean) =>
        dispatch(Actions.uiSetEditTopicModalIsOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopicReportTable);
