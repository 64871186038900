import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';

import { GenericParams, GenericResponse } from './SystemConfigurationBase';
import {
    AddTopicParams,
    DeleteTopicParams,
    EditTopicParams,
    GenericIndexedResponse,
    GetTopicsParams,
    GetTopicsResponse,
    ITopicReportGateway,
} from './TopicReportBase';

export default class TopicReportGateway extends ITopicReportGateway {
    async getTopics(
        params: GenericParams<GetTopicsParams>,
    ): GatewayResponse<GenericIndexedResponse<GetTopicsResponse>> {
        const response: ApiResponse<GenericIndexedResponse<GetTopicsResponse>> = await this.api.get(
            '/v1/a/topicReport/topics',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async addTopic(params: GenericParams<AddTopicParams>): GatewayResponse<GenericResponse> {
        const response: ApiResponse<GenericResponse> = await this.api.post(
            '/v1/a/topicReport/topic',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async editTopic(params: GenericParams<EditTopicParams>): GatewayResponse<GenericResponse> {
        const response: ApiResponse<GenericResponse> = await this.api.put(
            '/v1/a/topicReport/topic',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async deleteTopic(params: GenericParams<DeleteTopicParams>): GatewayResponse<GenericResponse> {
        const response: ApiResponse<GenericResponse> = await this.api.delete(
            `/v1/a/topicReport/topic/${params.params.topicId}`,
            {},
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }
}
