import React from 'react';

import cn from 'lib/TailwindMerge';

type DividerProps = {
    className?: string;
};

const Divider = (props: DividerProps): JSX.Element => {
    const { className } = props;

    return (
        <div
            className={cn(
                'h-[2px] w-full bg-white',
                className,
            )}
        />
    );
};

Divider.defaultProps = {
    className: '',
};

export default Divider;
