import React from 'react';

import { RotatingLines } from 'react-loader-spinner';

import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';
import CustomLineChart from 'components/molecules/CustomLineChart';

import { renderDiffPercentage } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { EContainerVariant, ETextVariant, TChartData } from 'entities/components';

const data = [
    {
        date: '12 Feb',
        counts: 300,
    },
    {
        date: '13 Feb',
        counts: 258,
    },
    {
        date: '14 Feb',
        counts: 411,
    },
    {
        date: '15 Feb',
        counts: 562,
    },
    {
        date: '16 Feb',
        counts: 945,
    },
    {
        date: '17 Feb',
        counts: 1435,
    },
    {
        date: '18 Feb',
        counts: 643,
    },
    {
        date: '19 Feb',
        counts: 562,
    },
    {
        date: '20 Feb',
        counts: 945,
    },
    {
        date: '21 Feb',
        counts: 1435,
    },
    {
        date: '22 Feb',
        counts: 643,
    },
];

type StatusWithChartCardProps = {
    value: number;
    label: string;
    diff: number | null;
    chartData: TChartData[];
    loading?: boolean;
    error?: string;
};

const StatusWithChartCard = (props: StatusWithChartCardProps): JSX.Element => {
    const {
        value,
        label,
        diff,
        chartData,
        loading,
        error,
    } = props;

    const renderContent = () => {
        if (loading) {
            return (
                <RotatingLines
                    strokeColor='white'
                    strokeWidth='5'
                    animationDuration='0.75'
                    width='100'
                    visible
                />
            );
        }

        if (error) {
            return (
                <Text variant={ETextVariant.error}>
                    {error}
                </Text>
            );
        }

        return (
            <>
                <div className='flex items-end gap-sm 2xl:gap-md'>
                    <Text
                        variant={ETextVariant.header}
                        className='font-heading-bold text-[3rem]'
                    >
                        {value}
                    </Text>
                    <Text className='mb-1'>
                        {label}
                    </Text>
                </div>
                <div className='flex gap-sm'>
                    <Text className={cn(
                        diff && diff > 0 && 'text-success',
                        diff && diff < 0 && 'text-error',
                    )}
                    >
                        {renderDiffPercentage(diff)}
                    </Text>
                    <Text>
                        Last 30 days
                    </Text>
                </div>
                <div className='h-[120px] w-full pt-sm'>
                    <CustomLineChart data={chartData} />
                </div>
            </>
        );
    };

    return (
        <Container
            variant={EContainerVariant.gradientBorder}
            className='p-md'
        >
            {renderContent()}
        </Container>
    );
};

StatusWithChartCard.defaultProps = {
    loading: false,
    error: '',
};

export default StatusWithChartCard;
