import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Divider from 'components/atoms/Divider';
import Modal from 'components/molecules/Modal';

import { AddTopicParams } from 'api/TopicReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import cn from 'lib/TailwindMerge';
import { EButtonVariant } from 'entities/components';

type AddTopicModalProps = {
    addTopicAttempt: boolean;
    modalIsOpen: boolean;
    setModalIsOpen: (state: boolean) => void;
    addTopic: (params: AddTopicParams) => void;
};

const AddTopicModal = (props: AddTopicModalProps): JSX.Element => {
    const { addTopicAttempt, modalIsOpen, setModalIsOpen, addTopic } = props;

    const [topicNameInputValue, setTopicName] = useState<string>();
    const [topicDescriptionInputValue, setDescription] = useState<string>();

    const handleTopicNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTopicName(e.target.value);
    };

    const handleDescriptionChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setDescription(e.target.value);
    };

    const handlePublish = () => {
        addTopic({
            name: topicNameInputValue ?? '',
            description: topicDescriptionInputValue ?? '',
        });

        setTopicName('');
        setDescription('');
    };

    return (
        <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
            <div
                className={cn(
                    'w-[650px] flex flex-col',
                    'items-center justify-center',
                    'gap-default p-md',
                )}
            >
                <input
                    type='text'
                    className={cn(
                        'rounded-input bg-background p-0 w-full focus:outline-none text-sm text-white',
                        'font-heading-bold 2xl:text-[60px]',
                    )}
                    placeholder='TOPIC NAME'
                    value={topicNameInputValue}
                    onChange={(e) => handleTopicNameChange(e)}
                />
                <Divider />
                <textarea
                    placeholder='Topic description'
                    rows={8}
                    className={cn(
                        'w-full',
                        'rounded-input outline-none',
                        'bg-transparent',
                        'border-2 border-white',
                        'resize-none p-2.5',
                    )}
                    value={topicDescriptionInputValue}
                    onChange={(e) => handleDescriptionChange(e)}
                />
                <div className='self-end'>
                    <Button
                        variant={EButtonVariant.default}
                        className='px-xl'
                        loading={addTopicAttempt}
                        onClick={() => handlePublish()}
                    >
                        Publish
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    addTopicAttempt: Selectors.topicReportAddTopicAttempting(state),
    modalIsOpen: Selectors.uiAddTopicModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) =>
        dispatch(Actions.uiSetAddTopicModalIsOpen(state)),
    addTopic: (params: AddTopicParams) =>
        dispatch(Actions.topicReportAddTopicAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTopicModal);
