import React from 'react';

import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';

import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';

import { GetUserGradesResponse } from 'api/UserReportBase';

import { renderPostRating, renderPostStatus, renderUserTier } from 'lib/RenderFunction';
import { ETextVariant } from 'entities/components';

type UserGradesTableProps = {
    userGradesData: GetUserGradesResponse[] | [];
    loading?: boolean;
};

const UserGradesTable = (props: UserGradesTableProps): JSX.Element => {
    const {
        userGradesData,
        loading,
    } = props;
    const userGradesTableHeader = ['Date', 'Posting', 'Topic', 'Expiry Date', 'Status', 'Public/Paid', 'Graded'];

    const renderTableBody = () => {
        if (!userGradesData || userGradesData.length === 0) {
            return (
                <tr>
                    <td
                        colSpan={userGradesTableHeader.length}
                        className='py-xl text-center'
                    >
                        <Text variant={ETextVariant.default}>
                            There is no data! try adjusting the filters.
                        </Text>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return (
                <tr>
                    <td
                        colSpan={userGradesTableHeader.length}
                        className='py-xl text-center'
                    >
                        <div className='flex items-center justify-center gap-default'>
                            <RotatingLines
                                strokeColor='white'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='100'
                                visible
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return userGradesData?.map((grade) => {
            return (
                <tr
                    key={grade.postId}
                    className='cursor-pointer hover:bg-glass'
                >
                    <td className='py-md text-center'>
                        <Text>{dayjs(grade.postDetails[0].createdAt).format('DD MMM YYYY')}</Text>
                    </td>
                    <td className='p-md'>
                        <div className='flex flex-col'>
                            <Text
                                variant={ETextVariant.default}
                                className='font-general-bold text-base 2xl:text-xl'
                            >
                                {grade.postDetails[0].title}
                            </Text>
                            <Text variant={ETextVariant.subheader}>
                                {grade.postDetails[0].content}
                            </Text>
                        </div>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{grade.topicDetails[0].name}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{dayjs(grade.postDetails[0].verificationDateEnd).format('DD MMM YYYY')}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{renderPostStatus(grade.postDetails[0].postStage)}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>Public</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{renderPostRating(grade.rating, true)}</Text>
                    </td>
                </tr>
            );
        });
    };

    return (
        <Table
            className='table-auto'
            tableHeader={userGradesTableHeader}
            tableData={renderTableBody()}
            expandedColumns={1}
            expandedWidth='w-1/3'
        />
    );
};

UserGradesTable.defaultProps = {
    loading: false,
};

export default UserGradesTable;
