import React, { useEffect, useState } from 'react';

import { FiPercent } from 'react-icons/fi';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';

import { GetSystemConfigResponse, SetSystemConfigParams } from 'api/SystemConfigurationBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import cn from 'lib/TailwindMerge';
import { EButtonVariant, EContainerVariant, EInputVariant, ETextVariant } from 'entities/components';

type SystemSettingProps = {
    setSystemConfigAttempting: boolean;
    setSystemConfigError: string;

    getSystemConfigAttempting: boolean;
    getSystemConfigError: string;

    setSystemConfig: (params: SetSystemConfigParams) => void;
    getSystemConfig: () => void;

    systemConfig: GetSystemConfigResponse;
};

const SystemSetting = (props: SystemSettingProps): JSX.Element => {
    const {
        setSystemConfigAttempting,
        setSystemConfigError,
        getSystemConfigAttempting,
        getSystemConfigError,

        setSystemConfig,
        getSystemConfig,

        systemConfig,
    } = props;

    const [tempCharacterLimit, setTempCharacterLimit] = useState<number>(systemConfig.postTextMaxChar);
    const [tempCommissionRate, setTempCommissionRate] = useState<number>((systemConfig.commissionRate) * 100);
    const [tempAGradeScoring, setTempAGradeScoring] = useState<number>(systemConfig.postGradingAScore);
    const [tempBGradeScoring, setTempBGradeScoring] = useState<number>(systemConfig.postGradingBScore);
    const [tempCGradeScoring, setTempCGradeScoring] = useState<number>(systemConfig.postGradingCScore);
    const [tempDGradeScoring, setTempDGradeScoring] = useState<number>(systemConfig.postGradingDScore);

    const saveButtonHandler = () => {
        if (tempCharacterLimit > 10000) {
            toast.warn('Max Post Character Limit cannot be more than 10,000');
        } else {
            setSystemConfig({
                postTextMaxChar: tempCharacterLimit,
                commissionRate: tempCommissionRate / 100,
                A: tempAGradeScoring,
                B: tempBGradeScoring,
                C: tempCGradeScoring,
                D: tempDGradeScoring,
            });
        }
    };

    useEffect(() => {
        getSystemConfig();
    }, []);

    useEffect(() => {
        setTempCharacterLimit(systemConfig.postTextMaxChar);
        setTempCommissionRate((systemConfig.commissionRate) * 100);
        setTempAGradeScoring(systemConfig.postGradingAScore);
        setTempBGradeScoring(systemConfig.postGradingBScore);
        setTempCGradeScoring(systemConfig.postGradingCScore);
        setTempDGradeScoring(systemConfig.postGradingDScore);
    }, [systemConfig]);

    return (
        <div className='flex flex-col gap-default 2xl:gap-xl'>
            <Text variant={ETextVariant.header} className='font-heading-bold text-[50px] 2xl:text-[60px]'>
                SYSTEM CONFIGURATION
            </Text>
            <div className='flex flex-col gap-md'>
                <Container
                    variant={EContainerVariant.gradientBorder}
                    className='flex items-center justify-between p-default'
                >
                    <div className='flex flex-col gap-sm'>
                        <Text variant={ETextVariant.default} className='font-general-bold text-base 2xl:text-lg'>
                            Commission Rate
                        </Text>
                        <Text variant={ETextVariant.default} className='text-xs 2xl:text-sm'>
                            Set default commission rate for all users (%)
                        </Text>
                    </div>
                    <div className='flex items-center gap-sm'>
                        {/* <div className='pr-xl italic text-warning'>warning text here</div> */}
                        <Input
                            type='number'
                            value={tempCommissionRate} // Set value to empty string for 0 or undefined
                            max={100}
                            min={1}
                            variant={EInputVariant.ghost}
                            className='size-fit w-[100px] text-black'
                            // onFocus={() => setTempCommissionRate(0)}
                            onChange={(e) => {
                                setTempCommissionRate(Number(e.target.value));
                            }}
                        />
                        <FiPercent size={40} color='white' />
                    </div>
                </Container>
                <Container
                    variant={EContainerVariant.gradientBorder}
                    className='flex items-center justify-between p-default'
                >
                    <div className='flex flex-col gap-sm'>
                        <Text variant={ETextVariant.default} className='font-general-bold text-base 2xl:text-lg'>
                            Maximum Character Limit For Paid Post
                        </Text>
                        <Text variant={ETextVariant.default} className='text-xs 2xl:text-sm'>
                            Set the maximum character limit for paid posts that can be created
                        </Text>
                    </div>
                    <div className='flex items-center gap-sm'>
                        {/* <div className='pr-xl italic text-warning'>warning text here</div> */}
                        <input
                            type='number'
                            value={tempCharacterLimit}
                            className={cn(
                                'w-[80px]',
                                'bg-transparent',
                                'border-b-2 border-white',
                                'outline-none',
                                'text-xl text-center',
                            )}
                            // onFocus={() => setTempCharacterLimit(0)}
                            onChange={(e) => {
                                setTempCharacterLimit(Number(e.target.value));
                            }}
                        />
                        <Text>/10000</Text>
                    </div>
                </Container>
                <Container
                    variant={EContainerVariant.gradientBorder}
                    className='flex items-start justify-between p-default'
                >
                    <div className='flex flex-col gap-sm'>
                        <Text variant={ETextVariant.default} className='font-general-bold text-base 2xl:text-lg'>
                            Set Global Grade Scoring Value
                        </Text>
                    </div>
                    <div className='flex flex-col gap-sm'>
                        <div className='flex items-center gap-sm'>
                            <div className='pr-sm text-color-primary'>A</div>
                            <Input
                                type='number'
                                value={tempAGradeScoring} // Set value to empty string for 0 or undefined
                                variant={EInputVariant.ghost}
                                placeholder='0'
                                className='size-fit w-[100px] text-black'
                                // onFocus={() => setTempAGradeScoring(0)}
                                onChange={(e) => {
                                    setTempAGradeScoring(Number(e.target.value));
                                }}
                            />
                        </div>
                        <div className='flex items-center gap-sm'>
                            <div className='pr-sm text-color-primary'>B</div>
                            <Input
                                type='number'
                                value={tempBGradeScoring} // Set value to empty string for 0 or undefined
                                variant={EInputVariant.ghost}
                                placeholder='0'
                                className='size-fit w-[100px] text-black'
                                // onFocus={() => setTempBGradeScoring(0)}
                                onChange={(e) => {
                                    setTempBGradeScoring(Number(e.target.value));
                                }}
                            />
                        </div>
                        <div className='flex items-center gap-sm'>
                            <div className='pr-sm text-color-primary'>C</div>
                            <Input
                                type='number'
                                value={tempCGradeScoring} // Set value to empty string for 0 or undefined
                                variant={EInputVariant.ghost}
                                placeholder='0'
                                className='size-fit w-[100px] text-black'
                                // onFocus={() => setTempCGradeScoring(0)}
                                onChange={(e) => {
                                    setTempCGradeScoring(Number(e.target.value));
                                }}
                            />
                        </div>
                        <div className='flex items-center gap-sm'>
                            <div className='pr-sm text-color-primary'>D</div>
                            <Input
                                type='number'
                                value={tempDGradeScoring} // Set value to empty string for 0 or undefined
                                variant={EInputVariant.ghost}
                                placeholder='0'
                                className='size-fit w-[100px] text-black'
                                // onFocus={() => setTempDGradeScoring(0)}
                                onChange={(e) => {
                                    setTempDGradeScoring(Number(e.target.value));
                                }}
                            />
                        </div>
                    </div>
                </Container>
            </div>
            <div className='flex justify-end'>
                <Button
                    variant={EButtonVariant.default}
                    className='w-fit px-md py-sm text-sm'
                    loading={setSystemConfigAttempting}
                    onClick={saveButtonHandler}
                >
                    Save changes
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.authGetLoginAttempting(state),
    setSystemConfigAttempting: Selectors.systemConfigurationSetSystemConfigAttempting(state),
    setSystemConfigError: Selectors.systemConfigurationSetSystemConfigError(state),
    getSystemConfigAttempting: Selectors.systemConfigurationGetSystemConfigAttempting(state),
    getSystemConfigError: Selectors.systemConfigurationGetSystemConfigError(state),
    systemConfig: Selectors.systemConfigurationGetSystemConfigData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    logout: () => dispatch(Actions.authLogout()),
    setSystemConfig: (params: SetSystemConfigParams) =>
        dispatch(Actions.systemConfigurationSetSystemConfigAttempt(params)),
    getSystemConfig: () => dispatch(Actions.systemConfigurationGetSystemConfigAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemSetting);
