import React from 'react';

import { RotatingLines } from 'react-loader-spinner';

import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';
import CustomLineChart from 'components/molecules/CustomLineChart';

import {
    EContainerVariant,
    ETextVariant,
    TPostChartData,
} from 'entities/components';

type PostStatusCardProps = {
    value: number;
    diff: number;
    label: string;
    data: TPostChartData[];
    loading?: boolean;
    error?: string;
};

const PostStatusCard = (props: PostStatusCardProps): JSX.Element => {
    const { value, diff, label, data, loading, error } = props;

    const renderContent = () => {
        if (loading) {
            return (
                <RotatingLines
                    strokeColor='white'
                    strokeWidth='5'
                    animationDuration='0.75'
                    width='100'
                    visible
                />
            );
        }

        if (error) {
            return <Text variant={ETextVariant.error}>{error}</Text>;
        }

        return (
            <>
                <div className='flex items-end gap-sm 2xl:gap-md'>
                    <Text
                        variant={ETextVariant.header}
                        className='font-heading-bold text-[3rem]'
                    >
                        {value}
                    </Text>
                    <Text className='mb-1'>{label}</Text>
                </div>
                <div className='flex gap-sm'>
                    <Text className='text-success'>{diff}</Text>
                    <Text>this week</Text>
                </div>
                <div className='h-[120px] w-full pt-sm'>
                    <CustomLineChart
                        data={data.map((row) => ({
                            counts: row.count,
                            date: row.week.toString(),
                        }))}
                    />
                </div>
            </>
        );
    };

    return (
        <Container variant={EContainerVariant.gradientBorder} className='p-md'>
            {renderContent()}
        </Container>
    );
};

PostStatusCard.defaultProps = {
    loading: false,
    error: '',
};

export default PostStatusCard;
