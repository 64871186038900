import React from 'react';

import { FaChevronLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import AuthLayout from 'components/layout/AuthLayout';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import cn from 'lib/TailwindMerge';
import {
    EButtonVariant,
    EContainerVariant,
    ETextVariant,
} from 'entities/components';

interface ForgotPasswordProps {
    loading: boolean;
    error: string;

    forgotPassword: (email: string) => void;
}
const ForgotPasword = (props: ForgotPasswordProps): JSX.Element => {
    const {
        loading,
        error,
        forgotPassword,
    } = props;

    const handleForgotPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const email = formData.get('email') as string;
        if (email) {
            forgotPassword(email);
        } else {
            toast.error('Please fill in all fields');
        }
    };

    return (
        <AuthLayout>
            <Container
                variant={EContainerVariant.glass}
                className={cn(
                    'w-[430px] 2xl:w-[480px]',
                    'flex flex-col',
                    'gap-default',
                    'p-default',
                )}
            >
                <Button
                    variant={EButtonVariant.ghost}
                    onClick={() => NavActions.navBack()}
                    className='flex size-fit items-center gap-2'
                >
                    <FaChevronLeft size={20} />
                    <div className='pb-1'>
                        BACK
                    </div>
                </Button>
                <div>
                    <Text variant={ETextVariant.header}>
                        FORGOT PASSWORD
                    </Text>
                    <Text variant={ETextVariant.subheader}>
                        Enter your email to reset your password.
                    </Text>
                </div>
                <form
                    className='flex flex-col gap-default'
                    onSubmit={handleForgotPassword}
                >
                    <Input
                        type='text'
                        name='email'
                        placeholder='Email Address'
                    />
                    <Button
                        type='submit'
                        className='mt-lg'
                        loading={loading}
                    >
                        Reset
                    </Button>
                </form>
                <Text
                    variant={ETextVariant.error}
                    className='flex justify-center'
                >
                    {error}
                </Text>
            </Container>
        </AuthLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.authGetForgotPasswordAttempting(state),
    error: Selectors.authGetForgotPasswordError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    forgotPassword: (email: string) => dispatch(Actions.authForgotPasswordAttempt({ email })),
    resetErrors: () => dispatch(Actions.authResetErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasword);
