import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'typed-redux-saga/macro';

import config from 'config';

import AuthGateway from 'api/Auth';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { VerifyOtpParamsPayload } from 'redux/slices/auth/types';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';

export default function* watchVerifyOtp(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authVerifyOtpAttempt', handleVerifyOtp, api);
}

function* handleVerifyOtp(api: AuthGateway, data: VerifyOtpParamsPayload) {
    const { email, otp } = data.payload;

    if (!otp) {
        yield put(Actions.authVertifyOtpFailure('Please enter your otp'));
        return;
    }

    const response = yield* call([api, api.verifyOtp], { email, otp });

    if (response.status === GatewayResponseStatus.Error) {
        toast.error(response.message);
        yield put(Actions.authVertifyOtpFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            toast.success(config.useMockApi ? response.data : 'otp verified successfully');
            yield put(Actions.authVertifyOtpSuccess());

            NavActions.navToResetPassword(email, otp);
        } else {
            yield put(Actions.authVertifyOtpFailure('Something went wrong. Please try again.'));
        }
    }
}
