import { TDateFilterString } from 'entities/components';
import {
    EMenuList,
    EPostDetailsTab,
    ETotalUserType,
    EUserDetailsTab,
} from 'entities/ui';

import { UiState } from '.';

const getCurrentPage = (state: UiState): EMenuList => state.currentPage || EMenuList.home;

const getCurrentTotalUserType = (state: UiState): ETotalUserType =>
    state.currentTotalUserType || ETotalUserType.newUser;
const getCurrentUserDetailsTab = (state: UiState): EUserDetailsTab =>
    state.currentUserDetailsTab || EUserDetailsTab.allPosts;
const getCurrentPostDetailsTab = (state: UiState): EPostDetailsTab =>
    state.currentPostDetailsTab || EPostDetailsTab.commentsHistories;

const getCurrentUserReportDateFilter = (state: UiState): TDateFilterString => state.dateFilter.userReport;
const getCurrentUserDetailsDateFilter = (state: UiState): TDateFilterString => state.dateFilter.userDetails;
const getCurrentPostReportDateFilter = (state: UiState): TDateFilterString => state.dateFilter.postReport;
const getCurrentPostDetailsDateFilter = (state: UiState): TDateFilterString => state.dateFilter.postDetails;
const getCurrentPurchaseReportDateFilter = (state: UiState): TDateFilterString => state.dateFilter.purchaseReport;
const getCurrentPaymentReportDateFilter = (state: UiState): TDateFilterString => state.dateFilter.paymentReport;
const getCurrentPaymentDetailsDateFilter = (state: UiState): TDateFilterString => state.dateFilter.paymentDetails;
const getCurrentModerationReportDateFilter = (state: UiState): TDateFilterString => state.dateFilter.moderationReport;

const banUserModalIsOpen = (state: UiState): boolean => state.userReportModalIsOpen.banUser;
const unbanUserModalIsOpen = (state: UiState): boolean => state.userReportModalIsOpen.unbanUser;
const deleteCommentModalIsOpen = (state: UiState): boolean => state.userReportModalIsOpen.deleteComment;
const addTopicModalIsOpen = (state: UiState): boolean => state.topicReportModalIsOpen.addTopic;
const editTopicModalIsOpen = (state: UiState): boolean => state.topicReportModalIsOpen.editTopic;
const deleteTopicModalIsOpen = (state: UiState): boolean => state.topicReportModalIsOpen.deleteTopic;
const setPostModerationReviewModalIsOpen = (state: UiState): boolean => state.postReportModalIsOpen.postModerationReview;
const deleteUserPostModalIsOpen = (state: UiState): boolean => state.postReportModalIsOpen.deletePost;
const addSubadminModalIsOpen = (state: UiState): boolean => state.subadminAccessModalIsOpen.addSubadmin;
const viewSubadminModalIsOpen = (state: UiState): boolean => state.subadminAccessModalIsOpen.viewSubadmin;
const editSubadminModalIsOpen = (state: UiState): boolean => state.subadminAccessModalIsOpen.editSubadmin;
const revokeSubadminModalIsOpen = (state: UiState): boolean => state.subadminAccessModalIsOpen.revokeSubadmin;
const getModerationReportDetailsModalIsOpen = (state: UiState): boolean => state.moderationReportDetailsModalIsOpen;

export default {
    getCurrentPage,
    getCurrentTotalUserType,
    getCurrentUserDetailsTab,
    getCurrentPostDetailsTab,

    getCurrentUserReportDateFilter,
    getCurrentUserDetailsDateFilter,
    getCurrentPostReportDateFilter,
    getCurrentPostDetailsDateFilter,
    getCurrentPurchaseReportDateFilter,
    getCurrentPaymentReportDateFilter,
    getCurrentPaymentDetailsDateFilter,
    getCurrentModerationReportDateFilter,

    banUserModalIsOpen,
    unbanUserModalIsOpen,
    deleteCommentModalIsOpen,
    addTopicModalIsOpen,
    editTopicModalIsOpen,
    deleteTopicModalIsOpen,
    setPostModerationReviewModalIsOpen,
    deleteUserPostModalIsOpen,
    addSubadminModalIsOpen,
    viewSubadminModalIsOpen,
    editSubadminModalIsOpen,
    revokeSubadminModalIsOpen,
    getModerationReportDetailsModalIsOpen,
};
