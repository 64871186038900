import { goBack, push, replace } from 'redux-first-history';

import { store } from 'redux/store';

const navPush = (routeName: string) => {
    store.dispatch(push(routeName));
};

const navBack = (): void => {
    store.dispatch(goBack());
};

const navReplace = (routeName: string) => {
    store.dispatch(replace(routeName));
};

const navResetToLogin = (): void => navReplace('/login');
const navToForgotPassword = (): void => navPush('/forgot-password');
const navToVerifyOtp = (email: string, otp?: string): void => {
    if (otp) {
        navPush(`/verify-otp?email=${email}&otp=${otp}`);
    } else {
        navPush(`/verify-otp?email=${email}`);
    }
};

const navToResetPassword = (email: string, otp: string): void => navPush(`/reset-password?email=${email}&otp=${otp}`);

const navToReports = (module: string): void => navPush(`/reports/${module}`);

const navToModuleDetails = (module: string, id: string): void => navPush(`/reports/${module}/${id}`);

const navToSettings = (module: string): void => navPush(`/settings/${module}`);

const navToHome = (): void => navPush('/');

export default {
    navBack,
    navResetToLogin,
    navToForgotPassword,
    navToVerifyOtp,
    navToResetPassword,
    navToReports,
    navToModuleDetails,
    navToSettings,
    navToHome,
};
