import React from 'react';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';
import CustomAreaChart from 'components/molecules/CustomAreaChart';

import NavActions from 'lib/NavActions';
import { EContainerVariant, ETextVariant, TChartData, TPurchasesChartData } from 'entities/components';

type ChartCardProps = {
    value: number,
    label: string,
    data: TPurchasesChartData[],
    viewButton: boolean,
}

const ChartCard = (props: ChartCardProps): JSX.Element => {
    const {
        value,
        label,
        data,
        viewButton,
    } = props;

    const initValue = {
        date: '',
        amount: 0,
    };

    const checkData = data.reduceRight((acc, curr) => {
        if (curr.amount > 0) {
            return curr;
        }
        return acc;
    }, { ...initValue });

    const massagedChartData: TChartData[] = data.map((item) => ({
        date: item.date,
        counts: item.amount,
    }));

    const renderChart = () => {
        if (checkData.amount > 0) {
            return <CustomAreaChart data={massagedChartData} type='SGD' isPayment />;
        }

        return <Text>No data</Text>;
    };

    return (
        <Container
            variant={EContainerVariant.gradientBorder}
            className='p-default px-xl'
        >
            <div className='flex items-end gap-sm'>
                <Text
                    variant={ETextVariant.header}
                    className='font-heading-bold text-[5rem]'
                >
                    {value > 1000 ? `${value / 1000}K` : value}
                </Text>
                <Text className='mb-1'>
                    {label}
                </Text>
            </div>
            <div className='h-[250px] w-full py-md'>
                {renderChart()}
            </div>
            <div className='mt-2 flex items-center justify-center'>
                {
                    viewButton && (
                        <Button className='w-fit px-8' onClick={() => NavActions.navToReports('purchaseReport')}>
                            <Text>View Details</Text>
                        </Button>
                    )
                }
            </div>
        </Container>
    );
};

export default ChartCard;
