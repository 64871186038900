import { EModerationCategory } from 'entities/report';

export const BannedCategoriesLOV = [
    {
        label: 'Gore',
        value: EModerationCategory.GORE,
    },
    {
        label: 'Nudity',
        value: EModerationCategory.NUDITY,
    },
    {
        label: 'Offensive',
        value: EModerationCategory.OFFENSIVE,
    },
    {
        label: 'Unrelated',
        value: EModerationCategory.UNRELATED,
    },
    {
        label: 'Harassment',
        value: EModerationCategory.HARASSMENT,
    },
    {
        label: 'Boosting',
        value: EModerationCategory.BOOSTING,
    },
    {
        label: 'Copyright',
        value: EModerationCategory.COPYRIGHT,
    },
];

export default {
    BannedCategoriesLOV,
};
