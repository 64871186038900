import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetDashboardDetailsResponse } from 'api/DashboardBase';

import { DashboardReduxState, StringOrNullPayload } from './types';

const initialState: DashboardReduxState = {
    actions: {
        getDashboardDetails: false,

    },
    dashboardDetails: {
        newUsers: {
            count: 0,
            diff: null,
            data: [],
        },
        activeUsers: {
            count: 0,
            diff: null,
            data: [],
        },
        pendingReports: {
            count: 0,
            diff: null,
            data: [],
        },
        purchaseReport: {
            amount: 0,
            data: [],
        },
        paymentReport: {
            commissionMade: 0,
            pendingPayment: 0,
        },
    },
    error: {
        getDashboardDetails: '',
    },
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getDashboardDetailsAttempt: (state) => {
            state.actions.getDashboardDetails = true;
            state.error.getDashboardDetails = '';
        },

        getDashboardDetailsSuccess: (state, action:PayloadAction<GetDashboardDetailsResponse>) => {
            state.dashboardDetails = action.payload;
            state.actions.getDashboardDetails = false;
        },
        getDashboardDetailsFailure: (state, action: StringOrNullPayload) => {
            state.actions.getDashboardDetails = false;
            if (action.payload) {
                state.error.getDashboardDetails = action.payload;
            }
        },
    },
});

export type DashboardState = typeof initialState;

export default {
    actions: dashboardSlice.actions,
    reducers: dashboardSlice.reducer,
};
