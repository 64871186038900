import React from 'react';

import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Modal from 'components/molecules/Modal';

import { DeleteTopicParams } from 'api/TopicReportBase';
import { DeleteUserCommentParams } from 'api/UserReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import cn from 'lib/TailwindMerge';
import { EButtonVariant, ETextVariant } from 'entities/components';

type DeleteTopicModalProps = {
    deleteTopicAttempt: boolean;
    modalIsOpen: boolean;
    setModalIsOpen: (state: boolean) => void;
    deleteTopic: (params: DeleteTopicParams) => void;

    topicId: string;
};

const DeleteTopicModal = (props: DeleteTopicModalProps): JSX.Element => {
    const { deleteTopicAttempt, modalIsOpen, setModalIsOpen, deleteTopic, topicId } = props;

    return (
        <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
            <div className={cn('w-[450px] flex flex-col', 'items-center justify-center', 'gap-default p-xl')}>
                <Text variant={ETextVariant.header} className='font-heading-bold text-button-primary 2xl:text-[60px]'>
                    ARE YOU SURE?
                </Text>
                <Text className='text-center'>
                    Confirm deleting this topic?
                </Text>
                <Text variant={ETextVariant.caption}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.
                </Text>
                <Button
                    variant={EButtonVariant.default}
                    className='mt-default py-md'
                    loading={deleteTopicAttempt}
                    onClick={() => {
                        deleteTopic({ topicId });
                    }}
                >
                    Confirm
                </Button>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    deleteTopicAttempt: Selectors.topicReportDeleteTopicAttempting(state),
    modalIsOpen: Selectors.uiDeleteTopicModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) => dispatch(Actions.uiSetDeleteTopicModalIsOpen(state)),
    deleteTopic: (params: DeleteTopicParams) => dispatch(Actions.topicReportDeleteTopicAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTopicModal);
