/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';

import { IoClose } from 'react-icons/io5';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';

import cn from 'lib/TailwindMerge';
import { EButtonVariant, EContainerVariant } from 'entities/components';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const Modal = (props: ModalProps):JSX.Element => {
    const {
        isOpen,
        onClose,
        children,
    } = props;

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            onClose();
        }
    };

    const handleModalClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    const handleParentClick = () => {
        onClose();
    };

    return (
        <div
            className={cn(
                isOpen ? 'flex' : 'hidden',
                'justify-center items-center',
                'fixed left-0 top-0',
                'z-50 h-screen w-screen',
                'bg-glass filter backdrop-blur-glass',
                'shadow-glass',
            )}
            onClick={handleParentClick}
        >
            <div
                onClick={handleModalClick}
                className={cn(
                    'bg-background rounded-default shadow-input',
                    'size-fit min-w-[250px] min-h-[250px]',
                    'relative flex rounded-xl',
                    'p-md',
                )}
            >
                <Button
                    variant={EButtonVariant.ghost}
                    className='absolute right-4 top-4'
                    onClick={onClose}
                >
                    <IoClose color='white' size={30} />
                </Button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
