import React, { FunctionComponent } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import DashboardLayout from 'components/layout/DashboardLayout';

import Utils from 'lib/Utils';

const PrivateRoute: FunctionComponent = () => {
    const isAuthenticated = Utils.Auth.getAuthToken();

    if (!isAuthenticated) return <Navigate replace to='/login' />;

    return (
        <DashboardLayout>
            <Outlet />
        </DashboardLayout>
    );
};

export default PrivateRoute;
