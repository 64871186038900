/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import dayjs from 'dayjs';
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import Text from 'components/atoms/Text';

import { ETextVariant, TChartData, TPurchasesChartData } from 'entities/components';

const CustomYTick = (props: any) => {
    const { x, y, payload } = props;
    if (payload.value === 0) return null;

    if (payload.value >= 1000) {
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={5} textAnchor='end' fill='#fff'>
                    {`${payload.value / 1000}K`}
                </text>
            </g>
        );
    }
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={5} textAnchor='end' fill='#fff'>
                {payload.value}
            </text>
        </g>
    );
};

const CustomXTick = (props: any) => {
    const { x, y, payload } = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={10} dy={5} textAnchor='middle' fill='#fff'>
                {dayjs(payload.value).format('DD MMM')}
            </text>
        </g>
    );
};

const CustomTooltip = (props: any) => {
    const { active, payload, type, isPayment } = props;

    if (active && payload && payload.length) {
        return (
            <div className='flex flex-col rounded-2xl bg-success px-md py-sm'>
                <Text
                    variant={ETextVariant.default}
                    className='font-general-bold'
                >
                    {isPayment ? `${type} ${payload[0].value}` : `${payload[0].value} ${type}`}
                </Text>
                <Text
                    variant={ETextVariant.subheader}
                >
                    {payload[0].payload.date}
                </Text>
            </div>
        );
    }

    return null;
};
type TotalUserChartProps = {
    data: TChartData[];
    type: string;
    isPayment?: boolean;
};

const CustomAreaChart = (props: TotalUserChartProps): JSX.Element => {
    const { data, type, isPayment } = props;

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <AreaChart
                data={data}
                margin={{
                    top: 10,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            >
                <defs>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='5%' stopColor='#54DE90' stopOpacity={0.8} />
                        <stop offset='98%' stopColor='#54DE90' stopOpacity={0} />
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray='0' vertical={false} />
                <XAxis
                    dataKey='date'
                    stroke='#ffffff'
                    tickLine={false}
                    axisLine={false}
                    tick={<CustomXTick />}
                />
                <YAxis
                    stroke='#ffffff'
                    strokeWidth={0}
                    tickLine={false}
                    tick={<CustomYTick />}
                    width={40}
                />
                <Tooltip
                    content={<CustomTooltip type={type} isPayment={isPayment} />}
                />
                <Area
                    type='linear'
                    dataKey='counts'
                    stroke='#00FF4E'
                    fillOpacity={1}
                    fill='url(#colorPv)'
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

CustomAreaChart.defaultProps = {
    isPayment: false,
};

export default CustomAreaChart;
