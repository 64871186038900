import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import SystemConfigurationGateway from 'api/SystemConfiguration';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetSystemConfig(api: SystemConfigurationGateway): SagaWatcherReturnType {
    yield takeEvery('systemConfiguration/systemConfigurationGetSystemConfigAttempt', handleGetSystemConfig, api);
}

function* handleGetSystemConfig(api: SystemConfigurationGateway) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.getSystemConfig], {
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.systemConfigurationGetSystemConfigFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.systemConfigurationGetSystemConfigSuccess(response.data));
    }
}
