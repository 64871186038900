import React from 'react';

import { connect } from 'react-redux';

import ContentLayout from 'components/layout/ContentLayout';

import Selectors from 'redux/Selectors';
import { RootState } from 'redux/store';

import { EMenuList } from 'entities/ui';

import ModerationReport from './moderation';
import PaymentReport from './payment';
import PostReport from './post';
import PurchaseReport from './purchase';
import TopicReport from './topic';
import UserReport from './user';

interface HomeScreenProps {
    currentPage: EMenuList;
}

const ReportScreen = (props: HomeScreenProps): JSX.Element => {
    const {
        currentPage,
    } = props;

    const renderReports = () => {
        switch (currentPage) {
            case EMenuList.userReport:
                return <UserReport />;
            case EMenuList.postReport:
                return <PostReport />;
            case EMenuList.topicReport:
                return <TopicReport />;
            case EMenuList.purchaseReport:
                return <PurchaseReport />;
            case EMenuList.paymentReport:
                return <PaymentReport />;
            case EMenuList.moderationReport:
                return <ModerationReport />;
            default:
                return <UserReport />;
        }
    };

    return (
        <ContentLayout>
            {renderReports()}
        </ContentLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentPage: Selectors.uiGetCurrentPage(state),
});

export default connect(mapStateToProps)(ReportScreen);
