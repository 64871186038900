import { takeEvery } from 'typed-redux-saga/macro';

import Actions from 'redux/Actions';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';

export default function* watchLogout(): SagaWatcherReturnType {
    yield takeEvery('auth/authLogout', handleLogout);
}

function handleLogout() {
    Utils.Auth.clearAuthToken();
    Utils.Auth.clearUserEmail();
    Actions.authClearUserInfo();
    NavActions.navResetToLogin();
}
