import React, { useEffect, useState } from 'react';

import { FiPercent } from 'react-icons/fi';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';

import { SetUserCommissionParams } from 'api/UserReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import {
    EButtonVariant,
    EContainerVariant,
    EInputVariant,
    ETextVariant,
} from 'entities/components';
import { EUserStatus } from 'entities/report';
import { BannedCategoriesLOV } from 'lov/report';

import UserBanModal from '../components/userDetails/UserBanModal';
import UserUnbanModal from '../components/userDetails/UserUnbanModal';

type UserSettingTabProps = {
    setUserCommissionAttempt: boolean;
    setUserCommissionError: string;

    setUserCommission: (params: SetUserCommissionParams) => void;
    setBanUserModalIsOpen: (state: boolean) => void;
    setUnBanUserModalIsOpen: (state: boolean) => void;

    userId: string;
    commissionRate?: number;
    userStatus?: EUserStatus;
    category?: number;
    remarks?: string;
    loading?: boolean;
    error?: string;
};

const UserSettingTab = (props: UserSettingTabProps): JSX.Element => {
    const {
        setUserCommissionAttempt,
        setUserCommissionError,
        setUserCommission,
        setBanUserModalIsOpen,
        setUnBanUserModalIsOpen,
        userId,
        commissionRate,
        userStatus,
        category,
        remarks,
        loading,
        error,
    } = props;

    const [commissionRateState, setcommissionRateState] = useState<number | undefined>();

    useEffect(() => {
        if (commissionRate) {
            const newRate = commissionRate * 100;
            setcommissionRateState(newRate);
        }
    }, [commissionRate]);

    const SaveButtonHandler = () => {
        if (commissionRateState) {
            const newRate = commissionRateState / 100;
            setUserCommission({ userId, commissionRate: newRate });
        }
    };

    const renderWarning = () => {
        if (commissionRateState && commissionRateState < 0) {
            return (
                <Text
                    variant={ETextVariant.error}
                    className='text-sm text-warning'
                >
                    Commission rate cannot be negative
                </Text>
            );
        }
        if (commissionRateState && commissionRateState > 100) {
            return (
                <Text
                    variant={ETextVariant.error}
                    className='text-sm text-warning'
                >
                    Commission rate cannot be greater than 100
                </Text>
            );
        }

        if (commissionRate && commissionRateState && commissionRateState !== commissionRate * 100) {
            return (
                <Text
                    variant={ETextVariant.error}
                    className='text-sm text-warning'
                >
                    Commission rate has been changed! Please click save.
                </Text>
            );
        }
        return null;
    };

    const renderBanDescription = () => {
        if (userStatus === EUserStatus.SuspendedUser) {
            return (
                <div className='flex flex-col gap-sm'>
                    <Text
                        variant={ETextVariant.default}
                        className='font-general-bold text-base 2xl:text-lg'
                    >
                        {`Banned Category: ${BannedCategoriesLOV[category ? (category - 1) : 0].label}`}
                    </Text>
                    <Text
                        variant={ETextVariant.default}
                        className='text-xs 2xl:text-sm'
                    >
                        {`Banned Remarks: ${remarks}`}
                    </Text>
                </div>
            );
        }
        return (
            <div className='flex flex-col gap-sm'>
                <Text
                    variant={ETextVariant.default}
                    className='font-general-bold text-base 2xl:text-lg'
                >
                    Ban User Account
                </Text>
                <Text
                    variant={ETextVariant.default}
                    className='text-xs 2xl:text-sm'
                >
                    Use this button to ban this user account with caution!
                </Text>
            </div>
        );
    };

    const renderBanButton = () => {
        if (userStatus === EUserStatus.SuspendedUser) {
            return (
                <Button
                    variant={EButtonVariant.warning}
                    className='size-fit px-md py-sm text-sm text-black'
                    onClick={() => setUnBanUserModalIsOpen(true)}
                >
                    Unban Account
                </Button>
            );
        }
        return (
            <Button
                variant={EButtonVariant.danger}
                className='size-fit px-md py-sm text-sm'
                onClick={() => setBanUserModalIsOpen(true)}
            >
                Ban Account
            </Button>
        );
    };

    if (error || setUserCommissionError) {
        return (
            <div className='flex items-center justify-center'>
                <Text variant={ETextVariant.error}>
                    {error || setUserCommissionError}
                </Text>
            </div>
        );
    }

    if (loading || setUserCommissionAttempt) {
        return (
            <div className='flex items-center justify-center'>
                <RotatingLines
                    strokeColor='white'
                    strokeWidth='5'
                    animationDuration='0.75'
                    width='100'
                    visible
                />
            </div>
        );
    }

    return (
        <div className='flex flex-col gap-default'>
            <Container
                variant={EContainerVariant.gradientBorder}
                className='flex items-center justify-between p-default'
            >
                <div className='flex flex-col gap-sm'>
                    <Text
                        variant={ETextVariant.default}
                        className='font-general-bold text-base 2xl:text-lg'
                    >
                        Commission Rate
                    </Text>
                    <Text
                        variant={ETextVariant.default}
                        className='text-xs 2xl:text-sm'
                    >
                        Set commission rate for this user account (%)
                    </Text>
                </div>
                <div className='flex items-center gap-sm'>
                    <div className='pr-xl'>
                        {renderWarning()}
                    </div>
                    <Input
                        type='number'
                        value={commissionRateState ?? undefined}
                        variant={EInputVariant.ghost}
                        placeholder='0'
                        className='size-fit w-[100px] text-black'
                        onFocus={() => setcommissionRateState(undefined)}
                        onChange={(e) => {
                            setcommissionRateState(Number(e.target.value));
                        }}
                    />
                    <FiPercent size={40} color='white' />
                </div>
            </Container>
            <Container
                variant={EContainerVariant.gradientBorder}
                className='flex items-center justify-between p-default'
            >
                {renderBanDescription()}
                {renderBanButton()}
            </Container>
            <div className='flex justify-end'>
                <Button
                    variant={EButtonVariant.default}
                    className='w-fit px-md py-sm text-sm'
                    onClick={SaveButtonHandler}
                >
                    Save changes
                </Button>
            </div>
            <UserBanModal userId={userId} />
            <UserUnbanModal userId={userId} />
        </div>
    );
};

UserSettingTab.defaultProps = {
    commissionRate: 0,
    userStatus: EUserStatus.ActiveUser,
    category: 0,
    remarks: '',
    loading: false,
    error: '',
};

const mapStateToProps = (state: RootState) => ({
    setUserCommissionAttempt: Selectors.userReportSetUserCommissionAttempting(state),
    setUserCommissionError: Selectors.userReportSetUserCommissionError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setUserCommission: (params: SetUserCommissionParams) => dispatch(Actions.userReportSetUserCommissionAttempt(params)),
    setBanUserModalIsOpen: (state: boolean) => dispatch(Actions.uiSetBanUserModalIsOpen(state)),
    setUnBanUserModalIsOpen: (state: boolean) => dispatch(Actions.uiSetUnbanUserModalIsOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingTab);
