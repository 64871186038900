import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import { GatewayResponseStatus } from 'api/types/types';
import UserReportGateway from 'api/UserReport';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { GetUserGradesParamsPayload } from 'redux/slices/userReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetUserGrades(api: UserReportGateway): SagaWatcherReturnType {
    yield takeEvery('userReport/userReportGetUserGradesAttempt', handleGetUserGrades, api);
}

function* handleGetUserGrades(api: UserReportGateway, data: GetUserGradesParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.getUserGrades], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.userReportGetUserGradesFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.userReportGetUserGradesSuccess(response.data));
    }
}
