import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import config from 'config';

import { GatewayResponseStatus } from 'api/types/types';
import UserReportGateway from 'api/UserReport';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { SetUserToBanParamsPayload, SetUserToUnbanParamsPayload } from 'redux/slices/userReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchSetUserToUnban(api: UserReportGateway): SagaWatcherReturnType {
    yield takeEvery('userReport/userReportSetUserToUnbanAttempt', handleSetUserToUnban, api);
}

function* handleSetUserToUnban(api: UserReportGateway, data: SetUserToUnbanParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.setUserToUnban], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.uiSetUnbanUserModalIsOpen(false));
        yield put(Actions.userReportSetUserToUnbanFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.userReportSetUserToUnbanSuccess());
        yield put(Actions.userReportGetUserSummaryAttempt(data.payload.userId));
        yield put(Actions.uiSetUnbanUserModalIsOpen(false));
        toast.success(config.useMockApi ? response.data : 'User unbanned successfully!');
    }
}
