import config from 'config';

import AuthGateway from './Auth';
import DashboardGateway from './Dashboard';
import ModerationReportGateway from './ModerationReport';
import PaymentReportGateway from './PaymentReport';
import PostReportGateway from './PostReport';
import PurchaseReportGateway from './PurchaseReport';
import SubAdminGateway from './SubAdmin';
import SystemConfigurationGateway from './SystemConfiguration';
import TopicReportGateway from './TopicReport';
import UserReportGateway from './UserReport';

const baseUrl = config.baseUrl as string;

const auth = new AuthGateway(baseUrl);
const userReport = new UserReportGateway(baseUrl);
const postReport = new PostReportGateway(baseUrl);
const topicReport = new TopicReportGateway(baseUrl);
const purchaseReport = new PurchaseReportGateway(baseUrl);
const paymentReport = new PaymentReportGateway(baseUrl);
const moderationReport = new ModerationReportGateway(baseUrl);
const systemConfiguration = new SystemConfigurationGateway(baseUrl);
const subAdmin = new SubAdminGateway(baseUrl);
const dashboard = new DashboardGateway(baseUrl);

export default {
    auth,
    userReport,
    postReport,
    purchaseReport,
    topicReport,
    paymentReport,
    moderationReport,
    systemConfiguration,
    subAdmin,
    dashboard,
};
