import React from 'react';

import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Modal from 'components/molecules/Modal';

import { SetUserToUnbanParams } from 'api/UserReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import cn from 'lib/TailwindMerge';
import { EButtonVariant, ETextVariant } from 'entities/components';

type UserBanModalProps = {
    modalIsOpen: boolean;
    setModalIsOpen: (state: boolean) => void;
    setUserToUnban: (params: SetUserToUnbanParams) => void;

    userId: string;
}

const UserUnbanModal = (props: UserBanModalProps): JSX.Element => {
    const {
        modalIsOpen,
        setModalIsOpen,
        setUserToUnban,
        userId,
    } = props;

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
        >
            <div className={cn(
                'w-[450px] flex flex-col',
                'items-center justify-center',
                'gap-default p-xl',
            )}
            >
                <Text
                    variant={ETextVariant.header}
                    className='font-heading-bold text-button-primary 2xl:text-[60px]'
                >
                    ARE YOU SURE?
                </Text>
                <Text className='text-center'>
                    Lorem ipsum dolor sit amet,
                    consetetur sadipscing elitr,
                    sed diam nonumy eirmod tempor invidunt
                    ut labore et dolore magna aliquyam erat,
                    sed diam voluptua. At vero eos et accusam
                    et justo duo dolores et ea rebum.
                </Text>
                <Text variant={ETextVariant.caption}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing
                    elitr, sed diam nonumy eirmod tempor.
                </Text>
                <Button
                    variant={EButtonVariant.default}
                    className='mt-default py-md'
                    onClick={() => {
                        setUserToUnban({ userId });
                    }}
                >
                    Confirm
                </Button>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    modalIsOpen: Selectors.uiUnbanUserModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) => dispatch(Actions.uiSetUnbanUserModalIsOpen(state)),
    setUserToUnban: (params: SetUserToUnbanParams) => dispatch(Actions.userReportSetUserToUnbanAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserUnbanModal);
