import {
    GenericIndexedResponse,
    GetAllTopicsResponse,
    GetPostCommentHistoryResponse,
    GetPostDetailsResponse,
    GetPostGradeHistoryResponse,
    GetPostModerationDetailsResponse,
    GetPostModerationResponse,
    GetPostPurchaseHistoryResponse,
    GetPostReportResponse,
} from 'api/PostReportBase';

import { PostReportState } from '.';

const getPostReportAttempting = (state: PostReportState): boolean => state.actions.getPostReport || false;
const getPostReportError = (state: PostReportState): string => state.error.getPostReport || '';
const getPostReportData = (state: PostReportState): GetPostReportResponse => state.postReport;

const getAllTopicsAttempting = (state: PostReportState): boolean => state.actions.getAllTopics || false;
const getAllTopicsError = (state: PostReportState): string => state.error.getAllTopics || '';
const getAllTopicsData = (state: PostReportState): GetAllTopicsResponse[] => state.allTopics;

const getPostDetailsAttempting = (state: PostReportState): boolean => state.actions.getPostDetails || false;
const getPostDetailsError = (state: PostReportState): string => state.error.getPostDetails || '';
const getPostDetailsData = (state: PostReportState): GetPostDetailsResponse => state.postDetails;

const getPostCommentHistoryAttempting = (state: PostReportState): boolean =>
    state.actions.getPostCommentHistory || false;
const getPostCommentHistoryError = (state: PostReportState): string => state.error.getPostCommentHistory || '';
const getPostCommentHistoryData = (state: PostReportState): GenericIndexedResponse<GetPostCommentHistoryResponse> => state.postComments;

const getPostGradeHistoryAttempting = (state: PostReportState): boolean => state.actions.getPostGradeHistory || false;
const getPostGradeHistoryError = (state: PostReportState): string => state.error.getPostGradeHistory || '';
const getPostGradeHistoryData = (state: PostReportState): GenericIndexedResponse<GetPostGradeHistoryResponse> => state.postGradeHistory;

const getPostModerationAttempting = (state: PostReportState): boolean => state.actions.getPostModeration || false;
const getPostModerationError = (state: PostReportState): string => state.error.getPostModeration || '';
const getPostModerationData = (state: PostReportState): GenericIndexedResponse<GetPostModerationResponse> => state.postModeration;

const getPostModerationDetailsAttempting = (state: PostReportState): boolean =>
    state.actions.getPostModerationDetails || false;
const getPostModerationDetailsError = (state: PostReportState): string => state.error.getPostModerationDetails || '';
const getPostModerationDetailsData = (state: PostReportState): GetPostModerationDetailsResponse =>
    state.postModerationDetails;

const setPostModerationReviewAttempting = (state: PostReportState): boolean =>
    state.actions.setPostModerationReview || false;
const setPostModerationReviewError = (state: PostReportState): string => state.error.setPostModerationReview || '';

const getPostPurchaseHistoryAttempting = (state: PostReportState): boolean =>
    state.actions.getPostPurchaseHistory || false;
const getPostPurchaseHistoryError = (state: PostReportState): string => state.error.getPostPurchaseHistory || '';
const getPostPurchaseHistoryData = (state: PostReportState): GenericIndexedResponse<GetPostPurchaseHistoryResponse> => state.postPurchaseHistory;

const setFeaturedPostAttempting = (state: PostReportState): boolean => state.actions.setFeaturedPost || false;
const setFeaturedPostError = (state: PostReportState): string => state.error.setFeaturedPost || '';

const deleteUserPostAttempting = (state: PostReportState): boolean => state.actions.deleteUserPost || false;
const deleteUserPostError = (state: PostReportState): string => state.error.deleteUserPost || '';

export default {
    getPostReportAttempting,
    getPostReportError,
    getPostReportData,

    getAllTopicsAttempting,
    getAllTopicsError,
    getAllTopicsData,

    getPostDetailsAttempting,
    getPostDetailsError,
    getPostDetailsData,

    getPostCommentHistoryAttempting,
    getPostCommentHistoryError,
    getPostCommentHistoryData,

    getPostGradeHistoryAttempting,
    getPostGradeHistoryError,
    getPostGradeHistoryData,

    getPostModerationAttempting,
    getPostModerationError,
    getPostModerationData,

    getPostModerationDetailsAttempting,
    getPostModerationDetailsError,
    getPostModerationDetailsData,

    setPostModerationReviewAttempting,
    setPostModerationReviewError,

    getPostPurchaseHistoryAttempting,
    getPostPurchaseHistoryError,
    getPostPurchaseHistoryData,

    setFeaturedPostAttempting,
    setFeaturedPostError,

    deleteUserPostAttempting,
    deleteUserPostError,
};
