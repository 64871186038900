import React from 'react';

import { FaCrown } from 'react-icons/fa';
import { RotatingLines } from 'react-loader-spinner';

import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';

import { renderImage, renderUserTier } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import {
    EContainerVariant,
    ETextVariant,
} from 'entities/components';
import { TRepLeaderboardUser } from 'entities/report';

type RepRankingCardProps = {
    leaderboard: TRepLeaderboardUser[];
    loading?: boolean;
    error?: string;
};

const RepRankingCard = (props: RepRankingCardProps): JSX.Element => {
    const {
        leaderboard,
        loading,
        error,
    } = props;

    const renderRepRankingLeaderboard = () => {
        if (leaderboard.length === 0) {
            return (
                <div className='flex h-full items-center justify-center'>
                    <Text
                        variant={ETextVariant.subheader}
                        className='mb-sm text-2xl'
                    >
                        No leaderboard yet!
                    </Text>
                </div>
            );
        }

        if (loading) {
            return (
                <div className='flex h-full items-center justify-center'>
                    <RotatingLines
                        strokeColor='white'
                        strokeWidth='5'
                        animationDuration='0.75'
                        width='100'
                        visible
                    />
                </div>
            );
        }

        if (error) {
            return (
                <div className='flex size-full items-center justify-center'>
                    <Text variant={ETextVariant.error}>
                        {error}
                    </Text>
                </div>
            );
        }

        const topFive = leaderboard.slice(0, 5);

        return topFive.map((user, index) => {
            const renderRank = () => {
                switch (index) {
                    case 0:
                        return <FaCrown size={20} color='white' />;
                    default:
                        return (
                            <Text
                                variant={ETextVariant.header}
                                className='mb-sm text-2xl'
                            >
                                {index + 1}
                            </Text>
                        );
                }
            };
            return (
                <div
                    key={user.username}
                    className={cn(
                        'flex items-center justify-between',
                        'py-sm px-md gap-sm',
                        'rounded-input bg-other-place',
                        index === 0 && 'bg-first-place',
                        (index === 1 || index === 2) && 'bg-second-place',
                    )}
                >
                    <div className='flex w-full items-center gap-sm'>
                        <div className='flex w-[20px] items-center justify-center'>
                            {renderRank()}
                        </div>
                        <div className='size-[30px] 2xl:size-[40px]'>
                            {renderImage(user.profileImageUrl ?? '')}
                        </div>
                        <div className='flex flex-col'>
                            <Text
                                variant={ETextVariant.default}
                                className='font-general-bold !text-base'
                            >
                                {user.displayName}
                            </Text>
                            <Text
                                variant={ETextVariant.subheader}
                                className='!text-xs'
                            >
                                {`@${user.username}`}
                            </Text>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <Text
                            variant={ETextVariant.header}
                            className='mb-sm text-right text-2xl'
                        >
                            {user.points}
                        </Text>
                        <div className='flex justify-end'>
                            <Text variant={ETextVariant.subheader} className='!text-sm'>
                                {renderUserTier(user.userTier)}
                            </Text>
                        </div>
                    </div>
                </div>
            );
        });
    };
    return (
        <Container
            variant={EContainerVariant.glassBorder}
            className={cn(
                'flex flex-col',
                'h-[500px]',
                'gap-md px-default py-md',
                'rounded-2xl',
                'overflow-hidden',
            )}
        >
            <div className='flex items-end gap-sm'>
                <Text
                    variant={ETextVariant.header}
                    className='text-3xl'
                >
                    REP RANKING
                </Text>
                <Text
                    variant={ETextVariant.subheader}
                    className='text-color-secondary'
                >
                    (Latest Updated 26 Feb)
                </Text>
            </div>
            <div className='flex size-full flex-col gap-sm'>
                {renderRepRankingLeaderboard()}
            </div>
        </Container>
    );
};

RepRankingCard.defaultProps = {
    loading: false,
    error: '',
};

export default RepRankingCard;
