import React from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';

import cn from 'lib/TailwindMerge';
import { EButtonVariant, ETextVariant } from 'entities/components';

type TTabMenu = {
    text: string;
    type: number;
    onClick: () => void;
}
type TabMenuProps = {
    menuData: TTabMenu[];
    selected: number;
    textClassName?: string;
    buttonClassName?: string;
    wrapperClassName?: string;
}

const TabMenu = (props: TabMenuProps): JSX.Element => {
    const {
        menuData,
        selected,
        textClassName,
        buttonClassName,
        wrapperClassName,
    } = props;

    const renderMenu = (data: TTabMenu[]) => {
        return data.map((item) => {
            return (
                <Button
                    variant={EButtonVariant.ghost}
                    key={item.text}
                    className={cn(
                        'flex items-center justify-center',
                        'rounded-3xl px-md',
                        'text-white',
                        'pb-1',
                        item.type === selected
                            ? 'bg-button-primary'
                            : 'border border-white bg-transparent',
                        buttonClassName,
                    )}
                    onClick={item.onClick}
                >
                    <Text
                        variant={ETextVariant.header}
                        className={cn(
                            'm-0 text-sm xl:text-base',
                            textClassName,
                        )}
                    >
                        {item.text}
                    </Text>
                </Button>
            );
        });
    };
    return (
        <div className={cn(
            'flex items-center gap-sm xl:gap-md',
            wrapperClassName,
        )}
        >
            {renderMenu(menuData)}
        </div>
    );
};

TabMenu.defaultProps = {
    textClassName: '',
    buttonClassName: '',
    wrapperClassName: '',
};

export default TabMenu;
