import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Modal from 'components/molecules/Modal';

import { GetModerationReportDetailsParams, GetModerationReportDetailsResponse, SetModerationReportStatusParams } from 'api/ModerationReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import cn from 'lib/TailwindMerge';
import { EButtonVariant, ETextVariant } from 'entities/components';
import { EModerationStatus } from 'entities/report';

type ModerationReportDetailsModalProps = {
    modalIsOpen: boolean;
    setModalIsOpen: (state: boolean) => void;
    setModerationReportStatusLoading: boolean;
    setModerationReportStatusError: string;

    moderationId: string;
    moderationReportDetails: GetModerationReportDetailsResponse | null;
    getModerationReportDetails: (params: GetModerationReportDetailsParams) => void;
    setModerationReportStatus: (params: SetModerationReportStatusParams) => void;
};

const ModerationReportDetailsModal = (props: ModerationReportDetailsModalProps): JSX.Element => {
    const {
        modalIsOpen,
        setModalIsOpen,
        moderationId,
        moderationReportDetails,
        getModerationReportDetails,
        setModerationReportStatus,
        setModerationReportStatusLoading,
        setModerationReportStatusError,
    } = props;

    useEffect(() => {
        if (moderationId) {
            getModerationReportDetails({ moderationId });
        }
    }, [moderationId]);

    const handleSetStatusApproveClick = () => {
        setModerationReportStatus({ moderationId, status: EModerationStatus.APPROVED });
    };

    const handleSetStatusRejectClick = () => {
        setModerationReportStatus({ moderationId, status: EModerationStatus.REJECTED });
    };

    return (
        <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
            <div className={cn('w-[450px] flex flex-col', 'gap-default p-xl')}>
                <Text variant={ETextVariant.header} className='font-heading-bold text-button-primary 2xl:text-[40px]'>
                    Moderation Details
                </Text>
                <Text variant={ETextVariant.caption}>
                    Title =
                    {' '}
                    {moderationReportDetails ? moderationReportDetails.title : ''}
                </Text>
                <Text variant={ETextVariant.caption}>
                    Content =
                    {' '}
                    {moderationReportDetails ? moderationReportDetails.content : ''}
                </Text>
                <Text variant={ETextVariant.caption}>
                    Target =
                    {' '}
                    {moderationReportDetails ? moderationReportDetails.targetUserDetails.username : ''}
                </Text>
                <Text variant={ETextVariant.caption}>
                    Reported by =
                    {' '}
                    {moderationReportDetails ? moderationReportDetails.reporterUsername : ''}
                </Text>
                <div className='relative flex items-center gap-default'>
                    <Button variant={EButtonVariant.default} onClick={handleSetStatusApproveClick} loading={setModerationReportStatusLoading}>
                        APPROVE REPORT
                    </Button>
                    <Button variant={EButtonVariant.danger} onClick={handleSetStatusRejectClick} loading={setModerationReportStatusLoading}>
                        REJECT REPORT
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    modalIsOpen: Selectors.uiGetModerationReportDetailsModalIsOpen(state),
    moderationReportDetails: Selectors.moderationReportGetModerationReportDetailsData(state),
    setModerationReportStatusLoading: Selectors.moderationReportSetModerationReportStatusAttempting(state),
    setModerationReportStatusError: Selectors.moderationReportSetModerationReportStatusError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) => dispatch(Actions.uiGetModerationReportDetailsModalIsOpen(state)),
    getModerationReportDetails: (params: GetModerationReportDetailsParams) => dispatch(Actions.moderationReportGetModerationReportDetailsAttempt(params)),
    setModerationReportStatus: (params: SetModerationReportStatusParams) => dispatch(Actions.moderationReportSetModerationReportStatusAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModerationReportDetailsModal);
