import { createSlice } from '@reduxjs/toolkit';

import {
    GetSystemConfigResponsePayload,
    SetSystemConfigParamsPayload,
    StringOrNullPayload,
    SystemConfigurationReduxState,
} from './types';

const initialState: SystemConfigurationReduxState = {
    actions: {
        setSystemConfig: false,
        getSystemConfig: false,
    },
    systemConfig: {
        __v: 0,
        _id: '',
        updatedAt: '',
        createdAt: '',
        postGradingAScore: 0,
        postGradingBScore: 0,
        postGradingCScore: 0,
        postGradingDScore: 0,
        commissionRate: 0,
        postMaxPrice: 0,
        postTextMaxChar: 0,
    },
    error: {
        setSystemConfig: '',
        getSystemConfig: '',
    },
};

const systemConfigurationSlice = createSlice({
    name: 'systemConfiguration',
    initialState,
    reducers: {
        systemConfigurationSetSystemConfigAttempt: (state, _action: SetSystemConfigParamsPayload) => {
            state.actions.setSystemConfig = true;
            state.error.setSystemConfig = '';
        },
        systemConfigurationSetSystemConfigFailure: (state, action: StringOrNullPayload) => {
            state.actions.setSystemConfig = false;
            if (action.payload) {
                state.error.setSystemConfig = action.payload;
            }
        },
        systemConfigurationSetSystemConfigSuccess: (state) => {
            state.actions.setSystemConfig = false;
        },
        systemConfigurationGetSystemConfigAttempt: (state) => {
            state.actions.getSystemConfig = true;
            state.error.getSystemConfig = '';
        },
        systemConfigurationGetSystemConfigFailure: (state, action: StringOrNullPayload) => {
            state.actions.getSystemConfig = false;
            if (action.payload) {
                state.error.getSystemConfig = action.payload;
            }
        },
        systemConfigurationGetSystemConfigSuccess: (state, action: GetSystemConfigResponsePayload) => {
            state.actions.getSystemConfig = false;
            state.systemConfig = action.payload;
        },
    },
});

export type SystemConfigurationState = typeof initialState;

export default {
    actions: systemConfigurationSlice.actions,
    reducers: systemConfigurationSlice.reducer,
};
