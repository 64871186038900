import React from 'react';

import dayjs from 'dayjs';
import { BsArrowRight } from 'react-icons/bs';
import { RotatingLines } from 'react-loader-spinner';

import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';
import UserProfileImage from 'components/molecules/UserProfileImage';

import { GetPaymentReportListingResponse } from 'api/PaymentReportBase';

import NavActions from 'lib/NavActions';
import { renderCurrency, renderPaymentStatus } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { ETextVariant } from 'entities/components';
import { EPaymentReportStatus } from 'entities/report';

type PaymentTableProps = {
    paymentReportData: GetPaymentReportListingResponse[] | [];
    loading?: boolean;
};

const PaymentTable = (props: PaymentTableProps): JSX.Element => {
    const { paymentReportData, loading } = props;
    const PaymentTableHeader = [
        'Date',
        'User',
        'Total Amount (SGD)',
        'Total Paid (SGD)',
        'Total Remaining (SGD)',
        'Status',
        '',
    ];

    const renderTableBody = () => {
        if (!paymentReportData || paymentReportData.length === 0) {
            return (
                <tr>
                    <td colSpan={PaymentTableHeader.length} className='py-xl text-center'>
                        <Text variant={ETextVariant.default}>There is no data! try adjusting the filters.</Text>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return (
                <tr>
                    <td colSpan={PaymentTableHeader.length} className='py-xl text-center'>
                        <div className='flex items-center justify-center gap-default'>
                            <RotatingLines
                                strokeColor='white'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='100'
                                visible
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return paymentReportData.map((payment) => {
            return (
                <tr
                    key={payment._id}
                    className={cn(payment.userDetails._id !== null && 'cursor-pointer hover:bg-glass')}
                    onClick={() => payment.userDetails._id !== null && NavActions.navToModuleDetails('paymentReport', payment._id)}
                >
                    <td className='py-md text-center'>
                        <Text>{dayjs(payment.date).format('DD MMM YYYY')}</Text>
                        <Text>{dayjs(payment.date).format('h:mm a')}</Text>
                    </td>
                    <td className='p-md'>
                        <div className='flex gap-md 3xl:gap-default'>
                            <div className='size-[60px] h-full 2xl:size-[70px] 3xl:size-[80px]'>
                                <UserProfileImage
                                    userTier={payment.userDetails.userTier}
                                    profileImageUrl={payment.userDetails.profileImageUrl}
                                    className='!p-2'
                                />
                            </div>
                            <div className='flex flex-col items-center justify-center 3xl:py-sm'>
                                <div className='flex flex-col gap-1 self-start'>
                                    <Text
                                        variant={ETextVariant.default}
                                        className='font-heading text-base 2xl:text-[25px]'
                                    >
                                        {payment.userDetails.displayName}
                                    </Text>
                                    <Text
                                        variant={ETextVariant.default}
                                        className='font-general-medium text-base 2xl:text-[15px]'
                                    >
                                        {payment.userDetails.username}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{renderCurrency(payment.totalAmount)}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{renderCurrency(payment.totalPaid)}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{renderCurrency(payment.totalRemaining)}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text
                            className={cn(
                                payment.status === EPaymentReportStatus.PENDING && 'text-warning',
                                payment.status === EPaymentReportStatus.COMPLETED && 'text-success',
                            )}
                        >
                            {renderPaymentStatus(payment.status)}
                        </Text>
                    </td>
                    <td className='p-md text-center'>
                        <BsArrowRight size={25} color='white' />
                    </td>
                </tr>
            );
        });
    };

    return (
        <Table
            className='table-auto'
            tableHeader={PaymentTableHeader}
            tableData={renderTableBody()}
            expandedColumns={1}
            expandedWidth='w-1/3'
        />
    );
};

PaymentTable.defaultProps = {
    loading: false,
};

export default PaymentTable;
