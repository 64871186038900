import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import throttle from 'lodash.throttle';
import { connect } from 'react-redux';

import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import CustomDateRangePicker from 'components/molecules/CustomDateRangePicker';
import Paginator from 'components/molecules/Paginator';

import { DateParams } from 'api/PostReportBase';
import {
    GetPurchaseReportParams,
    GetPurchaseReportResponse,
    GetTotalPurchasesGraphResponse,
} from 'api/PurchaseReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { EInputVariant, ETextVariant, TDateFilterString } from 'entities/components';

import PurchaseChartCard from './components/index/PurchaseChartCard';
import PurchaseTable from './components/index/PurchaseTable';

type PurchaseProps = {
    dateFilter: TDateFilterString;

    getTotalPurchasesGraphAttempting: boolean;
    getTotalPurchasesGraphError: string;
    totalPurchasesGraph: GetTotalPurchasesGraphResponse;

    getPurchaseReportAttempting: boolean;
    getPurchaseReportError: string;
    purchaseReport: GetPurchaseReportResponse;

    setDateFilter: (date: TDateFilterString) => void;
    getPurchaseReport: (params: GetPurchaseReportParams) => void;
    getTotalPurchasesGraph: (params: DateParams) => void;
};

const PurchaseReport = (props: PurchaseProps): JSX.Element => {
    const {
        dateFilter,
        getPurchaseReportAttempting,
        getPurchaseReportError,
        purchaseReport,
        getTotalPurchasesGraphAttempting,
        getTotalPurchasesGraphError,
        totalPurchasesGraph,
        getPurchaseReport,
        setDateFilter,
        getTotalPurchasesGraph,
    } = props;

    const [dateRangeFilter, setDateRangeFilter] = useState<[Date | null, Date | null]>([
        dayjs(dateFilter[0]).toDate(),
        dayjs(dateFilter[1]).toDate(),
    ]);
    const [startDate, endDate] = dateRangeFilter;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [page, setPage] = useState<number>(0);

    const throttledSetSearch = useRef(
        throttle(
            (searchWord: string) => {
                setSearchQuery(searchWord);
            },
            500,
            { leading: false },
        ),
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        throttledSetSearch.current(value);
    };

    useEffect(() => {
        getPurchaseReport({
            index: page,
            searchQuery: searchQuery || undefined,
            dateFrom: dayjs(startDate).startOf('day').utc().format(),
            dateTo: dayjs(endDate).endOf('day').utc().format(),
        });
        if (startDate && endDate) {
            getTotalPurchasesGraph({
                dateFrom: dayjs(startDate).startOf('day').utc().format(),
                dateTo: dayjs(endDate).endOf('day').utc().format(),
            });
            setDateFilter([dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]);
        }
    }, [endDate, searchQuery, page]);

    useEffect(() => {
        setPage(0);
    }, [endDate, searchQuery]);

    return (
        <div className='flex flex-col gap-default 2xl:gap-xl'>
            <div>
                <Text variant={ETextVariant.header} className='font-heading-bold text-[50px] 2xl:text-[60px]'>
                    PURCHASE REPORT
                </Text>
                <Text variant={ETextVariant.subheader} className='pt-sm'>
                    The purchase report represents the total sales on the platform, i.e. when a user purchases another
                    user&apos;s post
                </Text>
            </div>
            <div className='flex flex-col gap-md'>
                <div className='flex justify-end'>
                    <CustomDateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(dates) => {
                            setDateRangeFilter(dates);
                        }}
                        className='w-menu'
                    />
                </div>
                <div className='flex gap-md'>
                    <div className='flex grow'>
                        <PurchaseChartCard
                            value={totalPurchasesGraph.totalPurchases.amount}
                            label='Total Purchases Made'
                            data={totalPurchasesGraph.totalPurchases.data.map((purchase) => ({
                                counts: purchase.amount,
                                date: purchase.date,
                            }))}
                        />
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-md'>
                <div className='flex justify-end'>
                    <div className='flex items-center gap-md'>
                        <Input
                            variant={EInputVariant.searchbar}
                            placeholder='Search...'
                            onChange={handleSearch}
                            className='w-full'
                        />
                    </div>
                </div>
                <div>
                    <PurchaseTable
                        purchaseReportData={purchaseReport.data}
                        loading={getPurchaseReportAttempting || endDate === null}
                    />
                    <Paginator
                        currentPage={page}
                        maxPage={(purchaseReport && purchaseReport.maxIndex !== 0 && purchaseReport.maxIndex) || 0}
                        onFirst={() => page !== 0 && setPage(0)}
                        onLast={() => purchaseReport.maxIndex !== 0 && setPage((purchaseReport && purchaseReport.maxIndex) || 0)}
                        onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                        onNext={() => purchaseReport.maxIndex !== 0 && setPage(page !== purchaseReport.maxIndex ? page + 1 : page)}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    dateFilter: Selectors.uiGetPurchaseReportDateFilter(state),

    getTotalPurchasesGraphAttempting: Selectors.purchaseReportGetTotalPurchasesGraphAttempting(state),
    getTotalPurchasesGraphError: Selectors.purchaseReportGetTotalPurchasesGraphError(state),
    totalPurchasesGraph: Selectors.purchaseReportGetTotalPurchasesGraphData(state),

    getPurchaseReportAttempting: Selectors.purchaseReportGetPurchaseReportAttempting(state),
    getPurchaseReportError: Selectors.purchaseReportGetPurchaseReportError(state),
    purchaseReport: Selectors.purchaseReportGetPurchaseReportData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getTotalPurchasesGraph: (params: DateParams) =>
        dispatch(Actions.purchaseReportGetTotalPurchasesGraphAttempt(params)),
    getPurchaseReport: (params: GetPurchaseReportParams) =>
        dispatch(Actions.purchaseReportGetPurchaseReportAttempt(params)),
    setDateFilter: (date: TDateFilterString) => dispatch(Actions.uiSetPurchaseReportDateFilter(date)),
    logout: () => dispatch(Actions.authLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseReport);
