/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import Text from 'components/atoms/Text';

import cn from 'lib/TailwindMerge';
import { ETextVariant } from 'entities/components';

type CustomDatePickerProps = {
    selectedDate: Date | null;
    onChange: (date: Date) => void;
    className?: string,
};

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const CustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    const {
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    } = props;

    return (
        <div className='flex flex-col items-center justify-center gap-sm px-sm pb-sm'>
            <div className='flex w-full items-center justify-between gap-md'>
                <button
                    type='button'
                    className='focus:outline-none'
                    onClick={() => changeYear(date.getFullYear() - 1)}
                >
                    <FaChevronLeft size={15} />
                </button>
                <div>
                    <Text variant={ETextVariant.subheader}>{date.getFullYear()}</Text>
                </div>
                <button
                    type='button'
                    className='focus:outline-none'
                    onClick={() => changeYear(date.getFullYear() + 1)}
                >
                    <FaChevronRight size={15} />
                </button>
            </div>
            <div className='flex w-full items-center justify-between gap-md'>
                <button
                    type='button'
                    className='focus:outline-none'
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                >
                    <FaChevronLeft size={15} />
                </button>
                <div>
                    <Text variant={ETextVariant.subheader}>{months[date.getMonth()]}</Text>
                </div>
                <button
                    type='button'
                    className='focus:outline-none'
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                >
                    <FaChevronRight size={15} />
                </button>
            </div>
        </div>
    );
};

const CustomDatePicker = (props: CustomDatePickerProps): JSX.Element => {
    const { selectedDate, onChange, className } = props;

    return (
        <div className={cn('w-full', className)}>
            <DatePicker
                selected={selectedDate}
                onChange={onChange}
                dateFormat='dd MMM YY'
                wrapperClassName='w-full'
                className={cn(
                    'w-full',
                    'bg-transparent',
                    'rounded-input',
                    'border border-white',
                    'text-color-primary',
                    'p-[5px] pl-3',
                    'text-start font-general text-xs 2xl:text-base',
                    'focus:outline-none',
                    'cursor-pointer',
                    'opacity-100',
                )}
                calendarClassName={cn(
                    'bg-glass',
                    'rounded-input',
                    'border border-white',
                    'shadow-glass backdrop-blur-glass',
                    'text-color-primary',
                    'p-2.5',
                    'text-center font-general',
                    'focus:outline-none',
                )}
                renderCustomHeader={CustomHeader}
            />
        </div>
    );
};

CustomDatePicker.defaultProps = {
    className: '',
};

export default CustomDatePicker;
