import React, { useState } from 'react';

import dayjs from 'dayjs';
import { BsThreeDots } from 'react-icons/bs';
import { LuDot } from 'react-icons/lu';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';

import Badge from 'components/atoms/Badge';
import Container from 'components/atoms/Container';
import Dropdown from 'components/atoms/Dropdown';
import Text from 'components/atoms/Text';
import Paginator from 'components/molecules/Paginator';

import { GenericIndexedResponse, GetUserCommentsResponse, GetUserSummaryResponse } from 'api/UserReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { renderPostRating } from 'lib/RenderFunction';
import {
    EBadgeVariant,
    EContainerVariant,
    ETextVariant,
} from 'entities/components';
import { ECommentStatus } from 'entities/report';

import DeleteCommentModal from '../components/userDetails/DeleteCommentModal';

type UserCommentsTabProps = {
    deleteUserCommentAttempt: boolean;
    deleteUserCommentError: string;

    setDeleteCommentModalIsOpen: (state: boolean) => void;

    user: GetUserSummaryResponse;
    userComments: GenericIndexedResponse<GetUserCommentsResponse>;
    page: number;
    setPage: (page: number) => void;
    dateFrom: string;
    dateTo: string;
    loading?: boolean;
    error?: string;
};

const UserCommentsTab = (props: UserCommentsTabProps): JSX.Element => {
    const {
        deleteUserCommentAttempt,
        deleteUserCommentError,
        setDeleteCommentModalIsOpen,
        user,
        userComments,
        page,
        setPage,
        dateFrom,
        dateTo,
        loading,
        error,
    } = props;

    const [commentId, setCommentId] = useState<string>('');

    const deleteCommentHandler = (id: string) => {
        setCommentId(id);
        setDeleteCommentModalIsOpen(true);
    };

    const renderCommentStatus = (status: ECommentStatus) => {
        switch (status) {
            case ECommentStatus.ADMIN_REMOVED:
                return (
                    <Badge
                        text='Deleted By Admin'
                        variant={EBadgeVariant.danger}
                    />
                );
            case ECommentStatus.USER_REMOVED:
                return (
                    <Badge
                        text='Deleted By User'
                        variant={EBadgeVariant.danger}
                    />
                );
            case ECommentStatus.ACTIVE:
                return null;
            default:
                return null;
        }
    };
    const renderComments = () => {
        if (!userComments.data || userComments.data.length === 0) {
            return (
                <Text>
                    There is no comments made by this user yet!
                </Text>
            );
        }

        if (loading || deleteUserCommentAttempt) {
            return (
                <div className='flex items-center justify-center'>
                    <RotatingLines
                        strokeColor='white'
                        strokeWidth='5'
                        animationDuration='0.75'
                        width='100'
                        visible
                    />
                </div>
            );
        }

        if (error || deleteUserCommentError) {
            return (
                <div className='flex items-center justify-center'>
                    <Text variant={ETextVariant.error}>
                        {error || deleteUserCommentError}
                    </Text>
                </div>
            );
        }

        return userComments.data.map((comment) => {
            return (
                <Container
                    key={comment._id}
                    variant={EContainerVariant.gradientBorder}
                    className='flex flex-col gap-md p-default'
                >
                    <div className='flex flex-col gap-sm 2xl:gap-md'>
                        <div className='flex justify-between'>
                            <Badge text={comment.postDetails.topicName} variant={EBadgeVariant.topic} />
                            <Dropdown
                                label={<BsThreeDots size={20} color='white' />}
                                data={[{ text: 'Delete', onClick: () => deleteCommentHandler(comment._id) }]}
                            />
                        </div>
                        <div className='flex flex-col gap-sm'>
                            <div className='flex items-center gap-md'>
                                <Text
                                    variant={ETextVariant.default}
                                    className='max-w-[50%] truncate font-general-bold text-lg capitalize 2xl:text-2xl'
                                >
                                    {comment.postDetails.title ?? 'no title'}
                                </Text>
                                <Badge
                                    text={renderPostRating(comment.postDetails.postRating, true)}
                                    variant={EBadgeVariant.grade}
                                />
                            </div>
                            <Text
                                variant={ETextVariant.subheader}
                                className='flex items-center text-sm 2xl:text-lg'
                            >
                                {user.username}
                                <LuDot size={20} />
                                {dayjs(comment.createdAt).format('DD MMM YYYY')}
                                {comment.commentStatus !== ECommentStatus.ACTIVE && (
                                    <>
                                        <LuDot size={20} />
                                        {renderCommentStatus(comment.commentStatus)}
                                    </>
                                )}
                            </Text>
                        </div>
                    </div>
                    <Text variant={ETextVariant.default}>
                        {comment.content}
                    </Text>
                </Container>
            );
        });
    };

    return (
        <div className='flex flex-col gap-md'>
            {renderComments()}
            <Paginator
                currentPage={page}
                maxPage={(userComments && userComments.maxIndex !== 0 && userComments.maxIndex) || 0}
                onFirst={() => page !== 0 && setPage(0)}
                onLast={() => userComments.maxIndex !== 0 && setPage((userComments && userComments.maxIndex) || 0)}
                onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                onNext={() => userComments.maxIndex !== 0 && setPage(page !== userComments.maxIndex ? page + 1 : page)}
            />
            <DeleteCommentModal
                commentId={commentId}
                userId={user._id}
                postId=''
                index={page}
                dateFrom={dateFrom}
                dateTo={dateTo}
            />
        </div>
    );
};

UserCommentsTab.defaultProps = {
    loading: false,
    error: '',
};
const mapStateToProps = (state: RootState) => ({
    deleteUserCommentAttempt: Selectors.userReportDeleteUserCommentAttempting(state),
    deleteUserCommentError: Selectors.userReportDeleteUserCommentError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDeleteCommentModalIsOpen: (state: boolean) => dispatch(Actions.uiSetDeleteCommentModalIsOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCommentsTab);
