import Gateway from 'api/types/Gateway';

import ESubAdminPermission, { TSubAdminDetails } from 'entities/subadmin';

import { GatewayResponse } from './types/types';

export type GenericParams<T> = {
    authToken: string;
    params: T;
};

// ! Params
export type GetSubAdminListParams = {
    searchQuery?: string;
};

export type SetSubAdminAccessParams = {
    email: string;
    permissions: ESubAdminPermission[];
};

export type EditSubAdminAccessParams = {
    userId: string;
    permissions: ESubAdminPermission[];
    fullRevoke: boolean;
};

// ! Response
export type GenericResponse = string | undefined;

export type GetSubAdminListResponse = TSubAdminDetails[];

export abstract class ISubAdminGateway extends Gateway {
    abstract getSubAdminList(params: GenericParams<GetSubAdminListParams>): GatewayResponse<GetSubAdminListResponse>;

    abstract setSubAdminAccess(params: GenericParams<SetSubAdminAccessParams>): GatewayResponse<GenericResponse>;

    abstract editSubAdminAccess(params: GenericParams<EditSubAdminAccessParams>): GatewayResponse<GenericResponse>;
}
