import { ApiResponse } from 'apisauce';

import Utils from 'lib/Utils';

import { GatewayResponse } from './types/types';
import { GetDashboardDetailsParams, GetDashboardDetailsResponse, IDashboardGateway } from './DashboardBase';

export default class DashboardGateway extends IDashboardGateway {
    async getDashboardDetails(params: GetDashboardDetailsParams): GatewayResponse<GetDashboardDetailsResponse> {
        const response: ApiResponse<GetDashboardDetailsResponse> = await this.api.get(
            '/v1/a/dashboard',
            {},
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }
}
