import { createSlice } from '@reduxjs/toolkit';

import {
    GetPurchaseReportParamsPayload,
    GetPurchaseReportResponsePayload,
    GetTotalPurchasesGraphParamsPayload,
    GetTotalPurchasesGraphResponsePayload,
    PurchaseReportReduxState,
    StringOrNullPayload,
} from './types';

const initialState: PurchaseReportReduxState = {
    actions: {
        getTotalPurchasesGraph: false,
        getPurchaseReport: false,
    },
    purchaseReport: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    totalPurchasesGraph: {
        totalPurchases: {
            amount: 0,
            data: [],
        },
    },
    error: {
        getTotalPurchasesGraph: '',
        getPurchaseReport: '',
    },
};

const purchaseReportSlice = createSlice({
    name: 'purchaseReport',
    initialState,
    reducers: {
        purchaseReportGetTotalPurchasesGraphAttempt: (state, _action: GetTotalPurchasesGraphParamsPayload) => {
            state.actions.getTotalPurchasesGraph = true;
            state.error.getTotalPurchasesGraph = '';
        },
        purchaseReportGetTotalPurchasesGraphSuccess: (state, action: GetTotalPurchasesGraphResponsePayload) => {
            state.actions.getTotalPurchasesGraph = false;
            if (action.payload) {
                state.totalPurchasesGraph = action.payload;
            }
        },
        purchaseReportGetTotalPurchasesGraphFailure: (state, action: StringOrNullPayload) => {
            state.actions.getTotalPurchasesGraph = false;
            if (action.payload) {
                state.error.getTotalPurchasesGraph = action.payload;
            }
        },
        purchaseReportGetPurchaseReportAttempt: (state, _action: GetPurchaseReportParamsPayload) => {
            state.actions.getPurchaseReport = true;
            state.error.getPurchaseReport = '';
        },
        purchaseReportGetPurchaseReportSuccess: (state, action: GetPurchaseReportResponsePayload) => {
            state.actions.getPurchaseReport = false;
            if (action.payload) {
                state.purchaseReport = action.payload;
            }
        },
        purchaseReportGetPurchaseReportFailure: (state, action: StringOrNullPayload) => {
            state.actions.getPurchaseReport = false;
            if (action.payload) {
                state.error.getPurchaseReport = action.payload;
            }
        },
    },
});

export type PurchaseReportState = typeof initialState;

export default {
    actions: purchaseReportSlice.actions,
    reducers: purchaseReportSlice.reducer,
};
