import { fork } from 'redux-saga/effects';

import UserReportGateway from 'api/UserReport';
import { RootSagaReturnType } from 'sagas/types';

import watchDeleteUserComment from './deleteUserComment';
import watchGetRepLeaderboard from './getRepLeaderboard';
import watchGetTotalUsers from './getTotalUsers';
import watchGetUserComments from './getUserComments';
import watchGetUserGrades from './getUserGrades';
import watchGetUserPosts from './getUserPosts';
import watchGetUserReport from './getUserReport';
import watchGetUserSummary from './getUserSummary';
import watchSetUserCommission from './setUserCommission';
import watchSetUserToBan from './setUserToBan';
import watchSetUserToUnban from './setUserToUnban';

export default (api: UserReportGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetUserReport, api);
        yield fork(watchGetTotalUsers, api);
        yield fork(watchGetUserSummary, api);
        yield fork(watchGetUserPosts, api);
        yield fork(watchGetUserComments, api);
        yield fork(watchGetUserGrades, api);
        yield fork(watchGetRepLeaderboard, api);
        yield fork(watchSetUserCommission, api);
        yield fork(watchSetUserToBan, api);
        yield fork(watchSetUserToUnban, api);
        yield fork(watchDeleteUserComment, api);
    }

    return {
        rootSaga,
    };
};
