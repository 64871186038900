import { createSlice } from '@reduxjs/toolkit';

import { EModerationTargetType, EUserTier } from 'entities/report';

import {
    GetModerationReportDetailsParamsPayload,
    GetModerationReportDetailsResponsePayload,
    GetModerationReportParamsPayload,
    GetModerationReportResponsePayload,
    ModerationReportReduxState,
    SetModerationReportStatusParamsPayload,
    StringOrNullPayload,
} from './types';

const initialState: ModerationReportReduxState = {
    actions: {
        getModerationReport: false,
        getModerationReportDetails: false,
        setModerationReportStatus: false,
    },
    moderationReport: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    moderationReportDetails: {
        _id: '',
        content: '',
        title: '',
        targetUserDetails: {
            _id: '',
            username: '',
            displayName: '',
            userTier: EUserTier.BEGINNER,
            profileImageUrl: '',
        },
        reporterId: '',
        reporterUsername: '',
        targetType: EModerationTargetType.USER,
    },
    error: {
        getModerationReport: '',
        getModerationReportDetails: '',
        setModerationReportStatus: '',
    },
};

const moderationReportSlice = createSlice({
    name: 'moderationReport',
    initialState,
    reducers: {
        moderationReportGetModerationReportAttempt: (
            state,
            _action: GetModerationReportParamsPayload,
        ) => {
            state.actions.getModerationReport = true;
            state.error.getModerationReport = '';
        },
        moderationReportGetModerationReportSuccess: (
            state,
            action: GetModerationReportResponsePayload,
        ) => {
            state.actions.getModerationReport = false;
            if (action.payload) {
                state.moderationReport = action.payload;
            }
        },
        moderationReportGetModerationReportFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.getModerationReport = false;
            if (action.payload) {
                state.error.getModerationReport = action.payload;
            }
        },
        moderationReportGetModerationReportDetailsAttempt: (
            state,
            _action: GetModerationReportDetailsParamsPayload,
        ) => {
            state.actions.getModerationReportDetails = true;
            state.error.getModerationReportDetails = '';
        },
        moderationReportGetModerationReportDetailsSuccess: (
            state,
            action: GetModerationReportDetailsResponsePayload,
        ) => {
            state.actions.getModerationReportDetails = false;
            if (action.payload) {
                state.moderationReportDetails = action.payload;
            }
        },
        moderationReportGetModerationReportDetailsFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.getModerationReportDetails = false;
            if (action.payload) {
                state.error.getModerationReportDetails = action.payload;
            }
        },
        moderationReportSetModerationReportStatusAttempt: (
            state,
            _action: SetModerationReportStatusParamsPayload,
        ) => {
            state.actions.setModerationReportStatus = true;
            state.error.setModerationReportStatus = '';
        },
        moderationReportSetModerationReportStatusSuccess: (
            state,
        ) => {
            state.actions.setModerationReportStatus = false;
        },
        moderationReportSetModerationReportStatusFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.setModerationReportStatus = false;
            if (action.payload) {
                state.error.setModerationReportStatus = action.payload;
            }
        },
    },
});

export type ModerationReportState = typeof initialState;

export default {
    actions: moderationReportSlice.actions,
    reducers: moderationReportSlice.reducer,
};
