import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';

import { GetPostDetailsResponse } from 'api/PostReportBase';

import NavActions from 'lib/NavActions';
import { renderPostImage, renderPostMedia, renderPostRating, renderPostStatus, renderPostType } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { EContainerVariant, ETextVariant } from 'entities/components';

type PostDetailsCardProps = {
    postDetails: GetPostDetailsResponse;
    onClick?: () => void;
    loading?: boolean;
    error?: string;
};

const PostDetailsCard = (props: PostDetailsCardProps): JSX.Element => {
    const { postDetails, onClick, loading, error } = props;

    const PostDetailsLabel = [
        {
            title: "Post Author's",
            detail: postDetails.details.username,
        },
        {
            title: 'Created Date',
            detail: dayjs(postDetails.details.createdAt).format('DD MMM YYYY h:mm a'),
        },
        {
            title: 'Topic',
            detail: postDetails.details.topicName,
        },
        {
            title: 'Expiry Date',
            detail: dayjs(postDetails.details.verificationDateEnd).format('DD MMM YYYY h:mm a'),
        },
        {
            title: 'Status',
            detail: renderPostStatus(postDetails.details.postStage),
        },
        {
            title: 'Reports',
            detail: postDetails.details.timesReported,
        },
        {
            title: 'Post Access',
            detail: renderPostType(postDetails.details.postType),
        },
        {
            title: 'Points Gained',
            detail: postDetails.details.pointsGained,
        },
        {
            title: 'Grade',
            detail: renderPostRating(postDetails.details.postRating),
        },
    ];

    const renderPostDetails = () => {
        return PostDetailsLabel.map((label) => {
            return (
                <div key={label.title}>
                    <Text
                        variant={ETextVariant.default}
                        className='font-general-bold'
                    >
                        {label.title}
                    </Text>
                    <Text>
                        {label.detail}
                    </Text>
                </div>
            );
        });
    };

    const renderPostMediaSummary = () => {
        if (postDetails.details.postAttachmentUrl) {
            return (
                <div className={cn(
                    'flex justify-center items-center',
                    'w-fit max-h-[200px]',
                    'bg-white',
                    'object-contain aspect-video',
                )}
                >
                    {renderPostMedia(postDetails.details.postAttachmentUrl)}
                </div>
            );
        }

        return null;
    };
    const renderContent = () => {
        if (loading) {
            return (
                <RotatingLines
                    strokeColor='white'
                    strokeWidth='5'
                    animationDuration='0.75'
                    width='100'
                    visible
                />
            );
        }

        if (error) {
            <Text variant={ETextVariant.error}>
                {error}
            </Text>;
        }

        return (
            <>
                <button
                    onClick={onClick}
                    type='button'
                    className='flex w-3/5 items-center gap-md 2xl:min-h-[200px]'
                >
                    {renderPostMediaSummary()}
                    <div className={cn(
                        'flex 2xl:flex-1 flex-col gap-md',
                        'mr-default',
                        'gap-md',
                        'overflow-hidden',
                    )}
                    >
                        <Text
                            variant={ETextVariant.default}
                            className='text-start font-general-bold 2xl:text-3xl'
                        >
                            {postDetails.details.title}
                        </Text>
                        <div className='inline-flex text-start'>
                            <Text className='line-clamp-5'>
                                {postDetails.details.content}
                            </Text>
                        </div>
                    </div>
                </button>
                <div className='w-2/5'>
                    <div className='flex max-h-[150px] w-full flex-col flex-wrap gap-md 2xl:max-h-[250px]'>
                        {renderPostDetails()}
                    </div>
                </div>
            </>
        );
    };

    return (
        <Container
            variant={EContainerVariant.glassBorder}
            className={cn(
                'w-full',
                'flex items-center justify-center',
                'rounded-default',
                'p-md 2xl:p-default',
            )}
        >
            {renderContent()}
        </Container>
    );
};

PostDetailsCard.defaultProps = {
    onClick: () => null,
    loading: false,
    error: '',
};

export default PostDetailsCard;
