import React, { useState } from 'react';

import { RotatingLines } from 'react-loader-spinner';
import Masonry from 'react-responsive-masonry';

import Text from 'components/atoms/Text';
import Paginator from 'components/molecules/Paginator';

import { GenericIndexedResponse, GetUserPostsResponse } from 'api/UserReportBase';

import NavActions from 'lib/NavActions';
import cn from 'lib/TailwindMerge';
import { ETextVariant } from 'entities/components';

import UserPostCard from '../components/userDetails/UserPostCard';

type UserPostsTabProps = {
    userPosts: GenericIndexedResponse<GetUserPostsResponse>;
    page: number;
    setPage: (page: number) => void;
    loading?: boolean;
    error?: string;
};

const UserPostsTab = (props: UserPostsTabProps): JSX.Element => {
    const {
        userPosts,
        page,
        setPage,
        loading,
        error,
    } = props;

    const renderPosts = () => {
        if (!userPosts.data || userPosts.data.length === 0) {
            return (
                <Text>
                    There is no posts made by this user yet!
                </Text>
            );
        }

        return userPosts.data.map((post) => (
            <div key={post._id}>
                <UserPostCard
                    onClick={() => NavActions.navToModuleDetails('postReport', post._id)}
                    post={post}
                />
            </div>
        ));
    };

    const renderPostAction = () => {
        if (error) {
            return (
                <Text variant={ETextVariant.error}>
                    {error}
                </Text>
            );
        }

        return (
            <RotatingLines
                strokeColor='white'
                strokeWidth='5'
                animationDuration='0.75'
                width='100'
                visible
            />
        );
    };

    if (loading || error) {
        return (
            <div className='flex w-full items-center justify-center'>
                {renderPostAction()}
            </div>
        );
    }

    return (
        <div className='flex flex-wrap gap-md'>
            <Masonry columnsCount={4} gutter='1.5rem'>
                {renderPosts()}
            </Masonry>
            <Paginator
                currentPage={page}
                maxPage={(userPosts && userPosts.maxIndex !== 0 && userPosts.maxIndex) || 0}
                onFirst={() => page !== 0 && setPage(0)}
                onLast={() => userPosts.maxIndex !== 0 && setPage((userPosts && userPosts.maxIndex) || 0)}
                onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                onNext={() => userPosts.maxIndex !== 0 && setPage(page !== userPosts.maxIndex ? page + 1 : page)}
            />
        </div>
    );
};

UserPostsTab.defaultProps = {
    loading: false,
    error: '',
};

export default UserPostsTab;
