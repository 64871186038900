import React from 'react';

import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';

import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';

import { GetUserReportResponse } from 'api/UserReportBase';

import NavActions from 'lib/NavActions';
import {
    renderImage,
    renderUserStatus,
    renderUserTier,
} from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { ETextVariant } from 'entities/components';
import { EUserStatus } from 'entities/report';

type UserReportTableProps = {
    userReportData: GetUserReportResponse[] | [];
    loading?: boolean;
};

const UserReportTable = (props: UserReportTableProps): JSX.Element => {
    const {
        userReportData,
        loading,
    } = props;
    const userReportTableHeader = ['User Name', 'Tier', 'Rep Ranking', 'Date joined', 'Status'];

    const renderTableBody = () => {
        if (!userReportData || userReportData.length === 0) {
            return (
                <tr>
                    <td
                        colSpan={userReportTableHeader.length}
                        className='py-xl text-center'
                    >
                        <Text variant={ETextVariant.default}>
                            There is no data! try adjusting the filters.
                        </Text>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return (
                <tr>
                    <td
                        colSpan={userReportTableHeader.length}
                        className='py-xl text-center'
                    >
                        <div className='flex items-center justify-center gap-default'>
                            <RotatingLines
                                strokeColor='white'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='100'
                                visible
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return userReportData?.map((user) => {
            return (
                <tr
                    key={user.displayName}
                    className='cursor-pointer hover:bg-glass'
                    onClick={() => NavActions.navToModuleDetails('userReport', user._id)}
                >
                    <td className={cn(
                        'flex items-center',
                        'gap-md 2xl:gap-default',
                        'py-md 2xl:py-default',
                        'pl-md 2xl:pl-default',
                    )}
                    >
                        <div className='size-[50px] 2xl:size-[60px]'>
                            {renderImage(user.profileImageUrl ?? '')}
                        </div>
                        <div className='flex flex-col'>
                            <Text
                                variant={ETextVariant.default}
                                className='mb-sm text-base font-bold 3xl:text-xl'
                            >
                                {user.username}
                            </Text>
                            <Text variant={ETextVariant.subheader}>
                                {user.displayName}
                            </Text>
                        </div>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{renderUserTier(user.userTier)}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{user.repRanking}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{dayjs(user.dateJoined).format('DD MMM YYYY')}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text
                            className={cn(
                                (user.userStatus && user.userStatus === EUserStatus.ActiveUser) && 'text-success',
                                (user.userStatus && user.userStatus === EUserStatus.SuspendedUser) && 'text-error',
                            )}
                        >
                            {renderUserStatus(user.userStatus)}
                        </Text>
                    </td>
                </tr>
            );
        });
    };

    return (
        <Table
            className='table-auto'
            tableHeader={userReportTableHeader}
            tableData={renderTableBody()}
        />
    );
};

UserReportTable.defaultProps = {
    loading: false,
};

export default UserReportTable;
