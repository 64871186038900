export enum EAuthRole {
    User = 1,
    Admin = 2,
    Subadmin = 3,
}
export enum EPermission {
    userReport = 21,
    postReport = 22,
    topicReport = 23,
    purchaseReport = 24,
    paymentReport = 25,
    systemSetting = 31,
    subadminSetting = 32,
    moderationReport = 33,
}
export interface IAuthUser {
    userId: string;
    email: string;
    role: EAuthRole;
    permissions: EPermission[];
}
