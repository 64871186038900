import {
    GenericIndexedResponse,
    GetRepLeaderboardResponse,
    GetTotalUsersResponse,
    GetUserCommentsResponse,
    GetUserGradesResponse,
    GetUserPostsResponse,
    GetUserReportResponse,
    GetUserSummaryResponse,
} from 'api/UserReportBase';

import { UserReportState } from '.';

const getUserReportAttempting = (state: UserReportState): boolean => state.actions.getUserReport || false;
const getUserReportError = (state: UserReportState): string => state.error.getUserReport || '';
const getUserReportData = (state: UserReportState): GenericIndexedResponse<GetUserReportResponse> => state.userReport;

const getTotalUsersAttempting = (state: UserReportState): boolean => state.actions.getTotalUsers || false;
const getTotalUsersError = (state: UserReportState): string => state.error.getTotalUsers || '';
const getTotalUsersData = (state: UserReportState): GetTotalUsersResponse => state.totalUsers;

const getUserSummaryAttempting = (state: UserReportState): boolean => state.actions.getUserSummary || false;
const getUserSummaryError = (state: UserReportState): string => state.error.getUserSummary || '';
const getUserSummaryData = (state: UserReportState): GetUserSummaryResponse => state.userSummary;

const getUserPostsAttempting = (state: UserReportState): boolean => state.actions.getUserPosts || false;
const getUserPostsError = (state: UserReportState): string => state.error.getUserPosts || '';
const getUserPostsData = (state: UserReportState): GenericIndexedResponse<GetUserPostsResponse> => state.userPosts;

const getUserCommentsAttempting = (state: UserReportState): boolean => state.actions.getUserComments || false;
const getUserCommentsError = (state: UserReportState): string => state.error.getUserComments || '';
const getUserCommentsData = (state: UserReportState): GenericIndexedResponse<GetUserCommentsResponse> => state.userComments;

const getUserGradesAttempting = (state: UserReportState): boolean => state.actions.getUserGrades || false;
const getUserGradesError = (state: UserReportState): string => state.error.getUserGrades || '';
const getUserGradesData = (state: UserReportState): GenericIndexedResponse<GetUserGradesResponse> => state.userGrades;

const getRepLeaderboardAttempting = (state: UserReportState): boolean => state.actions.getRepLeaderboard || false;
const getRepLeaderboardError = (state: UserReportState): string => state.error.getRepLeaderboard || '';
const getRepLeaderboardData = (state: UserReportState): GenericIndexedResponse<GetRepLeaderboardResponse> => state.repLeaderboard;

const setUserCommissionAttempting = (state: UserReportState): boolean => state.actions.setUserCommisionRate || false;
const setUserCommissionError = (state: UserReportState): string => state.error.setUserCommisionRate || '';

const setUserToBanAttempting = (state: UserReportState): boolean => state.actions.setUserToBan || false;
const setUserToBanError = (state: UserReportState): string => state.error.setUserToBan || '';

const setUserToUnbanAttempting = (state: UserReportState): boolean => state.actions.setUserToUnban || false;
const setUserToUnbanError = (state: UserReportState): string => state.error.setUserToUnban || '';

const deleteUserCommentsAttempting = (state: UserReportState): boolean => state.actions.deleteUserComment || false;
const deleteUserCommentsError = (state: UserReportState): string => state.error.deleteUserComment || '';

export default {
    getUserReportAttempting,
    getUserReportError,
    getUserReportData,

    getTotalUsersAttempting,
    getTotalUsersError,
    getTotalUsersData,

    getUserSummaryAttempting,
    getUserSummaryError,
    getUserSummaryData,

    getUserPostsAttempting,
    getUserPostsError,
    getUserPostsData,

    getUserCommentsAttempting,
    getUserCommentsError,
    getUserCommentsData,

    getUserGradesAttempting,
    getUserGradesError,
    getUserGradesData,

    getRepLeaderboardAttempting,
    getRepLeaderboardData,
    getRepLeaderboardError,

    setUserCommissionAttempting,
    setUserCommissionError,

    setUserToBanAttempting,
    setUserToBanError,

    setUserToUnbanAttempting,
    setUserToUnbanError,

    deleteUserCommentsAttempting,
    deleteUserCommentsError,
};
