import Gateway from 'api/types/Gateway';

import {
    EModerationCategory,
    EModerationStatus,
    IUserDetails,
    TPostComment,
    TPostDetails,
    TTotalPostSummary,
} from 'entities/report';

import { GatewayResponse } from './types/types';

export type GenericParams<T> = {
    authToken: string;
    params: T;
};
export type GenericIndexedResponse<T> = {
    index: number;
    maxIndex: number;
    data: T[];
};

export type GenericResponse = string | undefined;

// ! Params
export type DateParams = {
    dateFrom: string;
    dateTo: string;
};

export type GetPostReportParams = {
    index: number;
    searchQuery: string;
    dateFrom: string;
    dateTo: string;
    topicId?: string;
};

export type GetPostCommentHistoryParams = {
    id: string;
    index: number;
    dateFrom: string;
    dateTo: string;
};

export type GetPostGradeHistoryParams = {
    id: string;
    index: number;
    dateFrom: string;
    dateTo: string;
};

export type GetPostModerationParams = {
    postId: string;
    index: number;
};

export type GetPostModerationDetailsParams = {
    reporterUserId: string;
    reportId: string;
};

export type SetPostModerationReviewParams = {
    postId: string;
    reportId: string;
    moderationStatus: number;
};

export type GetPostPurchaseHistoryParams = {
    postId: string;
    index: number;
    dateTo: string;
    dateFrom: string;
};

export type SetFeaturedPostParams = {
    postId: string;
    isFeatured: boolean;
};

export type DeleteUserPostParams = {
    id: string;
};

// ! Response
export type GetPostReportResponse = {
    index: number;
    maxIndex: number;
    data: TPostDetails[];
    totalPosts: TTotalPostSummary;
    totalFeaturedPosts: number;
    totalReportedPosts: number;
};

export type GetAllTopicsResponse = {
    _id: string;
    name: string;
    enabled: boolean;
};

export type GetPostDetailsResponse = {
    details: {
        _id: string;
        userId: string;
        username: string;
        title: string;
        content: string;
        topicId: string;
        topicName: string;
        verificationDateEnd: string;
        postType: number;
        postPrice: number;
        postStage: number;
        isFeatured: boolean;
        createdAt: string;
        updatedAt: string;
        postAttachmentUrl: string;
        timesReported: number;
        pointsGained: number;
        postRating?: number;
    };
    comments: {
        total: number;
        pastWeekDiff: number;
        graph: {
            count: number;
            week: number;
        }[];
    };
    moderation: {
        total: number;
        pastWeekDiff: number;
        graph: {
            count: number;
            week: number;
        }[];
    };
    rating: {
        total: number;
        pastWeekDiff: number;
        graph: {
            count: number;
            week: number;
        }[];
    };
    purchases: {
        total: number;
        pastWeekDiff: number;
        graph: {
            count: number;
            week: number;
        }[];
    };
};

export type GetPostCommentHistoryResponse = TPostComment;

export type GetPostGradeHistoryResponse = {
    _id: string;
    createdAt: string;
    rating: number;
    userDetails: IUserDetails;
};

export type GetPostModerationResponse = {
    status: EModerationStatus;
    _id: string;
    reporter: {
        userId: string;
        username: string;
        displayName: string;
        profileImageUrl: string;
        userTier: number;
        repRanking: number;
    };
    reportCategory: EModerationCategory;
    reportDate: string;
};

export type GetPostModerationDetailsResponse = {
    _id: string;
    reporter: {
        userId: string;
        username: string;
        displayName: string;
        profileImageUrl: string;
        userTier: number;
        repRanking: number;
    };
    reportCategory: number;
    reportDate: string;
};

export type SetPostModerationReviewResponse = GenericResponse;

export type GetPostPurchaseHistoryResponse = {
    _id: string;
    createdAt: string;
    userDetails: IUserDetails;
};

export type SetFeaturedPostResponse = GenericResponse;

export type DeleteUserPostResponse = GenericResponse;

export abstract class IPostReportGateway extends Gateway {
    abstract getPostReport(
        params: GenericParams<GetPostReportParams>,
    ): GatewayResponse<GetPostReportResponse>;

    abstract getAllTopics(
        params: GenericParams<string>,
    ): GatewayResponse<GetAllTopicsResponse[]>;

    abstract getPostDetails(
        params: GenericParams<string>,
    ): GatewayResponse<GetPostDetailsResponse>;

    abstract getPostCommentHistory(
        params: GenericParams<GetPostCommentHistoryParams>,
    ): GatewayResponse<GenericIndexedResponse<GetPostCommentHistoryResponse>>;

    abstract getPostGradeHistory(
        params: GenericParams<GetPostGradeHistoryParams>,
    ): GatewayResponse<GenericIndexedResponse<GetPostGradeHistoryResponse>>;

    abstract getPostModeration(
        params: GenericParams<GetPostModerationParams>,
    ): GatewayResponse<GenericIndexedResponse<GetPostModerationResponse>>;

    abstract getPostModerationDetails(
        params: GenericParams<GetPostModerationDetailsParams>,
    ): GatewayResponse<GetPostModerationDetailsResponse>;

    abstract setPostModerationReview(
        params: GenericParams<SetPostModerationReviewParams>,
    ): GatewayResponse<SetPostModerationReviewResponse>;

    abstract getPostPurchaseHistory(
        params: GenericParams<GetPostPurchaseHistoryParams>,
    ): GatewayResponse<GenericIndexedResponse<GetPostPurchaseHistoryResponse>>;

    abstract setFeaturedPost(
        params: GenericParams<SetFeaturedPostParams>,
    ): GatewayResponse<SetFeaturedPostResponse>;

    abstract deleteUserPost(
        params: GenericParams<DeleteUserPostParams>,
    ): GatewayResponse<DeleteUserPostResponse>;
}
