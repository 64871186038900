import React from 'react';

import cn from 'lib/TailwindMerge';

type LayoutProps = {
    children: React.ReactNode;
    className?: string;
}

const ContentLayout = (props: LayoutProps): JSX.Element => {
    const { children, className } = props;
    return (
        <div className={cn(
            'flex flex-col',
            'h-full w-full',
            'overflow-auto',
            'py-default',
            'px-default 3xl:px-[100px]',
            'font-general text-color-primary',
            className,
        )}
        >
            {children}
        </div>
    );
};

ContentLayout.defaultProps = {
    className: '',
};

export default ContentLayout;
