import { fork } from 'redux-saga/effects';

import TopicReportGateway from 'api/TopicReport';
import { RootSagaReturnType } from 'sagas/types';

import watchAddTopic from './addTopic';
import watchDeleteTopic from './deleteTopic';
import watchEditTopic from './editTopic';
import watchGetTopics from './getTopics';

export default (api: TopicReportGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetTopics, api);
        yield fork(watchAddTopic, api);
        yield fork(watchEditTopic, api);
        yield fork(watchDeleteTopic, api);
    }

    return {
        rootSaga,
    };
};
