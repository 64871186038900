import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ContentLayout from 'components/layout/ContentLayout';
import CustomDateRangePicker from 'components/molecules/CustomDateRangePicker';
import TabMenu from 'components/molecules/TabMenu';

import {
    GenericIndexedResponse,
    GetPostCommentHistoryParams,
    GetPostCommentHistoryResponse,
    GetPostDetailsResponse,
    GetPostGradeHistoryParams,
    GetPostGradeHistoryResponse,
    GetPostModerationParams,
    GetPostModerationResponse,
    GetPostPurchaseHistoryParams,
    GetPostPurchaseHistoryResponse,
} from 'api/PostReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { tabPostDetails } from 'lib/Constant';
import { TDateFilterString } from 'entities/components';
import { EPostDetailsTab } from 'entities/ui';

import PostContentModal from './components/postDetails/PostContentModal';
import PostDetailsCard from './components/postDetails/PostDetailsCard';
import PostStatusCard from './components/postDetails/PostStatusCard';
import PostCommentsTab from './tabs/PostCommentsTab';
import PostGradesTab from './tabs/PostGradesTab';
import PostPurchaseTab from './tabs/PostPurchaseTab';
import PostSettingsTab from './tabs/PostSettingsTab';
import PostViewReportTab from './tabs/PostViewReportTab';

type PostDetailsProps = {
    getPostDetailsAttempt: boolean;
    getPostDetailsError: string;
    postDetails: GetPostDetailsResponse;

    getPostCommentHistoryAttempt: boolean;
    getPostCommentHistoryError: string;
    postCommentHistory: GenericIndexedResponse<GetPostCommentHistoryResponse>;

    getPostGradeHistoryAttempt: boolean;
    getPostGradeHistoryError: string;
    postGradeHistory: GenericIndexedResponse<GetPostGradeHistoryResponse>;

    getPostModerationAttempt: boolean;
    getPostModerationError: string;
    postModeration: GenericIndexedResponse<GetPostModerationResponse>;

    getPostPurchaseHistoryAttempt: boolean;
    getPostPurchaseHistoryError: string;
    postPurchaseHistory: GenericIndexedResponse<GetPostPurchaseHistoryResponse>;

    currentTab: EPostDetailsTab;
    dateFilter: TDateFilterString;

    getPostDetails: (params: string) => void;
    getPostCommentHistory: (params: GetPostCommentHistoryParams) => void;
    getPostGradeHistory: (params: GetPostGradeHistoryParams) => void;
    getPostModeration: (params: GetPostModerationParams) => void;
    getPostPurchaseHistory: (params: GetPostPurchaseHistoryParams) => void;
    setCurrentTab: (tab: EPostDetailsTab) => void;
    resetError: () => void;
    setDateFilter: (date: TDateFilterString) => void;
};

const PostDetails = (props: PostDetailsProps): JSX.Element => {
    const {
        getPostDetailsAttempt,
        getPostDetailsError,
        postDetails,

        getPostCommentHistoryAttempt,
        getPostCommentHistoryError,
        postCommentHistory,

        getPostGradeHistoryAttempt,
        getPostGradeHistoryError,
        postGradeHistory,

        getPostModerationAttempt,
        getPostModerationError,
        postModeration,

        getPostPurchaseHistoryAttempt,
        getPostPurchaseHistoryError,
        postPurchaseHistory,

        currentTab,
        dateFilter,

        getPostDetails,
        getPostCommentHistory,
        getPostGradeHistory,
        getPostModeration,
        getPostPurchaseHistory,
        setCurrentTab,
        resetError,
        setDateFilter,
    } = props;

    const { pathname } = useLocation();
    const postId = pathname.split('/').pop();

    const [tempModalisOpen, setTempModalIsOpen] = useState(false);

    const [dateRangeFilter, setDateRangeFilter] = useState<
        [Date | null, Date | null]
    >([dayjs(dateFilter[0]).toDate(), dayjs(dateFilter[1]).toDate()]);
    const [startDate, endDate] = dateRangeFilter;
    const [commentPage, setCommentPage] = useState(0);
    const [gradePage, setGradePage] = useState(0);
    const [moderationPage, setModerationPage] = useState(0);
    const [purchasePage, setPurchasePage] = useState(0);

    useEffect(() => {
        if (postId) {
            getPostDetails(postId);
        }
    }, [postId]);

    useEffect(() => {
        resetError();

        if (postId && startDate && endDate) {
            switch (currentTab) {
                case EPostDetailsTab.commentsHistories:
                    getPostCommentHistory({
                        id: postId,
                        index: commentPage,
                        dateFrom: dayjs(startDate)
                            .startOf('day')
                            .utc()
                            .format(),
                        dateTo: dayjs(endDate).endOf('day').utc().format(),
                    });
                    setDateFilter([
                        dayjs(startDate).format('YYYY-MM-DD'),
                        dayjs(endDate).format('YYYY-MM-DD'),
                    ]);

                    break;
                case EPostDetailsTab.gradeHistories:
                    getPostGradeHistory({
                        id: postId,
                        index: gradePage,
                        dateFrom: dayjs(startDate)
                            .startOf('day')
                            .utc()
                            .format(),
                        dateTo: dayjs(endDate).endOf('day').utc().format(),
                    });
                    setDateFilter([
                        dayjs(startDate).format('YYYY-MM-DD'),
                        dayjs(endDate).format('YYYY-MM-DD'),
                    ]);

                    break;
                case EPostDetailsTab.viewReport:
                    getPostModeration({
                        postId,
                        index: moderationPage,
                    });

                    break;
                case EPostDetailsTab.purchaseHistories:
                    getPostPurchaseHistory({
                        postId,
                        index: purchasePage,
                        dateFrom: dayjs(startDate)
                            .startOf('day')
                            .utc()
                            .format(),
                        dateTo: dayjs(endDate).endOf('day').utc().format(),
                    });
                    setDateFilter([
                        dayjs(startDate).format('YYYY-MM-DD'),
                        dayjs(endDate).format('YYYY-MM-DD'),
                    ]);

                    break;
                case EPostDetailsTab.settings:
                    break;
                default:
                    break;
            }
        }
    }, [currentTab, endDate]);

    const postDetailsTab = () => {
        return tabPostDetails.map((tab) => {
            return {
                text: tab.text,
                type: tab.type,
                onClick: () => {
                    setCurrentTab(tab.type);
                },
            };
        });
    };

    const renderTabContent = () => {
        switch (currentTab) {
            case EPostDetailsTab.commentsHistories:
                return (
                    <PostCommentsTab
                        postCommentHistory={postCommentHistory}
                        loading={getPostCommentHistoryAttempt || endDate === null}
                        error={getPostCommentHistoryError}
                        page={commentPage}
                        setPage={setCommentPage}
                        dateFrom={dayjs(startDate).startOf('day').utc().format()}
                        dateTo={dayjs(endDate).endOf('day').utc().format()}
                    />
                );
            case EPostDetailsTab.gradeHistories:
                return (
                    <PostGradesTab
                        postGradeHistory={postGradeHistory}
                        loading={getPostGradeHistoryAttempt || endDate === null}
                        error={getPostGradeHistoryError}
                        page={gradePage}
                        setPage={setGradePage}
                    />
                );
            case EPostDetailsTab.viewReport:
                return (
                    <PostViewReportTab
                        postModeration={postModeration}
                        loading={getPostModerationAttempt}
                        error={getPostModerationError}
                        page={moderationPage}
                        setPage={setModerationPage}
                    />
                );
            case EPostDetailsTab.purchaseHistories:
                return (
                    <PostPurchaseTab
                        postPurchaseHistory={postPurchaseHistory}
                        loading={getPostPurchaseHistoryAttempt}
                        error={getPostPurchaseHistoryError}
                        page={purchasePage}
                        setPage={setPurchasePage}
                    />
                );
            case EPostDetailsTab.settings:
                return <PostSettingsTab />;
            default:
                return null;
        }
    };

    return (
        <ContentLayout className='gap-xl'>
            <PostDetailsCard
                postDetails={postDetails}
                loading={getPostDetailsAttempt}
                error={getPostDetailsError}
                onClick={() => setTempModalIsOpen(true)}
            />
            <div className='flex gap-md'>
                <PostStatusCard
                    label='Total Comments'
                    value={postDetails.comments.total}
                    diff={postDetails.comments.pastWeekDiff}
                    data={postDetails.comments.graph}
                />
                <PostStatusCard
                    label='Total Purchases'
                    value={postDetails.purchases.total}
                    diff={postDetails.purchases.pastWeekDiff}
                    data={postDetails.purchases.graph}
                />
                <PostStatusCard
                    label='of the Mood Graph'
                    value={postDetails.moderation.total}
                    diff={postDetails.moderation.pastWeekDiff}
                    data={postDetails.moderation.graph}
                />
            </div>
            <div className='flex justify-between'>
                <TabMenu
                    menuData={postDetailsTab()}
                    selected={currentTab}
                    textClassName='2xl:text-xl'
                />
                <CustomDateRangePicker
                    disabled={currentTab === EPostDetailsTab.settings || currentTab === EPostDetailsTab.viewReport}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(dates) => {
                        setDateRangeFilter(dates);
                    }}
                    className='w-menu'
                />
            </div>
            <div>{renderTabContent()}</div>
            <PostContentModal
                postDetails={postDetails}
                modalIsOpen={tempModalisOpen}
                setModalIsOpen={setTempModalIsOpen}
            />
        </ContentLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    getPostDetailsAttempt: Selectors.postReportGetPostDetailsAttempting(state),
    getPostDetailsError: Selectors.postReportGetPostDetailsError(state),
    postDetails: Selectors.postReportGetPostDetailsData(state),

    getPostCommentHistoryAttempt:
        Selectors.postReportGetPostCommentHistoryAttempting(state),
    getPostCommentHistoryError:
        Selectors.postReportGetPostCommentHistoryError(state),
    postCommentHistory: Selectors.postReportGetPostCommentHistoryData(state),

    getPostGradeHistoryAttempt:
        Selectors.postReportGetPostGradeHistoryAttempting(state),
    getPostGradeHistoryError:
        Selectors.postReportGetPostGradeHistoryError(state),
    postGradeHistory: Selectors.postReportGetPostGradeHistoryData(state),

    getPostModerationAttempt:
        Selectors.postReportGetPostModerationAttempting(state),
    getPostModerationError: Selectors.postReportGetPostModerationError(state),
    postModeration: Selectors.postReportGetPostModerationData(state),

    getPostPurchaseHistoryAttempt:
        Selectors.postReportGetPostPurchaseHistoryAttempting(state),
    getPostPurchaseHistoryError:
        Selectors.postReportGetPostPurchaseHistoryError(state),
    postPurchaseHistory: Selectors.postReportGetPostPurchaseHistoryData(state),

    currentTab: Selectors.uiGetCurrentPostDetailsTab(state),
    dateFilter: Selectors.uiGetPostDetailsDateFilter(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPostDetails: (params: string) =>
        dispatch(Actions.postReportGetPostDetailsAttempt(params)),
    getPostCommentHistory: (params: GetPostCommentHistoryParams) =>
        dispatch(Actions.postReportGetPostCommentHistoryAttempt(params)),
    getPostGradeHistory: (params: GetPostGradeHistoryParams) =>
        dispatch(Actions.postReportGetPostGradeHistoryAttempt(params)),
    getPostModeration: (params: GetPostModerationParams) =>
        dispatch(Actions.postReportGetPostModerationAttempt(params)),
    getPostPurchaseHistory: (params: GetPostPurchaseHistoryParams) =>
        dispatch(Actions.postReportGetPostPurchaseHistoryAttempt(params)),
    setCurrentTab: (tab: EPostDetailsTab) =>
        dispatch(Actions.uiSetCurrentPostDetailsTab(tab)),
    setDateFilter: (date: TDateFilterString) =>
        dispatch(Actions.uiSetPostDetailsDateFilter(date)),

    resetError: () => dispatch(Actions.postReportResetErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostDetails);
