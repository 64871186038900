import { createSlice } from '@reduxjs/toolkit';

import { EmptyIndexedResponse } from 'entities/report';

import {
    AddTopicParamsPayload,
    DeleteTopicParamsPayload,
    EditTopicParamsPayload,
    GetTopicsParamsPayload,
    GetTopicsResponsePayload,
    StringOrNullPayload,
    TopicReportReduxState,
} from './types';

const initialState: TopicReportReduxState = {
    actions: {
        getTopics: false,
        addTopic: false,
        editTopic: false,
        deleteTopic: false,
    },
    topics: EmptyIndexedResponse,
    error: {
        getTopics: '',
        addTopic: '',
        editTopic: '',
        deleteTopic: '',
    },
};

const topicReportSlice = createSlice({
    name: 'topicReport',
    initialState,
    reducers: {
        topicReportGetTopicsAttempt: (state, _action: GetTopicsParamsPayload) => {
            state.actions.getTopics = true;
            state.error.getTopics = '';
        },
        topicReportGetTopicsSuccess: (state, action: GetTopicsResponsePayload) => {
            state.actions.getTopics = false;
            if (action.payload) {
                state.topics = action.payload;
            }
        },
        topicReportGetTopicsFailure: (state, action: StringOrNullPayload) => {
            state.actions.getTopics = false;
            if (action.payload) {
                state.error.getTopics = action.payload;
            }
        },
        topicReportAddTopicAttempt: (state, _action: AddTopicParamsPayload) => {
            state.actions.addTopic = true;
            state.error.addTopic = '';
        },
        topicReportAddTopicSuccess: (state) => {
            state.actions.addTopic = false;
        },
        topicReportAddTopicFailure: (state, action: StringOrNullPayload) => {
            state.actions.addTopic = false;
            if (action.payload) {
                state.error.addTopic = action.payload;
            }
        },
        topicReportEditTopicAttempt: (state, _action: EditTopicParamsPayload) => {
            state.actions.editTopic = true;
            state.error.editTopic = '';
        },
        topicReportEditTopicSuccess: (state) => {
            state.actions.editTopic = false;
        },
        topicReportEditTopicFailure: (state, action: StringOrNullPayload) => {
            state.actions.editTopic = false;
            if (action.payload) {
                state.error.editTopic = action.payload;
            }
        },
        topicReportDeleteTopicAttempt: (state, _action: DeleteTopicParamsPayload) => {
            state.actions.deleteTopic = true;
            state.error.deleteTopic = '';
        },
        topicReportDeleteTopicSuccess: (state) => {
            state.actions.deleteTopic = false;
        },
        topicReportDeleteTopicFailure: (state, action: StringOrNullPayload) => {
            state.actions.deleteTopic = false;
            if (action.payload) {
                state.error.deleteTopic = action.payload;
            }
        },
    },
});

export type TopicReportState = typeof initialState;

export default {
    actions: topicReportSlice.actions,
    reducers: topicReportSlice.reducer,
};
