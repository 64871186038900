import { fork } from 'redux-saga/effects';

import PaymentReportGateway from 'api/PaymentReport';
import { RootSagaReturnType } from 'sagas/types';

import watchCreatePayment from './createPayment';
import watchGetPaymentReportListing from './getPaymenReportListing';
import watchGetPaymentReportDetails from './getPaymentReportDetails';
import watchGetPaymentSelector from './getPaymentSelector';
import watchGetTotalPaymentReport from './getTotalPaymentReport';

export default (api: PaymentReportGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetTotalPaymentReport, api);
        yield fork(watchGetPaymentReportListing, api);
        yield fork(watchGetPaymentSelector, api);
        yield fork(watchGetPaymentReportDetails, api);
        yield fork(watchCreatePayment, api);
    }

    return {
        rootSaga,
    };
};
