import React, { useState } from 'react';

import dayjs from 'dayjs';
import { BsThreeDots } from 'react-icons/bs';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';

import Dropdown from 'components/atoms/Dropdown';
import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';

import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import { renderImage } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { ETextVariant } from 'entities/components';
import { TSubAdminDetails } from 'entities/subadmin';

import EditSubadminModal from './EditSubadminModal';
import RevokeSubadminModal from './RevokeSubadminModal';
import ViewSubadminModal from './ViewSubadminModal';

type SubadminReportTableProps = {
    setViewSubadminModalIsOpen: (state: boolean) => void;
    setEditSubadminModalIsOpen: (state: boolean) => void;
    setRevokeSubadminModalIsOpen: (state: boolean) => void;
    subAdminList: TSubAdminDetails[];
    loading?: boolean;
};

const SubadminReportTable = (props: SubadminReportTableProps): JSX.Element => {
    const {
        setViewSubadminModalIsOpen,
        setEditSubadminModalIsOpen,
        setRevokeSubadminModalIsOpen,
        subAdminList,
        loading,
    } = props;

    const subadminReportTableHeader = ['Name', 'Joining Date', ''];

    const [subAdminId, setSubAdminId] = useState<string>('');

    const viewSubAdminClickHandler = (id: string) => {
        setSubAdminId(id);
        setViewSubadminModalIsOpen(true);
    };

    const editSubAdminClickHandler = (id: string) => {
        setSubAdminId(id);
        setEditSubadminModalIsOpen(true);
    };

    const revokeSubAdminClickHandler = (id: string) => {
        setSubAdminId(id);
        setRevokeSubadminModalIsOpen(true);
    };

    const renderTableBody = () => {
        if (!subAdminList || subAdminList.length === 0) {
            return (
                <tr>
                    <td colSpan={subadminReportTableHeader.length} className='py-xl text-center'>
                        <Text variant={ETextVariant.default}>There no sub admins right now. Please add some.</Text>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return (
                <tr>
                    <td colSpan={subadminReportTableHeader.length} className='py-xl text-center'>
                        <div className='flex items-center justify-center gap-default'>
                            <RotatingLines
                                strokeColor='white'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='100'
                                visible
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return subAdminList?.map((subAdmin) => {
            return (
                <tr key={Math.random()}>
                    <td
                        className={cn(
                            'flex items-center',
                            'gap-md 2xl:gap-default',
                            'py-md 2xl:py-default',
                            'pl-md 2xl:pl-default',
                        )}
                    >
                        <div className='size-[50px] 2xl:size-[60px]'>{renderImage(subAdmin.profileImageUrl)}</div>
                        <Text variant={ETextVariant.default} className='mb-sm text-base font-bold 2xl:text-xl'>
                            {subAdmin.email}
                        </Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{dayjs(subAdmin.createdAt).format('DD MMM YYYY')}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Dropdown
                            label={<BsThreeDots size={20} color='white' />}
                            data={[
                                {
                                    text: 'View',
                                    onClick: () => {
                                        viewSubAdminClickHandler(subAdmin._id);
                                    },
                                },
                                {
                                    text: 'Edit',
                                    onClick: () => {
                                        editSubAdminClickHandler(subAdmin._id);
                                    },
                                },
                                {
                                    text: 'Revoke',
                                    onClick: () => {
                                        revokeSubAdminClickHandler(subAdmin._id);
                                    },
                                },
                            ]}
                        />
                    </td>
                </tr>
            );
        });
    };

    return (
        <div>
            <Table
                className='table-auto'
                tableHeader={subadminReportTableHeader}
                tableData={renderTableBody()}
                expandedColumns={0}
                expandedWidth='w-3/4'
            />
            <ViewSubadminModal subAdminId={subAdminId} />
            <EditSubadminModal subAdminId={subAdminId} />
            <RevokeSubadminModal subAdminId={subAdminId} />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    // modalIsOpen: Selectors.uiBanUserModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setViewSubadminModalIsOpen: (state: boolean) => dispatch(Actions.uiSetViewSubadminModalIsOpen(state)),
    setEditSubadminModalIsOpen: (state: boolean) => dispatch(Actions.uiSetEditSubadminModalIsOpen(state)),
    setRevokeSubadminModalIsOpen: (state: boolean) => dispatch(Actions.uiSetRevokeSubadminModalIsOpen(state)),
});

SubadminReportTable.defaultProps = {
    loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubadminReportTable);
