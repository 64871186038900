import { fork } from 'redux-saga/effects';

import DashboardGateway from 'api/Dashboard';
import { RootSagaReturnType } from 'sagas/types';

import watchGetDashboardDetails from './getDashboardDetails';

export default (api: DashboardGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetDashboardDetails, api);
    }

    return {
        rootSaga,
    };
};
