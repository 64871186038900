import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import ModerationReportGateway from 'api/ModerationReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { SetModerationReportStatusParamsPayload } from 'redux/slices/moderationReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

import { EModerationStatus } from 'entities/report';

export default function* watchSetModerationReportStatus(api: ModerationReportGateway): SagaWatcherReturnType {
    yield takeEvery('moderationReport/moderationReportSetModerationReportStatusAttempt', handleSetModerationReportStatus, api);
}

function* handleSetModerationReportStatus(api: ModerationReportGateway, data: SetModerationReportStatusParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.setModerationReportStatus], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.moderationReportSetModerationReportStatusFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.moderationReportSetModerationReportStatusSuccess());
        yield put(Actions.moderationReportGetModerationReportAttempt({
            index: 0,
            dateFrom: dayjs().subtract(30, 'day').startOf('day').utc()
                .format(),
            dateTo: dayjs().endOf('day').utc().format(),
        }));
        yield put(Actions.uiGetModerationReportDetailsModalIsOpen(false));
    }
}
