import { IAuthUser } from 'entities/auth';

import { AuthState } from '.';

const getLoginAttempting = (state: AuthState): boolean => state.actions.login || false;
const getLoginError = (state: AuthState): string => state.error.login || '';

const getStartupAttempting = (state: AuthState): boolean => state.actions.startup || false;
const getStartupError = (state: AuthState): string => state.error.startup || '';

const getForgotPasswordAttempting = (state: AuthState): boolean => state.actions.forgotPassword || false;
const getForgotPasswordError = (state: AuthState): string => state.error.forgotPassword || '';

const getVerifyOtpAttempting = (state: AuthState): boolean => state.actions.verifyOtp || false;
const getVerifyOtpError = (state: AuthState): string => state.error.verifyOtp || '';

const getResetPasswordAttempting = (state: AuthState): boolean => state.actions.resetPassword || false;
const getResetPasswordError = (state: AuthState): string => state.error.resetPassword || '';

const getAuthToken = (state: AuthState): string => state.authToken || '';

const getUserInfo = (state: AuthState): IAuthUser | null => state.userInfo || null;

export default {
    getLoginAttempting,
    getLoginError,
    getStartupAttempting,
    getStartupError,
    getForgotPasswordAttempting,
    getForgotPasswordError,
    getVerifyOtpAttempting,
    getVerifyOtpError,
    getResetPasswordAttempting,
    getResetPasswordError,
    getAuthToken,
    getUserInfo,
};
