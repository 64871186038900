import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { TDateFilterString } from 'entities/components';
import {
    EMenuList,
    EPostDetailsTab,
    ETotalUserType,
    EUserDetailsTab,
} from 'entities/ui';

import { UiReduxState } from './types';

const defaultDateFilter: TDateFilterString = [
    dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
];

const initialState: UiReduxState = {
    currentPage: EMenuList.home,
    currentTotalUserType: ETotalUserType.newUser,
    currentUserDetailsTab: EUserDetailsTab.allPosts,
    currentPostDetailsTab: EPostDetailsTab.commentsHistories,
    dateFilter: {
        userReport: defaultDateFilter,
        userDetails: defaultDateFilter,
        postReport: defaultDateFilter,
        postDetails: defaultDateFilter,
        purchaseReport: defaultDateFilter,
        paymentReport: defaultDateFilter,
        paymentDetails: defaultDateFilter,
        moderationReport: defaultDateFilter,
    },
    userReportModalIsOpen: {
        banUser: false,
        unbanUser: false,
        deleteComment: false,
    },
    topicReportModalIsOpen: {
        addTopic: false,
        editTopic: false,
        deleteTopic: false,
    },
    postReportModalIsOpen: {
        postModerationReview: false,
        deletePost: false,
    },
    subadminAccessModalIsOpen: {
        addSubadmin: false,
        viewSubadmin: false,
        editSubadmin: false,
        revokeSubadmin: false,
    },
    moderationReportDetailsModalIsOpen: false,
};

const uiSlice = createSlice({
    name: 'Ui',
    initialState,
    reducers: {
        // ! pages
        uiSetCurrentPage: (state, action: PayloadAction<EMenuList>) => {
            state.currentPage = action.payload;
        },
        uiSetCurrentTotalUserType: (
            state,
            action: PayloadAction<ETotalUserType>,
        ) => {
            state.currentTotalUserType = action.payload;
        },
        uiSetCurrentUserDetailsTab: (
            state,
            action: PayloadAction<EUserDetailsTab>,
        ) => {
            state.currentUserDetailsTab = action.payload;
        },
        uiSetCurrentPostDetailsTab: (
            state,
            action: PayloadAction<EPostDetailsTab>,
        ) => {
            state.currentPostDetailsTab = action.payload;
        },
        // ! date
        uiSetUserReportDateFilter: (
            state,
            action: PayloadAction<TDateFilterString>,
        ) => {
            state.dateFilter.userReport = action.payload;
        },
        uiSetUserDetailsDateFilter: (
            state,
            action: PayloadAction<TDateFilterString>,
        ) => {
            state.dateFilter.userDetails = action.payload;
        },
        uiSetPostReportDateFilter: (
            state,
            action: PayloadAction<TDateFilterString>,
        ) => {
            state.dateFilter.postReport = action.payload;
        },
        uiSetPostDetailsDateFilter: (
            state,
            action: PayloadAction<TDateFilterString>,
        ) => {
            state.dateFilter.postDetails = action.payload;
        },
        uiSetPurchaseReportDateFilter: (state, action: PayloadAction<TDateFilterString>) => {
            state.dateFilter.purchaseReport = action.payload;
        },
        uiSetPaymentReportDateFilter: (state, action: PayloadAction<TDateFilterString>) => {
            state.dateFilter.paymentReport = action.payload;
        },
        uiSetPaymentDetailsDateFilter: (state, action: PayloadAction<TDateFilterString>) => {
            state.dateFilter.paymentDetails = action.payload;
        },
        uiSetModerationReportDateFilter: (
            state,
            action: PayloadAction<TDateFilterString>,
        ) => {
            state.dateFilter.moderationReport = action.payload;
        },
        // ! modal
        uiSetBanUserModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.userReportModalIsOpen.banUser = action.payload;
        },
        uiSetUnbanUserModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.userReportModalIsOpen.unbanUser = action.payload;
        },
        uiSetDeleteCommentModalIsOpen: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.userReportModalIsOpen.deleteComment = action.payload;
        },
        uiSetAddTopicModalIsOpen: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.topicReportModalIsOpen.addTopic = action.payload;
        },
        uiSetEditTopicModalIsOpen: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.topicReportModalIsOpen.editTopic = action.payload;
        },
        uiSetDeleteTopicModalIsOpen: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.topicReportModalIsOpen.deleteTopic = action.payload;
        },
        uiSetPostModerationReviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.postReportModalIsOpen.postModerationReview = action.payload;
        },
        uiSetDeleteUserPostModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.postReportModalIsOpen.deletePost = action.payload;
        },
        uiSetAddSubadminModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.subadminAccessModalIsOpen.addSubadmin = action.payload;
        },
        uiSetViewSubadminModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.subadminAccessModalIsOpen.viewSubadmin = action.payload;
        },
        uiSetEditSubadminModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.subadminAccessModalIsOpen.editSubadmin = action.payload;
        },
        uiSetRevokeSubadminModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.subadminAccessModalIsOpen.revokeSubadmin = action.payload;
        },
        uiGetModerationReportDetailsModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.moderationReportDetailsModalIsOpen = action.payload;
        },
    },
});

export type UiState = typeof initialState;

export default {
    actions: uiSlice.actions,
    reducers: uiSlice.reducer,
};
