import React, { useState } from 'react';

import { AiFillExclamationCircle } from 'react-icons/ai';
import { BsEyeFill } from 'react-icons/bs';
import { FaChevronLeft } from 'react-icons/fa';
import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Toggle from 'components/atoms/Toggle';
import Modal from 'components/molecules/Modal';

import { SetSubAdminAccessParams } from 'api/SubAdminBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { renderImage, renderSubAdminPermission } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { EButtonVariant, EInputVariant, ETextVariant } from 'entities/components';
import ESubAdminPermission from 'entities/subadmin';

type SetSubAdminModalProps = {
    setSubAdminAccessAttempting: boolean;
    setSubAdminAccessError: string;

    modalIsOpen: boolean;
    setModalIsOpen: (state: boolean) => void;

    setSubAdminAccess: (params: SetSubAdminAccessParams) => void;
};

const SetSubAdminModal = (props: SetSubAdminModalProps): JSX.Element => {
    const { setSubAdminAccessAttempting, setSubAdminAccessError, modalIsOpen, setModalIsOpen, setSubAdminAccess } = props;
    const [step, setStep] = useState<number>(0);

    const [emailInput, setEmailInput] = useState<string>('');
    const [tempPerms, setTempPerms] = useState<ESubAdminPermission[]>([]);

    const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailInput(e.target.value);
    };

    const toggleHandler = (permissionToToggle: ESubAdminPermission) => {
        const isPermOn = tempPerms.includes(permissionToToggle);

        if (isPermOn) {
            setTempPerms(tempPerms.filter((perm) => perm !== permissionToToggle));
        } else {
            setTempPerms([...tempPerms, permissionToToggle]);
        }
    };

    const renderSubAdminPermissionList = () => {
        const permissionList = [
            ESubAdminPermission.USER_REPORT,
            ESubAdminPermission.POST_REPORT,
            ESubAdminPermission.TOPIC_REPORT,
            ESubAdminPermission.PURCHASE_REPORT,
            ESubAdminPermission.PAYMENT_REPORT,
            ESubAdminPermission.SYSTEM_SETTING,
        ];

        return permissionList.map((permission) => {
            return (
                <div className='flex w-full flex-row justify-between pt-md'>
                    <div className='flex flex-col gap-sm'>
                        <Text
                            variant={ETextVariant.default}
                            className='font-general-bold text-base !leading-none 2xl:text-lg'
                        >
                            {renderSubAdminPermission(permission)}
                        </Text>
                        <Text variant={ETextVariant.default} className='text-xs !leading-none 2xl:text-sm'>
                            Populate access per report later
                        </Text>
                    </div>
                    <div className='flex items-center gap-sm'>
                        <Toggle
                            isActive={
                                tempPerms.includes(permission)
                                // tempPerms.find(
                                //     (givenPerm) => givenPerm === permission,
                                // ) !== undefined
                            }
                            onClick={() => {
                                toggleHandler(permission);
                            }}
                        />
                    </div>
                </div>
            );
        });
    };

    switch (step) {
        case 0:
            return (
                <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
                    <div className={cn('w-[650px] max-h-[90vh] flex flex-col', 'items-center justify-center', 'gap-default p-xl', 'overflow-y-auto break-words')}>
                        <Text
                            variant={ETextVariant.header}
                            className='text-center font-heading-bold text-button-primary 2xl:text-[60px]'
                        >
                            ADD NEW SUBADMIN
                        </Text>
                        <Text
                            variant={ETextVariant.default}
                            className='self-start font-heading-bold text-white 2xl:text-[30px]'
                        >
                            Fineline access means:
                        </Text>
                        <div className='flex gap-md'>
                            <div>
                                <BsEyeFill color='white' size={24} />
                            </div>
                            <Text variant={ETextVariant.default} className='text-start xl:text-[12px]'>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                                invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
                                accusam et justo duo dolores et ea rebum.
                            </Text>
                        </div>
                        <div className='flex gap-md'>
                            <div>
                                <AiFillExclamationCircle color='white' size={24} />
                            </div>
                            <Text variant={ETextVariant.default} className='text-start xl:text-[12px]'>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                                invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
                                accusam et justo duo dolores et ea rebum.
                            </Text>
                        </div>
                        <div className='self-end'>
                            <Button
                                variant={EButtonVariant.default}
                                className='mt-default px-lg'
                                onClick={() => {
                                    setStep(1);
                                }}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </Modal>
            );

        case 1:
            return (
                <Modal
                    isOpen={modalIsOpen}
                    onClose={() => {
                        setModalIsOpen(false);
                        setStep(0);
                        setEmailInput('');
                        setTempPerms([]);
                    }}
                >
                    <Button
                        variant={EButtonVariant.ghost}
                        className='absolute left-4 top-4 flex'
                        onClick={() => setStep(1)}
                    >
                        <div className='self-center px-1 py-2'>
                            <FaChevronLeft color='white' size={20} />
                        </div>
                        Back
                    </Button>
                    <div className={cn('w-[650px] max-h-[90vh] flex flex-col', 'items-start justify-start', 'gap-sm p-xl')}>
                        <Text
                            variant={ETextVariant.default}
                            className='self-center pb-0 font-heading-bold text-[20px] text-white 2xl:text-[30px]'
                        >
                            ADD NEW SUBADMIN
                        </Text>
                        <div className='flex w-full flex-col gap-sm pt-2'>
                            <Text
                                variant={ETextVariant.default}
                                className='self-start font-general-bold text-[20px] text-white 2xl:text-[30px]'
                            >
                                Add Subadmin Email
                            </Text>
                            <input
                                className={cn(
                                    'w-full',
                                    'rounded-full outline-none',
                                    'bg-transparent',
                                    'border-[1px] border-white',
                                    'resize-none py-2.5 px-3',
                                )}
                                value={emailInput}
                                onChange={(e) => handleEmailInputChange(e)}
                                placeholder='enter email address'
                            />
                        </div>
                        <Text
                            variant={ETextVariant.default}
                            className='self-start pt-3 font-general-bold text-[20px] text-white 2xl:text-[22px]'
                        >
                            Subadmin can manage the following
                        </Text>
                        <div className='w-full overflow-y-auto'>
                            {renderSubAdminPermissionList()}
                        </div>
                        <div className='self-end'>
                            <Button
                                variant={EButtonVariant.default}
                                className='mt-default px-lg'
                                loading={setSubAdminAccessAttempting}
                                onClick={() => {
                                    setSubAdminAccess({
                                        email: emailInput,
                                        permissions: tempPerms,
                                    });
                                }}
                            >
                                Give Access
                            </Button>
                        </div>
                    </div>
                </Modal>
            );

        default:
            return <div>Something went wrong, didnt detech modal steps</div>;
    }
};

const mapStateToProps = (state: RootState) => ({
    setSubAdminAccessAttempting: Selectors.subAdminSetSubAdminAccessAttempting(state),
    setSubAdminAccessError: Selectors.subAdminSetSubAdminAccessError(state),
    modalIsOpen: Selectors.uiAddSubadminModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) => dispatch(Actions.uiSetAddSubadminModalIsOpen(state)),
    setSubAdminAccess: (params: SetSubAdminAccessParams) => dispatch(Actions.subAdminSetSubAdminAccessAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetSubAdminModal);
