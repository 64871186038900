import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';

import { DateParams } from './PostReportBase';
import {
    GenericParams,
    GetPurchaseReportParams,
    GetPurchaseReportResponse,
    GetTotalPurchasesGraphResponse,
    IPurchaseReportGateway,
} from './PurchaseReportBase';

export default class PurchaseReportGateway extends IPurchaseReportGateway {
    async getTotalPurchasesGraph(
        params: GenericParams<DateParams>,
    ): GatewayResponse<GetTotalPurchasesGraphResponse> {
        const response: ApiResponse<GetTotalPurchasesGraphResponse> = await this.api.get(
            '/v1/a/purchaseReport/totalPurchasesGraph',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getPurchaseReport(
        params: GenericParams<GetPurchaseReportParams>,
    ): GatewayResponse<GetPurchaseReportResponse> {
        const response: ApiResponse<GetPurchaseReportResponse> = await this.api.get(
            '/v1/a/purchaseReport/purchases',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }
}
