import { fork } from 'redux-saga/effects';

import AuthGateway from 'api/Auth';
import { RootSagaReturnType } from 'sagas/types';

import watchForgotPassword from './forgotPassword';
import watchLogin from './login';
import watchLogout from './logout';
import watchResetPassword from './resetPassword';
import watchStartup from './startup';
import watchVerifyOtp from './verifyOtp';

export default (api: AuthGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchLogin, api);
        yield fork(watchStartup, api);
        yield fork(watchForgotPassword, api);
        yield fork(watchVerifyOtp, api);
        yield fork(watchResetPassword, api);
        yield fork(watchLogout);
    }

    return {
        rootSaga,
    };
};
