import React, { FunctionComponent, useEffect } from 'react';

import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import AuthLayout from 'components/layout/AuthLayout';

import { LoginParams } from 'api/AuthBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import cn from 'lib/TailwindMerge';
import {
    EButtonVariant,
    EContainerVariant,
    EInputVariant,
    ETextVariant,
} from 'entities/components';

import icons from 'assets/icons';

interface LoginProps {
    loading: boolean;
    error: string;

    login(params: LoginParams): void;
    resetErrors: () => void;
}

const Login: FunctionComponent<LoginProps> = (props: LoginProps) => {
    const {
        login,
        loading,
        error,
        resetErrors,
    } = props;

    useEffect(() => {
        return () => {
            resetErrors();
        };
    }, []);

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const email = formData.get('email') as string;
        const password = formData.get('password') as string;
        const rememberMe = formData.get('remember-me') as string;

        if (email && password) {
            login({
                email,
                password,
                rememberMe: rememberMe === 'on',
            });
        } else {
            toast.error('Please fill in all fields');
        }
    };

    return (
        <AuthLayout>
            <div className='size-fit'>
                <SVG src={icons.FinelinesLogo} width={100} height={100} />
            </div>
            <Container
                variant={EContainerVariant.glass}
                className={cn(
                    'w-[430px] 2xl:w-[480px]',
                    'flex flex-col',
                    'gap-default',
                    'p-default',
                )}
            >
                <Text
                    variant={ETextVariant.header}
                    className='pt-md'
                >
                    LOGIN
                </Text>
                <form className='flex flex-col gap-md' onSubmit={handleLogin}>
                    <Input
                        variant={EInputVariant.default}
                        type='text'
                        name='email'
                        placeholder='Email Address'
                    />
                    <Input
                        variant={EInputVariant.password}
                        type='password'
                        name='password'
                        placeholder='Password'
                    />
                    <div className='flex justify-between'>
                        <div className='flex items-center gap-1.5'>
                            <Input
                                variant={EInputVariant.checkbox}
                                type='checkbox'
                                name='remember-me'
                            />
                            <Text variant={ETextVariant.subheader}>
                                Remember me?
                            </Text>
                        </div>
                        <Button
                            variant={EButtonVariant.link}
                            type='button'
                            onClick={() => NavActions.navToForgotPassword()}
                        >
                            Forgot password?
                        </Button>
                    </div>
                    <Button
                        type='submit'
                        className='mt-lg'
                        loading={loading}
                        disabled={loading}
                    >
                        Log In
                    </Button>
                </form>
                <Text
                    variant={ETextVariant.error}
                    className='flex justify-center'
                >
                    {error}
                </Text>
            </Container>
        </AuthLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.authGetLoginAttempting(state),
    error: Selectors.authGetLoginError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    login: (params: LoginParams) => dispatch(Actions.authLoginAttempt(params)),
    resetErrors: () => dispatch(Actions.authResetErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
