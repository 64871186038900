import React, { useEffect } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

import config from 'config';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import AuthLayout from 'components/layout/AuthLayout';
import Routes from 'navigation/Routes';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import { EButtonVariant, ETextVariant } from 'entities/components';

import PackageJson from '../../package.json';

const Full = (): JSX.Element => {
    const dispatch = useDispatch();
    const loading = useSelector(Selectors.authGetStartupAttempting);

    useEffect(() => {
        dispatch(Actions.authStartupAttempt());

        console.log(`Version: ${PackageJson.version} (${config.env})`);
    }, []);

    if (loading) {
        return (
            <AuthLayout>
                <RotatingLines
                    strokeColor='white'
                    strokeWidth='5'
                    animationDuration='0.75'
                    width='250'
                    visible
                />
            </AuthLayout>
        );
    }

    const renderError = (error: Error) => {
        return (
            <AuthLayout>
                <Text variant={ETextVariant.header}>
                    Sorry, something went wrong on our end.
                </Text>
                <Text variant={ETextVariant.default}>
                    Please refresh the page in a little bit or contact us at liew@bikebear.com.my
                </Text>
                <Text variant={ETextVariant.error}>
                    {error.message}
                </Text>
                <Button
                    className='w-fit px-14 py-2'
                    onClick={() => [NavActions.navBack(), window.location.reload()]}
                >
                    Go Back
                </Button>
            </AuthLayout>
        );
    };

    const renderMockApiBadge = () => {
        return (
            <div className='fixed left-2 top-2 z-10 rounded-lg bg-warning p-2'>
                <Text variant={ETextVariant.default} className='font-general-bold text-slate-600'>
                    Mock API
                </Text>
            </div>
        );
    };

    return (
        <ErrorBoundary fallbackRender={({ error }) => renderError(error)}>
            {config.useMockApi ? renderMockApiBadge() : null}
            <Routes />
        </ErrorBoundary>
    );
};

export default Full;
