import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Divider from 'components/atoms/Divider';
import Modal from 'components/molecules/Modal';

import { AddTopicParams, DeleteTopicParams, EditTopicParams, GetTopicsResponse } from 'api/TopicReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import cn from 'lib/TailwindMerge';
import { EButtonVariant } from 'entities/components';

type EditTopicModalProps = {
    editTopicAttempting: boolean;
    modalIsOpen: boolean;
    setModalIsOpen: (state: boolean) => void;
    editTopic: (params: EditTopicParams) => void;

    topic: GetTopicsResponse | undefined;
};

const EditTopicModal = (props: EditTopicModalProps): JSX.Element => {
    const { editTopicAttempting, modalIsOpen, setModalIsOpen, editTopic, topic } = props;

    const [topicNameInputValue, setTopicName] = useState<string>(topic?.name ?? '');
    const [topicDescriptionInputValue, setDescription] = useState<string>(topic?.description ?? '');

    useEffect(() => {
        setTopicName(topic?.name ?? '');
        setDescription(topic?.description ?? '');
    }, [topic]);

    const handleTopicNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTopicName(e.target.value);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    };

    return (
        <Modal
            key={topic?._id}
            isOpen={modalIsOpen}
            onClose={() => {
                setTopicName(topic?.name ?? '');
                setDescription(topic?.description ?? '');
                setModalIsOpen(false);
            }}
        >
            <div className={cn('w-[650px] flex flex-col', 'items-center justify-center', 'gap-default p-md')}>
                <input
                    type='text'
                    className={cn(
                        'rounded-input bg-background p-0 w-full focus:outline-none text-[45px] text-white',
                        'font-heading-bold 2xl:text-[60px]',
                    )}
                    value={topicNameInputValue}
                    onChange={(e) => handleTopicNameChange(e)}
                />
                <Divider />
                <textarea
                    rows={8}
                    className={cn(
                        'w-full',
                        'rounded-input outline-none',
                        'bg-transparent',
                        'border-2 border-white',
                        'resize-none p-2.5',
                    )}
                    value={topicDescriptionInputValue}
                    onChange={(e) => handleDescriptionChange(e)}
                />
                <div className='self-end'>
                    <Button
                        variant={EButtonVariant.default}
                        className='px-xl'
                        loading={editTopicAttempting}
                        onClick={() => {
                            editTopic({
                                topicId: topic?._id ?? '',
                                name: topicNameInputValue,
                                description: topicDescriptionInputValue,
                                enabled: topic?.enabled ?? false,
                            });
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    editTopicAttempting: Selectors.topicReportEditTopicAttempting(state),
    modalIsOpen: Selectors.uiEditTopicModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) => dispatch(Actions.uiSetEditTopicModalIsOpen(state)),
    editTopic: (params: EditTopicParams) => dispatch(Actions.topicReportEditTopicAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTopicModal);
