import React from 'react';

import Text from 'components/atoms/Text';

import { renderImage } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { ETextVariant } from 'entities/components';

type UserProfileProps = {
    name: string;
    position: string;
    image?: string;
}

const UserProfile = (props: UserProfileProps): JSX.Element => {
    const {
        name,
        position,
        image,
    } = props;

    return (
        <div className='flex items-center gap-sm'>
            <div className='size-[40px] 2xl:size-[50px]'>
                {renderImage(image ?? '')}
            </div>
            <div className='flex flex-col gap-1'>
                <Text
                    variant={ETextVariant.subheader}
                    className='max-w-[120px] truncate font-general-bold'
                >
                    {name}
                </Text>
                <Text
                    variant={ETextVariant.subheader}
                    className='text-xs text-color-secondary'
                >
                    {position}
                </Text>
            </div>
        </div>
    );
};

UserProfile.defaultProps = {
    image: '',
};

export default UserProfile;
