import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'typed-redux-saga/macro';

import config from 'config';

import AuthGateway from 'api/Auth';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { ForgotPasswordParamsPayload } from 'redux/slices/auth/types';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';

export default function* watchForgotPassword(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authForgotPasswordAttempt', handleForgotPassword, api);
}

function* handleForgotPassword(api: AuthGateway, data: ForgotPasswordParamsPayload) {
    const { email } = data.payload;
    if (!email) {
        yield put(Actions.authLoginFailure('Please enter your email address'));
        return;
    }
    const response = yield* call([api, api.forgotPassword], { email });

    if (response.status === GatewayResponseStatus.Error) {
        toast.error(response.message);
        yield put(Actions.authForgotPasswordFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            toast.success(config.useMockApi ? response.data : 'Verification email sent!');
            yield put(Actions.authForgotPasswordSuccess());

            NavActions.navToVerifyOtp(email, '');
        } else {
            yield put(Actions.authForgotPasswordFailure('Something went wrong. Please try again.'));
        }
    }
}
