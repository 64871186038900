import React from 'react';

import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';

import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';

import NavActions from 'lib/NavActions';
import {
    renderPostStatus,
    renderPostType,
} from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { ETextVariant } from 'entities/components';
import { EPostStage, TPostDetails } from 'entities/report';

type PostTableProps = {
    postReportData: TPostDetails[] | [];
    loading?: boolean;
};

const PostTable = (props: PostTableProps): JSX.Element => {
    const {
        postReportData,
        loading,
    } = props;
    const PostTableHeader = ['Date', 'Posting', 'Topic', 'Expiry Date', 'Status', 'Public/Paid', 'Report'];

    const renderTableBody = () => {
        if (!postReportData || postReportData.length === 0) {
            return (
                <tr>
                    <td
                        colSpan={PostTableHeader.length}
                        className='py-xl text-center'
                    >
                        <Text variant={ETextVariant.default}>
                            There is no data! try adjusting the filters.
                        </Text>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return (
                <tr>
                    <td
                        colSpan={PostTableHeader.length}
                        className='py-xl text-center'
                    >
                        <div className='flex items-center justify-center gap-default'>
                            <RotatingLines
                                strokeColor='white'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='100'
                                visible
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return postReportData?.map((post) => {
            return (
                <tr
                    key={post._id}
                    className='cursor-pointer hover:bg-glass'
                    onClick={() => NavActions.navToModuleDetails('postReport', post._id)}
                >
                    <td className='py-md text-center'>
                        <Text>{dayjs(post.createdAt).format('DD MMM YYYY')}</Text>
                        <Text>{dayjs(post.createdAt).format('h:mm a')}</Text>
                    </td>
                    <td className='p-md'>
                        <div className='flex flex-col'>
                            <Text
                                variant={ETextVariant.default}
                                className='font-general-bold text-xl'
                            >
                                {post.title}
                            </Text>
                            <Text variant={ETextVariant.subheader} className='line-clamp-6 break-words'>
                                {post.content}
                            </Text>
                        </div>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{post.topicName}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{dayjs(post.verificationDateEnd).format('DD MMM YYYY')}</Text>
                        <Text>{dayjs(post.verificationDateEnd).format('h:mm a')}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text
                            className={cn(
                                (post.postStage && post.postStage === EPostStage.PENDING_VERIFICATION) && 'text-warning',
                                (post.postStage && post.postStage === EPostStage.VERIFIED) && 'text-success',
                            )}
                        >
                            {renderPostStatus(post.postStage)}
                        </Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{renderPostType(post.postType)}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text className='font-general-bold text-error'>{post.timesReported}</Text>
                    </td>
                </tr>
            );
        });
    };

    return (
        <Table
            className='table-fixed'
            tableHeader={PostTableHeader}
            tableData={renderTableBody()}
            expandedColumns={1}
            expandedWidth='w-1/3'
        />
    );
};

PostTable.defaultProps = {
    loading: false,
};

export default PostTable;
