import React from 'react';

import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Modal from 'components/molecules/Modal';

import { EditSubAdminAccessParams } from 'api/SubAdminBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import cn from 'lib/TailwindMerge';
import { EButtonVariant, ETextVariant } from 'entities/components';

type RevokeSubadminModalProps = {
    subAdminId: string;
    modalIsOpen: boolean;
    setModalIsOpen: (state: boolean) => void;
    revokeSubAdminAccess: (params: EditSubAdminAccessParams) => void;
};

const RevokeSubadminModal = (props: RevokeSubadminModalProps): JSX.Element => {
    const { subAdminId, modalIsOpen, setModalIsOpen, revokeSubAdminAccess } = props;

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => {
                setModalIsOpen(false);
            }}
        >
            <div className={cn('w-[450px] flex flex-col', 'items-start justify-start', 'gap-sm p-xl')}>
                <Text
                    variant={ETextVariant.default}
                    className='self-center pb-0 font-heading-bold text-[20px] text-white 2xl:text-[30px]'
                >
                    REVOKE SUBADMIN
                </Text>
                <Text variant={ETextVariant.header} className='font-heading-bold text-button-primary 2xl:text-[60px]'>
                    ARE YOU SURE?
                </Text>
                <Text className='text-center'>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                    et ea rebum.
                </Text>
                <Text variant={ETextVariant.caption}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.
                </Text>
                <Button
                    variant={EButtonVariant.default}
                    className='mt-default py-md'
                    // loading={deleteUserCommentAttempt}
                    onClick={() => {
                        revokeSubAdminAccess({
                            userId: subAdminId,
                            permissions: [],
                            fullRevoke: true,
                        });
                    }}
                >
                    Confirm
                </Button>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    modalIsOpen: Selectors.uiRevokeSubadminModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) => dispatch(Actions.uiSetRevokeSubadminModalIsOpen(state)),
    revokeSubAdminAccess: (params: EditSubAdminAccessParams) =>
        dispatch(Actions.subAdminRevokeSubAdminAccessAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RevokeSubadminModal);
