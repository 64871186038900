import Gateway from 'api/types/Gateway';

import { TChartData } from 'entities/components';
import {
    IUserDetails,
    TPostDetails,
    TRepLeaderboardUser,
    TTopicDetails,
} from 'entities/report';

import { GatewayResponse } from './types/types';

export type GenericParams<T> = {
    authToken: string;
    params: T,
}
export type GenericIndexedResponse<T> = {
    index: number;
    maxIndex: number;
    data: T[];
}

// ! Params
export type DateParams = {
    dateFrom: string;
    dateTo: string;
}
export type GetUserReportParams = {
    index: number;
    searchQuery: string;
    stage: number;
    dateFrom: string;
    dateTo: string;
}
export type GetUserDetailsParams = {
    userId: string;
    index: number;
    dateTo: string;
    dateFrom: string;
}
export type SetUserCommissionParams = {
    userId: string;
    commissionRate: number;
}
export type SetUserToBanParams = {
    userId: string;
    category: number;
    remarks?: string;
}
export type SetUserToUnbanParams = Pick<SetUserToBanParams, 'userId'>
export type DeleteUserCommentParams = {
    commentId: string;
}

export type GetRepLeaderboardParams = {
    index: number;
    timeFrame: number; // Can pass in default 3 = ALLTIME from Admin Console
    startFrom?: string;
}

// ! Response
export type GetUserReportResponse = {
    _id: string;
    username: string,
    dateJoined: string,
    displayName?: string,
    userTier?: number,
    repRanking?: number,
    profileImageUrl?: string,
    userStatus?: number,
}
export type GetTotalUsersResponse = {
    totalUsers: number,
    newUsers: number,
    activeUsers: number,
    newUsersData: TChartData[],
    activeUsersData: TChartData[],
}
export type GetUserSummaryResponse = {
    _id: string;
    username: string;
    displayName: string;
    followersCount: number;
    followingCount: number;
    topics: string[];
    commissionRate?: number,
    userTier?: number,
    repRanking?: number,
    profileImageUrl?: string,
    userStatus?: number,
    category?: number,
    remarks?: string,
    bankId?: string,
    bankName?: string,
    bankAccNo?: string,
    bankAccName?: string,
}
export type GetUserPostsResponse = {
    _id: string,
    userId: string,
    userDetails: IUserDetails[],
    title: string,
    content: string,
    postType: number,
    postRating?: number,
    verificationDateEnd: string,
    isFeatured: boolean,
    numberOfComments: number,
    postAttachmentUrl?: string,
    topicName: string,
}
export type GetUserCommentsResponse = {
    _id: string,
    content: string,
    commentStatus: number,
    createdAt: string,
    postId: string,
    postDetails: Pick<TPostDetails, 'title' | 'content' | 'createdAt' | 'topicName' | 'postRating'>,
}
export type GetUserGradesResponse = {
    _id: string,
    rating: number,
    postId: string,
    postDetails: Omit<TPostDetails, '_id' | 'postType'| 'topicName' | 'timesReported'>[],
    topicDetails: TTopicDetails[],
}
export type GetRepLeaderboardResponse = TRepLeaderboardUser;

export abstract class IUserReportGateway extends Gateway {
    abstract getUserReport(params: GenericParams<GetUserReportParams>):
        GatewayResponse<GenericIndexedResponse<GetUserReportResponse>>;

    abstract getTotalUsers(params: GenericParams<DateParams>):
        GatewayResponse<GetTotalUsersResponse>;

    abstract getUserSummary(params: GenericParams<string>):
        GatewayResponse<GetUserSummaryResponse>;

    abstract getUserPosts(params: GenericParams<GetUserDetailsParams>):
        GatewayResponse<GenericIndexedResponse<GetUserPostsResponse>>;

    abstract getUserComments(params: GenericParams<GetUserDetailsParams>):
        GatewayResponse<GenericIndexedResponse<GetUserCommentsResponse>>;

    abstract getUserGrades(params: GenericParams<GetUserDetailsParams>):
        GatewayResponse<GenericIndexedResponse<GetUserGradesResponse>>;

    abstract getRepLeaderboard(params: GenericParams<GetRepLeaderboardParams>):
        GatewayResponse<GenericIndexedResponse<GetRepLeaderboardResponse>>;

    abstract setUserCommission(params: GenericParams<SetUserCommissionParams>):
        GatewayResponse<string | null>;

    abstract setUserToBan(params: GenericParams<SetUserToBanParams>):
        GatewayResponse<string | null>;

    abstract setUserToUnban(params: GenericParams<SetUserToUnbanParams>):
        GatewayResponse<string | null>;

    abstract deleteUserComments(params: GenericParams<DeleteUserCommentParams>):
        GatewayResponse<string | null>;
}
