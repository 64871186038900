import dayjs from 'dayjs';
import { call, put, takeEvery } from 'typed-redux-saga/macro';

import AuthGateway from 'api/Auth';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';

export default function* watchStartup(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authStartupAttempt', handleStartup, api);
}

function* handleStartup(api: AuthGateway) {
    const authToken = Utils.Auth.getAuthToken();

    const resetAuthLocalStorage = () => {
        Utils.Auth.clearAuthToken();
        Utils.Auth.clearUserEmail();
        Utils.Auth.clearExpiresAt();
        Actions.authClearUserInfo();
        NavActions.navResetToLogin();
    };

    if (authToken) {
        if ((Number(Utils.Auth.getExpiresAt()) > dayjs().unix())) {
            const response = yield* call([api, api.refresh], { authToken });

            if (response.status === GatewayResponseStatus.Success) {
                if (response.data) {
                    const { token, user } = response.data;

                    Utils.Auth.storeAuthToken(token);
                    Utils.Auth.storeUserEmail(user.email);
                    yield put(Actions.authSetUserInfo(user));
                    yield* put(Actions.authLoginSuccess(token));
                } else {
                    yield put(Actions.authLoginFailure('Something went wrong. Please try again.'));
                }
            }

            if (response.status === GatewayResponseStatus.Error) {
                resetAuthLocalStorage();

                yield put(Actions.authStartupFailure(response.message));
            }
        } else {
            resetAuthLocalStorage();
        }
    } else {
        resetAuthLocalStorage();
    }
    yield put(Actions.authStartupSuccess());
}
