import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PaymentReportGateway from 'api/PaymentReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { CreatePaymentParamsPayLoad } from 'redux/slices/paymentReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchCreatePayment(api: PaymentReportGateway): SagaWatcherReturnType {
    yield takeEvery('paymentReport/paymentReportCreatePaymentAttempt', handleCreatePayment, api);
}

function* handleCreatePayment(api: PaymentReportGateway, data: CreatePaymentParamsPayLoad) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.createPayment], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.paymentReportCreatePaymentFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.paymentReportCreatePaymentSuccess());
        yield put(Actions.paymentReportGetPaymentReportDetailsAttempt(data.payload.refetch));
        yield put(Actions.paymentReportGetPaymentSelectorAttempt(data.payload.refetch));
        toast.success('Payment created successfully!');
    }
}
