import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PostReportGateway from 'api/PostReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import {
    GetPostModerationDetailsParamsPayload,
    GetPostModerationParamsPayload,
    SetPostModerationReviewParamsPayload,
} from 'redux/slices/postReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchSetPostModerationReview(
    api: PostReportGateway,
): SagaWatcherReturnType {
    yield takeEvery(
        'postReport/postReportSetPostModerationReviewAttempt',
        handleSetPostModerationReview,
        api,
    );
}

function* handleSetPostModerationReview(
    api: PostReportGateway,
    data: SetPostModerationReviewParamsPayload,
) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.setPostModerationReview], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(
            Actions.postReportSetPostModerationReviewFailure(
                response.message || '',
            ),
        );
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.postReportSetPostModerationReviewSuccess());
        yield put(Actions.uiSetPostModerationReviewModalIsOpen(false));
        yield put(
            Actions.postReportGetPostModerationAttempt({
                postId: data.payload.postId,
                index: 0,
            }),
        );
        toast.success('Post Moderation Review submitted successfully!');
    }
}
