import React, { FunctionComponent } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import ForgotPasswordScreen from 'containers/auth/ForgotPassword';
import LoginScreen from 'containers/auth/Login';
import ResetPasswordScreen from 'containers/auth/ResetPassword';
import VerifyOtpScreen from 'containers/auth/VerifyOtp';
import HomeScreen from 'containers/home';
import ReportScreen from 'containers/reports';
import PaymentDetails from 'containers/reports/payment/PaymentDetails';
import PostDetailsScreen from 'containers/reports/post/PostDetails';
import UserDetailsScreen from 'containers/reports/user/UserDetails';
import SettingScreen from 'containers/settings';

import { history } from 'redux/store';

import PrivateRoute from './PrivateRoutes';

const PrivateBucket: FunctionComponent = () => {
    return (
        <Route path='/' element={<PrivateRoute />}>
            <Route
                path='/'
                element={<HomeScreen />}
            />
            <Route
                path='/reports/:module'
                element={<ReportScreen />}
            />
            <Route
                path='/reports/userReport/:userId'
                element={<UserDetailsScreen />}
            />
            <Route
                path='/reports/postReport/:postId'
                element={<PostDetailsScreen />}
            />
            <Route
                path='/reports/paymentReport/:paymentId'
                element={<PaymentDetails />}
            />
            <Route
                path='/settings/:module'
                element={<SettingScreen />}
            />
        </Route>
    );
};

const NavRoutes: FunctionComponent = (props) => {
    return (
        <Router history={history}>
            <Routes>
                <Route
                    path='/login'
                    element={<LoginScreen />}
                />
                <Route
                    path='/forgot-password'
                    element={<ForgotPasswordScreen />}
                />
                <Route
                    path='/verify-otp'
                    element={<VerifyOtpScreen />}
                />
                <Route
                    path='/reset-password'
                    element={<ResetPasswordScreen />}
                />
                <Route
                    path='*'
                    element={<Navigate replace to='/' />}
                />
                {PrivateBucket(props)}
            </Routes>
        </Router>
    );
};

export default NavRoutes;
