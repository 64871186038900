import React from 'react';

import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Modal from 'components/molecules/Modal';

import { GetPostDetailsResponse } from 'api/PostReportBase';
import { DeleteUserCommentParams } from 'api/UserReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { renderPostMedia } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { EButtonVariant, ETextVariant } from 'entities/components';

type PostContentModalProps = {
    postDetails: GetPostDetailsResponse,
    modalIsOpen: boolean,
    setModalIsOpen: (state: boolean) => void,
}

const PostContentModal = (props: PostContentModalProps): JSX.Element => {
    const {
        postDetails,
        modalIsOpen,
        setModalIsOpen,
    } = props;

    const renderTitle = () => {
        return (
            <Text
                variant={ETextVariant.default}
                className='text-start font-general-bold 2xl:text-3xl'
            >
                {postDetails.details.title}
            </Text>
        );
    };

    const renderContent = () => {
        return (
            <Text
                variant={ETextVariant.default}
                className='overflow-y-auto break-words text-start 2xl:text-lg'
            >
                {postDetails.details.content}
            </Text>
        );
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
        >
            <div className={cn(
                'w-[700px] max-h-[80vh] flex flex-col',
                'gap-default p-xl',
            )}
            >
                {renderPostMedia(postDetails.details.postAttachmentUrl)}
                {renderTitle()}
                {renderContent()}
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    // modalIsOpen: Selectors.uiDeleteCommentIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // setModalIsOpen: (state: boolean) => dispatch(Actions.uiSetDeleteCommentModalIsOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostContentModal);
