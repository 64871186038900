import React from 'react';

import Paginator from 'components/molecules/Paginator';

import { GenericIndexedResponse, GetPostGradeHistoryResponse } from 'api/PostReportBase';

import PostGradeTable from '../components/postDetails/PostGradeTable';

type PostCommentsTabProps = {
    postGradeHistory: GenericIndexedResponse<GetPostGradeHistoryResponse>;

    page: number;
    setPage: (page: number) => void;

    loading?: boolean;
    error?: string;
};

const PostGradesTab = (props: PostCommentsTabProps): JSX.Element => {
    const {
        postGradeHistory,
        page,
        setPage,
        loading,
        error,
    } = props;

    return (
        <div>
            <PostGradeTable postGradeHistoryData={postGradeHistory.data} loading={loading} />
            <Paginator
                currentPage={page}
                maxPage={(postGradeHistory && postGradeHistory.maxIndex !== 0 && postGradeHistory.maxIndex) || 0}
                onFirst={() => page !== 0 && setPage(0)}
                onLast={() => postGradeHistory.maxIndex !== 0 && setPage((postGradeHistory && postGradeHistory.maxIndex) || 0)}
                onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                onNext={() => postGradeHistory.maxIndex !== 0 && setPage(page !== postGradeHistory.maxIndex ? page + 1 : page)}
            />
        </div>
    );
};

PostGradesTab.defaultProps = {
    loading: false,
    error: '',
};
export default PostGradesTab;
