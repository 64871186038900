import React from 'react';

import _ from 'lodash';

import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';

import { EContainerVariant, ETextVariant } from 'entities/components';

type PaymentStatusCardProps = {
    value: number;
    label: string;
    icon: React.ReactNode;
};

const PaymentStatusCard = (props: PaymentStatusCardProps): JSX.Element => {
    const { value, label, icon } = props;

    return (
        <Container
            variant={EContainerVariant.gradientBorder}
            className='p-default'
        >
            <div className='flex h-full flex-col gap-0'>
                <div className='flex justify-end'>{icon}</div>
                <div className='-mt-2 flex h-full items-end'>
                    <Text
                        variant={ETextVariant.header}
                        className='mb-0 font-general-bold text-[50px] xl:text-[60px] 2xl:text-[70px]'
                    >
                        {_.round(value, 2)}
                    </Text>
                    <Text variant={ETextVariant.default} className='mb-2 text-[18px]'>SGD</Text>
                </div>
                <Text variant={ETextVariant.default} className='mb-0 text-[18px]'>{label}</Text>
            </div>
        </Container>
    );
};

export default PaymentStatusCard;
