import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ContentLayout from 'components/layout/ContentLayout';
import CustomDateRangePicker from 'components/molecules/CustomDateRangePicker';
import TabMenu from 'components/molecules/TabMenu';

import {
    GenericIndexedResponse,
    GetUserCommentsResponse,
    GetUserDetailsParams,
    GetUserGradesResponse,
    GetUserPostsResponse,
    GetUserSummaryResponse,
} from 'api/UserReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { tabUserDetails } from 'lib/Constant';
import { TDateFilterString } from 'entities/components';
import { EUserDetailsTab } from 'entities/ui';

import UserSummaryCard from './components/userDetails/UserSummaryCard';
import UserCommentsTab from './tabs/UserCommentsTab';
import UserGradeTab from './tabs/UserGradeTab';
import UserPostsTab from './tabs/UserPostsTab';
import UserSettingTab from './tabs/UserSettingsTab';

interface UserDetailsProps {
    getUserSummaryAttempt: boolean;
    getUserSummaryError: string;
    userSummary: GetUserSummaryResponse;

    getUserPostsAttempt: boolean;
    getUserPostsError: string;
    userPosts: GenericIndexedResponse<GetUserPostsResponse>;

    getUserCommentsAttempt: boolean;
    getUserCommentsError: string;
    userComments: GenericIndexedResponse<GetUserCommentsResponse>;

    getUserGradesAttempt: boolean;
    getUserGradesError: string;
    userGrades: GenericIndexedResponse<GetUserGradesResponse>;

    setUserToBanAttempt: boolean;
    setUserToBanError: string;

    setUserToUnbanAttempt: boolean;
    setUserToUnbanError: string;

    dateFilter: TDateFilterString;
    currentTab: EUserDetailsTab;

    getUserSummary: (params: string) => void;
    getUserPosts: (params: GetUserDetailsParams) => void;
    getUserComments: (params: GetUserDetailsParams) => void;
    getUserGrades: (params: GetUserDetailsParams) => void;

    setDateFilter: (date: TDateFilterString) => void;
    setCurrentTab: (tab: EUserDetailsTab) => void;
    resetError: () => void;
}
const UserDetails = (props: UserDetailsProps): JSX.Element => {
    const {
        getUserSummaryAttempt,
        getUserSummaryError,
        userSummary,

        getUserPostsAttempt,
        getUserPostsError,
        userPosts,

        getUserCommentsAttempt,
        getUserCommentsError,
        userComments,

        getUserGradesAttempt,
        getUserGradesError,
        userGrades,

        setUserToBanAttempt,
        setUserToBanError,

        setUserToUnbanAttempt,
        setUserToUnbanError,

        dateFilter,
        currentTab,

        getUserSummary,
        getUserPosts,
        getUserComments,
        getUserGrades,

        setDateFilter,
        setCurrentTab,
        resetError,
    } = props;

    const { pathname } = useLocation();
    const userId = pathname.split('/').pop();

    const [dateRangeFilter, setDateRangeFilter] = useState<[Date | null, Date | null]>([
        dayjs(dateFilter[0]).toDate(),
        dayjs(dateFilter[1]).toDate(),
    ]);

    const [startDate, endDate] = dateRangeFilter;
    const [postPage, setPostPage] = useState<number>(0);
    const [commentPage, setCommentPage] = useState<number>(0);
    const [gradePage, setGradePage] = useState<number>(0);

    useEffect(() => {
        if (userId) {
            getUserSummary(userId);
        }
    }, [userId]);

    useEffect(() => {
        resetError();
    }, [currentTab]);

    useEffect(() => {
        if (userId && startDate && endDate) {
            switch (currentTab) {
                case EUserDetailsTab.allPosts:
                    getUserPosts({
                        index: postPage,
                        userId,
                        dateFrom: dayjs(startDate).startOf('day').utc().format(),
                        dateTo: dayjs(endDate).endOf('day').utc().format(),
                    });
                    setDateFilter([dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]);

                    break;
                case EUserDetailsTab.commentsHistories:
                    getUserComments({
                        index: commentPage,
                        userId,
                        dateFrom: dayjs(startDate).startOf('day').utc().format(),
                        dateTo: dayjs(endDate).endOf('day').utc().format(),
                    });
                    setDateFilter([dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]);

                    break;
                case EUserDetailsTab.gradeHistories:
                    getUserGrades({
                        index: gradePage,
                        userId,
                        dateFrom: dayjs(startDate).startOf('day').utc().format(),
                        dateTo: dayjs(endDate).endOf('day').utc().format(),
                    });
                    setDateFilter([dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]);

                    break;
                default:
                    break;
            }
        }
    }, [currentTab, endDate, postPage, commentPage, gradePage]);

    const userDetailsTab = () => {
        return tabUserDetails.map((tab) => {
            return {
                text: tab.text,
                type: tab.type,
                onClick: () => {
                    setCurrentTab(tab.type);
                },
            };
        });
    };

    const renderTabContent = () => {
        switch (currentTab) {
            case EUserDetailsTab.allPosts:
                return (
                    <UserPostsTab
                        userPosts={userPosts}
                        loading={getUserPostsAttempt || getUserSummaryAttempt || (endDate === null)}
                        error={getUserPostsError}
                        page={postPage}
                        setPage={setPostPage}
                    />
                );
            case EUserDetailsTab.commentsHistories:
                return (
                    <UserCommentsTab
                        user={userSummary}
                        userComments={userComments}
                        loading={getUserCommentsAttempt || getUserSummaryAttempt || (endDate === null)}
                        error={getUserCommentsError}
                        page={commentPage}
                        setPage={setCommentPage}
                        dateFrom={dayjs(startDate).startOf('day').utc().format()}
                        dateTo={dayjs(endDate).endOf('day').utc().format()}
                    />
                );
            case EUserDetailsTab.gradeHistories:
                return (
                    <UserGradeTab
                        userGrades={userGrades}
                        loading={getUserGradesAttempt || getUserSummaryAttempt || (endDate === null)}
                        error={getUserGradesError}
                        page={gradePage}
                        setPage={setGradePage}
                    />
                );
            case EUserDetailsTab.settings:
                return (
                    <UserSettingTab
                        commissionRate={userSummary.commissionRate}
                        userStatus={userSummary.userStatus}
                        category={userSummary.category}
                        remarks={userSummary.remarks}
                        loading={getUserSummaryAttempt || setUserToBanAttempt || setUserToUnbanAttempt}
                        error={setUserToBanError || setUserToUnbanError}
                        userId={userSummary._id}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <ContentLayout className='gap-xl'>
            <UserSummaryCard
                userSummary={userSummary}
                loading={getUserSummaryAttempt}
                error={getUserSummaryError}
            />
            <div className='flex justify-between'>
                <TabMenu
                    menuData={userDetailsTab()}
                    selected={currentTab}
                    textClassName='2xl:text-xl'
                />
                <CustomDateRangePicker
                    disabled={currentTab === EUserDetailsTab.settings}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(dates) => {
                        setDateRangeFilter(dates);
                    }}
                    className='w-menu'
                />
            </div>
            <div>
                {renderTabContent()}
            </div>
        </ContentLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    getUserSummaryAttempt: Selectors.userReportGetUserSummaryAttempting(state),
    getUserSummaryError: Selectors.userReportGetUserSummaryError(state),
    userSummary: Selectors.userReportGetUserSummaryData(state),

    getUserPostsAttempt: Selectors.userReportGetUserPostsAttempting(state),
    getUserPostsError: Selectors.userReportGetUserPostsError(state),
    userPosts: Selectors.userReportGetUserPostsData(state),

    getUserCommentsAttempt: Selectors.userReportGetUserCommentsAttempting(state),
    getUserCommentsError: Selectors.userReportGetUserCommentsError(state),
    userComments: Selectors.userReportGetUserCommentsData(state),

    getUserGradesAttempt: Selectors.userReportGetUserGradesAttempting(state),
    getUserGradesError: Selectors.userReportGetUserGradesError(state),
    userGrades: Selectors.userReportGetUserGradesData(state),

    setUserToBanAttempt: Selectors.userReportSetUserToBanAttempting(state),
    setUserToBanError: Selectors.userReportSetUserToBanError(state),

    setUserToUnbanAttempt: Selectors.userReportSetUserToUnbanAttempting(state),
    setUserToUnbanError: Selectors.userReportSetUserToUnbanError(state),

    dateFilter: Selectors.uiGetUserDetailsDateFilter(state),
    currentTab: Selectors.uiGetCurrentUserDetailsTab(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUserSummary: (params: string) => dispatch(Actions.userReportGetUserSummaryAttempt(params)),

    getUserPosts: (params: GetUserDetailsParams) => dispatch(Actions.userReportGetUserPostsAttempt(params)),
    getUserComments: (params: GetUserDetailsParams) => dispatch(Actions.userReportGetUserCommentsAttempt(params)),
    getUserGrades: (params: GetUserDetailsParams) => dispatch(Actions.userReportGetUserGradesAttempt(params)),

    setDateFilter: (date: TDateFilterString) => dispatch(Actions.uiSetUserDetailsDateFilter(date)),
    setCurrentTab: (tab: EUserDetailsTab) => dispatch(Actions.uiSetCurrentUserDetailsTab(tab)),

    resetError: () => dispatch(Actions.userReportResetErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
