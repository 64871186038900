import { fork } from 'redux-saga/effects';

import PurchaseReportGateway from 'api/PurchaseReport';
import { RootSagaReturnType } from 'sagas/types';

import watchGetPurchaseReport from './getPurchaseReport';
import watchGetTotalPurchasesGraph from './getTotalPurchasesGraph';

export default (api: PurchaseReportGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetTotalPurchasesGraph, api);
        yield fork(watchGetPurchaseReport, api);
    }

    return {
        rootSaga,
    };
};
