import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import SystemConfigurationGateway from 'api/SystemConfiguration';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { SetSystemConfigParamsPayload } from 'redux/slices/systemConfiguration/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchSetSystemConfig(api: SystemConfigurationGateway): SagaWatcherReturnType {
    yield takeEvery('systemConfiguration/systemConfigurationSetSystemConfigAttempt', handleSetSystemConfig, api);
}

function* handleSetSystemConfig(api: SystemConfigurationGateway, data: SetSystemConfigParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.setSystemConfig], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.systemConfigurationSetSystemConfigFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.systemConfigurationSetSystemConfigSuccess());
        yield put(Actions.systemConfigurationGetSystemConfigAttempt());
        toast.success('System config successfully updated!');
    }
}
