import React from 'react';

import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';
import CustomAreaChart from 'components/molecules/CustomAreaChart';

import { EContainerVariant, ETextVariant, TChartData } from 'entities/components';

type PurchaseChartCardProps = {
    value: number,
    label: string,
    data: TChartData[],
}

const PurchaseChartCard = (props: PurchaseChartCardProps): JSX.Element => {
    const {
        value,
        label,
        data,
    } = props;

    const initValue = {
        date: '',
        counts: 0,
    };

    const checkData = data.reduceRight((acc, curr) => {
        if (curr.counts > 0) {
            return curr;
        }
        return acc;
    }, { ...initValue });

    const renderChart = () => {
        if (checkData.counts > 0) {
            return <CustomAreaChart data={data} type='SGD' isPayment />;
        }

        return <Text>No purchases made within specified date range, try changing it!</Text>;
    };
    return (
        <Container
            variant={EContainerVariant.gradientBorder}
            className='p-default px-xl'
        >
            <div className='flex items-end gap-sm'>
                <Text
                    variant={ETextVariant.header}
                    className='font-heading-bold text-[5rem]'
                >
                    {value}
                </Text>
                <Text className='mb-1'>
                    {label}
                </Text>
            </div>
            <div className='h-[250px] w-full py-md'>
                {renderChart()}
            </div>
        </Container>
    );
};

export default PurchaseChartCard;
