import React, { ButtonHTMLAttributes } from 'react';

import { RotatingLines } from 'react-loader-spinner';

import cn from 'lib/TailwindMerge';
import { EButtonVariant } from 'entities/components';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: EButtonVariant;
    loading?: boolean;
};

const Button = (props: ButtonProps): JSX.Element => {
    const {
        variant,
        loading,
        type,
        children,
        className,
        ...otherProps
    } = props;

    const defaultClass = cn(
        'flex justify-center',
        'w-full p-2.5',
        'rounded-input',
        'bg-button-primary',
        'font-general-medium',
        'text-color-primary',
        'shadow-button',
        'transition-transform',
        'focus:outline-none',
        'active:translate-y-1',
    );

    const variantClasses = {
        [EButtonVariant.default]: defaultClass,
        [EButtonVariant.link]: 'cursor-pointer text-xs 2xl:text-sm text-link',
        [EButtonVariant.ghost]: 'cursor-pointer font-heading text-2xl tracking-wider text-color-primary',
        [EButtonVariant.danger]: cn(defaultClass, 'bg-error font-general-bold'),
        [EButtonVariant.warning]: cn(defaultClass, 'bg-warning font-general-bold'),
        [EButtonVariant.disabled]: cn(defaultClass, 'bg-slate-500 font-general-bold'),
    };

    return (
        <button
            type={type === 'submit' ? 'submit' : 'button'}
            className={cn(
                variantClasses[variant ?? EButtonVariant.default],
                className,
            )}
            {...otherProps}
        >
            {loading ? (
                <RotatingLines
                    strokeColor='white'
                    strokeWidth='5'
                    animationDuration='0.75'
                    width='24'
                    visible
                />
            ) : children}
        </button>
    );
};

Button.defaultProps = {
    variant: EButtonVariant.default,
    loading: false,
};

export default Button;
