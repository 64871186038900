import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import config from 'config';

import { GatewayResponseStatus } from 'api/types/types';
import UserReportGateway from 'api/UserReport';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { SetUserCommissionParamsPayload } from 'redux/slices/userReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchSetUserCommission(api: UserReportGateway): SagaWatcherReturnType {
    yield takeEvery('userReport/userReportSetUserCommissionAttempt', handleSetUserCommission, api);
}

function* handleSetUserCommission(api: UserReportGateway, data: SetUserCommissionParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.setUserCommission], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.userReportSetUserCommissionFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.userReportSetUserCommissionSuccess());
        yield put(Actions.userReportGetUserSummaryAttempt(data.payload.userId));
        toast.success(config.useMockApi ? response.data : 'Commission set successfully!');
    }
}
