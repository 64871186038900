import { createSlice } from '@reduxjs/toolkit';

import {
    EModerationCategory,
    EModerationStatus,
    EmptyIndexedResponse,
} from 'entities/report';

import {
    DeleteUserPostParamsPayload,
    GetAllTopicsResponsePayload,
    GetPostCommentHistoryParamsPayload,
    GetPostCommentHistoryResponsePayload,
    GetPostDetailsParamsPayload,
    GetPostDetailsResponsePayload,
    GetPostGradeHistoryParamsPayload,
    GetPostGradeHistoryResponsePayload,
    GetPostModerationDetailsParamsPayload,
    GetPostModerationDetailsResponsePayload,
    GetPostModerationParamsPayload,
    GetPostModerationResponsePayload,
    GetPostPurchaseHistoryParamsPayload,
    GetPostPurchaseHistoryResponsePayload,
    GetPostReportParamsPayload,
    GetPostReportResponsePayload,
    PostReportReduxState,
    SetFeaturedPostParamsPayload,
    SetPostModerationReviewParamsPayload,
    StringOrNullPayload,
} from './types';

const initialState: PostReportReduxState = {
    actions: {
        getPostReport: false,
        getAllTopics: false,
        getPostDetails: false,
        getPostCommentHistory: false,
        getPostGradeHistory: false,
        getPostModeration: false,
        getPostModerationDetails: false,
        setPostModerationReview: false,
        getPostPurchaseHistory: false,
        setFeaturedPost: false,
        deleteUserPost: false,
    },
    postReport: {
        index: 0,
        maxIndex: 0,
        data: [],
        totalPosts: {
            amount: 0,
            data: [],
        },
        totalFeaturedPosts: 0,
        totalReportedPosts: 0,
    },
    allTopics: [],
    postDetails: {
        details: {
            _id: '',
            userId: '',
            username: '',
            title: '',
            content: '',
            topicId: '',
            topicName: '',
            verificationDateEnd: '',
            postType: 0,
            postPrice: 0,
            postStage: 0,
            isFeatured: false,
            createdAt: '',
            updatedAt: '',
            postAttachmentUrl: '',
            timesReported: 0,
            pointsGained: 0,
            postRating: 0,
        },
        comments: {
            total: 0,
            pastWeekDiff: 0,
            graph: [
                {
                    count: 0,
                    week: 0,
                },
            ],
        },
        moderation: {
            total: 0,
            pastWeekDiff: 0,
            graph: [
                {
                    count: 0,
                    week: 0,
                },
            ],
        },
        rating: {
            total: 0,
            pastWeekDiff: 0,
            graph: [
                {
                    count: 0,
                    week: 0,
                },
            ],
        },
        purchases: {
            total: 0,
            pastWeekDiff: 0,
            graph: [
                {
                    count: 0,
                    week: 0,
                },
            ],
        },
    },
    postComments: EmptyIndexedResponse,
    postGradeHistory: EmptyIndexedResponse,
    postModeration: EmptyIndexedResponse,
    postModerationDetails: {
        _id: '',
        reportCategory: 0,
        reportDate: '',
        reporter: {
            displayName: '',
            profileImageUrl: '',
            userId: '',
            username: '',
            userTier: 0,
            repRanking: 0,
        },
    },
    postPurchaseHistory: EmptyIndexedResponse,
    error: {
        getPostReport: '',
        getAllTopics: '',
        getPostDetails: '',
        getPostCommentHistory: '',
        getPostGradeHistory: '',
        getPostModeration: '',
        getPostModerationDetails: '',
        setPostModerationReview: '',
        getPostPurchaseHistory: '',
        setFeaturedPost: '',
        deleteUserPost: '',
    },
};

const postReportSlice = createSlice({
    name: 'postReport',
    initialState,
    reducers: {
        postReportResetActions: (state) => {
            state.actions = {
                getPostReport: false,
                getAllTopics: false,
                getPostDetails: false,
                getPostCommentHistory: false,
                getPostGradeHistory: false,
                getPostModeration: false,
                getPostModerationDetails: false,
                setPostModerationReview: false,
                getPostPurchaseHistory: false,
                setFeaturedPost: false,
                deleteUserPost: false,
            };
        },
        postReportResetErrors: (state) => {
            state.error = {
                getPostReport: '',
                getAllTopics: '',
                getPostDetails: '',
                getPostCommentHistory: '',
                getPostGradeHistory: '',
                getPostModeration: '',
                getPostModerationDetails: '',
                setPostModerationReview: '',
                getPostPurchaseHistory: '',
                setFeaturedPost: '',
                deleteUserPost: '',
            };
        },
        postReportGetPostReportAttempt: (
            state,
            _action: GetPostReportParamsPayload,
        ) => {
            state.actions.getPostReport = true;
            state.error.getPostReport = '';
        },
        postReportGetPostReportSuccess: (
            state,
            action: GetPostReportResponsePayload,
        ) => {
            state.actions.getPostReport = false;
            if (action.payload) {
                state.postReport = action.payload;
            }
        },
        postReportGetPostReportFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.getPostReport = false;
            if (action.payload) {
                state.error.getPostReport = action.payload;
            }
        },
        postReportGetAllTopicsAttempt: (state) => {
            state.actions.getAllTopics = true;
            state.error.getAllTopics = '';
        },
        postReportGetAllTopicsSuccess: (
            state,
            action: GetAllTopicsResponsePayload,
        ) => {
            state.actions.getAllTopics = false;
            if (action.payload) {
                state.allTopics = action.payload;
            }
        },
        postReportGetAllTopicsFailure: (state, action: StringOrNullPayload) => {
            state.actions.getAllTopics = false;
            if (action.payload) {
                state.error.getAllTopics = action.payload;
            }
        },
        postReportGetPostDetailsAttempt: (
            state,
            _action: GetPostDetailsParamsPayload,
        ) => {
            state.actions.getPostDetails = true;
            state.error.getPostDetails = '';
        },
        postReportGetPostDetailsSuccess: (
            state,
            action: GetPostDetailsResponsePayload,
        ) => {
            state.actions.getPostDetails = false;
            if (action.payload) {
                state.postDetails = action.payload;
            }
        },
        postReportGetPostDetailsFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.getPostDetails = false;
            if (action.payload) {
                state.error.getPostDetails = action.payload;
            }
        },
        postReportGetPostCommentHistoryAttempt: (
            state,
            _action: GetPostCommentHistoryParamsPayload,
        ) => {
            state.actions.getPostCommentHistory = true;
            state.error.getPostCommentHistory = '';
        },
        postReportGetPostCommentHistorySuccess: (
            state,
            action: GetPostCommentHistoryResponsePayload,
        ) => {
            state.actions.getPostCommentHistory = false;
            if (action.payload) {
                state.postComments = action.payload;
            }
        },
        postReportGetPostCommentHistoryFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.getPostCommentHistory = false;
            if (action.payload) {
                state.error.getPostCommentHistory = action.payload;
            }
        },
        postReportGetPostGradeHistoryAttempt: (
            state,
            _action: GetPostGradeHistoryParamsPayload,
        ) => {
            state.actions.getPostGradeHistory = true;
            state.error.getPostGradeHistory = '';
        },
        postReportGetPostGradeHistorySuccess: (
            state,
            action: GetPostGradeHistoryResponsePayload,
        ) => {
            state.actions.getPostGradeHistory = false;
            if (action.payload) {
                state.postGradeHistory = action.payload;
            }
        },
        postReportGetPostGradeHistoryFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.getPostGradeHistory = false;
            if (action.payload) {
                state.error.getPostGradeHistory = action.payload;
            }
        },
        postReportGetPostModerationAttempt: (
            state,
            _action: GetPostModerationParamsPayload,
        ) => {
            state.actions.getPostModeration = true;
            state.error.getPostModeration = '';
        },
        postReportGetPostModerationSuccess: (
            state,
            action: GetPostModerationResponsePayload,
        ) => {
            state.actions.getPostModeration = false;
            if (action.payload) {
                state.postModeration = action.payload;
            }
        },
        postReportGetPostModerationFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.getPostModeration = false;
            if (action.payload) {
                state.error.getPostModeration = action.payload;
            }
        },
        postReportGetPostModerationDetailsAttempt: (
            state,
            _action: GetPostModerationDetailsParamsPayload,
        ) => {
            state.actions.getPostModerationDetails = true;
            state.error.getPostModerationDetails = '';
        },
        postReportGetPostModerationDetailsSuccess: (
            state,
            action: GetPostModerationDetailsResponsePayload,
        ) => {
            state.actions.getPostModerationDetails = false;
            if (action.payload) {
                state.postModerationDetails = action.payload;
            }
        },
        postReportGetPostModerationDetailsFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.getPostModerationDetails = false;
            if (action.payload) {
                state.error.getPostModerationDetails = action.payload;
            }
        },
        postReportSetPostModerationReviewAttempt: (
            state,
            _action: SetPostModerationReviewParamsPayload,
        ) => {
            state.actions.setPostModerationReview = true;
            state.error.setPostModerationReview = '';
        },
        postReportSetPostModerationReviewSuccess: (state) => {
            state.actions.setPostModerationReview = false;
        },
        postReportSetPostModerationReviewFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.setPostModerationReview = false;
            if (action.payload) {
                state.error.setPostModerationReview = action.payload;
            }
        },
        postReportGetPostPurchaseHistoryAttempt: (state, _action: GetPostPurchaseHistoryParamsPayload) => {
            state.actions.getPostPurchaseHistory = true;
            state.error.getPostPurchaseHistory = '';
        },
        postReportGetPostPurchaseHistorySuccess: (state, action: GetPostPurchaseHistoryResponsePayload) => {
            state.actions.getPostPurchaseHistory = false;
            if (action.payload) {
                state.postPurchaseHistory = action.payload;
            }
        },
        postReportGetPostPurchaseHistoryFailure: (state, action: StringOrNullPayload) => {
            state.actions.getPostPurchaseHistory = false;
            if (action.payload) {
                state.error.getPostPurchaseHistory = action.payload;
            }
        },
        postReportSetFeaturedPostAttempt: (
            state,
            _action: SetFeaturedPostParamsPayload,
        ) => {
            state.actions.setFeaturedPost = true;
            state.error.setFeaturedPost = '';
        },
        postReportSetFeaturedPostSuccess: (state) => {
            state.actions.setFeaturedPost = false;
        },
        postReportSetFeaturedPostFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.setFeaturedPost = false;
            if (action.payload) {
                state.error.setFeaturedPost = action.payload;
            }
        },
        postReportDeleteUserPostAttempt: (
            state,
            _action: DeleteUserPostParamsPayload,
        ) => {
            state.actions.deleteUserPost = true;
            state.error.deleteUserPost = '';
        },
        postReportDeleteUserPostSuccess: (state) => {
            state.actions.deleteUserPost = false;
        },
        postReportDeleteUserPostFailure: (
            state,
            action: StringOrNullPayload,
        ) => {
            state.actions.deleteUserPost = false;
            if (action.payload) {
                state.error.deleteUserPost = action.payload;
            }
        },
    },
});

export type PostReportState = typeof initialState;

export default {
    actions: postReportSlice.actions,
    reducers: postReportSlice.reducer,
};
