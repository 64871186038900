import React, { FC } from 'react';

import { ToastContainer, ToastContainerProps } from 'react-toastify';

import cn from 'lib/TailwindMerge';

const Toast: FC<ToastContainerProps> = () => {
    return (
        <ToastContainer
            toastClassName={cn(
                'bg-toast filter backdrop-blur-glass',
                'text-color-primary font-general',
                'text-sm 2xl:text-base',
            )}
            closeButton={false}
            draggablePercent={60}
            limit={5}
            hideProgressBar
            closeOnClick
            pauseOnHover
            pauseOnFocusLoss
        />
    );
};

export default Toast;
