import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import config from 'config';

import { GatewayResponseStatus } from 'api/types/types';
import UserReportGateway from 'api/UserReport';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { SetUserToBanParamsPayload } from 'redux/slices/userReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchSetUserToBan(api: UserReportGateway): SagaWatcherReturnType {
    yield takeEvery('userReport/userReportSetUserToBanAttempt', handleSetUserToBan, api);
}

function* handleSetUserToBan(api: UserReportGateway, data: SetUserToBanParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.setUserToBan], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.uiSetBanUserModalIsOpen(false));
        yield put(Actions.userReportSetUserToBanFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.userReportSetUserToBanSuccess());
        yield put(Actions.userReportGetUserSummaryAttempt(data.payload.userId));
        yield put(Actions.uiSetBanUserModalIsOpen(false));
        toast.success(config.useMockApi ? response.data : 'User banned successfully!');
    }
}
