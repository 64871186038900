import authSlice from 'redux/slices/auth';
import dashboardSlice from 'redux/slices/dashboard';
import moderationReportSlice from 'redux/slices/moderationReport';
import paymentReportSlice from 'redux/slices/paymentReport';
import postReportSlice from 'redux/slices/postReport';
import purchaseReportSlice from 'redux/slices/purchaseReport';
import subAdminSlice from 'redux/slices/subAdmin';
import systemConfigurationSlice from 'redux/slices/systemConfiguration';
import topicReportSlice from 'redux/slices/topicReport';
import uiSlice from 'redux/slices/ui';
import userReportSlice from 'redux/slices/userReport';

export default {
    ...uiSlice.actions,
    ...authSlice.actions,
    ...dashboardSlice.actions,
    ...userReportSlice.actions,
    ...postReportSlice.actions,
    ...topicReportSlice.actions,
    ...purchaseReportSlice.actions,
    ...paymentReportSlice.actions,
    ...moderationReportSlice.actions,
    ...subAdminSlice.actions,
    ...systemConfigurationSlice.actions,
};
