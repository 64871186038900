import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'typed-redux-saga/macro';

import config from 'config';

import AuthGateway from 'api/Auth';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { ResetPasswordParamsPayload } from 'redux/slices/auth/types';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';

export default function* watchResetPassword(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authResetPasswordAttempt', handleResetPassword, api);
}

function* handleResetPassword(api: AuthGateway, data: ResetPasswordParamsPayload) {
    const { email, otp, password } = data.payload;

    if (!password) {
        yield put(Actions.authResetPasswordFailure('Please enter your password'));
        return;
    }

    const response = yield* call([api, api.resetPassword], { email, otp, password });

    if (response.status === GatewayResponseStatus.Error) {
        toast.error(response.message);
        yield put(Actions.authResetPasswordFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            toast.success(config.useMockApi ? response.data : 'Password reset successful!');
            yield put(Actions.authResetPasswordSuccess());

            NavActions.navResetToLogin();
        } else {
            yield put(Actions.authResetPasswordFailure('Something went wrong. Please try again.'));
        }
    }
}
