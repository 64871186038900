import React, { useEffect } from 'react';

import { AiOutlineHome } from 'react-icons/ai';
import { TbLogout2 } from 'react-icons/tb';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { menuReports, menuSettings } from 'lib/Constant';
import NavActions from 'lib/NavActions';
import cn from 'lib/TailwindMerge';
import { EAuthRole, EPermission, IAuthUser } from 'entities/auth';
import {
    EButtonVariant,
    EContainerVariant,
    ETextVariant,
} from 'entities/components';
import { EMenuList } from 'entities/ui';

import icons from 'assets/icons';

import UserProfile from './UserProfile';

type SideMenuProps = {
    userInfo: IAuthUser | null;
    currentPage: EMenuList;

    uiSetCurrentPage: (page: EMenuList) => void;
    logout: () => void;
};

const SideMenu = (props: SideMenuProps): JSX.Element => {
    const {
        userInfo,
        currentPage,
        uiSetCurrentPage,
        logout,
    } = props;

    const location = useLocation();
    const { pathname } = location;
    const selectedPage = pathname.split('/')[2];
    const userRolePermission = userInfo?.permissions || [];

    useEffect(() => {
        if (selectedPage) {
            uiSetCurrentPage(EMenuList[selectedPage as keyof typeof EMenuList]);
        } else {
            uiSetCurrentPage(EMenuList.home);
        }
    }, [selectedPage]);

    const handleMenuClick = (module: EMenuList, page: EMenuList) => {
        if (module === EMenuList.home) {
            uiSetCurrentPage(page);
            NavActions.navToHome();
        } else {
            uiSetCurrentPage(page);

            if (module === EMenuList.reports) {
                NavActions.navToReports(EMenuList[page]);
            }
            if (module === EMenuList.settings) {
                NavActions.navToSettings(EMenuList[page]);
            }
        }
    };
    const checkCurrentPage = (selected: boolean) => {
        if (selected) {
            return 'text-color-primary font-general-medium';
        }
        return 'text-color-secondary';
    };

    const renderReports = () => {
        if (userRolePermission) {
            return menuReports.map((item) => {
                if (userRolePermission.includes(item.permission)) {
                    return (
                        <Button
                            key={item.text}
                            variant={EButtonVariant.ghost}
                            onClick={() => handleMenuClick(EMenuList.reports, item.type)}
                            className={cn(
                                'w-full',
                                'flex items-center gap-sm',
                                'rounded-md py-sm',
                                'text-left font-general text-xs font-normal 2xl:text-sm',
                                checkCurrentPage(currentPage === item.type),
                            )}
                        >
                            {item.icon}
                            {item.text}
                        </Button>
                    );
                }
                return null;
            });
        }
        return null;
    };

    const renderSettings = () => {
        if (userRolePermission) {
            return menuSettings.map((item) => {
                if (userRolePermission.includes(item.permission)) {
                    return (
                        <Button
                            key={item.text}
                            variant={EButtonVariant.ghost}
                            onClick={() => handleMenuClick(EMenuList.settings, item.type)}
                            className={cn(
                                'w-full',
                                'flex items-center gap-sm',
                                'rounded-md py-sm',
                                'text-left font-general text-xs font-normal 2xl:text-sm',
                                checkCurrentPage(currentPage === item.type),
                            )}
                        >
                            {item.icon}
                            {item.text}
                        </Button>
                    );
                }
                return null;
            });
        }
        return null;
    };

    return (
        <Container
            variant={EContainerVariant.glassBorder}
            className={cn(
                'fixed inset-y-0 left-3',
                'flex flex-col',
                'm-md gap-md p-md',
                'h-menu w-menu',
                'rounded-menu',
            )}
        >
            <div className='flex items-center justify-evenly'>
                <SVG src={icons.FinelinesLogo} width={40} height={40} />
                <Text className='font-general-medium'>
                    Finelines
                </Text>
            </div>
            <div className='h-1 w-full border-b-2 border-white' />
            <div>
                <Button
                    variant={EButtonVariant.ghost}
                    onClick={() => handleMenuClick(EMenuList.home, EMenuList.home)}
                    className={cn(
                        'w-full',
                        'flex items-center gap-sm',
                        'rounded-md py-1 2xl:py-sm',
                        'font-general text-xs 2xl:text-sm',
                        checkCurrentPage(currentPage === EMenuList.home),
                    )}
                >
                    <AiOutlineHome size={20} />
                    Home
                </Button>
            </div>
            <div className='flex flex-col gap-1 2xl:gap-2'>
                <Text
                    variant={ETextVariant.subheader}
                    className='font-bold'
                >
                    {renderReports()?.every((item) => item === null) ? null : 'Reports'}
                </Text>
                {renderReports()}
            </div>
            <div className='flex flex-col'>
                <Text
                    variant={ETextVariant.subheader}
                    className='font-bold'
                >
                    {renderSettings()?.every((item) => item === null) ? null : 'Settings'}
                </Text>
                {renderSettings()}
            </div>
            <div className='absolute inset-x-0 bottom-[70px] flex justify-center px-sm py-2'>
                <UserProfile
                    name={userInfo?.email ?? ''}
                    position={EAuthRole[userInfo?.role ?? '1']}
                />
            </div>
            <div className='absolute bottom-[60px] left-0 h-1 w-full border-b-2 border-white' />
            <Button
                variant={EButtonVariant.ghost}
                onClick={() => logout()}
                className={cn(
                    'absolute bottom-3',
                    'flex items-center justify-between',
                    'w-4/5 rounded-md py-sm',
                    'font-general text-sm',
                )}
            >
                <TbLogout2 size={25} />
                Log Out
            </Button>
        </Container>
    );
};

const mapStateToProps = (state: RootState) => ({
    userInfo: Selectors.authGetUserInfo(state),
    currentPage: Selectors.uiGetCurrentPage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    uiSetCurrentPage: (page: EMenuList) => dispatch(Actions.uiSetCurrentPage(page)),
    logout: () => dispatch(Actions.authLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
