import { createSlice } from '@reduxjs/toolkit';

import { EmptyIndexedResponse } from 'entities/report';

import {
    CreatePaymentParamsPayLoad,
    GetPaymentReportDetailsParamsPayLoad,
    GetPaymentReportDetailsResponsePayLoad,
    GetPaymentReportListingParamsPayload,
    GetPaymentReportListingResponsePayload,
    GetPaymentSelectorParamsPayload,
    GetPaymentSelectorResponsePayload,
    GetTotalPaymentReportParamsPayload,
    GetTotalPaymentReportResponsePayload,
    PaymentReportReduxState,
    StringOrNullPayload,
} from './types';

const initialState: PaymentReportReduxState = {
    actions: {
        getTotalPaymentReport: false,
        getPaymentReportListing: false,
        getPaymentSelector: false,
        getPaymentReportDetails: false,
        createPayment: false,
    },
    totalPaymentReport: {
        commissionMade: 0,
        paymentsPending: 0,
        paymentsCompleted: 0,
    },
    paymentReportListing: EmptyIndexedResponse,
    paymentSelector: [],
    paymentReportDetails: [],
    error: {
        getTotalPaymentReport: '',
        getPaymentReportListing: '',
        getPaymentSelector: '',
        getPaymentReportDetails: '',
        createPayment: '',
    },
};

const paymentReportSlice = createSlice({
    name: 'paymentReport',
    initialState,
    reducers: {
        paymentReportGetTotalPaymentReportAttempt: (state, _action: GetTotalPaymentReportParamsPayload) => {
            state.actions.getTotalPaymentReport = true;
            state.error.getTotalPaymentReport = '';
        },
        paymentReportGetTotalPaymentReportSuccess: (state, action: GetTotalPaymentReportResponsePayload) => {
            state.actions.getTotalPaymentReport = false;
            if (action.payload) {
                state.totalPaymentReport = action.payload;
            }
        },
        paymentReportGetTotalPaymentReportFailure: (state, action: StringOrNullPayload) => {
            state.actions.getTotalPaymentReport = false;
            if (action.payload) {
                state.error.getTotalPaymentReport = action.payload;
            }
        },
        paymentReportGetPaymentReportListingAttempt: (state, _action: GetPaymentReportListingParamsPayload) => {
            state.actions.getPaymentReportListing = true;
            state.error.getPaymentReportListing = '';
        },
        paymentReportGetPaymentReportListingSuccess: (state, action: GetPaymentReportListingResponsePayload) => {
            state.actions.getPaymentReportListing = false;
            if (action.payload) {
                state.paymentReportListing = action.payload;
            }
        },
        paymentReportGetPaymentReportListingFailure: (state, action: StringOrNullPayload) => {
            state.actions.getPaymentReportListing = false;
            if (action.payload) {
                state.error.getPaymentReportListing = action.payload;
            }
        },
        paymentReportGetPaymentSelectorAttempt: (state, _action: GetPaymentSelectorParamsPayload) => {
            state.actions.getPaymentSelector = true;
            state.error.getPaymentSelector = '';
        },
        paymentReportGetPaymentSelectorSuccess: (state, action: GetPaymentSelectorResponsePayload) => {
            state.actions.getPaymentSelector = false;
            if (action.payload) {
                state.paymentSelector = action.payload;
            }
        },
        paymentReportGetPaymentSelectorFailure: (state, action: StringOrNullPayload) => {
            state.actions.getPaymentSelector = false;
            if (action.payload) {
                state.error.getPaymentSelector = action.payload;
            }
        },
        paymentReportGetPaymentReportDetailsAttempt: (state, _action: GetPaymentReportDetailsParamsPayLoad) => {
            state.actions.getPaymentReportDetails = true;
            state.error.getPaymentReportDetails = '';
        },
        paymentReportGetPaymentReportDetailsSuccess: (state, action: GetPaymentReportDetailsResponsePayLoad) => {
            state.actions.getPaymentReportDetails = false;
            if (action.payload) {
                state.paymentReportDetails = action.payload;
            }
        },
        paymentReportGetPaymentReportDetailsFailure: (state, action: StringOrNullPayload) => {
            state.actions.getPaymentReportDetails = false;
            if (action.payload) {
                state.error.getPaymentReportDetails = action.payload;
            }
        },
        paymentReportCreatePaymentAttempt: (state, _action: CreatePaymentParamsPayLoad) => {
            state.actions.createPayment = true;
            state.error.createPayment = '';
        },
        paymentReportCreatePaymentSuccess: (state) => {
            state.actions.createPayment = false;
        },
        paymentReportCreatePaymentFailure: (state, action: StringOrNullPayload) => {
            state.actions.createPayment = false;
            if (action.payload) {
                state.error.createPayment = action.payload;
            }
        },
    },
});

export type PaymentReportState = typeof initialState;

export default {
    actions: paymentReportSlice.actions,
    reducers: paymentReportSlice.reducer,
};
