import { fork } from 'redux-saga/effects';

import config from 'config';

import api from 'api';
import mocksApi from 'api/mocks';

import auth from './auth';
import dashboard from './dashboard';
import moderationReport from './moderationReport';
import paymentReport from './paymentReport';
import postReport from './postReport';
import purchaseReport from './purchaseReport';
import subAdmin from './subAdmin';
import systemConfiguration from './systemConfiguration';
import topicReport from './topicReport';
import { SagaForkReturnType } from './types';
import userReport from './userReport';

const apiInstance = api;

if (config.useMockApi) {
    // apiInstance = mocksApi;
}

export default function* root(): SagaForkReturnType {
    yield fork(auth(apiInstance.auth).rootSaga);
    yield fork(dashboard(apiInstance.dashboard).rootSaga);
    yield fork(userReport(apiInstance.userReport).rootSaga);
    yield fork(postReport(apiInstance.postReport).rootSaga);
    yield fork(topicReport(apiInstance.topicReport).rootSaga);
    yield fork(purchaseReport(apiInstance.purchaseReport).rootSaga);
    yield fork(paymentReport(apiInstance.paymentReport).rootSaga);
    yield fork(moderationReport(apiInstance.moderationReport).rootSaga);
    yield fork(systemConfiguration(apiInstance.systemConfiguration).rootSaga);
    yield fork(subAdmin(apiInstance.subAdmin).rootSaga);
}
