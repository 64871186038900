import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import {
    ForgotPasswordParam,
    IAuthGateway,
    LoginParams,
    LoginResponse,
    RefreshParam,
    ResetPasswordParams,
    VerifyOtpParams,
} from './AuthBase';

export default class AuthGateway extends IAuthGateway {
    async login(params: LoginParams): GatewayResponse<LoginResponse> {
        const response: ApiResponse<LoginResponse> = await this.api.post('/v1/a/auth/login', params);

        return this.process(response);
    }

    async refresh(params: RefreshParam): GatewayResponse<LoginResponse> {
        const response: ApiResponse<LoginResponse> = await this.api.post('/v1/a/auth/refresh', params);

        return this.process(response);
    }

    async forgotPassword(params: ForgotPasswordParam): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.post('/v1/a/auth/forgotPassword', params);

        return this.process(response);
    }

    async verifyOtp(params: VerifyOtpParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.post('/v1/a/auth/verifyOtp', params);

        return this.process(response);
    }

    async resetPassword(params: ResetPasswordParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.post('/v1/a/auth/resetPassword', params);
        return this.process(response);
    }
}
