import Gateway from 'api/types/Gateway';

import { TPaymentHistory, TPaymentListingDetails, TPaymentReportDetails } from 'entities/report';

import { GatewayResponse } from './types/types';

export type GenericParams<T> = {
    authToken: string;
    params: T;
};
export type GenericIndexedResponse<T> = {
    index: number;
    maxIndex: number;
    data: T[];
};
export type GenericResponse = string | undefined;

// ! Params
export type DateParams = {
    dateFrom: string;
    dateTo: string;
};

export type GetPaymentReportListingParams = {
    index: number;
    filter?: number;
    searchQuery?: string;
};

export type GetPaymentSelectorParams = {
    userId: string;
    dateFrom: string;
    dateTo: string;
};

export type GetPaymentReportDetailsParams = {
    userId: string;
    dateFrom: string;
    dateTo: string;
};

export type CreatePaymentParams = {
    refetch: { userId: string; dateFrom: string; dateTo: string };
    userId: string;
    title: string;
    date: string;
    amount: number;
    type: number;
    paymentId: string;
};

// ! Response
export type GetTotalPaymentReportResponse = {
    commissionMade: number;
    paymentsPending: number;
    paymentsCompleted: number;
};

export type GetPaymentReportListingResponse = TPaymentListingDetails;

export type GetPaymentSelectorResponse = {
    _id: string;
    type: number;
    status: number;
    title: string;
}[];

export type GetPaymentReportDetailsResponse = TPaymentReportDetails[];

export abstract class IPaymentReportGategory extends Gateway {
    abstract getTotalPaymentReport(params: GenericParams<DateParams>): GatewayResponse<GetTotalPaymentReportResponse>;

    abstract getPaymentReportListing(
        params: GenericParams<GetPaymentReportListingParams>,
    ): GatewayResponse<GenericIndexedResponse<GetPaymentReportListingResponse>>;

    abstract getPaymentSelector(
        params: GenericParams<GetPaymentSelectorParams>,
    ): GatewayResponse<GetPaymentSelectorResponse>;

    abstract getPaymentReportDetails(
        params: GenericParams<GetPaymentReportDetailsParams>,
    ): GatewayResponse<GetPaymentReportDetailsResponse>;

    abstract createPayment(params: GenericParams<CreatePaymentParams>): GatewayResponse<GenericResponse>;
}
