import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PaymentReportGateway from 'api/PaymentReport';
import PostReportGateway from 'api/PostReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { GetTotalPaymentReportParamsPayload } from 'redux/slices/paymentReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetTotalPaymentReport(api: PaymentReportGateway): SagaWatcherReturnType {
    yield takeEvery('paymentReport/paymentReportGetTotalPaymentReportAttempt', handleGetTotalPaymentReport, api);
}

function* handleGetTotalPaymentReport(api: PaymentReportGateway, data: GetTotalPaymentReportParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.getTotalPaymentReport], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.paymentReportGetTotalPaymentReportFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.paymentReportGetTotalPaymentReportSuccess(response.data));
    }
}
