import React, { useState } from 'react';

import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Select from 'components/atoms/Select';
import Text from 'components/atoms/Text';
import CustomDatePicker from 'components/molecules/CustomDatePicker';

import { CreatePaymentParams, GetPaymentSelectorResponse } from 'api/PaymentReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import cn from 'lib/TailwindMerge';
import {
    EButtonVariant,
    EContainerVariant,
    ESelectVariant,
    ETextVariant,
    TDateFilterString,
} from 'entities/components';

type MakePaymentCardProps = {
    createPaymentAttempting: boolean;
    createPaymentError: string;
    createPayment: (params: CreatePaymentParams) => void;

    userId: string;
    paymentSelector: GetPaymentSelectorResponse;
    dateFilter: TDateFilterString;

    loading: boolean;
    error: string;
};

const MakePaymentCard = (props: MakePaymentCardProps): JSX.Element => {
    const {
        createPaymentAttempting,
        createPaymentError,
        createPayment,
        userId,
        paymentSelector,
        dateFilter,
        loading,
        error,
    } = props;
    const [selectedPaymentId, setSelectedPaymentId] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [amount, setAmount] = useState<string>();
    const [tempPaymentTitle, setTempPaymentTitle] = useState<string>('');
    const [paymentType, setPaymentType] = useState<number>(1);

    const handleValueChange = (value: number | string) => {
        setSelectedPaymentId(value as string);
    };

    const renderAmountChoices = () => {
        const amountList = [50, 100, 200, 500, 1000];
        return amountList.map((choice) => (
            <Button
                variant={EButtonVariant.ghost}
                key={choice}
                className={cn('flex w-fit', 'gap-sm rounded-3xl', 'border-[1px] border-slate-400', 'px-3 py-1')}
                onClick={() => setAmount(choice.toString())}
            >
                <Text variant={ETextVariant.default} key={choice} className='text-slate-400'>
                    {`SGD ${choice}`}
                </Text>
            </Button>
        ));
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(e.target.value);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTempPaymentTitle(e.target.value);
    };

    const handleCreatePayment = () => {
        const selectedPayment = paymentSelector.find((payment) => payment._id === selectedPaymentId);

        if (tempPaymentTitle && amount && selectedDate && (paymentType === 1 || (paymentType === 2 && selectedPayment))) {
            createPayment({
                refetch: {
                    userId,
                    dateFrom: dayjs(dateFilter[0]).startOf('day').utc().format(),
                    dateTo: dayjs(dateFilter[1]).endOf('day').utc().format(),
                },
                amount: Number(amount),
                date: selectedDate.toISOString(),
                paymentId: paymentType === 2 && selectedPayment ? selectedPayment._id : '',
                title: tempPaymentTitle,
                type: paymentType,
                userId,
            });
        } else {
            toast.error('Please make sure details are provided to create payment');
        }
    };

    if (error) {
        return (
            <div className='flex size-full items-center justify-center'>
                <Text variant={ETextVariant.error}>{error}</Text>
            </div>
        );
    }

    if (loading) {
        return (
            <div className='flex size-full items-center justify-center'>
                <RotatingLines strokeColor='white' strokeWidth='5' animationDuration='0.75' width='100' visible />
            </div>
        );
    }

    return (
        <Container variant={EContainerVariant.glass} className='flex items-center justify-between p-default'>
            <div className='flex size-full flex-col gap-[24px]'>
                <Text variant={ETextVariant.default} className='font-heading text-base 2xl:text-[20px] 3xl:text-[24px]'>
                    MAKE PAYMENT FOR
                </Text>
                <div className='flex flex-row justify-around'>
                    <Button
                        variant={EButtonVariant.ghost}
                        className={cn(
                            'flex w-fit',
                            'gap-sm rounded-md',
                            'border-[1px]',
                            paymentType === 2 && 'border-slate-400',
                            'px-3 py-2',
                        )}
                        onClick={() => setPaymentType(1)}
                    >
                        <Text
                            variant={ETextVariant.default}
                            className={cn('text-base 2xl:text-[18px]', paymentType === 2 && 'text-slate-400')}
                        >
                            Main Payment
                        </Text>
                    </Button>
                    <Button
                        variant={EButtonVariant.ghost}
                        className={cn(
                            'flex w-fit',
                            'gap-sm rounded-md',
                            'border-[1px]',
                            paymentType === 1 && 'border-slate-400',
                            'px-3 py-2',
                            paymentSelector.length === 0 && '!bg-slate-600 border-slate-600',
                        )}
                        onClick={() => paymentSelector.length !== 0 && setPaymentType(2)}
                    >
                        <Text
                            variant={ETextVariant.default}
                            className={cn('text-base 2xl:text-[18px]', paymentType === 1 && 'text-slate-400')}
                        >
                            Sub Payment
                        </Text>
                    </Button>
                </div>
                {paymentType === 2 && (
                    <Select
                        variant={ESelectVariant.default}
                        options={paymentSelector.map((selector) => ({
                            label: selector.title,
                            value: selector._id,
                        }))}
                        onValueChange={handleValueChange}
                        className='!rounded-md !border-0 !border-b'
                        selectedClassName='font-general-bold text-base 2xl:text-[28px]'
                        dropdownClassName='bg-secondary'
                    />
                )}
                <Text variant={ETextVariant.default} className='font-heading text-base 2xl:text-[20px] 3xl:text-[24px]'>
                    PAYMENT TITLE
                </Text>
                <input
                    name='amount'
                    type='text'
                    className={cn(
                        'bg-transparent p-0 w-full focus:outline-none text-sm text-white border-b',
                        'font-general-bold text-base 2xl:text-[28px]',
                    )}
                    placeholder='Title'
                    value={tempPaymentTitle}
                    onChange={handleTitleChange}
                />
                <Text variant={ETextVariant.default} className='font-heading text-base 2xl:text-[20px] 3xl:text-[24px]'>
                    SCHEDULE PAYMENT DATE
                </Text>
                <CustomDatePicker selectedDate={selectedDate} onChange={(date) => setSelectedDate(date)} />
                <Text variant={ETextVariant.default} className='font-heading text-base 2xl:text-[20px] 3xl:text-[24px]'>
                    ENTER PAYMENT AMOUNT
                </Text>
                <input
                    name='amount'
                    type='number'
                    step='0.01'
                    className={cn(
                        'bg-transparent p-0 w-full focus:outline-none text-sm text-white border-b',
                        'font-general-bold text-base 2xl:text-[28px]',
                    )}
                    placeholder='SGD'
                    value={amount}
                    onChange={handleAmountChange}
                />
                <div className='flex flex-wrap gap-sm py-sm'>{renderAmountChoices()}</div>
                <Button variant={EButtonVariant.default} className='py-4' onClick={() => handleCreatePayment()}>
                    Confirm
                </Button>
            </div>
        </Container>
    );
};

MakePaymentCard.defaultProps = {};

const mapStateToProps = (state: RootState) => ({
    createPaymentAttempting: Selectors.paymentReportCreatePaymentAttempting(state),
    createPaymentError: Selectors.paymentReportCreatePaymentError(state),
    dateFilter: Selectors.uiGetPaymentDetailsDateFilter(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createPayment: (params: CreatePaymentParams) => dispatch(Actions.paymentReportCreatePaymentAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MakePaymentCard);
