import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';

import {
    CreatePaymentParams,
    DateParams,
    GenericIndexedResponse,
    GenericParams,
    GenericResponse,
    GetPaymentReportDetailsParams,
    GetPaymentReportDetailsResponse,
    GetPaymentReportListingParams,
    GetPaymentReportListingResponse,
    GetPaymentSelectorParams,
    GetPaymentSelectorResponse,
    GetTotalPaymentReportResponse,
    IPaymentReportGategory,
} from './PaymentReportBase';

export default class PaymentReportGateway extends IPaymentReportGategory {
    async getTotalPaymentReport(params: GenericParams<DateParams>): GatewayResponse<GetTotalPaymentReportResponse> {
        const response: ApiResponse<GetTotalPaymentReportResponse> = await this.api.get(
            '/v1/a/paymentReport/totalPaymentReport',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getPaymentReportListing(
        params: GenericParams<GetPaymentReportListingParams>,
    ): GatewayResponse<GenericIndexedResponse<GetPaymentReportListingResponse>> {
        const response: ApiResponse<GenericIndexedResponse<GetPaymentReportListingResponse>> = await this.api.get(
            '/v1/a/paymentReport/paymentReportListing',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getPaymentSelector(
        params: GenericParams<GetPaymentSelectorParams>,
    ): GatewayResponse<GetPaymentSelectorResponse> {
        const response: ApiResponse<GetPaymentSelectorResponse> = await this.api.get(
            '/v1/a/paymentReport/paymentSelector',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getPaymentReportDetails(
        params: GenericParams<GetPaymentReportDetailsParams>,
    ): GatewayResponse<GetPaymentReportDetailsResponse> {
        const { userId, ...restOfParams } = params.params;

        const response: ApiResponse<GetPaymentReportDetailsResponse> = await this.api.get(
            `/v1/a/paymentReport/paymentReportDetails/${userId}`,
            restOfParams,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async createPayment(params: GenericParams<CreatePaymentParams>): GatewayResponse<GenericResponse> {
        const { refetch, ...otherParams } = params.params;
        const response: ApiResponse<GenericResponse> = await this.api.post(
            '/v1/a/paymentReport/createPayment',
            otherParams,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }
}
