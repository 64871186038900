import React from 'react';

import { RotatingLines } from 'react-loader-spinner';

import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';
import UserProfileImage from 'components/molecules/UserProfileImage';

import { GetUserSummaryResponse } from 'api/UserReportBase';

import { renderUserTier } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { EContainerVariant, ETextVariant } from 'entities/components';
import { EUserStatus } from 'entities/report';

type UserSummaryCardProps = {
    userSummary: GetUserSummaryResponse;
    loading?: boolean;
    error?: string;
};

const UserSummaryCard = (props: UserSummaryCardProps): JSX.Element => {
    const { userSummary, loading, error } = props;

    const {
        username,
        displayName,
        followersCount,
        followingCount,
        repRanking,
        topics,
        userTier,
        bankName,
        bankAccName,
        bankAccNo,
    } = userSummary;

    const renderTopics = () => {
        if (!topics || topics.length === 0) {
            return (
                <Text
                    variant={ETextVariant.subheader}
                    className=''
                >
                    User have not subscribed to any topic.
                </Text>
            );
        }
        return topics.map((topic) => (
            <div
                key={topic}
                className={cn(
                    'flex w-fit',
                    'gap-sm rounded-3xl',
                    'border-[1px] border-white',
                    'px-3 py-1',
                )}
            >
                <Text
                    variant={ETextVariant.default}
                    key={topic}
                >
                    {topic}
                </Text>
            </div>
        ));
    };

    const renderBannedBadge = () => {
        if (userSummary.userStatus === EUserStatus.SuspendedUser) {
            return (
                <div
                    className={cn(
                        'absolute left-[50%] translate-x-[-50%] -top-5',
                        'bg-error rounded-input',
                        'py-2 px-6',
                    )}
                >
                    <Text className='font-general-bold'>
                        BANNED
                    </Text>
                </div>
            );
        }

        return null;
    };

    if (loading || error) {
        return (
            <Container
                variant={EContainerVariant.glassBorder}
                className={cn(
                    'w-full',
                    'flex items-center justify-center',
                    'rounded-input p-default',
                )}
            >
                {
                    loading ? (
                        <RotatingLines
                            strokeColor='white'
                            strokeWidth='5'
                            animationDuration='0.75'
                            width='100'
                            visible
                        />
                    ) : (
                        <Text variant={ETextVariant.error}>
                            {error}
                        </Text>
                    )
                }
            </Container>
        );
    }

    return (
        <Container
            variant={EContainerVariant.glassBorder}
            className={cn(
                'w-full relative',
                'flex items-center justify-center',
                'rounded-default',
                'p-md 3xl:p-default',
                userSummary.userStatus === EUserStatus.SuspendedUser && 'border-4 border-error',
            )}
        >
            {renderBannedBadge()}
            <div className={cn(
                'w-[60%] 2xl:w-[70%]',
                'flex items-center justify-between',
                'gap-sm 2xl:gap-default',
                'p-sm 2xl:p-md',
            )}
            >
                <div className='flex gap-md 3xl:gap-default'>
                    <div className='size-[100px] h-full 2xl:size-[140px] 3xl:size-[180px]'>
                        <UserProfileImage
                            userTier={userSummary.userTier}
                            profileImageUrl={userSummary.profileImageUrl}
                        />
                    </div>
                    <div className='flex flex-col items-center justify-between gap-md 3xl:py-md'>
                        <div className='flex flex-col gap-sm self-start'>
                            <Text
                                variant={ETextVariant.default}
                                className='font-general-bold text-3xl 2xl:text-[2rem] 3xl:text-[3rem]'
                            >
                                {username}
                            </Text>
                            <Text
                                variant={ETextVariant.default}
                                className='font-general-medium text-base 3xl:text-xl'
                            >
                                {displayName}
                            </Text>
                        </div>
                        <div className='flex justify-between gap-sm self-start 3xl:gap-default'>
                            <Text
                                variant={ETextVariant.header}
                                className='text-base 3xl:text-2xl'
                            >
                                {`${renderUserTier(userTier).toUpperCase()} TIER`}
                            </Text>
                            <Text
                                variant={ETextVariant.header}
                                className='text-base 3xl:text-2xl'
                            >
                                {followersCount ? `${followersCount} FOLLOWERS` : '0 FOLLOWERS'}
                            </Text>
                            <Text
                                variant={ETextVariant.header}
                                className='text-base 3xl:text-2xl'
                            >
                                {followingCount ? `${followingCount} FOLLOWING` : '0 FOLLOWING'}
                            </Text>
                        </div>
                        {
                            (bankName && bankAccNo && bankAccName) && (
                                <div className='flex justify-between gap-md self-start 3xl:gap-default'>
                                    <div>
                                        <Text
                                            variant={ETextVariant.subheader}
                                            className='font-general-medium text-base 3xl:text-lg'
                                        >
                                            Bank Name
                                        </Text>
                                        <Text
                                            variant={ETextVariant.subheader}
                                            className='text-base 3xl:text-lg'
                                        >
                                            {bankName}
                                        </Text>
                                    </div>
                                    <div>
                                        <Text
                                            variant={ETextVariant.subheader}
                                            className='font-general-medium text-base 3xl:text-lg'
                                        >
                                            Bank Account Number
                                        </Text>
                                        <Text
                                            variant={ETextVariant.subheader}
                                            className='text-base 3xl:text-lg'
                                        >
                                            {bankAccNo}
                                        </Text>
                                    </div>
                                    <div>
                                        <Text
                                            variant={ETextVariant.subheader}
                                            className='font-general-medium text-base 3xl:text-lg'
                                        >
                                            Bank Account Name
                                        </Text>
                                        <Text
                                            variant={ETextVariant.subheader}
                                            className='text-base 3xl:text-lg'
                                        >
                                            {bankAccName}
                                        </Text>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

                <div className='min-w-[20%]'>
                    <Text>Rep Ranking</Text>
                    <Text
                        variant={ETextVariant.header}
                        className='m-0 font-heading-bold text-[50px] 3xl:text-[80px]'
                    >
                        {repRanking}
                    </Text>
                </div>
            </div>
            <div className='h-4/5 border-l-[1px] border-white' />
            <div className='flex h-full w-[30%] flex-col p-sm px-md 2xl:p-md 3xl:w-[30%]'>
                <Text
                    variant={ETextVariant.header}
                    className='text-base 3xl:text-2xl'
                >
                    TOPIC SUBSCRIBED
                </Text>
                <div className='flex flex-wrap gap-sm py-sm'>
                    {renderTopics()}
                </div>
            </div>
        </Container>
    );
};
UserSummaryCard.defaultProps = {
    loading: false,
    error: '',
};
export default UserSummaryCard;
