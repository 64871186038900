import React from 'react';

import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Modal from 'components/molecules/Modal';

import { DeleteUserPostParams } from 'api/PostReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import cn from 'lib/TailwindMerge';
import { EButtonVariant, ETextVariant } from 'entities/components';

type DeletePostModalProps = {
    deleteUserPostAttempting: boolean,
    modalIsOpen: boolean,
    setModalIsOpen: (state: boolean) => void,
    deleteUserPost: (params: DeleteUserPostParams) => void,

    postId:string;
}

const DeletePostModal = (props: DeletePostModalProps):JSX.Element => {
    const {
        deleteUserPostAttempting,
        modalIsOpen,
        setModalIsOpen,
        deleteUserPost,
        postId,
    } = props;

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
        >
            <div className={cn(
                'w-[450px] flex flex-col',
                'items-center justify-center',
                'gap-default p-xl',
            )}
            >
                <Text
                    variant={ETextVariant.header}
                    className='font-heading-bold text-button-primary 2xl:text-[60px]'
                >
                    ARE YOU SURE?
                </Text>
                <Text className='text-center'>
                    Confirm deleting user post.
                </Text>
                <Button
                    variant={EButtonVariant.default}
                    className='mt-default py-md'
                    loading={deleteUserPostAttempting}
                    onClick={() => {
                        deleteUserPost({ id: postId });
                    }}
                >
                    Confirm
                </Button>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    deleteUserPostAttempting: Selectors.postReportDeleteUserPostAttempting(state),
    modalIsOpen: Selectors.uiDeleteUserPostModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) => dispatch(Actions.uiSetDeleteUserPostModalIsOpen(state)),
    deleteUserPost: (params: DeleteUserPostParams) => dispatch(Actions.postReportDeleteUserPostAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeletePostModal);
