import React from 'react';

import { connect } from 'react-redux';

import Paginator from 'components/molecules/Paginator';

import { GenericIndexedResponse, GetPostModerationResponse } from 'api/PostReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import PostModerationReviewModal from '../components/postDetails/PostModerationReviewModal';
import PostReportsTable from '../components/postDetails/PostReportsTables';

type PostViewReportTabProps = {
    setPostModerationReviewAttempting: boolean;
    setPostModerationReviewError: string;

    setPostModerationReviewModalIsOpen: (state: boolean) => void;

    postModeration: GenericIndexedResponse<GetPostModerationResponse>;

    page: number;
    setPage: (page: number) => void;

    loading?: boolean;
    error?: string;
};

const PostViewReportTab = (props: PostViewReportTabProps): JSX.Element => {
    const {
        setPostModerationReviewAttempting,
        setPostModerationReviewError,
        setPostModerationReviewModalIsOpen,
        postModeration,
        page,
        setPage,
        loading,
        error,
    } = props;

    return (
        <div>
            <PostReportsTable
                postModerationData={postModeration.data}
                loading={loading}
            />
            <Paginator
                currentPage={page}
                maxPage={(postModeration && postModeration.maxIndex !== 0 && postModeration.maxIndex) || 0}
                onFirst={() => page !== 0 && setPage(0)}
                onLast={() => postModeration.maxIndex !== 0 && setPage((postModeration && postModeration.maxIndex) || 0)}
                onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                onNext={() => postModeration.maxIndex !== 0 && setPage(page !== postModeration.maxIndex ? page + 1 : page)}
            />
        </div>
    );
};

PostViewReportTab.defaultProps = {
    loading: false,
    error: '',
};

const mapStateToProps = (state: RootState) => ({
    setPostModerationReviewAttempting:
        Selectors.postReportSetPostModerationReviewAttempting(state),
    setPostModerationReviewError:
        Selectors.postReportSetPostModerationReviewError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setPostModerationReviewModalIsOpen: (state: boolean) =>
        dispatch(Actions.uiSetPostModerationReviewModalIsOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostViewReportTab);
