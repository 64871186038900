import React, { useState } from 'react';

import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';

import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';
import CustomAreaChart from 'components/molecules/CustomAreaChart';
import TabMenu from 'components/molecules/TabMenu';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { tabTotalUserType } from 'lib/Constant';
import cn from 'lib/TailwindMerge';
import { EContainerVariant, ETextVariant, TChartData } from 'entities/components';
import { ETotalUserType } from 'entities/ui';

type TotalUserCardProps = {
    currentTab: ETotalUserType;
    setCurrentTab: (currentTab: ETotalUserType) => void;

    totalUsers: number;
    newUsers: number;
    activeUsers: number;
    chartData: TChartData[];
    loading?: boolean;
    error?: string;
};

const TotalUserCard = (props: TotalUserCardProps): JSX.Element => {
    const {
        currentTab,
        setCurrentTab,

        totalUsers,
        newUsers,
        activeUsers,
        chartData,
        loading,
        error,
    } = props;

    const totalUserTypeTab = () => {
        return tabTotalUserType.map((tab) => {
            return {
                text: tab.text,
                type: tab.type,
                onClick: () => {
                    setCurrentTab(tab.type);
                },
            };
        });
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className='flex size-full items-center justify-center'>
                    <RotatingLines
                        strokeColor='white'
                        strokeWidth='5'
                        animationDuration='0.75'
                        width='100'
                        visible
                    />
                </div>
            );
        }

        if (error) {
            return (
                <div className='flex size-full items-center justify-center'>
                    <Text variant={ETextVariant.error}>
                        {error}
                    </Text>
                </div>
            );
        }

        return (
            <>
                <div className='flex flex-col items-center'>
                    <Text
                        variant={ETextVariant.header}
                        className='font-heading-bold text-[5rem]'
                    >
                        {totalUsers}
                    </Text>
                    <Text variant={ETextVariant.subheader}>
                        Total Users
                    </Text>
                </div>
                <div className='flex justify-evenly'>
                    <TabMenu
                        menuData={totalUserTypeTab()}
                        selected={currentTab}
                        buttonClassName='px-md w-[120px]'
                    />
                </div>
                <div className='flex items-end gap-sm'>
                    <Text variant={ETextVariant.header}>
                        {currentTab === ETotalUserType.newUser ? newUsers : activeUsers}
                    </Text>
                    <Text variant={ETextVariant.subheader}>
                        {currentTab === ETotalUserType.newUser ? 'New Users' : 'Active Users'}
                    </Text>
                </div>
                <div className='size-full'>
                    <CustomAreaChart
                        data={chartData}
                        type={currentTab === ETotalUserType.newUser ? 'New Users' : 'Active Users'}
                    />
                </div>
            </>
        );
    };

    return (
        <Container
            variant={EContainerVariant.glassBorder}
            className={cn(
                'flex flex-col',
                'h-[500px]',
                'gap-md px-default py-md',
                'rounded-2xl',
                'overflow-hidden',
            )}
        >
            {renderContent()}
        </Container>
    );
};

TotalUserCard.defaultProps = {
    loading: false,
    error: '',
};

const mapStateToProps = (state: RootState) => ({
    currentTab: Selectors.uiGetCurrentTotalUserType(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setCurrentTab: (tab: ETotalUserType) => dispatch(Actions.uiSetCurrentTotalUserType(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalUserCard);
