import React from 'react';

import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';

import { EContainerVariant, ETextVariant } from 'entities/components';

type StatusCardProps = {
    value: number,
    label: string,
    icon: React.ReactNode,
}

const StatusCard = (props: StatusCardProps): JSX.Element => {
    const {
        value,
        label,
        icon,
    } = props;

    return (
        <Container
            variant={EContainerVariant.gradientBorder}
            className='p-default'
        >
            <div className='relative flex h-full items-end gap-sm'>
                <Text
                    variant={ETextVariant.header}
                    className='font-heading-bold text-[80px]'
                >
                    {value}
                </Text>
                <Text className='mb-1'>
                    {label}
                </Text>
                <div className='absolute right-0 top-0'>
                    {icon}
                </div>
            </div>
        </Container>
    );
};

export default StatusCard;
