import Gateway from 'api/types/Gateway';

import { TPurchaseDetails } from 'entities/report';

import { GatewayResponse } from './types/types';

export type GenericParams<T> = {
    authToken: string;
    params: T;
};
export type GenericIndexedResponse<T> = {
    index: number;
    maxIndex: number;
    data: T[];
};

// ! Params
export type DateParams = {
    dateFrom: string;
    dateTo: string;
};
export type GetPurchaseReportParams = {
    index: number;
    searchQuery?: string;
    dateFrom: string;
    dateTo: string;
};

// ! Response
export type GetTotalPurchasesGraphResponse = {
    totalPurchases: {
        amount: number;
        data: {
            amount: number;
            date: string;
        }[];
    };
};

export type GetPurchaseReportResponse = {
    index: number;
    maxIndex: number;
    data: TPurchaseDetails[];
};

export abstract class IPurchaseReportGateway extends Gateway {
    abstract getTotalPurchasesGraph(
        params: GenericParams<DateParams>,
    ): GatewayResponse<GetTotalPurchasesGraphResponse>;

    abstract getPurchaseReport(
        params: GenericParams<GetPurchaseReportParams>,
    ): GatewayResponse<GetPurchaseReportResponse>;
}
