import React, { useState } from 'react';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import Text from 'components/atoms/Text';
import Modal from 'components/molecules/Modal';

import { SetUserToBanParams } from 'api/UserReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import cn from 'lib/TailwindMerge';
import { EButtonVariant, ETextVariant } from 'entities/components';
import { EModerationCategory } from 'entities/report';
import { BannedCategoriesLOV } from 'lov/report';

type UserBanModalProps = {
    modalIsOpen: boolean;
    setModalIsOpen: (state: boolean) => void;
    setUserToBan: (params: SetUserToBanParams) => void;

    userId: string;
}

const UserBanModal = (props: UserBanModalProps): JSX.Element => {
    const {
        modalIsOpen,
        setModalIsOpen,
        setUserToBan,
        userId,
    } = props;

    const [selectedCategory, setSelectedCategory] = useState<EModerationCategory>(1);
    const [remarks, setRemarks] = useState<string>('');

    const handleBan = () => {
        setUserToBan({
            userId,
            category: selectedCategory,
            remarks,
        });
    };

    const handleValueChange = (value: number | string) => {
        setSelectedCategory(value as EModerationCategory);
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
        >
            <div className={cn(
                'w-[450px] flex flex-col',
                'items-center justify-center',
                'gap-default p-xl',
            )}
            >
                <Text
                    variant={ETextVariant.header}
                    className='font-heading-bold text-button-primary 2xl:text-[60px]'
                >
                    ARE YOU SURE?
                </Text>
                <Text className='text-center'>
                    Lorem ipsum dolor sit amet,
                    consetetur sadipscing elitr,
                    sed diam nonumy eirmod tempor invidunt
                    ut
                </Text>
                <Select
                    options={BannedCategoriesLOV}
                    defaultOption={BannedCategoriesLOV[0]}
                    onValueChange={handleValueChange}
                />
                <textarea
                    placeholder='Remarks'
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                    rows={5}
                    className={cn(
                        'w-full',
                        'rounded-input outline-none',
                        'bg-transparent',
                        'border-2 border-white',
                        'resize-none p-2.5',
                    )}
                />
                <Text variant={ETextVariant.caption}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing
                    elitr, sed diam nonumy eirmod tempor.
                </Text>
                <Button
                    variant={EButtonVariant.default}
                    className='mt-default py-md'
                    onClick={handleBan}
                >
                    Confirm
                </Button>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    modalIsOpen: Selectors.uiBanUserModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) => dispatch(Actions.uiSetBanUserModalIsOpen(state)),
    setUserToBan: (params: SetUserToBanParams) => dispatch(Actions.userReportSetUserToBanAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBanModal);
