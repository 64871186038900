import { TChartData } from './components';

export enum EUserTier {
    BEGINNER = 1,
    INTERMEDIATE = 2,
    ADVANCED = 3,
    MASTER = 4,
}

export enum EPostStage {
    DRAFT = 100,
    PENDING_VERIFICATION = 101,
    VERIFIED = 102,
    ADMIN_REMOVED = 103,
    REKOGNITION_REMOVED = 104,
    UNVERIFIED = 105,
    UNGRADED = 106,
    USER_REMOVED = 107,
    REPOST = 108,
}

export enum EPostType {
    PUBLIC = 1,
    PAID = 2,
}

export enum EPostRating {
    A = 4,
    B = 3,
    C = 2,
    D = 1,
}

export enum EPaymentReportStatus {
    COMPLETED = 1,
    PENDING = 2,
}

export enum EModerationStatus {
    PENDING = 1,
    APPROVED = 2,
    REJECTED = 3,
}

// todo: later need to change the category accordingly
export enum EModerationCategory {
    GORE = 1,
    NUDITY = 2,
    OFFENSIVE = 3,
    UNRELATED = 4,
    HARASSMENT = 5,
    BOOSTING = 6,
    COPYRIGHT = 7,
}

export enum EModerationTargetType {
    POST = 1,
    COMMENT = 2,
    USER = 3,
}

export enum EUserStatus {
    ActiveUser = 100,
    SuspendedUser = 200,
    DeletedUser = 300,
}

export enum ECommentStatus {
    ACTIVE = 1,
    ADMIN_REMOVED = 2,
    USER_REMOVED = 3,
}

export const EmptyIndexedResponse = {
    index: 0,
    maxIndex: 1,
    data: [],
};

export interface IUserDetails {
    _id?: string;
    username: string;
    displayName?: string;
    profileImageUrl?: string;
    userTier?: number;
    repRanking?: number;
    pointsGained?: number;
}

export interface IPaymentUserDetails extends IUserDetails {
    userId: string;
    dateJoined: string;
}

export type TPostDetails = {
    _id: string;
    title: string;
    content: string;
    createdAt: string;
    verificationDateEnd: string;
    postStage: number;
    postType: EPostType;
    topicId: string;
    topicName: string;
    timesReported: number;
    postRating: number;
};

export type TTopicDetails = {
    name: string;
};

export type TRepLeaderboardUser = {
    username: string;
    displayName?: string;
    points: number;
    userTier: EUserTier;
    profileImageUrl?: string;
};

export type TTotalPostSummary = {
    amount: number;
    data: TChartData[];
};

export type TPostComment = {
    _id: string;
    childComments: TPostChildComment[];
    content: string;
    commentType: number;
    commentStatus: number;
    createdAt: string;
    userDetails: IUserDetails;
    childCommentCount: number;
};

export type TPostChildComment = {
    _id: string;
    content: string;
    commentType: number;
    commentStatus: number;
    createdAt: string;
    userDetails: IUserDetails;
};

export type TPurchaseDetails = {
    _id: string;
    date: string;
    quantity: number;
    price: number;
    topicId: string;
    total: number;
    title: string;
    content: string;
    topicName: string;
};

export type TPaymentListingDetails = {
    _id: string;
    userDetails: IPaymentUserDetails;
    totalAmount: number;
    totalPaid: number;
    totalRemaining: number;
    status: EPaymentReportStatus;
    date: string;
};

export type TPaymentReportDetails = {
    totalPaid: number;
    paymentHistory: TPaymentHistory[];
    _id: string;
    totalOwed: number;
    balance: number;
    status: number;
    title: string;
    userId: string;
};

export type TPaymentHistory = {
    _id: string;
    parentId: string;
    title: string;
    scheduledPaymentDate: string;
    paidAmount: number;
};

export type TModerationDetails = {
    _id: string;
    reporterUserDetails: {
        _id: string;
        userTier: EUserTier;
        username: string;
        displayName?: string;
        profileImageUrl?: string;
    };
    targetPostDetails?: {
        _id: string;
        title: string;
        content: string;
    };
    targetCommentDetails?: {
        _id: string;
        content: string;
    };
    targetUserDetails?: {
        _id: string;
        userTier: EUserTier;
        username: string;
        displayName?: string;
        profileImageUrl?: string;
    };
    targetType: EModerationTargetType;
    reportCategory: EModerationCategory;
    status: EModerationStatus;
    createdAt: string;
};
