/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import Text from 'components/atoms/Text';

import cn from 'lib/TailwindMerge';
import { ETextVariant } from 'entities/components';

type CustomDateRangePickerProps = {
    startDate: Date | null;
    endDate: Date | null;
    onChange: (dates: [Date | null, Date | null]) => void;
    disabled?: boolean;
    className?: string;
};

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const CustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    const {
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    } = props;

    return (
        <div className='flex flex-col items-center justify-center gap-sm px-sm pb-sm'>
            <div className='flex w-full items-center justify-between gap-md'>
                <button
                    type='button'
                    className='focus:outline-none'
                    onClick={() => changeYear(date.getFullYear() - 1)}
                >
                    <FaChevronLeft size={15} />
                </button>
                <div>
                    <Text variant={ETextVariant.subheader}>
                        {date.getFullYear()}
                    </Text>
                </div>
                <button
                    type='button'
                    className='focus:outline-none'
                    onClick={() => changeYear(date.getFullYear() + 1)}
                >
                    <FaChevronRight size={15} />
                </button>
            </div>
            <div className='flex w-full items-center justify-between gap-md'>
                <button
                    type='button'
                    className='focus:outline-none'
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                >
                    <FaChevronLeft size={15} />
                </button>
                <div>
                    <Text variant={ETextVariant.subheader}>
                        {months[date.getMonth()]}
                    </Text>
                </div>
                <button
                    type='button'
                    className='focus:outline-none'
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                >
                    <FaChevronRight size={15} />
                </button>
            </div>
        </div>
    );
};

const CustomDateRangePicker = (props: CustomDateRangePickerProps): JSX.Element => {
    const {
        startDate,
        endDate,
        onChange,
        disabled,
        className,
    } = props;

    return (
        <div className={className}>
            <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
                disabled={disabled}
                maxDate={new Date()}
                dateFormat='dd MMM YY'
                wrapperClassName='w-full'
                className={cn(
                    'w-full',
                    'bg-glass',
                    'rounded-input',
                    'shadow-glass backdrop-blur-glass',
                    'border border-white',
                    'text-color-primary',
                    'p-[5px]',
                    'text-center font-general text-xs 2xl:text-base',
                    'focus:outline-none',
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    disabled ? 'opacity-50' : 'opacity-100',
                )}
                calendarClassName={cn(
                    'bg-glass',
                    'rounded-input',
                    'shadow-glass backdrop-blur-glass',
                    'border border-white',
                    'text-color-primary',
                    'p-2.5',
                    'text-center font-general',
                    'focus:outline-none',
                )}
                monthClassName={() => cn(
                    'bg-glass',
                    'rounded-input',
                    'shadow-glass backdrop-blur-glass',
                    'border border-white',
                    'text-color-primary',
                    'p-2.5',
                    'text-center font-general',
                    'focus:outline-none',
                )}
                renderCustomHeader={CustomHeader}
            />
        </div>
    );
};

CustomDateRangePicker.defaultProps = {
    disabled: false,
    className: '',
};

export default CustomDateRangePicker;
