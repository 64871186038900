import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';

import {
    GenericParams,
    GenericResponse,
    GetSystemConfigParams,
    GetSystemConfigResponse,
    ISystemConfigurationGateway,
    SetSystemConfigParams,
} from './SystemConfigurationBase';

export default class SystemConfigurationGateway extends ISystemConfigurationGateway {
    async setSystemConfig(params: GenericParams<SetSystemConfigParams>): GatewayResponse<GenericResponse> {
        const response: ApiResponse<GenericResponse> = await this.api.post(
            '/v1/a/systemConfig/setSystemConfig',
            params.params,
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }

    async getSystemConfig(params: GetSystemConfigParams): GatewayResponse<GetSystemConfigResponse> {
        const response: ApiResponse<GetSystemConfigResponse> = await this.api.get(
            '/v1/a/systemConfig/getSystemConfig',
            {},
            Utils.getHeaders(params.authToken),
        );

        return this.process(response);
    }
}
