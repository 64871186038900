import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import SubAdminGateway from 'api/SubAdmin';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { GetSubAdminListParamsPayload } from 'redux/slices/subAdmin/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetSubAdminList(api: SubAdminGateway): SagaWatcherReturnType {
    yield takeEvery('subAdmin/subAdminGetSubAdminListAttempt', handleGetSubAdminList, api);
}

function* handleGetSubAdminList(api: SubAdminGateway, data: GetSubAdminListParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.getSubAdminList], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.subAdminGetSubAdminListFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.subAdminGetSubAdminListSuccess(response.data));
    }
}
