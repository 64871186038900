import { GetSubAdminListResponse } from 'api/SubAdminBase';

import { SubAdminState } from '.';

const getSubAdminListAttempting = (state: SubAdminState): boolean => state.actions.getSubAdminList || false;
const getSubAdminListError = (state: SubAdminState): string => state.error.getSubAdminList || '';
const getSubAdminListData = (state: SubAdminState): GetSubAdminListResponse => state.subAdminList;

const setSubAdminAccessAttempting = (state: SubAdminState): boolean =>
    state.actions.setSubAdminAccess || false;
const setSubAdminAccessError = (state: SubAdminState): string => state.error.setSubAdminAccess || '';
// const setSubAdminAccessData = (state: SystemConfigurationState): number => state.globalCommissionRate;

const editSubAdminAccessAttempting = (state: SubAdminState): boolean =>
    state.actions.editSubAdminAccess || false;
const editSubAdminAccessError = (state: SubAdminState): string => state.error.editSubAdminAccess || '';
// const editSubAdminAccessData = (state: SystemConfigurationState): number => state.maxPostPrice;

export default {
    getSubAdminListAttempting,
    getSubAdminListError,
    getSubAdminListData,

    setSubAdminAccessAttempting,
    setSubAdminAccessError,

    editSubAdminAccessAttempting,
    editSubAdminAccessError,
};
