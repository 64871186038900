import React, { TableHTMLAttributes } from 'react';

import cn from 'lib/TailwindMerge';

type TableProps = TableHTMLAttributes<HTMLTableElement> & {
    tableHeader: string[];
    tableData: React.ReactNode;
    expandedColumns?: number;
    expandedWidth?: string;
};

const Table = (props: TableProps): JSX.Element => {
    const {
        tableHeader,
        tableData,
        className,
        expandedColumns,
        expandedWidth,
        ...otherProps
    } = props;

    const renderHeader = () => {
        return tableHeader.map((header, index) => {
            return (
                <th
                    key={header}
                    className={cn(
                        'py-[15px] text-sm 2xl:text-base font-general-bold',
                        (index === expandedColumns ?? 0) ? cn('pl-default', expandedWidth) : 'text-center',
                    )}
                >
                    {header}
                </th>
            );
        });
    };

    return (
        <table
            className={cn(
                'table-fixed text-color-primary w-full',
                className,
            )}
            {...otherProps}
        >
            <thead className='border-y border-white'>
                <tr>
                    {renderHeader()}
                </tr>
            </thead>
            <tbody>
                {tableData}
            </tbody>
        </table>
    );
};

Table.defaultProps = {
    expandedColumns: 0,
    expandedWidth: 'w-1/3',
};

export default Table;
