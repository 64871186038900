import React from 'react';

import { connect } from 'react-redux';

import ContentLayout from 'components/layout/ContentLayout';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { EMenuList } from 'entities/ui';

import SubadminSetting from './subadmin';
import SystemSetting from './system';

interface HomeScreenProps {
    currentPage: EMenuList;
}

const SettingScreen = (props: HomeScreenProps): JSX.Element => {
    const {
        currentPage,
    } = props;

    const renderSettings = () => {
        switch (currentPage) {
            case EMenuList.systemSetting:
                return <SystemSetting />;
            case EMenuList.subadminSetting:
                return <SubadminSetting />;
            default:
                return <SystemSetting />;
        }
    };

    return (
        <ContentLayout>
            {renderSettings()}
        </ContentLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentPage: Selectors.uiGetCurrentPage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    logout: () => dispatch(Actions.authLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingScreen);
