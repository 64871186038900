import { fork } from 'redux-saga/effects';

import SubAdminGateway from 'api/SubAdmin';
import { RootSagaReturnType } from 'sagas/types';

import watchEditSubAdminAccess from './editSubAdminAccess';
import watchGetSubAdminList from './getSubAdminList';
import watchRevokeSubAdminAccess from './revokeSubAdminAccess';
import watchSetSubAdminAccess from './setSubAdminAccess';

export default (api: SubAdminGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetSubAdminList, api);
        yield fork(watchSetSubAdminAccess, api);
        yield fork(watchEditSubAdminAccess, api);
        yield fork(watchRevokeSubAdminAccess, api);
    }

    return {
        rootSaga,
    };
};
