import React from 'react';

import { Line, LineChart, ResponsiveContainer } from 'recharts';

import { TChartData } from 'entities/components';

type CustomLineChartProps = {
    data: TChartData[];
};

const CustomLineChart = (props: CustomLineChartProps): JSX.Element => {
    const { data } = props;
    return (
        <ResponsiveContainer width='100%' height='100%'>
            <LineChart
                data={data}
            >
                <Line
                    type='monotone'
                    dataKey='counts'
                    dot={false}
                    stroke='#54DE90'
                    strokeWidth={2}
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default CustomLineChart;
