import React, { useEffect, useRef, useState } from 'react';

import throttle from 'lodash.throttle';
import { FaPlus } from 'react-icons/fa';
import { connect } from 'react-redux';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';

import { GetSubAdminListParams, GetSubAdminListResponse, SetSubAdminAccessParams } from 'api/SubAdminBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { EButtonVariant, EInputVariant, ETextVariant } from 'entities/components';

import SetSubAdminModal from './components/index/SetSubAdminModal';
import SubadminReportTable from './components/index/SubadminReportTable';

type SubadminAccessProps = {
    getSubAdminListAttempting: boolean;
    getSubAdminListError: string;
    subAdminList: GetSubAdminListResponse;

    setSubAdminAccessAttempting: boolean;
    setSubAdminAccessError: string;

    setAddSubAdminModalIsOpen: (state: boolean) => void;
    getSubAdminList: (params: GetSubAdminListParams) => void;
    setSubAdminAccess: (params: SetSubAdminAccessParams) => void;
};

const SubadminSetting = (props: SubadminAccessProps): JSX.Element => {
    const {
        getSubAdminListAttempting,
        getSubAdminListError,
        setSubAdminAccessAttempting,
        setSubAdminAccessError,

        setAddSubAdminModalIsOpen,
        getSubAdminList,
        setSubAdminAccess,
        subAdminList,

    } = props;

    const [searchQuery, setSearchQuery] = useState<string>('');

    const throttledSetSearch = useRef(
        throttle(
            (searchWord: string) => {
                setSearchQuery(searchWord);
            },
            500,
            { leading: false },
        ),
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        throttledSetSearch.current(value);
    };

    useEffect(() => {
        getSubAdminList({
            searchQuery,
        });
    }, [searchQuery]);

    return (
        <div className='flex flex-col gap-default 2xl:gap-xl'>
            <div className='flex w-full items-center justify-between'>
                <Text variant={ETextVariant.header} className='font-heading-bold text-[50px] 2xl:text-[60px]'>
                    SUBADMIN ACCESS
                </Text>
                <Button
                    variant={EButtonVariant.default}
                    className='flex w-[200px] items-center gap-sm px-md'
                    onClick={() => setAddSubAdminModalIsOpen(true)}
                >
                    <FaPlus />
                    <Text variant={ETextVariant.default} className='font-general-medium'>
                        New Subadmin
                    </Text>
                </Button>
            </div>
            <div className='flex flex-col gap-md'>
                <Input
                    className='w-[200px] 2xl:w-[300px]'
                    variant={EInputVariant.searchbar}
                    placeholder='Search...'
                    onChange={handleSearch}
                />
                <SubadminReportTable subAdminList={subAdminList} loading={false} />
            </div>
            <SetSubAdminModal />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.authGetLoginAttempting(state),
    // error: Selectors.authGetLoginError(state),
    getSubAdminListAttempting: Selectors.subAdminGetSubAdminListAttempting(state),
    getSubAdminListError: Selectors.subAdminGetSubAdminListError(state),
    subAdminList: Selectors.subAdminGetSubAdminListData(state),

    setSubAdminAccessAttempting: Selectors.subAdminSetSubAdminAccessAttempting(state),
    setSubAdminAccessError: Selectors.subAdminSetSubAdminAccessError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getSubAdminList: (params: GetSubAdminListParams) => dispatch(Actions.subAdminGetSubAdminListAttempt(params)),
    setSubAdminAccess: (params: SetSubAdminAccessParams) => dispatch(Actions.subAdminSetSubAdminAccessAttempt(params)),
    setAddSubAdminModalIsOpen: (state: boolean) => dispatch(Actions.uiSetAddSubadminModalIsOpen(state)),
    logout: () => dispatch(Actions.authLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubadminSetting);
