import React from 'react';

import { renderImage } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { EUserTier } from 'entities/report';

type UserProfileImageProps = {
    userTier?: EUserTier,
    profileImageUrl?: string,
    className?: string,
    size?: number,
}

const UserProfileImage = (props: UserProfileImageProps): JSX.Element => {
    const {
        userTier,
        profileImageUrl,
        className,
        size,
    } = props;

    const ringColor = ():string => {
        switch (userTier) {
            case EUserTier.MASTER:
                return 'border-user-tier-master';
            case EUserTier.ADVANCED:
                return 'border-user-tier-advanced';
            case EUserTier.INTERMEDIATE:
                return 'border-user-tier-intermediate';
            case EUserTier.BEGINNER:
                return 'border-user-tier-beginner';
            default:
                return 'border-user-tier-beginner';
        }
    };

    return (
        <div className={cn(
            'size-full',
            'flex items-center justify-center',
            'p-2 2xl:p-[1rem] rounded-[50%]',
            'border-2',
            ringColor(),
            className,
        )}
        >
            {renderImage(profileImageUrl, size)}
        </div>
    );
};

UserProfileImage.defaultProps = {
    userTier: EUserTier.BEGINNER,
    profileImageUrl: '',
    className: '',
    size: 20,
};

export default UserProfileImage;
