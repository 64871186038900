export enum EButtonVariant {
    default = 0,
    link = 1,
    ghost = 2,
    danger = 3,
    warning = 4,
    disabled = 5,
}

export enum EInputVariant {
    default = 0,
    password = 1,
    searchbar = 2,
    checkbox = 4,
    ghost = 5,
}

export enum ETextVariant {
    default = 0,
    header = 1,
    subheader = 2,
    caption = 3,
    error = 5,
}

export enum EContainerVariant {
    default = 0,
    glass = 1,
    glassBorder = 2,
    gradientBorder = 3,
    modal = 4,
}

export enum EBadgeVariant {
    default = 0,
    success = 1,
    warning = 2,
    danger = 3,
    topic = 4,
    grade = 5,
    paymentStatus = 6,
}

export enum ESelectVariant {
    default = 0,
    filter = 1,
}

export type TChartData = {
    date: string;
    counts: number;
}

export type TPostChartData = {
    count: number;
    week: number;
}

export type TPurchasesChartData = {
    date: string;
    amount: number;
}

export type TDateFilter = [Date | null, Date | null];

export type TDateFilterString = [string, string];

export type TDropdown = {
    text: string;
    onClick: () => void;
}
