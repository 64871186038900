import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'typed-redux-saga/macro';

import AuthGateway from 'api/Auth';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { LoginActionParamsPayload } from 'redux/slices/auth/types';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { EAuthRole } from 'entities/auth';

export default function* watchLogin(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authLoginAttempt', handleLogin, api);
}

function* handleLogin(api: AuthGateway, data: LoginActionParamsPayload) {
    const { email, password, rememberMe } = data.payload;

    if (!email || !password) {
        yield put(Actions.authLoginFailure('Please enter your email and password'));
        return;
    }

    const response = yield* call([api, api.login], {
        email,
        password,
        rememberMe,
    });

    if (response.status === GatewayResponseStatus.Error) {
        toast.error(response.message);
        yield put(Actions.authLoginFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            const { token, user, exp } = response.data;

            if (user.role !== EAuthRole.User) {
                Utils.Auth.storeAuthToken(token);
                Utils.Auth.storeUserEmail(user.email);
                Utils.Auth.storeExpiresAt(exp.toString());
                yield put(Actions.authSetUserInfo(user));
                yield put(Actions.authLoginSuccess(token));

                toast.success('Login Successful');
                NavActions.navToHome();
            }

            if (user.role === EAuthRole.User) {
                toast.error('You do not have permission to access this page.');
            }
        } else {
            yield put(Actions.authLoginFailure('Something went wrong. Please try again.'));
        }
    }
}
