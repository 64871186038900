import React from 'react';

import { RotatingLines } from 'react-loader-spinner';

import Text from 'components/atoms/Text';
import Paginator from 'components/molecules/Paginator';

import { GenericIndexedResponse, GetUserGradesResponse } from 'api/UserReportBase';

import { ETextVariant } from 'entities/components';

import UserGradesTable from '../components/userDetails/UserGradesTable';

type UserGradesTabProps = {
    userGrades: GenericIndexedResponse<GetUserGradesResponse>;
    page: number;
    setPage: (page: number) => void;
    loading?: boolean;
    error?: string;
};
const UserGradesTab = (props: UserGradesTabProps): JSX.Element => {
    const {
        userGrades,
        page,
        setPage,
        loading,
        error,
    } = props;

    if (error) {
        return (
            <div className='flex items-center justify-center'>
                <Text variant={ETextVariant.error}>
                    {error}
                </Text>
            </div>
        );
    }

    if (loading) {
        return (
            <div className='flex items-center justify-center'>
                <RotatingLines
                    strokeColor='white'
                    strokeWidth='5'
                    animationDuration='0.75'
                    width='100'
                    visible
                />
            </div>
        );
    }

    return (
        <div>
            <UserGradesTable
                userGradesData={userGrades.data}
                loading={loading}
            />
            <Paginator
                currentPage={page}
                maxPage={(userGrades && userGrades.maxIndex !== 0 && userGrades.maxIndex) || 0}
                onFirst={() => page !== 0 && setPage(0)}
                onLast={() => userGrades.maxIndex !== 0 && setPage((userGrades && userGrades.maxIndex) || 0)}
                onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                onNext={() => userGrades.maxIndex !== 0 && setPage(page !== userGrades.maxIndex ? page + 1 : page)}
            />
        </div>
    );
};

UserGradesTab.defaultProps = {
    loading: false,
    error: '',
};

export default UserGradesTab;
