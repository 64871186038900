import Gateway from 'api/types/Gateway';

import { GatewayResponse } from './types/types';

export type GenericParams<T> = {
    authToken: string;
    params: T;
};

// ! Params
export type SetSystemConfigParams = {
    postTextMaxChar: number;
    commissionRate: number;
    A: number;
    B: number;
    C: number;
    D: number;
};

export type GetSystemConfigParams = {
    authToken: string;
}

// ! Response
export type GenericResponse = string | undefined;

export type GetSystemConfigResponse = {
    _id: string;
    __v: number;
    createdAt: string;
    updatedAt: string;
    postMaxPrice: number;
    postTextMaxChar: number;
    commissionRate: number;
    postGradingAScore: number;
    postGradingBScore: number;
    postGradingCScore: number;
    postGradingDScore: number;
};

export abstract class ISystemConfigurationGateway extends Gateway {
    abstract setSystemConfig(
        params: GenericParams<SetSystemConfigParams>,
    ): GatewayResponse<GenericResponse>;

    abstract getSystemConfig(
        params: GetSystemConfigParams,
    ): GatewayResponse<GetSystemConfigResponse>;
}
