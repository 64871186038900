import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PostReportGateway from 'api/PostReport';
import PurchaseReportGateway from 'api/PurchaseReport';
import TopicReportGateway from 'api/TopicReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { GetPostReportParamsPayload } from 'redux/slices/postReport/types';
import { GetPurchaseReportParamsPayload } from 'redux/slices/purchaseReport/types';
import { AddTopicParamsPayload, EditTopicParamsPayload, GetTopicsParamsPayload } from 'redux/slices/topicReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchEditTopic(api: TopicReportGateway): SagaWatcherReturnType {
    yield takeEvery('topicReport/topicReportEditTopicAttempt', handleEditTopic, api);
}

function* handleEditTopic(api: TopicReportGateway, data: EditTopicParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.editTopic], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.topicReportEditTopicFailure(response.message || ''));
        yield put(Actions.uiSetEditTopicModalIsOpen(false));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.topicReportEditTopicSuccess());
        yield put(
            Actions.topicReportGetTopicsAttempt({
                index: 0,
                searchQuery: '',
            }),
        );
        yield put(Actions.uiSetEditTopicModalIsOpen(false));
        toast.success('Topic updated successfully!');
    }
}
