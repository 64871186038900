import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import _ from 'lodash';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Modal from 'components/molecules/Modal';
import UserProfileImage from 'components/molecules/UserProfileImage';

import {
    GetPostModerationDetailsParams,
    GetPostModerationDetailsResponse,
    SetPostModerationReviewParams,
} from 'api/PostReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { renderModerationCategory } from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { EButtonVariant, ETextVariant } from 'entities/components';
import { EModerationStatus } from 'entities/report';

type PostModerationReviewModalProps = {
    reporterUserId: string;
    reportId: string;

    confirmReportAttempt: boolean;
    modalIsOpen: boolean;
    getPostModerationDetailsAttempt: boolean;
    getPostModerationDetailsError: string;

    postModerationDetails: GetPostModerationDetailsResponse;
    moderationStatus: EModerationStatus;

    setModalIsOpen: (state: boolean) => void;
    getPostModerationDetails: (params: GetPostModerationDetailsParams) => void;
    setPostModerationReview: (params: SetPostModerationReviewParams) => void;
};

const PostModerationReviewModal = (
    props: PostModerationReviewModalProps,
): JSX.Element => {
    const {
        reporterUserId,
        reportId,

        confirmReportAttempt,
        modalIsOpen,
        getPostModerationDetailsAttempt,
        getPostModerationDetailsError,
        getPostModerationDetails,
        postModerationDetails,
        moderationStatus,
        setModalIsOpen,
        setPostModerationReview,
    } = props;

    const { pathname } = useLocation();
    const postId = pathname.split('/').pop();

    useEffect(() => {
        if (!_.isEmpty(reporterUserId) && !_.isEmpty(reportId)) {
            getPostModerationDetails({
                reporterUserId,
                reportId,
            });
        }
    }, [reporterUserId, reportId]);

    const renderActionButton = () => {
        return (
            <Button
                variant={EButtonVariant.default}
                className={cn(
                    'mt-default py-4',
                    moderationStatus === EModerationStatus.APPROVED ? 'bg-success' : 'bg-error',
                )}
                loading={confirmReportAttempt}
                onClick={() => {
                    setPostModerationReview({
                        reportId: postModerationDetails._id,
                        moderationStatus,
                        postId: postId ?? '',
                    });
                }}
            >
                {moderationStatus === EModerationStatus.APPROVED ? 'Approve report' : 'Reject report'}
            </Button>
        );
    };
    if (!postModerationDetails) {
        return (
            <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
                <div className='flex items-center justify-center gap-default'>
                    <RotatingLines
                        strokeColor='white'
                        strokeWidth='5'
                        animationDuration='0.75'
                        width='100'
                        visible
                    />
                </div>
            </Modal>
        );
    }

    if (getPostModerationDetailsAttempt) {
        return (
            <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
                <div className='flex items-center justify-center gap-default'>
                    <RotatingLines
                        strokeColor='white'
                        strokeWidth='5'
                        animationDuration='0.75'
                        width='100'
                        visible
                    />
                </div>
            </Modal>
        );
    }

    return (
        <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
            <div
                className={cn(
                    'w-[650px] flex flex-col',
                    'items-center justify-center',
                    'gap-default p-xl',
                )}
            >
                <Text
                    variant={ETextVariant.header}
                    className='text-center font-heading-bold text-button-primary 2xl:text-[60px]'
                >
                    REPORT DETAILS
                </Text>
                <Text variant={ETextVariant.caption} className='text-center'>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor.
                </Text>
                <div
                    className={cn(
                        'w-full',
                        'rounded-[18px] outline-none',
                        'bg-transparent',
                        'border-2 border-white',
                        'resize-none p-7',
                        'flex flex-col gap-sm',
                    )}
                >
                    <div className='flex items-center gap-md'>
                        <Text className='self-start font-general-bold text-base 2xl:text-lg'>
                            Reporter :
                        </Text>
                        <div className='flex size-[50px] h-full 2xl:size-[60px] 3xl:size-[80px]'>
                            <UserProfileImage
                                profileImageUrl={
                                    postModerationDetails.reporter
                                        .profileImageUrl
                                }
                                userTier={postModerationDetails.reporter.userTier}
                                className='2xl:!p-2'
                            />
                        </div>
                        <div className='flex flex-col'>
                            <Text
                                variant={ETextVariant.default}
                                className='font-general-bold text-base 2xl:text-xl'
                            >
                                {postModerationDetails.reporter.displayName}
                            </Text>
                            <Text variant={ETextVariant.subheader}>
                                {postModerationDetails.reporter.username}
                            </Text>
                        </div>
                    </div>
                    <div className='flex items-center gap-md'>
                        <Text className='self-start font-general-bold text-base 2xl:text-lg'>
                            Report Category :
                        </Text>
                        <Text className='font-general-medium text-base 2xl:text-lg'>
                            {renderModerationCategory(
                                postModerationDetails.reportCategory,
                            )}
                        </Text>
                    </div>
                    <div className='flex items-center gap-md'>
                        <Text className='self-start font-general-bold text-base 2xl:text-lg'>
                            Report Date :
                        </Text>
                        <Text className='font-general-medium text-base 2xl:text-lg'>
                            {dayjs(postModerationDetails.reportDate).format(
                                'DD MMM YYYY',
                            )}
                        </Text>
                    </div>
                </div>
                <div className='flex w-full justify-between gap-lg'>
                    <Button
                        variant={EButtonVariant.default}
                        className='mt-default bg-slate-400 py-4 text-slate-800'
                        loading={confirmReportAttempt}
                        onClick={() => {
                            setModalIsOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    {renderActionButton()}
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    confirmReportAttempt:
        Selectors.userReportDeleteUserCommentAttempting(state),
    getPostModerationDetailsAttempt:
        Selectors.postReportGetPostModerationDetailsAttempting(state),
    getPostModerationDetailsError:
        Selectors.postReportGetPostModerationDetailsError(state),
    postModerationDetails:
        Selectors.postReportGetPostModerationDetailsData(state),
    modalIsOpen: Selectors.uiSetPostModerationReviewModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) =>
        dispatch(Actions.uiSetPostModerationReviewModalIsOpen(state)),
    getPostModerationDetails: (params: GetPostModerationDetailsParams) =>
        dispatch(Actions.postReportGetPostModerationDetailsAttempt(params)),
    setPostModerationReview: (params: SetPostModerationReviewParams) =>
        dispatch(Actions.postReportSetPostModerationReviewAttempt(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PostModerationReviewModal);
