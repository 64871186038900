import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PostReportGateway from 'api/PostReport';
import PurchaseReportGateway from 'api/PurchaseReport';
import TopicReportGateway from 'api/TopicReport';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { GetPostReportParamsPayload } from 'redux/slices/postReport/types';
import { GetPurchaseReportParamsPayload } from 'redux/slices/purchaseReport/types';
import {
    AddTopicParamsPayload,
    DeleteTopicParamsPayload,
    EditTopicParamsPayload,
    GetTopicsParamsPayload,
} from 'redux/slices/topicReport/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchDeleteTopic(api: TopicReportGateway): SagaWatcherReturnType {
    yield takeEvery('topicReport/topicReportDeleteTopicAttempt', handleDeleteTopic, api);
}

function* handleDeleteTopic(api: TopicReportGateway, data: DeleteTopicParamsPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.deleteTopic], {
        authToken,
        params: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.topicReportDeleteTopicFailure(response.message || ''));
        yield put(Actions.uiSetDeleteTopicModalIsOpen(false));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.topicReportDeleteTopicSuccess());
        yield put(
            Actions.topicReportGetTopicsAttempt({
                index: 0,
                searchQuery: '',
            }),
        );
        yield put(Actions.uiSetDeleteTopicModalIsOpen(false));
        toast.success('Topic deleted successfully!');
    }
}
