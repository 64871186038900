/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import throttle from 'lodash.throttle';
import { FaRegStar } from 'react-icons/fa';
import { IoDocumentOutline } from 'react-icons/io5';
import { connect } from 'react-redux';

import Container from 'components/atoms/Container';
import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import Text from 'components/atoms/Text';
import CustomAreaChart from 'components/molecules/CustomAreaChart';
import CustomDateRangePicker from 'components/molecules/CustomDateRangePicker';
import Paginator from 'components/molecules/Paginator';

import { GetUserGradesData } from 'api/mocks/DummyData';
import { GetModerationReportParams, GetModerationReportResponse } from 'api/ModerationReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import {
    EContainerVariant,
    EInputVariant,
    ESelectVariant,
    ETextVariant,
    TChartData,
    TDateFilterString,
} from 'entities/components';
import { EModerationStatus, EModerationTargetType } from 'entities/report';
import { BannedCategoriesLOV } from 'lov/report';

import ModerationTable from './components/index/ModerationTable';

type ModerationProps = {
    dateFilter: TDateFilterString;

    getModerationReportAttempt: boolean;
    getModerationReportError: string;
    moderationReport: GetModerationReportResponse;

    setDateFilter: (date: TDateFilterString) => void;
    getModerationReport: (params: GetModerationReportParams) => void;
}

const ModerationReport = (props: ModerationProps): JSX.Element => {
    const {
        dateFilter,
        getModerationReportAttempt,
        getModerationReportError,
        moderationReport,
        setDateFilter,
        getModerationReport,
    } = props;

    const [dateRangeFilter, setDateRangeFilter] = useState<[Date | null, Date | null]>([
        dayjs(dateFilter[0]).toDate(),
        dayjs(dateFilter[1]).toDate(),
    ]);

    const [startDate, endDate] = dateRangeFilter;
    const [page, setPage] = useState<number>(0);
    const [statusFilter, setStatusFilter] = useState<EModerationStatus>();
    const [typeFilter, setTypeFilter] = useState<EModerationTargetType>();

    useEffect(() => {
        if (startDate && endDate) {
            getModerationReport({
                index: page,
                dateFrom: dayjs(startDate).startOf('day').utc().format(),
                dateTo: dayjs(endDate).endOf('day').utc().format(),
                status: statusFilter ?? undefined,
                filter: typeFilter ?? undefined,
            });

            setDateFilter([dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]);
        }
    }, [startDate, endDate, page, statusFilter, typeFilter]);

    const handleValueChange = (value: number | string, filterType: 'status' | 'type') => {
        if (filterType === 'status') {
            setStatusFilter(value === 0 ? undefined : (value as EModerationStatus));
        } else if (filterType === 'type') {
            setTypeFilter(value === 0 ? undefined : (value as EModerationTargetType));
        }
        setPage(0);
    };

    const moderationStatuses = [
        {
            label: 'All',
            value: 0,
        },
        {
            label: 'Pending',
            value: EModerationStatus.PENDING,
        },
        {
            label: 'Approved',
            value: EModerationStatus.APPROVED,
        },
        {
            label: 'Rejected',
            value: EModerationStatus.REJECTED,
        },
    ];

    const moderationTargetTypes = [
        {
            label: 'All',
            value: 0,
        },
        {
            label: 'Comment',
            value: EModerationTargetType.COMMENT,
        },
        {
            label: 'Post',
            value: EModerationTargetType.POST,
        },
        {
            label: 'User',
            value: EModerationTargetType.USER,
        },
    ];

    return (
        <div className='flex flex-col gap-default 2xl:gap-xl'>
            <Text
                variant={ETextVariant.header}
                className='font-heading-bold text-[50px] 2xl:text-[60px]'
            >
                MODERATION REPORT
            </Text>
            <div className='flex flex-col gap-md'>
                <div className='flex justify-end'>
                    <div className='flex w-1/2 items-center gap-md'>
                        <CustomDateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(dates) => {
                                setDateRangeFilter(dates);
                            }}
                            className='relative w-full'
                        />
                        <Select
                            variant={ESelectVariant.filter}
                            placeholder='Status Filter'
                            options={moderationStatuses}
                            onValueChange={(value) => handleValueChange(value, 'status')}
                        />
                        <Select
                            variant={ESelectVariant.filter}
                            placeholder='Type Filter'
                            options={moderationTargetTypes}
                            onValueChange={(value) => handleValueChange(value, 'type')}
                        />
                    </div>
                </div>
                <div>
                    <ModerationTable
                        moderationReportData={moderationReport.data}
                        loading={getModerationReportAttempt || (endDate === null)}
                    />
                    <Paginator
                        currentPage={page}
                        maxPage={(moderationReport && moderationReport.maxIndex) || 0}
                        onFirst={() => setPage(0)}
                        onLast={() => setPage((moderationReport && moderationReport.maxIndex) || 0)}
                        onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                        onNext={() => setPage(page !== moderationReport.maxIndex ? page + 1 : page)}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    dateFilter: Selectors.uiGetModerationReportDateFilter(state),

    getModerationReportAttempt: Selectors.moderationReportGetModerationReportAttempting(state),
    getModerationReportError: Selectors.moderationReportGetModerationReportError(state),
    moderationReport: Selectors.moderationReportGetModerationReportData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDateFilter: (date: TDateFilterString) => dispatch(Actions.uiSetModerationReportDateFilter(date)),
    getModerationReport: (params: GetModerationReportParams) => dispatch(Actions.moderationReportGetModerationReportAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModerationReport);
