import React from 'react';

import { BiCommentDots } from 'react-icons/bi';
import { RxLockClosed } from 'react-icons/rx';

import Badge from 'components/atoms/Badge';
import Container from 'components/atoms/Container';
import Text from 'components/atoms/Text';

import { GetUserPostsResponse } from 'api/UserReportBase';

import { renderUserTier } from 'lib/RenderFunction';
import {
    EBadgeVariant,
    EContainerVariant,
    ETextVariant,
} from 'entities/components';

type UserPostCardProps = {
    post: GetUserPostsResponse;
    onClick?: () => void;
}

const UserPostCard = (props: UserPostCardProps): JSX.Element => {
    const {
        post,
        onClick,
    } = props;

    const renderLock = () => {
        if (post.postType === 2) {
            return (
                <div className='absolute -top-5 right-5 rounded-[50%] bg-white p-2.5'>
                    <RxLockClosed size={25} color='black' />
                </div>
            );
        }
        return null;
    };

    const renderGrade = () => {
        if (post.postRating) {
            return (
                <Badge
                    text={renderUserTier(post.postRating, true)}
                    variant={EBadgeVariant.grade}
                />
            );
        }
        return null;
    };

    const renderPostMedia = () => {
        if (post.postAttachmentUrl) {
            if (post.postAttachmentUrl.includes('.mp4' || '.mov')) {
                return (
                    <div className='w-full'>
                        <video
                            src={post.postAttachmentUrl}
                            controls
                            className='max-h-[300px] object-contain'
                        >
                            <track kind='captions' />
                        </video>
                    </div>
                );
            }
            return (
                <div className='w-full'>
                    <img
                        src={post.postAttachmentUrl}
                        alt='postImage'
                        className='max-h-[300px] object-contain'
                    />
                </div>
            );
        }
        return null;
    };
    return (
        <Container
            clickable
            onClick={onClick}
            variant={EContainerVariant.gradientBorder}
            className='relative flex flex-col gap-md p-md pb-[70px] text-color-primary'
        >
            {renderLock()}
            <div className='flex flex-row gap-md'>
                <div className='flex gap-md'>
                    <Badge text={post.topicName} variant={EBadgeVariant.topic} />
                    {renderGrade()}
                </div>
            </div>
            <Text
                variant={ETextVariant.default}
                className='break-words font-general-bold 2xl:text-lg'
            >
                {post.title}
            </Text>
            <Text
                variant={ETextVariant.subheader}
                className='w-inherit break-words'
            >
                {post.content}
            </Text>
            {renderPostMedia()}
            <div className='absolute bottom-[40px] left-0 w-full border-b border-b-slate-700' />
            <div className='absolute bottom-2 right-4 flex items-center gap-sm'>
                <BiCommentDots size={20} style={{ transform: 'scaleX(-1)' }} />
                {post.numberOfComments}
            </div>
        </Container>
    );
};

UserPostCard.defaultProps = {
    onClick: () => null,
};
export default UserPostCard;
