import React from 'react';

import cn from 'lib/TailwindMerge';
import { ETextVariant } from 'entities/components';

type HeaderTextProps = {
    children: React.ReactNode;
    variant?: ETextVariant;
    className?: string;
};

const Text = (props: HeaderTextProps): JSX.Element => {
    const { children, variant, className } = props;

    const variantClasses = {
        [ETextVariant.default]: 'font-general text-xs 2xl:text-base text-color-primary m-0',
        [ETextVariant.header]: 'font-heading text-[2rem] tracking-wider text-color-primary m-0',
        [ETextVariant.subheader]: 'font-general text-xs 2xl:text-sm text-color-primary m-0',
        [ETextVariant.caption]: 'font-general text-sm italic text-color-primary m-0',
        [ETextVariant.error]: 'font-general text-sm 2xl:text-base italic text-error m-0',
    };

    const ElementTag = (variant === ETextVariant.header) ? 'h1' : 'p';

    return (
        <ElementTag
            className={
                cn(
                    variantClasses[variant ?? ETextVariant.default],
                    className,
                )
            }
        >
            {children}
        </ElementTag>
    );
};

Text.defaultProps = {
    variant: ETextVariant.default,
    className: '',
};

export default Text;
