import React, { useState } from 'react';

import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';

import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';

import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import {
    renderModerationCategory,
    renderModerationStatus,
    renderModerationType,
} from 'lib/RenderFunction';
import cn from 'lib/TailwindMerge';
import { ETextVariant } from 'entities/components';
import { EModerationStatus, TModerationDetails } from 'entities/report';

import ModerationReportDetailsModal from './ModerationReportDetailsModal';

type ModerationTableProps = {
    moderationReportData: TModerationDetails[] | [];
    loading?: boolean;
    getModerationReportDetailsModalIsOpen: (state: boolean) => void;
};

const ModerationTable = (props: ModerationTableProps): JSX.Element => {
    const {
        moderationReportData,
        loading,
        getModerationReportDetailsModalIsOpen,
    } = props;

    const [moderationId, setModerationId] = useState<string>('');

    const getModerationReportDetailsClickHandler = (id: string) => {
        setModerationId(id);
        getModerationReportDetailsModalIsOpen(true);
    };

    const ModerationTableHeader = ['Reporter', 'Target', 'Report Type', 'Report Category', 'Report Date', 'Status'];

    const renderTableBody = () => {
        if (!moderationReportData || moderationReportData.length === 0) {
            return (
                <tr>
                    <td
                        colSpan={ModerationTableHeader.length}
                        className='py-xl text-center'
                    >
                        <Text variant={ETextVariant.default}>
                            There is no data! try adjusting the filters.
                        </Text>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return (
                <tr>
                    <td
                        colSpan={ModerationTableHeader.length}
                        className='py-xl text-center'
                    >
                        <div className='flex items-center justify-center gap-default'>
                            <RotatingLines
                                strokeColor='white'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='100'
                                visible
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        return moderationReportData?.map((moderation) => {
            return (
                <tr
                    key={moderation._id}
                    className='cursor-pointer hover:bg-glass'
                    onClick={() => getModerationReportDetailsClickHandler(moderation._id)}
                >
                    <td className='p-md'>
                        <div className='flex flex-col'>
                            <Text
                                variant={ETextVariant.default}
                                className='font-general-bold text-xl'
                            >
                                {moderation.reporterUserDetails.username}
                            </Text>
                        </div>
                    </td>
                    <td className='p-md'>
                        <div className='flex flex-col'>
                            <Text
                                variant={ETextVariant.default}
                                className='font-general-bold text-xl'
                            >
                                {moderation.targetPostDetails?.title || moderation.targetCommentDetails?.content || moderation.targetUserDetails?.username}
                            </Text>
                        </div>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{renderModerationType(moderation.targetType)}</Text>
                    </td>
                    <td className='p-md'>
                        <div className='flex flex-col'>
                            <Text
                                variant={ETextVariant.default}
                                className='font-general-bold text-xl'
                            >
                                {renderModerationCategory(moderation.reportCategory)}
                            </Text>
                        </div>
                    </td>
                    <td className='py-md text-center'>
                        <Text>{dayjs(moderation.createdAt).format('DD MMM YYYY')}</Text>
                        <Text>{dayjs(moderation.createdAt).format('h:mm A')}</Text>
                    </td>
                    <td className='py-md text-center'>
                        <Text
                            className={cn(
                                (moderation.status && moderation.status === EModerationStatus.REJECTED) && 'text-warning',
                                (moderation.status && moderation.status === EModerationStatus.APPROVED) && 'text-success',
                            )}
                        >
                            {renderModerationStatus(moderation.status)}
                        </Text>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div>
            <Table
                className='table-fixed'
                tableHeader={ModerationTableHeader}
                tableData={renderTableBody()}
                expandedColumns={1}
                expandedWidth='w-1/3'
            />
            <ModerationReportDetailsModal moderationId={moderationId} />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getModerationReportDetailsModalIsOpen: (state: boolean) => dispatch(Actions.uiGetModerationReportDetailsModalIsOpen(state)),
});

ModerationTable.defaultProps = {
    loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModerationTable);
