import { GetModerationReportDetailsResponse, GetModerationReportResponse } from 'api/ModerationReportBase';

import { ModerationReportState } from '.';

const getModerationReportAttempting = (state: ModerationReportState): boolean => state.actions.getModerationReport || false;
const getModerationReportError = (state: ModerationReportState): string => state.error.getModerationReport || '';
const getModerationReportData = (state: ModerationReportState): GetModerationReportResponse => state.moderationReport;

const getModerationReportDetailsAttempting = (state: ModerationReportState): boolean => state.actions.getModerationReportDetails || false;
const getModerationReportDetailsError = (state: ModerationReportState): string => state.error.getModerationReportDetails || '';
const getModerationReportDetailsData = (state: ModerationReportState): GetModerationReportDetailsResponse => state.moderationReportDetails;

const setModerationReportStatusAttempting = (state: ModerationReportState): boolean => state.actions.setModerationReportStatus || false;
const setModerationReportStatusError = (state: ModerationReportState): string => state.error.setModerationReportStatus || '';

export default {
    getModerationReportAttempting,
    getModerationReportError,
    getModerationReportData,

    getModerationReportDetailsAttempting,
    getModerationReportDetailsError,
    getModerationReportDetailsData,

    setModerationReportStatusAttempting,
    setModerationReportStatusError,
};
