import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import throttle from 'lodash.throttle';
import { FaRegStar } from 'react-icons/fa';
import { IoDocumentOutline } from 'react-icons/io5';
import { connect } from 'react-redux';

import Container from 'components/atoms/Container';
import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import Text from 'components/atoms/Text';
import CustomAreaChart from 'components/molecules/CustomAreaChart';
import CustomDateRangePicker from 'components/molecules/CustomDateRangePicker';
import Paginator from 'components/molecules/Paginator';

import { GetUserGradesData } from 'api/mocks/DummyData';
import { GetAllTopicsResponse, GetPostReportParams, GetPostReportResponse } from 'api/PostReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import {
    EContainerVariant,
    EInputVariant,
    ESelectVariant,
    ETextVariant,
    TChartData,
    TDateFilterString,
} from 'entities/components';
import { BannedCategoriesLOV } from 'lov/report';

import ChartCard from './components/index/ChartCard';
import PostTable from './components/index/PostTable';
import StatusCard from './components/index/StatusCard';

type PostsProps = {
    dateFilter: TDateFilterString;

    getPostReportAttempt: boolean;
    getPostReportError: string;
    postReport: GetPostReportResponse;

    getAllTopicsAttempt: boolean;
    getAllTopicsError: string;
    allTopics: GetAllTopicsResponse[]

    setDateFilter: (date: TDateFilterString) => void;
    getPostReport: (params: GetPostReportParams) => void;
    getAllTopics: () => void;
}

const PostReport = (props: PostsProps): JSX.Element => {
    const {
        dateFilter,
        getPostReportAttempt,
        getPostReportError,
        postReport,
        getAllTopicsAttempt,
        getAllTopicsError,
        allTopics,
        setDateFilter,
        getPostReport,
        getAllTopics,
    } = props;

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [dateRangeFilter, setDateRangeFilter] = useState<[Date | null, Date | null]>([
        dayjs(dateFilter[0]).toDate(),
        dayjs(dateFilter[1]).toDate(),
    ]);

    const [startDate, endDate] = dateRangeFilter;
    const [page, setPage] = useState<number>(0);
    const [topicFilter, setTopicFilter] = useState<string>('');

    useEffect(() => {
        if (startDate && endDate) {
            getPostReport({
                index: page,
                searchQuery,
                dateFrom: dayjs(startDate).startOf('day').utc().format(),
                dateTo: dayjs(endDate).endOf('day').utc().format(),
                topicId: topicFilter ?? undefined,
            });

            getAllTopics();

            setDateFilter([dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]);
        }
    }, [endDate, searchQuery, page, topicFilter]);

    useEffect(() => {
        setPage(0);
    }, [endDate, searchQuery, topicFilter]);

    const throttledSetSearch = useRef(throttle((searchWord: string) => {
        setSearchQuery(searchWord);
    }, 500, { leading: false }));

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        throttledSetSearch.current(value);
    };

    const handleValueChange = (value: number | string) => {
        setTopicFilter(value as string);
        setPage(0);
    };

    const AllTopicLOV = [{
        label: 'All',
        value: '',
    }].concat(allTopics.map((topic) => ({
        label: topic.name,
        value: topic._id,
    })));

    return (
        <div className='flex flex-col gap-default 2xl:gap-xl'>
            <Text
                variant={ETextVariant.header}
                className='font-heading-bold text-[50px] 2xl:text-[60px]'
            >
                POST REPORT
            </Text>
            <div className='flex flex-col gap-md'>
                <div className='flex justify-end'>
                    <CustomDateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(dates) => {
                            setDateRangeFilter(dates);
                        }}
                        className='w-menu'
                    />
                </div>
                <div className='grid grid-cols-3 gap-md'>
                    <div className='col-span-2 row-span-2'>
                        <ChartCard
                            value={postReport.totalPosts.amount}
                            label='Total Posts'
                            data={postReport.totalPosts.data}
                        />
                    </div>
                    <div className='col-span-1'>
                        <StatusCard
                            value={postReport.totalFeaturedPosts}
                            label='Featured Posts'
                            icon={<FaRegStar size={40} color='white' />}
                        />
                    </div>
                    <div className='col-span-1'>
                        <StatusCard
                            value={postReport.totalReportedPosts}
                            label='Post Reports'
                            icon={<IoDocumentOutline size={40} color='white' />}
                        />
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-md'>
                <div className='flex justify-between'>
                    <Text
                        variant={ETextVariant.default}
                        className='font-general-bold text-3xl'
                    >
                        All Posting
                    </Text>
                    <div className='flex items-center gap-md'>
                        <Input
                            variant={EInputVariant.searchbar}
                            placeholder='Search...'
                            onChange={handleSearch}
                            className='w-full'
                        />
                        <Select
                            variant={ESelectVariant.filter}
                            placeholder='Topic Filter'
                            options={AllTopicLOV}
                            onValueChange={handleValueChange}
                        />
                    </div>
                </div>
                <div>
                    <PostTable
                        postReportData={postReport.data}
                        loading={getPostReportAttempt || (endDate === null)}
                    />
                    <Paginator
                        currentPage={page}
                        maxPage={(postReport && postReport.maxIndex !== 0 && postReport.maxIndex) || 0}
                        onFirst={() => page !== 0 && setPage(0)}
                        onLast={() => postReport.maxIndex !== 0 && setPage((postReport && postReport.maxIndex) || 0)}
                        onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                        onNext={() => postReport.maxIndex !== 0 && setPage(page !== postReport.maxIndex ? page + 1 : page)}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    dateFilter: Selectors.uiGetPostReportDateFilter(state),

    getPostReportAttempt: Selectors.postReportGetPostReportAttempting(state),
    getPostReportError: Selectors.postReportGetPostReportError(state),
    postReport: Selectors.postReportGetPostReportData(state),

    getAllTopicsAttempt: Selectors.postReportGetAllTopicsAttempting(state),
    getAllTopicsError: Selectors.postReportGetAllTopicsError(state),
    allTopics: Selectors.postReportGetAllTopicsData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDateFilter: (date: TDateFilterString) => dispatch(Actions.uiSetPostReportDateFilter(date)),
    getPostReport: (params: GetPostReportParams) => dispatch(Actions.postReportGetPostReportAttempt(params)),
    getAllTopics: () => dispatch(Actions.postReportGetAllTopicsAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostReport);
