import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import throttle from 'lodash.throttle';
import { connect } from 'react-redux';

import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import CustomDateRangePicker from 'components/molecules/CustomDateRangePicker';
import Paginator from 'components/molecules/Paginator';

import { GetUserReportRepRanking } from 'api/mocks/DummyData';
import {
    DateParams,
    GenericIndexedResponse,
    GetRepLeaderboardParams,
    GetRepLeaderboardResponse,
    GetTotalUsersResponse,
    GetUserReportParams,
    GetUserReportResponse,
} from 'api/UserReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { EInputVariant, ETextVariant, TDateFilterString } from 'entities/components';
import { TRepLeaderboardUser } from 'entities/report';
import { ETotalUserType } from 'entities/ui';

import RepRankingCard from './components/index/RepRankingCard';
import TotalUserCard from './components/index/TotalUserCard';
import UserReportTable from './components/index/UserReportTable';

dayjs.extend(utc);
type UsersProps = {
    dateFilter: TDateFilterString;
    currentTab: ETotalUserType;
    getUserReportAttempt: boolean;
    getUserReportError: string;
    userReport: GenericIndexedResponse<GetUserReportResponse>;
    getTotalUsersAttempt: boolean;
    getTotalUsersError: string;
    totalUsers: GetTotalUsersResponse;
    getRepLeaderboardAttempt: boolean;
    getRepLeaderboardError: string;
    repLeaderboard: GenericIndexedResponse<GetRepLeaderboardResponse>;

    setDateFilter: (date: TDateFilterString) => void;
    getUserReport: (params: GetUserReportParams) => void;
    getTotalUsers: (params: DateParams) => void;
    getRepLeaderboard: (params: GetRepLeaderboardParams) => void;
}

const UserReport = (props: UsersProps): JSX.Element => {
    const {
        dateFilter,
        currentTab,
        getUserReportAttempt,
        getUserReportError,
        userReport,
        getTotalUsersAttempt,
        getTotalUsersError,
        totalUsers,
        getRepLeaderboardAttempt,
        getRepLeaderboardError,
        repLeaderboard,
        setDateFilter,
        getUserReport,
        getTotalUsers,
        getRepLeaderboard,
    } = props;

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [dateRangeFilter, setDateRangeFilter] = useState<[Date | null, Date | null]>([
        dayjs(dateFilter[0]).toDate(),
        dayjs(dateFilter[1]).toDate(),
    ]);

    const [startDate, endDate] = dateRangeFilter;
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        if (startDate && endDate) {
            getUserReport({
                index: page,
                searchQuery,
                stage: 199,
                dateFrom: dayjs(startDate).startOf('day').utc().format(),
                dateTo: dayjs(endDate).endOf('day').utc().format(),
            });

            getTotalUsers({
                dateFrom: dayjs(startDate).startOf('day').utc().format(),
                dateTo: dayjs(endDate).endOf('day').utc().format(),
            });

            getRepLeaderboard({
                index: 0,
                timeFrame: 3,
            });

            setDateFilter([dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]);

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [endDate, searchQuery, page]);

    useEffect(() => {
        setPage(0);
    }, [endDate, searchQuery]);

    const throttledSetSearch = useRef(throttle((searchWord: string) => {
        setSearchQuery(searchWord);
    }, 500, { leading: false }));

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        throttledSetSearch.current(value);
    };

    const chartData = (activeTab: ETotalUserType) => {
        if (activeTab === ETotalUserType.newUser) {
            return totalUsers.newUsersData.map((data) => {
                return {
                    ...data,
                    date: dayjs(data.date).format('DD MMM'),
                };
            });
        }
        return totalUsers.activeUsersData.map((data) => {
            return {
                ...data,
                date: dayjs(data.date).format('DD MMM'),
            };
        });
    };

    if (getUserReportError) {
        return (
            <div className='flex h-full items-center justify-center'>
                <Text
                    variant={ETextVariant.error}
                    className='font-bold'
                >
                    {getUserReportError}
                </Text>
            </div>
        );
    }

    return (
        <div className='flex flex-col gap-default 2xl:gap-xl'>
            <Text
                variant={ETextVariant.header}
                className='font-heading-bold text-[50px] 2xl:text-[60px]'
            >
                USER REPORT
            </Text>
            <div className='flex justify-between'>
                <Input
                    className='w-menu 2xl:w-[300px]'
                    variant={EInputVariant.searchbar}
                    placeholder='Search...'
                    onChange={handleSearch}
                />
                <CustomDateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(dates) => {
                        setDateRangeFilter(dates);
                    }}
                    className='w-menu'
                />
            </div>
            <div className='flex gap-xl'>
                <div className='flex-1'>
                    <UserReportTable
                        loading={getUserReportAttempt || (endDate === null)}
                        userReportData={userReport?.data}
                    />
                    <Paginator
                        currentPage={page}
                        maxPage={(userReport && userReport.maxIndex !== 0 && userReport.maxIndex) || 0}
                        onFirst={() => page !== 0 && setPage(0)}
                        onLast={() => userReport.maxIndex !== 0 && setPage((userReport && userReport.maxIndex) || 0)}
                        onPrev={() => setPage(page !== 0 ? page - 1 : page)}
                        onNext={() => userReport.maxIndex !== 0 && setPage(page !== userReport.maxIndex ? page + 1 : page)}
                    />
                </div>
                <div className='flex w-[400px] flex-col gap-default'>
                    <TotalUserCard
                        totalUsers={totalUsers.totalUsers}
                        newUsers={totalUsers.newUsers}
                        activeUsers={totalUsers.activeUsers}
                        chartData={chartData(currentTab)}
                        loading={getTotalUsersAttempt || (endDate === null)}
                        error={getTotalUsersError}
                    />
                    <RepRankingCard
                        leaderboard={repLeaderboard.data}
                        loading={getRepLeaderboardAttempt}
                        error={getRepLeaderboardError}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    dateFilter: Selectors.uiGetUserReportDateFilter(state),
    currentTab: Selectors.uiGetCurrentTotalUserType(state),

    getUserReportAttempt: Selectors.userReportGetUserReportAttempting(state),
    getUserReportError: Selectors.userReportGetUserReportError(state),
    userReport: Selectors.userReportGetUserReportData(state),

    getTotalUsersAttempt: Selectors.userReportGetTotalUsersAttempting(state),
    getTotalUsersError: Selectors.userReportGetTotalUsersError(state),
    totalUsers: Selectors.userReportGetTotalUsersData(state),

    getRepLeaderboardAttempt: Selectors.userReportGetRepLeaderboardAttempting(state),
    getRepLeaderboardError: Selectors.userReportGetRepLeaderboardError(state),
    repLeaderboard: Selectors.userReportGetRepLeaderboardData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDateFilter: (date: TDateFilterString) => dispatch(Actions.uiSetUserReportDateFilter(date)),
    getUserReport: (params: GetUserReportParams) => dispatch(Actions.userReportGetUserReportAttempt(params)),
    getTotalUsers: (params: DateParams) => dispatch(Actions.userReportGetTotalUsersAttempt(params)),
    getRepLeaderboard: (params: GetRepLeaderboardParams) => dispatch(Actions.userReportGetRepLeaderboardAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserReport);
