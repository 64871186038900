import React, { useEffect, useState } from 'react';

import { FiPercent } from 'react-icons/fi';
import { RotatingLines } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Toggle from 'components/atoms/Toggle';

import { GetPostDetailsResponse, SetFeaturedPostParams } from 'api/PostReportBase';
import { SetUserCommissionParams } from 'api/UserReportBase';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import {
    EButtonVariant,
    EContainerVariant,
    EInputVariant,
    ETextVariant,
} from 'entities/components';
import { EPostStage } from 'entities/report';

import DeletePostModal from '../components/postDetails/DeletePostModal';

type PostSettingTabProps = {
    postDetails: GetPostDetailsResponse;
    setFeaturedPost: (params: SetFeaturedPostParams) => void;
    setDeleteUserPostModalIsOpen: (state: boolean) => void;
    loading?: boolean;
    error?: string;
};

const PostSettingTab = (props: PostSettingTabProps): JSX.Element => {
    const { postDetails, setFeaturedPost, setDeleteUserPostModalIsOpen, loading, error } = props;

    const { pathname } = useLocation();
    const postId = pathname.split('/').pop();

    const [tempIsToggle, setTempIsToggle] = useState<boolean>(postDetails.details.isFeatured);

    const renderDeletePostButton = () => {
        if (postDetails.details.postStage === EPostStage.ADMIN_REMOVED) {
            return (
                <Button
                    variant={EButtonVariant.disabled}
                    className='size-fit px-md py-sm text-sm'
                    disabled
                >
                    Delete Post
                </Button>
            );
        }
        return (
            <Button
                variant={EButtonVariant.danger}
                className='size-fit px-md py-sm text-sm'
                onClick={() => setDeleteUserPostModalIsOpen(true)}
            >
                Delete Post
            </Button>
        );
    };

    if (loading) {
        return (
            <div className='flex items-center justify-center'>
                <RotatingLines
                    strokeColor='white'
                    strokeWidth='5'
                    animationDuration='0.75'
                    width='100'
                    visible
                />
            </div>
        );
    }
    if (error) {
        return (
            <div className='flex items-center justify-center'>
                <Text variant={ETextVariant.error}>{error}</Text>
            </div>
        );
    }
    return (
        <div className='flex flex-col gap-default'>
            <Container
                variant={EContainerVariant.gradientBorder}
                className='flex items-center justify-between p-default'
            >
                <div className='flex flex-col gap-sm'>
                    <Text
                        variant={ETextVariant.default}
                        className='font-general-bold text-base 2xl:text-lg'
                    >
                        Set As Featured post
                    </Text>
                    <Text
                        variant={ETextVariant.default}
                        className='text-xs 2xl:text-sm'
                    >
                        Set this post as featured for all users
                    </Text>
                </div>
                <div className='flex items-center gap-sm'>
                    <Toggle
                        isActive={tempIsToggle}
                        onClick={() => {
                            setTempIsToggle(!tempIsToggle);
                            setFeaturedPost({
                                isFeatured: !tempIsToggle,
                                postId: postId ?? '',
                            });
                        }}
                    />
                </div>
            </Container>
            <Container
                variant={EContainerVariant.gradientBorder}
                className='flex items-center justify-between p-default'
            >
                <div className='flex flex-col gap-sm'>
                    <Text
                        variant={ETextVariant.default}
                        className='font-general-bold text-base 2xl:text-lg'
                    >
                        Delete post
                    </Text>
                    <Text
                        variant={ETextVariant.default}
                        className='text-xs 2xl:text-sm'
                    >
                        Use this button to delete this post, use with caution!
                    </Text>
                </div>
                {renderDeletePostButton()}
            </Container>
            <DeletePostModal postId={postId ?? ''} />
        </div>
    );
};

PostSettingTab.defaultProps = {
    loading: false,
    error: '',
};

const mapStateToProps = (state: RootState) => ({
    postDetails: Selectors.postReportGetPostDetailsData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setFeaturedPost: (params: SetFeaturedPostParams) =>
        dispatch(Actions.postReportSetFeaturedPostAttempt(params)),
    setDeleteUserPostModalIsOpen: (state: boolean) => dispatch(Actions.uiSetDeleteUserPostModalIsOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostSettingTab);
